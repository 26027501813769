import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ContentManage } from './views/ContentManage';
import NovelReview from './views/containers/novelReviewContainers';
import IdentityVerification from './views/containers/identityContainers';
import IdentityDetail from './views/containers/identityDetailContainers';
import ReportManager from './views/containers/reportManagerContainers';
import ReportDetail from './views/containers/reportDetailContainers';
import withdrawalReview from './views/containers/withdrawalContainers';

export const auditRouters = (
    <ContentManage>
        <Switch>
            <Route path="/auditManage/novelReview" component={NovelReview}/>
            <Route path="/auditManage/identity" component={IdentityVerification}/>
            <Route path="/auditManage/identityDetail" component={IdentityDetail}/>
            <Route path="/auditManage/reportManager" component={ReportManager}/>
            <Route path="/auditManage/reportDetail/" component={ReportDetail}/>
            <Route path="/auditManage/withdrawalReview" component={withdrawalReview}/>
            <Redirect to="/auditManage/novelReview"/>
        </Switch>
    </ContentManage>
);