import  DayData  from '../DayData';
import { connect } from 'react-redux';
import * as dayDataAction from '../../actions/dayDataAction';

const mapStateToProps = (state: any) => ({
    data: state.data.dayData,
});

const mapDispatchToProps = {
    initPage: dayDataAction.initPage,
    updatePage: dayDataAction.updatePage,
    submitExport: dayDataAction.submitExport,
    exportModalVisible: dayDataAction.exportModalVisible,
    dayDataListDataSearch: dayDataAction.dayDataListDataSearch,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DayData);