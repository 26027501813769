import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 获取tag 列表
 */
export function getTagList(payload?: any) {
    return postJson('/noveltag/getNovelTagList', payload);
}

/**
 * 获取类型列表
 */
export function getTypeList(param?: any) {
    return postJson('/noveltype/getNovelTypeList', param);
}

/**
 * 添加/修改类型
 */
export function addEditType(param: any) {
    if (param.type === 'add') {
        return postJson('/noveltype/addNovelType', param);
    } else {
        return postJson('/noveltype/modifyNovelType', param);
    }
}

/**
 * 获取已有tag
 */
export function getHasTag(id: any) {
    return postJson('/noveltag/getNovelTagListByType', id);
}

/**
 * 导出type
 */
export function typeExport(param: any) {
    return postJsonForExport('/noveltype/getNovelTypeList/export', param, 'type.xlsx');
}

/**
 * 批量删除类型
 */
export function betchDelType(param: any[]) {
    return postJson('/noveltype/deleteNovelTypeBatch', param);
}

/**
 * 批量增加小说id
 */
export function batchAddNovelId(param: any[]) {
    return postJson('/noveltype/ModifyNovelBatchByType', param);
}