import NovelManager from '../NovelManager';
import { connect } from 'react-redux';
import * as novelAction from '../../actions/novelManagerAction';

const mapStateToProps = (state: any) => ({
    data: state.content.novelManager,
});

const mapDispatchToProps = {
    initNovelPage: novelAction.initNovelPage,
    getNovelSearchParam: novelAction.getNovelSearchParam,
    getUpdatePage: novelAction.getUpdatePage,
    exportModalVisible: novelAction.exportModalVisible,
    submitExport: novelAction.submitExport,
    getNovelChangeStateParam: novelAction.getNovelChangeStateParam,
    getDelNovelId: novelAction.getDelNovelId,
    goNovelList: novelAction.goNovelList,
    changeNovelUpdateStatus: novelAction.changeNovelUpdateStatus,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NovelManager);