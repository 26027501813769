import  FloorData  from '../FloorData';
import { connect } from 'react-redux';
import * as floorDataAction from '../../actions/floorDataAction';

const mapStateToProps = (state: any) => ({
    data: state.data.floorData,
});

const mapDispatchToProps = {
    initPage: floorDataAction.initPage,
    updatePage: floorDataAction.updatePage,
    submitExport: floorDataAction.submitExport,
    exportModalVisible: floorDataAction.exportModalVisible,
    dataListSearch: floorDataAction.dataListSearch,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FloorData);