enum ChapterActionTypeKeys {
    INIT_PAGE = 'CHAPTER_CONTENT_INIT_PAGE_CHAPTER_CONTENT',
    SEND_CHAPTER_ID = 'SEND_CHAPTER_ID_CHAPTER_CONTENT',
    SEND_NOVEL_VALUE = 'SEND_NOVEL_ID_CHAPTER_CONTENT',
    LOADING = 'CHAPTER_CONTENT_LOADING',
    GET_CHAPTER_CONTENT_LIST_CHAPTER_CONTENT = 'GET_CHAPTER_CONTENT_LIST_CHAPTER_CONTENT',
    GET_NOVEL_LIST_BY_NAME_CHAPTER_CONTENT = 'GET_NOVEL_LIST_BY_NAME_CHAPTER_CONTENT',
    GET_ALL_NOVEL_LIST_BY_NAME_CHAPTER_CONTENT = 'GET_ALL_NOVEL_LIST_BY_NAME_CHAPTER_CONTENT',
    DUMP_CHAPTER = 'DUMP_CHAPTER_CHAPTER_CONTENT',
    CHAPTER_SORT = 'CHAPTER_SORT',
    MODAL_VISIBLE = 'MODAL_VISIBLE_CHAPTER_CONTENT',
    DOWNLOAD_CHAPTER = 'DOWNLOAD_CHAPTER_CHAPTER_CONTENT'
}

export default ChapterActionTypeKeys;