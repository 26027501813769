import Activity  from '../Activity';
import { connect } from 'react-redux';
import * as activityAction from '../../action/activityAction';
import * as novelAction from '../../../content/actions/novelManagerAction';

const mapStateToProps = (state: any) => ({
    data: state.creation.activity
});

const mapDispatchToProps = {
    initPage: activityAction.initPage,
    getSearchParam: activityAction.getSearchParam,
    getUpdatePage: activityAction.getUpdatePage,
    exportModalVisible: activityAction.exportModalVisible,
    submitExport: activityAction.submitExport,
    getDelId: activityAction.getDelId,
    getVirtualVote: activityAction.getVirtualVote,
    getAddBtn: activityAction.getAddBtn,
    getNovelList: activityAction.getNovelList,
    getSearchNovelParam: activityAction.getSearchNovelParam,
    getAddNovelId: activityAction.getAddNovelId,
    goNovelList: novelAction.goNovelList,

};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Activity);