import novelReviewKey from '../actions/actionsTypeKey/novelReviewTypeKey';

// public action

export function initPage() {
    return { type: novelReviewKey.INIT_PAGE };
}

export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: novelReviewKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: novelReviewKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: novelReviewKey.SUBMIT_EXPORT, payload: value };
}

export function getSearchData(param: any) {
    return { type: novelReviewKey.GET_SEARCH_DATA, payload: param };
}

export function loading(value: boolean) {
    return { type: novelReviewKey.LOADING, payload: value };
}

// page action

export function getNovelTypeList(param: any) {
    return { type: novelReviewKey.GET_TYPE_LIST, payload: param };
}

export function getTabsKey(key: string) {
    return { type: novelReviewKey.GET_TABS_KEY, payload: key };
}

export function getNovelReviewList(list: any) {
    return { type: novelReviewKey.GET_NOVEL_REVIEW_LIST, payload: list };
}