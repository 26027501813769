import RecommendActionsTypeKey from '../action/actionsTypeKey/recommendActionsTypeKey';
import { ActionTypes } from '../action/actionsType/recommendActionsType';

interface Page {
    current: number;
    pageSize: number;
}

export interface StoreState {
    floorList: any[];
    starList: any[];
    loading: boolean;
    page: Page;
    sorter: any;
    total: number;
    floorLocationModal: boolean;
    floorModalVisible: boolean;
    starIdModal: boolean;
    starNameModal: boolean;
    searchData: any;
}

const initialState: StoreState = {
    floorList: [],
    starList: [],
    floorLocationModal: false,
    floorModalVisible: false,
    starIdModal: false,
    starNameModal: false,
    loading: false,
    page: {
        current: 1,
        pageSize: 999
    },
    sorter: {},
    total: 1,
    searchData: {theme: 'novel'},
};

export const dashboardReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case RecommendActionsTypeKey.INIT_PAGE:
            return initialState;

        case RecommendActionsTypeKey.OPEN_CLOSE_FLOOR_LOCATION_MODAL:
            return Object.assign({}, state, {
               floorLocationModal: action.payload
            });

        case RecommendActionsTypeKey.LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload,
            });

        case RecommendActionsTypeKey.OPEN_CLOSE_FLOOR_MODAL:
            return Object.assign({}, state, {
                floorModalVisible: action.payload
            });

        case RecommendActionsTypeKey.UPDATE_FLOOR_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case RecommendActionsTypeKey.GET_FLOOR_LIST:
            return Object.assign({}, state, {
                floorList: action.payload
            });

        case RecommendActionsTypeKey.OPEN_CLOSE_STAR_ID_MODAL:
            return Object.assign({}, state, {
               starIdModal: action.payload
            });

        case RecommendActionsTypeKey.OPEN_CLOSE_STAR_NAME_MODAL:
            return Object.assign({}, state, {
                starNameModal: action.payload
            });

        case RecommendActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case RecommendActionsTypeKey.GET_STAR_LIST:
            return Object.assign({}, state, {
                starList: action.payload
            });

        default:
            return state;
    }
};
