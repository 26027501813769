import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 获取tag列表
 */
export function getNovelTagList(value: any) {
    return postJson('/noveltag/getAllNovelTagList', value);
}

/**
 * 添加tag
 */
export function addEditTag(value: any) {
    if (value.type === 'add') {
        return postJson('/noveltag/addNovelTag', value.name);
    } else {
        return postJson('/noveltag/modifyNovelTag', value);
    }
}

/**
 *  修改上下架
 */
export function changeTagStatus(value: any) {
    return postJson('/noveltag/modifyNovelTagShelfStatus', value);
}

/**
 * 删除tag
 */
export function delTag(value: any[]) {
    return postJson('/noveltag/deleteNovelTagBatch', value);
}

/**
 * 导出tag 列表
 */
export function exportTagList(param: any) {
    return postJsonForExport('/noveltag/getNovelTagList/export', param, 'tagList.xlsx');
}

/**
 * 修改tag位置
 */
export function changeLocation(param: any) {
    return postJson('/noveltag/modifyTagLocation', param);
}

/**
 * 获取排序规则
 */
export function getSorter() {
    return postJson('/rule/getRuleList');
}

/**
 * 修改排序规则
 */
export function changeSorter(id: number) {
    return postJson('/rule/modifySelectedRule', id);
}

/**
 * 根据类型获取标签列表
 */
export function getTypeListByTag(id: any) {
    return postJson('/noveltype/getNovelTypeListByTag', id);
}

/**
 * 根据标签获取小说列表
 */
export function getNovelListByType(id: any) {
    return postJson('/novel/getNovelNameListByType', id);
}

/**
 * 批量移除小说标签
 */
export function betchRemoveType(param: any) {
    return postJson('/noveltype/removeNovelTypeBatchByTag', param);
}