enum FloorDataActionsTypeKey {
    EXPORT_MODAL_VISIBLE = 'FLOOR_DATA_EXPORT_MODAL_VISIBLE',
    PAGE_INIT = 'FLOOR_DATA_PAGE_INIT',
    SUBMIT_EXPORT = 'SUBMIT_FLOOR_DATA_EXPORT',
    LOADING = 'FLOOR_DATA_LOADING',
    GET_DATA_LIST = 'GET_FLOOR_DATA_LIST',
    DATA_LIST_SEARCH = 'FLOOR_DATA_LIST_DATA_SEARCH',
    UPDATE_PAGE = 'UPDATE_PAGE_FLOOR_DATA',
    GET_FLOOR_LIST = 'GET_FLOOR_LIST_FLOOR_DATA_PAGE',
}

export default FloorDataActionsTypeKey;