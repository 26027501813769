import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Table, Form, Row, Col,  Select, Spin, Input, DatePicker } from 'antd';

import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../reducers/chapterDataReducer';
import { getExportTitles } from '../../../base/getExportTitles';
import { changeToStamp } from '../../../base/dealWithTime';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;

interface ChapterDataProps {
    data: StoreState;
    initPage: () => void;
    submitExport: (value: any) => void;
    exportModalVisible: (value: boolean) => void;
    listDataSearch: (value: any) => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
}

interface ChapterDataState {
    startTime: string;
    endTime: string;
}

class ChapterData extends React.Component<ChapterDataProps & FormComponentProps, ChapterDataState> {

    state: ChapterDataState = {
        startTime: '',
        endTime: '',
    };

    componentDidMount() {
        this.props.initPage();
    }

    columns = () => {
        return [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                className: 'column-center',
            },
            {
                title: 'Novel ID',
                dataIndex: 'novelId',
                key: 'novelId',
                className: 'column-center',
            },
            {
                title: 'Novel Name',
                dataIndex: 'novelName',
                key: 'novelName',
                className: 'column-center',
                width: 350,
            },
            {
                title: 'Chapter Name',
                dataIndex: 'chapterName',
                key: 'chapterName',
                className: 'column-center',
            },
            {
                title: 'Read times',
                dataIndex: 'readCount',
                key: 'readCount',
                className: 'column-center',
                sorter: true,
                sortField: 'readCount',
            },
        ];
    }

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: moment(value._d).format('YYYY-MM-DD 00:00:00')});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: moment(value._d).format('YYYY-MM-DD 23:59:59')});
        } else {
            this.setState({endTime});
        }
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    clear = () => {
        this.props.form.resetFields();
        this.setState({startTime: ''});
        this.setState({endTime: ''});
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            startTime: this.state.startTime.length === 0 ? undefined : changeToStamp(this.state.startTime) / 1000,
            endTime: this.state.endTime.length === 0 ? undefined : changeToStamp(this.state.endTime) / 1000,
            tag: value.tag_search,
            category: value.category_search,
            chapterName: value.chapterName_search,
            novelName: value.novelName_search,
            novelId: value.novelId_search,
            device: value.device
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }

        this.props.listDataSearch(param);
    }

    render() {
        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const {data, updatePage } = this.props;
        const input = {
            style: {width: '100%', color: '#000'}
        };
        const form = {
            style: {margin: '10px 30px'}
        };

        const categoryList = [
            {key: 'All', value: 'All'},
            {key: 1, value: '玄幻'},
            {key: 0, value: '武侠'}
        ];

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormItem label="Date" {...form}>
                                    {getFieldDecorator('startTime')(
                                        <DatePicker
                                            allowClear={false}
                                            format={'DD-MM-YYYY 00:00:00'}
                                            placeholder={'Start Time'}
                                            style={{width : '48%'}}
                                            onChange={this.startTimeChange}
                                        />
                                    )}
                                    {getFieldDecorator('endTime')(
                                        <DatePicker
                                            allowClear={false}
                                            format={'DD-MM-YYYY 23:59:59'}
                                            placeholder={'End Time'}
                                            style={{width : '48%', marginLeft : '4%'}}
                                            onChange={this.endTimeChange}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Tag"
                                    {...form}
                                >
                                    {getFieldDecorator('tag_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {
                                                data.typeList.map(item => {
                                                    return <Option key={item.id}>{item.name}</Option>;
                                                })
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Category"
                                    {...form}
                                >
                                    {getFieldDecorator('category_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {
                                                categoryList.map((item: any) => {
                                                    return <Option key={item.key}>{item.value}</Option>;
                                                })
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Chapter Name"
                                    {...form}
                                >
                                    {getFieldDecorator('chapterName_search')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Novel Name"
                                    {...form}
                                >
                                    {getFieldDecorator('novelName_search')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Novel ID"
                                    {...form}
                                >
                                    {getFieldDecorator('novelId_search')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Device"
                                    {...form}
                                >
                                    {getFieldDecorator('device', {
                                        initialValue: 'All'
                                    })(
                                        <Select>
                                            <Option value="All">All</Option>
                                            <Option value="0">iOS</Option>
                                            <Option value="1">Android</Option>

                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                    <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport}/>
                    <Table
                        bordered={true}
                        rowKey="novelId"
                        columns={this.columns()}
                        style={{marginTop: '20px'}}
                        dataSource={data.dataList}
                        onChange={updatePage}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total: ${total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={getFieldsValue}
                        visible={data.exportModalVisible}
                        loading={data.loading}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                    />
                </Spin>
            </div>
        );
    }
}

export default Form.create()(ChapterData);
