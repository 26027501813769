import novelKey from '../actions/actionsTypeKey/novelManagerActionsTypeKey';
import {  ActionTypes } from '../actions/actionsType/novelManagerActionsType';

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    data: any[];
    novelMsg: any;
    total: number;
    page: PageData;
    sorter: any;
    typeList: any[];
    searchData: any;
    exportModalVisible: boolean;
    loading: boolean;
    keyAuthor: any[];
    goNovelList: boolean;
    goNovelDetail: boolean;
    novelCoverUrl: any;
}

const initialState: StoreState = {
    data: [],
    novelMsg: {},
    total: 0,
    typeList: [],
    searchData: {},
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    exportModalVisible: false,
    loading: false,
    keyAuthor: [],
    goNovelList: false,
    goNovelDetail: false,
    novelCoverUrl: ''
};

export const novelManagerReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case novelKey.INIT_NOVEL_PAGE:
            return initialState;

        case novelKey.GET_NOVEL_LIST:
            return Object.assign({}, state, {
                data: action.payload.data,
                total: action.payload.total
            });

        case novelKey.GET_NOVEL_TYPE_LIST:
            return Object.assign({}, state, {
                typeList: action.payload
            });

        case novelKey.GET_NOVEL_SEARCH_PARAM:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case novelKey.NOVEL_UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case novelKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload,
            });

        case novelKey.NOVEL_LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case novelKey.GET_KEY_AUTHOR_List:
            return Object.assign({}, state, {
                keyAuthor: action.payload
            });

        case novelKey.GO_NOVEL_LIST:
            return Object.assign({}, state, {
               goNovelList: action.payload
            });

        case novelKey.GO_NOVEL_DETAIL:
            return Object.assign({}, state, {
                goNovelDetail: action.payload
            });

        case novelKey.GET_NOVEL_MSG_TO_ID:
            return Object.assign({}, state, {
                novelMsg: action.payload
            });

        case novelKey.GET_NOVEL_COVERURL:
            return Object.assign({}, state, {
                novelCoverUrl: action.payload
            });

        default:
            return state;
    }
};