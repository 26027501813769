import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { StarListManage } from './views/StarListManager';
import MonthlyList from './views/containers/monthlyListContainers';
import QuarterlyList from './views/containers/quarterlyListContainers';

export const starListRouters = (
    <StarListManage>
        <Switch>
            <Route path="/starListManage/monthlyList" component={MonthlyList}/>
            <Route path="/starListManage/quarterlyList" component={QuarterlyList}/>
            <Redirect to="/starListManage/monthlyList"/>
        </Switch>
    </StarListManage>
);
