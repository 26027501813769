import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Table, Form, Row, Col, Popconfirm, Spin, Select } from 'antd';
import { Link } from 'react-router-dom';

import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../reducers/userContentreducer';
import { getExportTitles } from '../../../base/getExportTitles';

const FormItem = Form.Item;
const Option = Select.Option;

interface UserContentState {
    checked: boolean;
    deviceList: any[];
}

interface UserContentProps extends FormComponentProps {
    data: StoreState;
    initPage: () => void;
    userListSearch: (value: any) => void;
    requestExportList: (value?: any) => void;
    changeUserStatus: (id: number, checked: boolean) => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
}

class UserContent extends React.Component<UserContentProps, UserContentState> {

    state: UserContentState = {
        checked: true,
        deviceList: ['Android', 'iOS', 'web']
    };

    componentWillMount() {
        this.props.initPage();
    }

    columns = () => {
        return[
            {
                title: 'User ID',
                dataIndex: 'id',
                key: 'id',
                width: 80,
                className: 'column-center',
            },
            {
                title: 'User Name',
                dataIndex: 'name',
                key: 'name',
                className: 'column-center',
            },
            {
                title: 'Login',
                dataIndex: 'socialType',
                key: 'socialType',
                className: 'column-center',
            },
            {
                title: 'Device',
                dataIndex: 'deviceValue',
                key: 'deviceValue',
                className: 'column-center',
            },
            {
                title: 'Registration Date',
                dataIndex: 'createdAtDateValue',
                key: 'createdAtDateValue',
                className: 'column-center',
                sortField: 'createdAt',
                sorter: true
            },
            {
                title: 'Registration Time',
                dataIndex: 'createdAtTimeValue',
                key: 'createdAtTimeValue',
                className: 'column-center',
            },
            {
                title: 'Author',
                dataIndex: 'author',
                key: 'authorValue',
                className: 'column-center',
                render: (value: string|number) => (value === 1 ? 'Yes' : 'No')
            },
            {
                title: 'Member status',
                dataIndex: 'vipType',
                key: 'vipType',
                className: 'column-center',
                render: (value: string|number) => (value === 1 ? 'Yes' : 'No')
            },
            {
                title: 'Expire date',
                dataIndex: 'vipExpiredDateTime',
                key: 'vipExpiredDateTime',
                className: 'column-center',
                sorter: true,
                sortField: 'vipExpiredAt',
                render: (text: string) => (text || '--')
            },
            {
                title: 'Social Account',
                dataIndex: 'socialAccount',
                key: 'socialAccount',
                className: 'column-center',
            },
            {
                title: 'Status',
                dataIndex: 'enabled',
                key: 'enabledValue',
                className: 'column-center',
                render: (value: number, record: any) => (
                    <p>{value === 1 ? 'Enable' : 'Freeze'}</p>
                )
            },
            {
                title: 'Setting',
                dataIndex: 'setting',
                key: 'setting',
                className: 'column-center',
                render: (value: string|number, record: any) => {
                    return (
                        <div>
                            <p>
                                <Link to={`/userManage/userDetail/${record.id}`}>View8</Link>
                            </p>
                            <p>
                                <Popconfirm
                                     placement="topLeft"
                                     title="Are you sure change this status"
                                     okText="Yes"
                                     cancelText="No"
                                     onConfirm={() => { this.props.changeUserStatus(record.id, record.enabled); }}
                                >
                                    <span
                                        style={{color: '#2392FC', cursor: 'pointer'}}
                                    >
                                        {record.enabled === 1 ? 'Freeze' : 'Enable'}
                                    </span>
                                </Popconfirm>
                            </p>
                        </div>

                    );
                }
            }
        ];
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    onClear = () => {
        this.props.form.resetFields();
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param = {
            name: value.name,
            id: value.userId,
            device: value.device,
            vipType: value.member
        };
        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.userListSearch(param);
    }

    render() {
        const { getFieldDecorator, getFieldsValue} = this.props.form;
        const { data, updatePage } = this.props;

        const input = { style: {width: '100%', color: '#000'}};
        const form = { style: {margin: '10px 30px'}};

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormItem label="User ID" {...form}>
                                    {getFieldDecorator('userId')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>

                            <Col span={8}>
                                <FormItem
                                    label="Member status"
                                    {...form}
                                >
                                    {getFieldDecorator('member', {
                                        initialValue: 'All'
                                    })(
                                        <Select>
                                            <Option value="All">All</Option>
                                            <Option value="1">Yes</Option>
                                            <Option value="0">No</Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="User Name" {...form}>
                                    {getFieldDecorator('name')(
                                       <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Device"
                                    {...form}
                                >
                                    {getFieldDecorator('device', {
                                        initialValue: 'All'
                                    })(
                                        <Select>
                                            <Option value="All">All</Option>
                                            <Option value="1">iOS</Option>
                                            <Option value="0">Android</Option>
                                            <Option value="2">Web</Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>

                    <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.onClear} onExport={this.handleExport}/>

                    <Table
                        rowKey="id"
                        bordered={true}
                        columns={this.columns()}
                        dataSource={data.getUserList}
                        style={{marginTop: '20px'}}
                        onChange={updatePage}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total: ${total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={getFieldsValue}
                        visible={data.exportModalVisible}
                        loading={data.loading}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                    />
                </Spin>
            </div>
        );
    }
}

export default Form.create()(UserContent);
