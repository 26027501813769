import  NovelData  from '../NovelData';
import { connect } from 'react-redux';
import * as novelDataAction from '../../actions/novelDataAction';

const mapStateToProps = (state: any) => ({
    data: state.data.novelData
});

const mapDispatchToProps = {
    initPage: novelDataAction.initPage,
    updatePage: novelDataAction.updatePage,
    loading: novelDataAction.loading,
    novelDataListDataSearch: novelDataAction.novelDataListDataSearch,
    exportModalVisible: novelDataAction.exportModalVisible,
    submitExport: novelDataAction.submitExport,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NovelData);