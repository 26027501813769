import adBannerKey from '../actions/actionsTypeKey/adBannerActionTypeKey';
import {  ActionTypes } from '../actions/actionsType/adBannerActionType';

export interface StoreState {
    getBannerList: any[];
    novelList: any[];
    allNovelList: any[];
    loading: boolean;
    modalLoading: boolean;
    modalVisible: boolean;
    searchData: any;
    page: any;
}

const initialState: StoreState = {
    loading: false,
    modalLoading: false,
    modalVisible: false,
    getBannerList: [],
    novelList: [],
    allNovelList: [],
    searchData: {},
    page: {
        current: 1,
        pageSize: 1000,
    },
};

export const adBannerReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case adBannerKey.INIT_PAGE:
            return initialState;

        case adBannerKey.GET_ADBANNER_LIST:
            return Object.assign({}, state, {
                getBannerList: action.payload,
            });

        case adBannerKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload,
            });

        case adBannerKey.LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload,
            });

        case adBannerKey.MODAL_LOADING:
            return Object.assign({}, state, {
                modalLoading: action.payload,
            });

        case adBannerKey.MODAL_VISIBLE:
            return Object.assign({}, state, {
                modalVisible: action.payload,
            });

        case adBannerKey.GET_NOVEL_LIST:
            return Object.assign({}, state, {
                novelList: action.payload,
            });

        case adBannerKey.GET_ALL_NOVEL_LIST:
            return Object.assign({}, state, {
                allNovelList: action.payload,
            });

        default:
            return state;
    }
};