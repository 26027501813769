import moment from 'moment-timezone';
// export function timeZone() {
//     return  Math.abs(new Date().getTimezoneOffset());
// }

export function timeZone() {
    return  Math.abs(new Date().getTimezoneOffset());
}

export function formatDate(time: number, noSeconds: boolean, isSet: boolean = false) {
    const date = isSet ? new Date(time) : new Date(time);
    // const date = isSet ? new Date(time) : new Date(timeZone() === 480 ? time - 3600000 : time);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const seconds = date.getSeconds();
    return noSeconds ? formatTen(day) + '-' + formatTen(month) + '-' + year + ' ' + formatTen(hour) + ':' + formatTen(minute) :
    formatTen(day) + '-' + formatTen(month) + '-' + year + ' ' + formatTen(hour) + ':' + formatTen(minute) + ':' + formatTen(seconds);
}

export function formatDateAndTime(time: number, noDate: boolean) {
    const date = new Date(time);
    // const date = new Date(timeZone() === 480 ? time - 3600000 : time);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const seconds = date.getSeconds();
    return noDate ? formatTen(day) + '-' + formatTen(month) + '-' + year :
        formatTen(hour) + ':' + formatTen(minute) + ':' + formatTen(seconds);
}

export function timeReverseWithHour(time: string) {
    return timeReverse(time) + ' ' + time.split(' ')[1];
}

export function timeReverseWithNoHour(time: string) {
    return timeReverse(time);
}

export function timeReverseWithMonth(time: string) {
    const yy = timeReverse(time).split('-')[1];
    const mm = timeReverse(time).split('-')[2];
    return yy + '-' + mm;
}

function formatTen(num: number) {
    return num > 9 ? (num + '') : ('0' + num);
}

function timeReverse(time: string) {
    const yy = time.split(' ')[0].split('-')[2];
    const mm = time.split(' ')[0].split('-')[1];
    const dd = time.split(' ')[0].split('-')[0];
    return yy + '-' + mm + '-' + dd ;
}

export function changeToStamp(time: string) {
    let date = new Date(time.replace(/-/g, '/'));
    return date.getTime() - (new Date().getTimezoneOffset() / 60 + 7) * 3600 * 1000;
}

/**
 * 将时间戳转化为需要格式
 * @param {string | number} stamp  stamp: 后台过来的时间戳
 * @param {string} setting  setting: 'YYYY-MM-DD', 'HH:mm:ss', 'YYYY-MM-DD HH:mm:ss'
 */
export function changeStampToDate(stamp: any, setting: string) {
    let a = moment.unix(stamp);
    return a.tz('Asia/Jakarta').format(setting);
}

export function handleString (value: string) {
    if (value && value.startsWith('e')) {
        return value.substring(0, 7);
    } else {
        return value && value.substring(0, 6);
    }
}

export function getNowTime(time?: any, addMonth: number = 0) {
    let date;
    if (time) {
        date = new Date(time * 1000);
    } else {
        date = new Date();
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1 + addMonth;
    return formatTen(month) + '-' + year;
}