import AddWeb  from '../AddWeb';
import { connect } from 'react-redux';
import * as addWebAction from '../../actions/addWebAction';

const mapStateToProps = (state: any) => ({
    data: state.webManager.addWebManager,
});

const mapDispatchToProps = {
    addDataList: addWebAction.addDataList,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddWeb);