import  categoryTypeKey  from './actionsTypeKey/categoryTypeKey';

export function initPage() {
    return { type: categoryTypeKey.CATEGORY_INIT_PAGE };
}

export function getTypeList(list: any[]) {
    return { type: categoryTypeKey.GET_TYPE_LIST, payload: list };
}

export function getTypeSearchParam(type: any) {
    return { type: categoryTypeKey.GET_TYPE_SEARCH_PARAM, payload: type };
}

export function addEditType(param: any) {
    return { type: categoryTypeKey.GET_ADD_EDIT_TYPE, payload: param };
}

export function getEditTypeId(id: any) {
    return { type: categoryTypeKey.GET_TYPE_ID, payload: id };
}

export function getHasTagList(payload: any) {
    return { type: categoryTypeKey.GET_TYPE_HAS_TAG, payload: payload };
}

export function getDefaultDevice(payload: any[]) {
    return { type: categoryTypeKey.GET_TYPE_DEFAULT_DEVICE, payload: payload };
}

export function loading(bool: boolean) {
    return { type: categoryTypeKey.LOADING, payload: bool };
}

export function exportModalVisible(value: boolean) {
    return { type: categoryTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: categoryTypeKey.SUBMIT_EXPORT, payload: value };
}

export function getBetchDelTypeId(param: any[]) {
    return { type: categoryTypeKey.GET_BETCH_DEL_TYPE_ID, payload: param };
}

export function getTagList(param: any[]) {
    return { type: categoryTypeKey.GET_TAG_LIST, payload: param };
}

export function getBatchNovelId(param: any[]) {
    return { type: categoryTypeKey.GET_BETCH_ADD_NOVEL_ID, payload: param };
}

export function changeUpdataPage(pagination: any, filters: any, sorter: any) {
    return { type: categoryTypeKey.UPDATE_PAGE, payload: { pagination, sorter} };
}