import novelUpdateDataTypeKey from './actionsTypeKey/novelUpdateDataTypeKey';

export function initPage() {
    return {type: novelUpdateDataTypeKey.INIT_PAGE};
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: novelUpdateDataTypeKey.UPDATE_PAGE, payload: {pagination, sorter} };
}

export function searchDate(value: any) {
    return { type: novelUpdateDataTypeKey.SEARCH_DATA, payload: value };
}

export function loading(bol: boolean) {
    return { type: novelUpdateDataTypeKey.LOADING, payload: bol };
}

export function exportModalVisible(value: boolean) {
    return { type: novelUpdateDataTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: novelUpdateDataTypeKey.SUBMIT_NOVEL_UPDATE_EXPORT, payload: value };
}

export function getTypeList(value: {data: any, Update: number}) {
    return { type: novelUpdateDataTypeKey.GET_TYPE_DATA_LIST, payload: value };
}

export function getUpdateData(value: any) {
    return { type: novelUpdateDataTypeKey.GET_UPDATE_DATE, payload: value };
}