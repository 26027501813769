import { all, fork, takeEvery, select, call, put, take } from 'redux-saga/effects';
import activityKeys from '../../components/creation/action/actionsTypeKey/activityActionsTypeKey';
import * as activityServer from '../../servers/activitySercer';
import { getNovelTypeList, getNovelListByType } from '../../servers/recommendServer';
import * as activityAction from '../../components/creation/action/activityAction';
import { openNotification } from '../../components/widgets/Notification';

function* initPage() {
    yield getVoteList();
}

function* getVoteList() {
    const data = yield select();
    const activity = data.creation.activity;
    let param = {
        ...activity.page,
        ...activity.sorter,
        data: activity.searchData,
    };
    try {
        const { result } = yield call(activityServer.getVoteList, param);
        yield put(activityAction.getVoteList(result));
    } catch (err) {
        openNotification('error', 'Fail to get List');
    }
}

function* delVote() {
    while (true) {
        const { payload } = yield take(activityKeys.GET_DEL_ID);
        try {
            yield call(activityServer.delVote, payload);
            yield getVoteList();
        } catch (err) {
            openNotification('error', 'Fail to del');
        }
    }
}

function* changeVirtualNum() {
    while (true) {
        const { payload } = yield take(activityKeys.GET_VIRTUAL_VOTE);
        try {
            yield call(activityServer.changeVirturlNum, payload);
            yield getVoteList();
        } catch (err) {
            openNotification('error', 'Fail to change');
        }
    }
}

function* exportModel() {
    while (true) {
        const { payload } = yield take(activityKeys.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);
        const state = yield select();
        const activity = state.creation.activity;
        let param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            ...activity.sorter,
            data: activity.searchData,
        };
        try {
            yield put(activityAction.activityLoading(true));
            yield call(activityServer.exportList, param);
            yield put(activityAction.activityLoading(false));
            openNotification('success', 'Export success.');
            yield put(activityAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(activityAction.activityLoading(false));
        }
    }
}

function* getTypeList() {
    try {
        const { result } = yield call(getNovelTypeList);
        yield put(activityAction.getTypeList(result));
    } catch (err) {
        openNotification('error', 'Failed to get type list');
    }
}

function* getSearchNovel() {
    while (true) {
        const { payload } = yield take(activityKeys.GET_SEARCH_NOVEL_PARAM);
        payload.typeId = payload.typeId === 'All' ? '' : payload.typeId;
        let param = {
            name: payload.name,
            typeId: payload.typeId
        };
        try {
            const { result } = yield call(getNovelListByType, param);
            yield put(activityAction.getNovelList(result));
        } catch (err) {
            openNotification('error', 'Failed to get novel list');
        }
    }
}

function* addNovel() {
    while (true) {
        const { payload } = yield take(activityKeys.GET_ADD_NOVEL_ID);
        try {
            yield call(activityServer.addEventEssay, payload);
            openNotification('success', 'Success to add the novel');
            yield getVoteList();
        } catch (err) {
            openNotification('error', 'Failed to add the novel');
        }
    }
}

function* watchAddBtn() {
    yield takeEvery(activityKeys.ADD_BTN, getTypeList);
}

function* watchInitPage() {
    yield takeEvery(activityKeys.INIT_PAGE, initPage);
}

function* watchActivitySearch() {
    yield takeEvery(activityKeys.GET_SEARCH_DATA, getVoteList );
}

function* watchUpdatapage() {
    yield takeEvery(activityKeys.UPDATE_PAGE, getVoteList );
}

export function* watchActivitySaga() {
    yield all([
        fork(watchInitPage),
        fork(watchActivitySearch),
        fork(watchUpdatapage),
        fork(delVote),
        fork(changeVirtualNum),
        fork(exportModel),
        fork(watchAddBtn),
        fork(getSearchNovel),
        fork(addNovel),
    ]);
}