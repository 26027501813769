import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 获取用户列表
 */
export function getUserList(param: any) {
    return postJson('/user/getAppUserList', param);
}

/**
 * 根据id 获取用户详情
 */
export function getUserDetailById(id: string) {
    return postJson('/user/getUserDetailById', id);
}

/**
 * 启用用户状态
 */
export function enableUser(param: number) {
    return postJson('/user/enableUser', param);
}

/**
 * 禁用用户状态
 */
export function disableUser(param: number) {
    return postJson('/user/disableUser', param);
}

/**
 * 修改用户授权状态
 */
export function modifyAuthorizationStatus(param: number) {
    return postJson('/user/modifyAuthorizationStatus', param);
}

/**
 * 导出用户列表
 */
export function exportUserList(param: any) {
    return postJsonForExport('/user/getAppUserList/export', param, 'user.xlsx');
}
