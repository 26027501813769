import * as React from 'react';
import { Modal, Button, Input, Form, Spin, Select } from 'antd';

import { openNotification } from '../../widgets/Notification';

const {TextArea} = Input;
const FormItem = Form.Item;
const Option = Select.Option;

interface Props {
    loading: boolean;
    visible: boolean;
    closeModal: () => void;
    getFieldDecorator: any;
    getFieldsValue: any;
    addVersionData: (value: any) => void;
}

export class AddVersionModals extends React.Component<Props, {}> {

    add = () => {
        const value = this.props.getFieldsValue(['systemType', 'versionCode', 'versionName', 'availableType', 'updateDesc']);
        const param = {
            systemType: value.systemType,
            versionCode: value.versionCode,
            versionName: value.versionName,
            available: value.availableType,
            updateNote: value.updateDesc,
        };

        if (isNaN(param.versionCode)) {
            openNotification('error', 'Version Code must be a number.');
        } else {
            this.props.addVersionData(param);

        }
    }

    onCancel = () => {
        this.props.closeModal();
    }

    render() {
        const {loading, visible, getFieldDecorator} = this.props;
        const formList = [
            {type: 'versionCode', label: 'Version Code', isNumber: true},
            {type: 'versionName', label: 'Version Name'},
            {type: 'updateDesc', label: 'Content'},
        ];

        const typeList = [
            {key: 0, value: 'Android'},
            {key: 1, value: 'iOS'}
        ];

        const availableList = [
            {key: 1, value: 'Yes'},
            {key: 0, value: 'No'}
        ];

        return (
            <Modal
                mask={false}
                footer={null}
                visible={visible}
                title="Add Version"
                maskClosable={false}
                onCancel={this.onCancel}
            >
                <Spin spinning={loading}>
                    <Form>
                        <FormItem label="Device">
                            {getFieldDecorator('systemType', {
                                rules: [{
                                    required: true, message: 'please input type',
                                }]
                            })(
                                <Select>
                                    {typeList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                </Select>
                            )}
                        </FormItem>
                        <FormItem label="Available">
                            {getFieldDecorator('availableType', {
                                rules: [{
                                    required: true, message: 'please input type',
                                }]
                            })(
                                <Select>
                                    {availableList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                </Select>
                            )}
                        </FormItem>
                        {
                            formList.map((item: any, index: number) =>
                                <FormItem
                                    key={item.label}
                                    label={item.label}
                                >
                                    {getFieldDecorator(item.type, {
                                        rules: [{
                                            required: true,
                                            message: item.isNumber ? 'The number must be greater than 0' : 'please input ' + item.label,
                                            pattern: item.isNumber ?  /^[1-9]\d*$/ : null,
                                        }]
                                    })(
                                        item.label === 'Content' ?
                                        <TextArea rows={2}/> : <Input/>
                                    )}
                                </FormItem>
                            )
                        }
                        <FormItem>
                            <Button type="primary" icon="plus" onClick={this.add}>Submit</Button>
                        </FormItem>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}