import { take, fork, all, put, call, takeEvery, select } from 'redux-saga/effects';
import VersionContentActionTypeKeys from '../../components/setting/actions/actionsTypeKey/actionTypeKey';
import { openNotification } from '../../components/widgets/Notification';

import * as versionServer from '../../servers/versionServer';
import * as versionAction from '../../components/setting/actions/actions';
import { isNumber } from 'util';

function* initPage() {
    yield getVersionList();
}

function* getVersionList() {
    try {
        yield put(versionAction.loading(true));
        const state = yield select();
        const data = state.setting.versionContent;
        const param = {
            ...data.page,
            ...data.sorter,
            data: {...data.searchData}
        };
        let result = yield call(versionServer.getVersionList, param);
        yield put(versionAction.getVersionList({data: result.result.data, total: result.result.total}));
        yield put(versionAction.loading(false));
    } catch (error) {
        openNotification('error', 'Failed to get version list');
        yield put(versionAction.loading(false));
    }
}

function* watchInitPage() {
    yield takeEvery(VersionContentActionTypeKeys.VERSION_CONTNET_INIT_PAGE, initPage);
}

function* watchUpdatePageSize() {
    yield takeEvery(VersionContentActionTypeKeys.UPDATE_PAGE, getVersionList);
}

function* watchDeleteVersionList() {
    while (true) {
        try {
            const { payload } = yield take(VersionContentActionTypeKeys.DELETE_VERSION_LIST_DATA);
            yield put(versionAction.loading(true));
            yield call(versionServer.deleteVersionData, payload);
            yield put(versionAction.loading(false));
            openNotification('success', 'Delete success');
            yield getVersionList();
        } catch (err) {
            openNotification('error', 'Delete failed');
            yield put(versionAction.loading(false));
        }
    }
}

function* watchChangeVersionStatus() {
    while (true) {
        const { payload } = yield take(VersionContentActionTypeKeys.CHANGE_VERSION_STATUS);
        if (payload.available === 1) {
            yield call(versionServer.disableVersion, payload.id);
            openNotification('success', 'Change Success');
            yield getVersionList();
        } else {
            yield call(versionServer.enableVersion, payload.id);
            openNotification('success', 'Change Success');
            yield getVersionList();
        }
    }
}

function* watchAddVersionData() {
    while (true) {
        const { payload } = yield take(VersionContentActionTypeKeys.ADD_VERSION_DATA);
        if (isNumber(payload.versionCode) || isNumber(payload.forceVersion)) {
            openNotification('error', 'Version Code Or ForceVersion Code must be number');
        } else {
            try {
                yield call(versionServer.addVersionData, payload);
                openNotification('success', 'Add success');
                yield getVersionList();
                yield put(versionAction.addVersionModalVisible(false));
            } catch (err) {
                if (err.code === 998) {
                    openNotification('error', 'Content param no more than 500 words.');
                }
                openNotification('error', 'Parameters can\'t be empty');
            }
        }
    }
}

function* watchExport() {
    while (true) {
        const { payload } = yield take(VersionContentActionTypeKeys.SUBMIT_EXPORT);
        const state = yield select();
        const data = state.setting.versionContent;
        payload.titles.splice(-1, 1, {title: 'Available', index: 'availableValue'});
        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            data: {...data.searchData},
            ...data.sorter
        };
        try {
            yield put(versionAction.loading(true));
            yield call(versionServer.exportVersionList, param);
            yield put(versionAction.loading(false));
            yield put(versionAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed');
            yield put(versionAction.loading(false));
        }
    }
}

function* watchSearchData() {
    yield takeEvery(VersionContentActionTypeKeys.VERSION_LIST_DATA_SEARCH, getVersionList);
}

export function* watchVersionManageRootSaga() {
    yield all([
        fork(watchExport),
        fork(watchInitPage),
        fork(watchUpdatePageSize),
        fork(watchSearchData),
        fork(watchChangeVersionStatus),
        fork(watchAddVersionData),
        fork(watchDeleteVersionList),
    ]);
}