import { fork, all, takeEvery, call, put, select, take } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import identityKey from '../../components/audit/actions/actionsTypeKey/identityTypeKey';
import * as identityAction from '../../components/audit/actions/identityAction';
import * as identityServer from '../../servers/identityServer';
import { openNotification } from '../../components/widgets/Notification';

function* initPage() {
    yield getIdentityList();
}

function* getIdentityList() {
    const data = yield select();
    const manager = data.audit.identity;
    const param = {
        ...manager.page,
        ...manager.sorter,
        'data': {...manager.searchData},
    };

    param.data.status = manager.tabsKey;
    yield put(identityAction.getIdentityList([]));
    try {
        yield put(identityAction.loading(true));
        const { result } = yield call(identityServer.getAuthenticationList, param);
        yield put(identityAction.getIdentityList(result));
        yield put(identityAction.loading(false));
    } catch (err) {
        yield put(identityAction.loading(false));
        openNotification('error', 'Failed to get list.');
    }
}

function* exportModel() {
    while (true) {
        const { payload } = yield take(identityKey.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);

        const state = yield select();
        const manager = state.audit.identity;

        payload.titles.map((o: any) => {
            if (o.index === 'adult') {
                o.index = 'adultExport';
            }
        });

        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            exportName: '',
            ...manager.sorter,
            data: {...manager.searchData},
        };

        param.data.status = manager.tabsKey;

        if (manager.tabsKey === '1') {
            param.exportName = 'IdentityPendingReview.xlsx';
        } else if (manager.tabsKey === '2') {
            param.exportName = 'IdentityPassed.xlsx';
        } else {
            param.exportName = 'IdentityAuditFailure.xlsx';
        }

        try {
            yield put(identityAction.loading(true));
            yield call(identityServer.exportAuthentication, param);
            yield put(identityAction.loading(false));
            openNotification('success', 'Export success.');
            yield put(identityAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(identityAction.loading(false));
        }
    }
}

function* getIdentityDetailMsg() {
    while (true) {
        const { payload } = yield take(identityKey.GET_IDENTITY_DETAIL_ID);
        try {
            yield put(identityAction.loading(true));
            const { result } = yield call(identityServer.authenticationDetail, payload);
            yield put(identityAction.getIdentityDetailMsg(result));
            yield put(identityAction.loading(false));
        } catch (err) {
            yield put(identityAction.loading(false));
            openNotification('error', 'Get Authentication Msg Failed');
        }
    }
}

function* identityMsg() {
    while (true) {
        const { payload } = yield take(identityKey.GET_IDENTITY_DETAIL_MODIFY_PARAM);
        try {
            yield call(identityServer.changeAuthentication, payload);
            payload.status === '2' ?
                openNotification('success', 'Release Success')
                :
                openNotification('success', 'Refuse Success');
            yield delay(2000);
            yield put(identityAction.identityDetailToList(true));
            yield delay(1000);
            yield put(identityAction.identityDetailToList(false));
        } catch (err) {
            payload.status === '2' ?
                openNotification('error', 'Release Failed')
                :
                openNotification('error', 'Refuse Failed');
        }
    }
}

function* watchSearchData() {
    yield takeEvery(identityKey.GET_SEARCH_DATA, getIdentityList);
}

function* watchChangeTabsKey() {
    yield takeEvery(identityKey.GET_IDENTITY_TABS_KEY, getIdentityList);
}

function* watchInitPage() {
    yield takeEvery(identityKey.INIT_PAGE, initPage);
}

function* watchUpdatePage() {
    yield takeEvery(identityKey.UPDATE_PAGE, getIdentityList);
}

export function* watchIdentitySaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearchData),
        fork(watchUpdatePage),
        fork(watchChangeTabsKey),
        fork(exportModel),
        fork(getIdentityDetailMsg),
        fork(identityMsg),
    ]);
}