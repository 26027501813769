import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 请求作者列表
 */
export function getAuthorList(param?: any) {
    return postJson('/author/getAuthorList', param);
}

/**
 * 获取作者小说列表
 */
export function getAuthorNovelList(id: number) {
    return postJson('/author/getNovelByAuthorId', id);
}

/**
 * 启用/禁用作者
 */
export function authorStateChange(param: any) {
    if (param.type === 'show') {
        return postJson('/author/enableAuthor', param.id);
    } else {
        return postJson('/author/disableAuthor', param.id);
    }
}

/**
 * 导出作者列表
 */
export function exportAuthor(param: any) {
    return postJsonForExport('/author/getAuthorList/export', param, 'author.xlsx');
}

/**
 * 根据ID获取作者详情
 */
export function getAuthorDetailById(id: string) {
    return postJson('/author/getAuthorDetailById', id);
}

/**
 * 作者详情页获取作者小说列表
 */
export function getAuthorDetailNovelList(param: any) {
    return postJson('/novel/getAuthorNovelList', param);
}