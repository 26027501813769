import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Table, Form, Row, Col, Popconfirm, Badge, Modal, Button, Select } from 'antd';
import { getExportTitles } from '../../../base/getExportTitles';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal } from '../../widgets/ExportModal';

const FormItem = Form.Item;
const Option = Select.Option;

interface ActivityProps extends FormComponentProps {
    data: any;
    initPage: () => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getDelId: (id: number) => void;
    getVirtualVote: (ticket: number) => void;
    getSearchParam: (param: any) => void;
    goNovelList: (value: boolean) => void;
    getAddBtn: () => void;
    getNovelList: (list: any[]) => void;
    getSearchNovelParam: (value: any) => void;
    getAddNovelId: (id: number) => void;
}

interface InitialState {
    virtualVoteModal: boolean;
    voteId: number;
    toDetail: boolean;
    detailId: number;
    addModal: boolean;
}

class Activity extends React.Component<ActivityProps, InitialState> {

    state: InitialState = {
        virtualVoteModal: false,
        voteId: 0,
        toDetail: false,
        detailId: 0,
        addModal: false
    };

    componentDidMount() {
        this.props.initPage();
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    clear = () => {
        this.props.form.resetFields();
    }

    handleDel = (id: number) => {
        this.props.getDelId(id);
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            userName: value.userName,
            essayName: value.essayName,
            userId: value.id,
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.getSearchParam(param);
    }

    showVoteModal = (record: any) => {
        this.setState({voteId: record.id, virtualVoteModal: true});
        this.props.form.setFieldsValue({
            voteVirtual: record.voteVirtual
        });
    }

    virtualVoteModalOk = () => {
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            id: this.state.voteId,
            voteVirtual: value.voteVirtual
        };

        this.props.getVirtualVote(param);
        this.virtualVoteModalCancel();
    }

    virtualVoteModalCancel = () => {
        this.setState({virtualVoteModal: false});
    }

    goDetail = (record: any) => {
        this.props.goNovelList(false);
        this.setState({
            toDetail: true,
            detailId: record.novelId
        });
    }

    addModalShow = () => {
        this.setState({
            addModal: true,
        });
        this.props.getAddBtn();
        this.props.getNovelList([]);
        this.props.form.setFieldsValue({
            typeId: 'All',
            name: ''
        });
    }

    addModelCancel = () => {
        this.setState({
            addModal: false
        });
    }

    searchNovelBtn = () => {
        const value: any = this.props.form.getFieldsValue();
        this.props.getSearchNovelParam(value);
    }

    addNovel = (id: number) => {
        this.props.getAddNovelId(id);
        this.addModelCancel();
    }

    columns = () => {
        return [
            {
                title: 'Entry ID',
                dataIndex: 'id',
                key: 'id',
                className: 'column-center'
            },
            {
                title: 'Essay Name',
                dataIndex: 'name',
                key: 'name',
                className: 'column-center',
                width: 350,
                render: (text: any, record: any) => {
                    return <Badge dot={record.updatedStatus === 1}>{text}</Badge>;
                }
            },
            {
                title: 'Update Date',
                dataIndex: 'updatedAtDateValue',
                key: 'updatedAtDateValue',
                className: 'column-center',
            },
            {
                title: 'Update Time',
                dataIndex: 'updatedAtTimeValue',
                key: 'updatedAtTimeValue',
                className: 'column-center',
            },
            {
                title: 'Vote',
                dataIndex: 'voteReal',
                key: 'voteReal',
                className: 'column-center',
                sorter: true,
                sortField: 'voteReal',
            },
            {
                title: 'Show Vote',
                dataIndex: 'voteVirtual',
                key: 'voteVirtual',
                className: 'column-center',
                sorter: true,
                sortField: 'voteVirtual',
                render: (text: number, record: any) => {
                    return <p style={{cursor: 'pointer', width: '100%', height: '100%', color: '#1890FF'}} onClick={() => {this.showVoteModal(record); }}>{text}</p>;
                }
            },
            {
                title: 'Click Times',
                dataIndex: 'clickTimes',
                key: 'clickTimes',
                className: 'column-center',
            },
            {
                title: 'Picture',
                dataIndex: 'pictureUrl',
                key: 'pictureUrl',
                className: 'column-center',
                render: (text: string) => {
                    return <img src={text} style={{width: '50px'}} alt=""/>;
                }
            },
            {
                title: 'Setting',
                dataIndex: 'setting',
                key: 'setting',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            <p style={{cursor: 'pointer', color: '#1890FF'}} onClick={() => { this.goDetail(record); }}>
                                View4
                            </p>
                            <Popconfirm
                                title="Are you sure delete this novel?"
                                onConfirm={() => {this.handleDel(record.id); }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <a href="javascript: void(0)" style={{color: 'red'}}>Delete</a>
                            </Popconfirm>
                        </div>
                    );
                },
            }
        ];
    }

    render() {

        const {getFieldDecorator, getFieldsValue} = this.props.form;
        const { getUpdatePage, data } = this.props;
        const { virtualVoteModal, toDetail, detailId, addModal } = this.state;
        const input = {
            style: {width: '100%', color: '#000'}
        };
        if (toDetail === true) {
            return <Redirect to={{pathname: `/content/chapterManager/${detailId}`}}/>;
        }

        return (
            <div>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <FormItem
                                label="Essay Name"
                            >
                                {getFieldDecorator('essayName')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem
                                label="User Name"
                            >
                                {getFieldDecorator('userName')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem
                                label="User ID"
                            >
                                {getFieldDecorator('id')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport}>
                    <Button
                        icon="plus"
                        type="primary"
                        onClick={(e: any) => {this.addModalShow(); }}
                    >
                        Add
                    </Button>
                </Toolbar>
                <Table
                    rowKey="id"
                    loading={data.loading}
                    bordered={true}
                    columns={this.columns()}
                    style={{marginTop: '20px'}}
                    onChange={getUpdatePage}
                    dataSource={data.activityList}
                    pagination={{
                        defaultPageSize: 20,
                        total: data.total,
                        current: data.page.current,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '50', '80', '100'],
                        showTotal: total => {
                            return `Total:${data.total} items`;
                        },
                    }}
                />
                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />
                <Modal
                    title=""
                    visible={virtualVoteModal}
                    onOk={this.virtualVoteModalOk}
                    onCancel={this.virtualVoteModalCancel}
                    maskClosable={false}
                >
                    <Form>
                        <FormItem
                            label="Virtual Vote"
                        >
                            {getFieldDecorator('voteVirtual')(
                                <Input {...input}/>
                            )}
                        </FormItem>
                    </Form>
                </Modal>
                <Modal
                    visible={addModal}
                    onCancel={this.addModelCancel}
                    footer={null}
                    maskClosable={false}
                    width="58%"
                >
                    <div>
                        <Row>
                            <Col span={18}>
                                <Form layout="inline" >
                                    <Row>
                                        <Col span={11}>
                                            <FormItem label="Category">
                                                {getFieldDecorator('typeId')(
                                                    <Select style={{width: '180px'}}>
                                                        <Option key="All">All</Option>
                                                        {data.typeList.map((item: any) =>
                                                            <Option key={item.id}>
                                                                {item.name}
                                                            </Option>
                                                        )}
                                                    </Select>
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={13}>
                                            <FormItem label="Novel Name">
                                                {getFieldDecorator('name')(
                                                    <Input/>
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col span={3}>
                                <Button type="primary" onClick={this.searchNovelBtn}>Search</Button>
                            </Col>
                        </Row>
                        <ul className="addNovelList">
                            {
                                data.searchList.length > 0 ?
                                    data.searchList.map((item: any, idx: number) => (
                                        <li key={idx}>
                                            <div className="noveList">
                                                <p>
                                                    <img src={item.coverUrl} alt={item.name}/>
                                                    <span className="novelName">{item.name}</span>
                                                </p>
                                                <Button
                                                    type="primary"
                                                    icon="plus"
                                                    onClick={(e: any) => {this.addNovel(item.id); }}
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                        </li>
                                    )) : <div style={{textAlign: 'center', fontSize: '20px', marginTop: '120px', color: '#ddd' }}>Please click the search button to add content</div>
                            }

                        </ul>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(Activity);