import helpCenterManagerKeys from '../action/actionsTypeKey/helpCenterActionTypeKey';
import { ActionTypes } from '../action/actionsType/helpCenterActionType';

export interface StoreState {
    dataSource: any[];
    loading: boolean;
    titleModalStatus: boolean;
    locationModalStatus: boolean;
}

const initialState: StoreState = {
    dataSource: [],
    loading: false,
    titleModalStatus: false,
    locationModalStatus: false,
};

export const helpCenterReducers = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case helpCenterManagerKeys.INIT_PAGE:
            return initialState;

        case helpCenterManagerKeys.LOADING:
            return Object.assign({}, state, {
               loading: action.payload
            });

        case helpCenterManagerKeys.OPEN_CLOSE_TITLE_MODAL:
            return Object.assign({}, state, {
                titleModalStatus: action.payload
            });

        case helpCenterManagerKeys.OPEN_CLOSE_LOCATION_MODAL:
            return Object.assign({}, state, {
                locationModalStatus: action.payload
            });

        case helpCenterManagerKeys.GET_HELP_MODAL_LIST:
            return Object.assign({}, state, {
                dataSource: action.payload
            });

        default:
          return state;
    }
};