enum BlockWordTypeKey {
    INIT_PAGE = 'BLOCK_WORD_INIT_PAGE',
    SEARCH_PARAM = 'BLOCK_WORD_SEARCH_PARAM',
    UPDATE_PAGE = 'BLOCK_WORD_UPDATE_PAGE',
    EXPORT_MODAL_VISIBLE = 'BLOCK_WORD_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'BLOCK_WORD_SUBMIT_EXPORT',
    LOADING = 'BLOCK_WORD_LOADING',
    GET_BLOCK_WORD_LIST = 'GET_BLOCK_WORD_LIST',
    GET_DELETE_ID = 'GET_BLOCK_WORD_DELETE_ID',
    GET_ADD_BLOCK_WORD_PARAM = 'GET_ADD_BLOCK_WORD_PARAM',

}

export default BlockWordTypeKey;