enum authorActionTypeKeys {
    INIT_PAGE = 'AUTHOR_DETAIL_INIt_PAGE', // 作者管理进入页面
    UPDATE_PAGE = 'UPDATE_PAGE_AUTHOR_DETAIL',
    LOADING = 'AUTHOR_DETAIL_LOADING',
    GET_AUTHOR_DETAIL_ID = 'GET_AUTHOR_DETAIL_ID',
    GET_AUTHOR_DETAIL = 'GET_AUTHOR_DETAIL',
    GET_AUTHOR_DETAIL_NOVEL_LIST = 'GET_AUTHOR_DETAIL_NOVEL_LIST'

}

export default authorActionTypeKeys;