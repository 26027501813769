import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Table, Form, Row, Col, Popconfirm, Spin, Modal, Button, Checkbox } from 'antd';

import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../reducers/categoryReducer';
import { getExportTitles } from '../../../base/getExportTitles';
import { isNum, isIntAndCommas } from '../../../base/regular';
import { openNotification } from '../../widgets/Notification';
const FormItem = Form.Item;

interface CategoryContentType extends FormComponentProps {
    data: StoreState;
    initPage: () => void;
    getTypeSearchParam: (type: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    addEditType: (param: any) => void;
    getBetchDelTypeId: (param: any) => void;
    getChangeSorterId: () => void;
    getBatchNovelId: (param: any) => void;
    getEditTypeId: (id: any) => void;
    getHasTagList: (param: any) => void;
    getDefaultDevice: (param: any[]) => void;
    changeUpdataPage: (pagination: any, filters: any, sorter: any) => void;
}

interface CategoryState {
    typeName: any;
    typeList: any;
    delDisabled: boolean;
    addEditModal: boolean;
    checkedValues: any[];
    selectedId: any;
    typeId: any;
    batchUploadModal: boolean;
}

class CategoryManager extends React.Component<CategoryContentType, CategoryState> {

    state: CategoryState = {
        typeName: '',
        typeList: [],
        delDisabled: true,
        addEditModal: false,
        checkedValues: [],
        selectedId: '',
        typeId: '',
        batchUploadModal: false,
    };

    componentDidMount() {
        this.props.initPage();
    }

    columns = () => {
        return [
            {
                title: 'Category ID',
                dataIndex: 'id',
                key: 'id',
                className: 'column-center',
            },
            {
                title: 'Category Name',
                dataIndex: 'name',
                key: 'name',
                className: 'column-center',
            },
            {
                title: 'Novel Count',
                dataIndex: 'count',
                key: 'count',
                className: 'column-center',
            },
            {
                title: 'Setting',
                dataIndex: 'edit',
                key: 'edit',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <div style={{color: '#1890ff'}}>
                            <span
                                style={{marginRight: '15px', cursor: 'pointer'}}
                                onClick={(e) => {this.typeModalShow(record); }}
                            >
                                Edit
                            </span>

                            <span
                                style={{marginRight: '15px', cursor: 'pointer', color: '#1890ff'}}
                                onClick={(e: any) => {this.batchUploadModalShow(record); }}
                            >
                                Upload
                            </span>
                        </div>
                    );
                },
            }
        ];
    }

    typeModalShow = (record: any =  null) => {
        if (record && record.id) {
            this.setState({
                selectedId: record.id,
                typeName: record.name
            });
            this.props.getEditTypeId(record.id);
        } else {
            this.setState({
                selectedId: ''
            });
        }

        this.setState({
            addEditModal: true,
        });

    }

    typeModalClose = () => {
        this.setState({
            addEditModal: false,
            typeName: ''
        });
        this.props.getHasTagList([]);
    }

    typeName = (e: any) => {
        this.setState({typeName: e.target.value});
    }

    handleGroup = (checkedValues: any) => {
        let param = {
             tagList: checkedValues,
            deviceArr: this.props.data.defaultDevice
        };
        this.props.getHasTagList(param);
    }

    handleDefaultDevice = (checkedValues: any) => {
        this.props.getDefaultDevice(checkedValues);
        // this.setState({checkedValues});
    }

    addEditType = () => {
        let param = {};
        const { selectedId, typeName} = this.state;
        if (selectedId) {
            param = {
                type: 'edit',
                id: selectedId,
                name: typeName,
            };
        } else {
            param = {
                type: 'add',
                name: typeName,
            };
        }
        if (isNum(typeName)) {
            if (typeName.length < 15) {
                this.props.addEditType(param);
                this.typeModalClose();
            } else {
                openNotification('error', 'No more than 15 characters');
            }
        }

    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        this.props.getTypeSearchParam(value.category);
    }

    onClear = () => {
        this.props.form.resetFields();
    }

    delType = () => {
        const { typeList } = this.state;
        this.props.getBetchDelTypeId(typeList);
    }

    batchUploadModalShow = (record: any) => {
        this.setState({batchUploadModal: true, typeId: record.id});
        this.props.form.setFieldsValue({
            novelId: ''
        });
    }

    batchUploadModalOk = () => {
        const value: any = this.props.form.getFieldsValue();
        let ids = value.novelId.split(',');
        if (ids[ids.length - 1] === '') {
           ids.splice(-1, 1);
        }
        let param = {
            id: this.state.typeId,
            novelList: ids
        };
        this.props.getBatchNovelId(param);
        this.batchUploadModalCancel();
    }

    batchUploadModalCancel = () => {
        this.setState({batchUploadModal: false});
    }

    render() {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { data, changeUpdataPage } = this.props;
        const { addEditModal, typeName, delDisabled, batchUploadModal } = this.state;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const check = { style: { marginBottom: '15px' } };

        const button = { style: {width: '95px', margin : '0 8px 8px 0'}};

        const rowSelection = {
            onChange: (selectedRowKeys: any) => {
                if (selectedRowKeys.length > 0) {
                    this.setState({
                        delDisabled: false
                    });
                } else {
                    this.setState({
                        delDisabled: true
                    });
                }
                this.setState({typeList: selectedRowKeys});
            }
        };

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormItem
                                    label="Category"
                                >
                                    {getFieldDecorator('category')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                    <Toolbar
                        showExport={true}
                        onSearch={this.onSearch}
                        onClear={this.onClear}
                        onExport={this.handleExport}
                    >
                        <Button
                            icon="plus"
                            {...button}
                            type="primary"
                            onClick={() => {this.typeModalShow(); }}
                        >
                            Add
                        </Button>

                        <Popconfirm
                            title="Are you sure delete this task?"
                            onConfirm={this.delType}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                icon="delete"
                                {...button}
                                type="danger"
                                disabled={delDisabled}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </Toolbar>
                    <Table
                        rowKey={(record: any) => record.id}
                        bordered={true}
                        columns={this.columns()}
                        style={{marginTop: '20px'}}
                        dataSource={data.typeList}
                        rowSelection={rowSelection}
                        onChange={changeUpdataPage}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total:${data.total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={getFieldsValue}
                        visible={data.exportModalVisible}
                        loading={data.loading}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                    />
                </Spin>
                <Modal
                    visible={addEditModal}
                    onCancel={this.typeModalClose}
                    footer={null}
                    maskClosable={false}
                >
                    <Spin spinning={data.loading}>
                        <Row style={{marginTop: '20px'}}>
                            <Col>
                                <Row>
                                    <Col span={4}>
                                        Category:
                                    </Col>
                                    <Col span={20} style={{marginBottom: '15px'}}>
                                        <Input value={typeName} onChange={(e: any) => {this.typeName(e); }} />
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox.Group value={data.hasTagList}  onChange={this.handleGroup}>
                                            <Row>
                                                {
                                                    data.tagList.map((o: any, i: number) => {
                                                      return  <Col key={i} {...check} span={8}><Checkbox value={o.id}>{o.name}</Checkbox></Col>;
                                                    })
                                                }

                                            </Row>
                                        </Checkbox.Group>
                                    </Col>
                                    {/*设置是否在app和web显示*/}
                                    <Col span={24}>
                                        <p>Device：</p>
                                        <Checkbox.Group value={data.defaultDevice}  onChange={this.handleDefaultDevice}>
                                            <Row>
                                                <Col span={8}><Checkbox value="app">App</Checkbox></Col>
                                                <Col span={8}><Checkbox value="web">Web</Checkbox></Col>

                                            </Row>
                                        </Checkbox.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{textAlign: 'center', marginTop: '30px'}}>
                                <Button
                                    type="primary"
                                    onClick={this.addEditType}
                                    disabled={data.hasTagList.length === 0}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Spin>
                </Modal>
                <Modal
                    title="upload novel"
                    visible={batchUploadModal}
                    onOk={this.batchUploadModalOk}
                    onCancel={this.batchUploadModalCancel}
                    maskClosable={false}
                >
                    <Form>
                        <Row>
                            <Col>
                                <FormItem
                                    label="Novel ID"
                                >
                                    {getFieldDecorator('novelId', {
                                        rules: [
                                            {validator: isIntAndCommas}
                                        ]
                                    })(
                                        <Input {...input} placeholder="1,2,3,4"/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(CategoryManager);