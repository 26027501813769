import vipOrderKey from '../actions/actionsTypeKey/vipOrderActionsTypeKey';
import {  ActionType } from '../actions/actionsType/vipOrderActionsType';

interface PageData {
    current: number;
    pageSize: number;
}

interface ExportData {
    titles: any;
}

export interface VipOrderState {
    page: PageData;
    sorter: any;
    loading: boolean;
    total: number;
    export: ExportData;
    searchData: any;
    versionModalVisible: boolean;
    exportModalVisible: boolean;
    vipOrderList: any[];
    vipTotalData: any;
    vipAllDay: any[];
}

const initialState: VipOrderState = {
    loading: false,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    total: 0,
    export: {
        titles: []
    },
    searchData: {},
    versionModalVisible: false,
    exportModalVisible: false,
    vipTotalData: {},
    vipOrderList: [],
    vipAllDay: []
};

export const vipOrderDataReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case vipOrderKey.INIT_PAGE:
            return initialState;

        case vipOrderKey.LOADING:
            return Object.assign({}, state, {
               loading: action.payload
            });

        case vipOrderKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload
            });

        case vipOrderKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case vipOrderKey.GET_TOTAL_VIP_ORDER:
            return Object.assign({}, state, {
                vipTotalData: action.payload
            });

        case vipOrderKey.GET_VIP_ORDER_LIST:
            return Object.assign({}, state, {
                vipOrderList: action.payload.data,
                total: action.payload.total
            });

        case vipOrderKey.SEARCH_DATA:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case vipOrderKey.GET_VIP_DAY_ALL:
            return Object.assign({}, state, {
                vipAllDay: action.payload
            });

        default:
            return state;
    }
};