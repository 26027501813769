import BannerActionsTypeKey from '../action/actionsTypeKey/bannerActionsTypeKey';
import {  ActionTypes } from '../action/actionsType/bannerActionsType';

export interface StoreState {
    getBannerList: any[];
    novelList: any[];
    allNovelList: any[];
    loading: boolean;
    modalLoading: boolean;
    modalVisible: boolean;
    searchData: any;
    page: any;
}

const initialState: StoreState = {
    loading: false,
    modalLoading: false,
    modalVisible: false,
    getBannerList: [],
    novelList: [],
    allNovelList: [],
    searchData: {theme: 'novel'},
    page: {
        current: 1,
        pageSize: 1000,
    },
};

export const bannerReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case BannerActionsTypeKey.INIT_PAGE:
            return initialState;

        case BannerActionsTypeKey.GET_CONTENT_BANNER_LIST:
            return Object.assign({}, state, {
                getBannerList: action.payload,
            });

        case BannerActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload,
            });

        case BannerActionsTypeKey.LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload,
            });

        case BannerActionsTypeKey.MODAL_LOADING:
            return Object.assign({}, state, {
                modalLoading: action.payload,
            });

        case BannerActionsTypeKey.MODAL_VISIBLE:
            return Object.assign({}, state, {
                modalVisible: action.payload,
            });

        case BannerActionsTypeKey.GET_NOVEL_LIST:
            return Object.assign({}, state, {
                novelList: action.payload,
            });

        case BannerActionsTypeKey.GET_ALL_NOVEL_LIST:
            return Object.assign({}, state, {
                allNovelList: action.payload,
            });

        default:
            return state;
    }
};