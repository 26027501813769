import  VersionContent  from '../VersionContent';
import { connect } from 'react-redux';
import * as versionContentActions from '../../actions/actions';

const mapStateToProps = (state: any) => ({
    data: state.setting.versionContent
});

const mapDispatchToProps = {
    initPage: versionContentActions.initPage,
    updatePage: versionContentActions.updatePage,
    exportModalVisible: versionContentActions.exportModalVisible,
    addVersionModalVisible: versionContentActions.addVersionModalVisible,
    addVersionData: versionContentActions.addVersionData,
    submitExport: versionContentActions.submitExport,
    versionListSearch: versionContentActions.versionListSearch,
    deleteVersionData: versionContentActions.deleteVersionList,
    changeVersionStatus: versionContentActions.changeVersionStatus,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(VersionContent);