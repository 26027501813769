import { postJson } from '@iliewen/js-common';

export function getRecommendLocationList() {
    return postJson('/recommendLocation/getRecommendLocationList');
}

export function addRecommendLocation(param: any) {
    return postJson('/recommendLocation/addRecommendLocation', param);
}

export function modifyRecommendLocation(param: any) {
    return postJson('/recommendLocation/modifyRecommendLocation', param);
}

export function deleteRecommendLocation(param: any) {
    return postJson('/recommendLocation/deleteRecommendLocation', param);
}

export function getRecommendBookList(param: any) {
    return postJson('/recommendLocation/getRecommendBookList', param);
}

export function addBookToRecommendLocation(param: any) {
    return postJson('/recommendLocation/addBookToRecommendLocation', param);
}

export function removeFromRecommendLocation(param: any) {
    return postJson('/recommendLocation/removeFromRecommendLocation', param);
}

export function changeRecommendBookSort(param: any) {
    return postJson('/recommendLocation/changeRecommendBookSort', param);
}