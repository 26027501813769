import FloorDataActionsTypeKey from '../actions/actionsTypeKey/floorDataActionsTypeKey';
import { ActionTypes } from '../actions/actionsType/floorDataActionsType';
import { DayDataList } from '../../../servers/responseTypes';

export interface SearchData {
    title: string;
    data: any[];
}

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    total: number;
    page: PageData;
    sorter: any;
    searchData: any;
    loading: boolean;
    exportModalVisible: boolean;
    dataList: DayDataList[];
    floorList: any[];
}

const initialState: StoreState = {
    total: 0,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    searchData: {},
    dataList: [],
    floorList: [],
    loading: false,
    exportModalVisible: false,
};

export const floorDataReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case FloorDataActionsTypeKey.PAGE_INIT:
            return initialState;

        case FloorDataActionsTypeKey.GET_DATA_LIST:
            return Object.assign({}, state, {
                dataList: action.payload.data,
                total: action.payload.total
            });

        case FloorDataActionsTypeKey.GET_FLOOR_LIST:
            return Object.assign({}, state, {
                floorList: action.payload
            });

        case FloorDataActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case FloorDataActionsTypeKey.DATA_LIST_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case FloorDataActionsTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize,
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'DESC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC'),
                }
            });

        case FloorDataActionsTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload,
            });

        default:
            return state;
        }
};