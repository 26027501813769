import quarterlyListKey from '../actions/actionsTypeKey/quarterlyListTypeKey';
import {  ActionType } from '../actions/actionsType/quarterlyListType';

interface PageData {
    current: number;
    pageSize: number;
}

interface ExportData {
    titles: any;
}

export interface QuarterlyListState {
    page: PageData;
    sorter: any;
    loading: boolean;
    total: number;
    export: ExportData;
    searchData: any;
    versionModalVisible: boolean;
    exportModalVisible: boolean;
    gameStatus: number;
    quarterlyId: string;
    quarterlyList: any[];
    competitionList: any[];
    novelTypeList: any[];
}

const initialState: QuarterlyListState = {
    loading: true,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    total: 0,
    export: {
        titles: []
    },
    searchData: {},
    versionModalVisible: false,
    exportModalVisible: false,
    gameStatus: 0,
    novelTypeList: [],
    quarterlyId: '',
    competitionList: [],
    quarterlyList: []
};

export const quarterlyListReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case quarterlyListKey.INIT_PAGE:
            return initialState;

        case quarterlyListKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case quarterlyListKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload
            });

        case quarterlyListKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case quarterlyListKey.GET_TYPE_LIST:
            return Object.assign({}, state, {
                novelTypeList: action.payload
            });

        case quarterlyListKey.GET_COMPETITION_LIST:
            return Object.assign({}, state, {
                competitionList: action.payload
            });

        case  quarterlyListKey.GET_SEARCH_DATA:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case quarterlyListKey.GET_QUARTERLY_LIST_DATA:
            return Object.assign({}, state, {
                quarterlyList: action.payload.quarterRankNovelList ? action.payload.quarterRankNovelList.data : [],
                gameStatus: action.payload.status || 0,
                quarterlyId: action.payload.id || '',
                total: action.payload.quarterRankNovelList ? action.payload.quarterRankNovelList.total : 0,
            });

        default:
            return state;
    }
};