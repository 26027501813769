import categoryTypeKey from '../action/actionsTypeKey/categoryTypeKey';
import { ActionTypes } from '../action/actionsType/categoryActionsType';

export interface StoreState {
    page: PageData;
    typeList: any[];
    loading: boolean;
    total: number;
    tagList: any[];
    searchData: any;
    exportModalVisible: boolean;
    hasTagList: any[];
    defaultDevice: any[];
    sorter: any;
}

interface PageData {
    current: number;
    pageSize: number;
}

const initialState: StoreState = {
    loading: false,
    typeList: [],
    page: {
        current: 1,
        pageSize: 20,
    },
    total: 0,
    tagList: [],
    exportModalVisible: false,
    searchData: '',
    hasTagList: [],
    defaultDevice: ['app', 'web'],
    sorter: {}
};

export const categoryReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {

        case categoryTypeKey.CATEGORY_INIT_PAGE:
            return initialState;

        case categoryTypeKey.GET_TYPE_LIST:
            return Object.assign({}, state, {
                typeList: action.payload,
                total: action.payload.length
            });

        case categoryTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload,
            });

        case categoryTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload,
            });

        case categoryTypeKey.GET_TYPE_SEARCH_PARAM:
            return Object.assign({}, state, {
                searchData: action.payload,
            });

        case categoryTypeKey.GET_TAG_LIST:
            return Object.assign({}, state, {
                tagList: action.payload,
            });

        case categoryTypeKey.GET_TYPE_HAS_TAG:
            return Object.assign({}, state, {
                hasTagList: action.payload.tagList || [],
                defaultDevice: action.payload.deviceArr || ['app', 'web']
            });

        case categoryTypeKey.GET_TYPE_DEFAULT_DEVICE:
            return Object.assign({}, state, {
                defaultDevice: action.payload
            });

        // case categoryTypeKey.GET_ADD_EDIT_TYPE:
        //     return Object.assign({}, state, {
        //
        //     });

        case categoryTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        default:
            return state;
    }
};