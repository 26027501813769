export function getPackageId() {
    return window.localStorage.getItem('packageId') || '1';
}

export function getPackageName() {
    return window.localStorage.getItem('pacakageName') || 'com.lovenovel.read';
}

export function isLightApp() {
    return getPackageName() === 'id.novelaku';
}