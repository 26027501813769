import SearchManage from '../SearchManage';
import * as searchAction from '../../action/searchAction';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => ({
    data: state.creation.search
});

const mapDispatchToProps = {
    initPage: searchAction.initPage,
    exportModalVisible: searchAction.exportModalVisible,
    submitExport: searchAction.submitExport,
    updatePage: searchAction.updatePage,
    changeDataListStatus: searchAction.changeDataListStatus,
    addDataList: searchAction.addDataList,
    deleteDataList: searchAction.deleteDataList,
    addModalVisible: searchAction.addModalVisible,
};

export function mergeProps(stateProps: any, dispatchProps: any, ownProps: any) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SearchManage);