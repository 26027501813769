import { fork, all, put, call, takeEvery, select, take } from 'redux-saga/effects';
import novelActionsTypeKey from '../../components/content/actions/actionsTypeKey/novelActionsTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as  contentServer from '../../servers/contentServer';
import * as novelAction from '../../components/content/actions/novelAction';

function* initPage() {
    yield getNovelList();
    yield checkSpiderStatus();
}

function* getNovelList() {
    try {
        yield put(novelAction.loading(true));
        const state = yield select();
        const novel = state.content.novel;
        const param = {
            current: novel.page.current,
            pageSize: novel.page.pageSize,
            data: {
                ...novel.searchData
            }

        };
        let result = yield call(contentServer.getSpiderNovelList, param);
        yield put(novelAction.getNovelList(result.result));
        yield put(novelAction.loading(false));
    } catch (error) {
        openNotification('error', 'Fail to get novel list.');
        yield put(novelAction.loading(false));
    }
}

function* watchDownloadNovel() {
    while (true) {
        let { payload } = yield take(novelActionsTypeKey.DOWNLOAD_NOVEL);
        try {
            yield call(contentServer.downloadNovel, {id: payload.id}, payload.name);
        } catch (err) {
            openNotification('error', 'Download novel failed.');
        }
    }
}

function* spiderStart() {
    try {
        yield call(contentServer.spiderStart);
        yield checkSpiderStatus();
        openNotification('success', 'Spider start success.');
    } catch (err) {
        if (err.code === 218) {
            openNotification('error', 'Spider start failed.');
        } else {
            openNotification('error', 'error.');
        }
    }
}

function* checkSpiderStatus() {
        try {
            let result = yield call(contentServer.checkStarted);
            yield put(novelAction.checkSpiderStatus(result.result));
        } catch (err) {
            openNotification('error', 'Check spider status failed.');
        }
}

function* watchDumpNovel() {
    while (true) {
        let { payload } = yield take(novelActionsTypeKey.DUMP_NOVEL);
        try {
            yield put(novelAction.loading(true));
            yield call(contentServer.redepositNovel, payload);
            yield getNovelList();
            yield put(novelAction.loading(false));
            yield put(novelAction.dumpModalVisible(false));
            openNotification('success', 'Dump success.');
        } catch (err) {
            openNotification('error', 'Dump failed.');
            yield put(novelAction.loading(false));
        }
    }
}

function* watchGetNovelListByName() {
    while (true) {
        let { payload } = yield take(novelActionsTypeKey.SEND_NOVEL_VALUE);
        try {
            let result = yield call(contentServer.getNovelName, payload);
            let arr: any = [];
            result.result.map((item: any) => {
                arr.push(item.name);
            });
            yield put(novelAction.getNovelListByName(arr));
            yield put(novelAction.getAllNovelListByName(result.result));
        } catch (err) {
            openNotification('error', 'Get novel name list failed.');
            yield put(novelAction.loading(false));
        }
    }
}

function* watchBriefModal() {
    while (true) {
        let { payload } = yield take(novelActionsTypeKey.BRIEF_MODAL_VISIBLE);
        try {
            yield put(novelAction.briefModalVisible(payload));
        } catch (err) {
            yield put(novelAction.briefModalVisible(false));
        }
    }
}

function* watchGetBriefList() {
    while (true) {
        let { payload } = yield take(novelActionsTypeKey.SEND_BRIEF_ID);
        try {
            yield put(novelAction.loading(true));
            let result = yield call(contentServer.getBriefById, payload);
            yield put(novelAction.getBriefList(result.result));
            yield put(novelAction.loading(false));
            yield put(novelAction.briefModalVisible(true));
        } catch (err) {
            openNotification('error', 'Failed.');
            yield put(novelAction.loading(false));
        }
    }
}

function* watchUpdatePage() {
    yield takeEvery(novelActionsTypeKey.UPDATE_PAGE, getNovelList);
}

function* watchInitPage() {
    yield takeEvery(novelActionsTypeKey.INIT_PAGE, initPage);
}

function* watchSearchData() {
    yield takeEvery(novelActionsTypeKey.LIST_DATA_SEARCH, getNovelList);
}

function* watchSpiderStart() {
    yield takeEvery(novelActionsTypeKey.SPIDER_START, spiderStart);
}

export function* watchNovelRootSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearchData),
        fork(watchDumpNovel),
        fork(watchBriefModal),
        fork(watchGetNovelListByName),
        fork(watchSpiderStart),
        fork(watchDownloadNovel),
        fork(watchGetBriefList),
        fork(watchUpdatePage)
    ]);
}