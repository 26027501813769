import  NovelActionTypeKeys  from './actionsTypeKey/novelActionsTypeKey';

export function initPage() {
    return { type: NovelActionTypeKeys.INIT_PAGE };
}

export function getNovelList(value: any) {
    return { type: NovelActionTypeKeys.GET_NOVEL_CONTENT_LIST, payload: value };
}

export function getBriefList(value: any) {
    return { type: NovelActionTypeKeys.GET_BRIEF_CONTENT_LIST, payload: value };
}

export function sendBriefId(value: any) {
    return { type: NovelActionTypeKeys.SEND_BRIEF_ID, payload: value };
}

export function novelListSearch(value: any) {
    return { type: NovelActionTypeKeys.LIST_DATA_SEARCH, payload: value };
}

export function dumpNovel(value: any) {
    return { type: NovelActionTypeKeys.DUMP_NOVEL, payload: value };
}

export function downloadNovel(value: number) {
    return { type: NovelActionTypeKeys.DOWNLOAD_NOVEL, payload: value };
}

export function loading(bool: boolean) {
    return { type: NovelActionTypeKeys.LOADING, payload: bool };
}

export function briefModalVisible(bool: boolean) {
    return { type: NovelActionTypeKeys.BRIEF_MODAL_VISIBLE, payload: bool };
}

export function getNovelListByName(value: any) {
    return { type: NovelActionTypeKeys.GET_NOVEL_LIST_BY_NAME, payload: value };
}

export function getAllNovelListByName(value: any) {
    return { type: NovelActionTypeKeys.GET_ALL_NOVEL_LIST_BY_NAME, payload: value };
}

export function sendNovelValue(value: any) {
    return { type: NovelActionTypeKeys.SEND_NOVEL_VALUE, payload: value };
}

export function spiderStart() {
    return { type: NovelActionTypeKeys.SPIDER_START };
}

export function checkSpiderStatus(bool: boolean) {
    return { type: NovelActionTypeKeys.CHECK_SPIDER_STATUS, payload: bool };
}

export function dumpModalVisible(bool: boolean) {
    return { type: NovelActionTypeKeys.DUMP_MODAL_VISIBLE, payload: bool };
}

export function getResourceTableChange(pagination: any, filters: any, sorter: any) {
    return { type: NovelActionTypeKeys.UPDATE_PAGE, payload: { pagination, sorter} };
}
