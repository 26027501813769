import { postJson } from '@iliewen/js-common';

export function getHelpList(type: number) {
    return postJson('/help/getHelpModuleList', type);
}

export function modifyHelpModalSort(param: any) {
    return postJson('/help/modifyHelpModuleLocation', param);
}

export function addHelpModal(param: any) {
    return postJson('/help/addHelpModule', param);
}

export function editHelpModal(param: any) {
    return postJson('/help/modifyHelpModule', param);
}

export function delHelpModal(param: any) {
    return postJson('/help/deleteHelpModule', param);
}

export function getHelpDetailList(id: number | string) {
    return postJson('/help/getHelpQuestionList', id);
}

export function addQuestion(param: any) {
    return postJson('/help/addHelpQuestion', param);
}

export function editQuestion(param: any) {
    return postJson('/help/modifyHelpQuestion', param);
}

export function delQuestion(id: number | string) {
    return postJson('/help/deleteHelpQuestion', id);
}

export function modifyQuestionSort(param: any) {
    return postJson('/help/modifyHelpQuestionLocation', param);
}