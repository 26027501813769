import * as React from 'react';
import { Link } from 'react-router-dom';
import { DatePicker, Form, Row, Col, Select, Input, Table , Button, Divider, Popconfirm } from 'antd';

import { FormComponentProps } from 'antd/lib/form/Form';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { getExportTitles } from '../../../base/getExportTitles';

import { MonthlyListState } from '../reducers/monthlyListReducers';
import { getNowTime, timeReverseWithMonth } from '../../../base/dealWithTime';
import { numFormat } from '../../../base/regular';
import { openNotification } from '../../widgets/Notification';

const { MonthPicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;
const monthFormat = 'MM-YYYY';

interface  MonthlyListProps extends FormComponentProps {
    data: MonthlyListState;
    initPage: () => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getMonthlySearchData: (param: any) => void;
    monthlyListConfirm: () => void;
    monthlyRemoveRank: (param: any) => void;
}

interface MonthlyState {
    searchMonth: string;
    currentMonth: string;
}

class MonthlyList extends React.Component<MonthlyListProps, MonthlyState> {

    state: MonthlyState = {
        searchMonth: '',
        currentMonth: '',
    };

    componentDidMount() {
        this.props.initPage();
        this.setState({currentMonth: getNowTime()});
    }

    // public action

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    clear = () => {
        this.setState({searchMonth: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {
        const { searchMonth } = this.state;
        // 获取当前月名称
        if (searchMonth) {
            this.setState({currentMonth: searchMonth});

            const value: any = this.props.form.getFieldsValue();
            const param: any = {
                month: timeReverseWithMonth(this.state.searchMonth),
                novelTypeId: value.typeId,
                novelName: value.name,
                novelId: value.id,
                author: value.author
            };

            for (let item in param) {
                if (param[item] === 'All' || param[item] === '') {
                    delete param[item];
                }
            }

            this.props.getMonthlySearchData(param);
        } else {
            openNotification('error', 'month cannot empty!');
        }
    }

    removeList = (record: any) => {
        const { data } = this.props;
        let param = {
            competitionId: data.monthlyId,
            novelId: record.novelId
        };
        this.props.monthlyRemoveRank(param);
    }

    columns = () => {
        return [{
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
            className: 'column-center',
        }, {
            title: 'Novel Id',
            dataIndex: 'novelId',
            key: 'novelId',
            className: 'column-center',
        }, {
            title: 'Novel Name',
            dataIndex: 'novelName',
            key: 'novelName',
            className: 'column-center',
        }, {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            className: 'column-center',
        }, {
            title: 'Category',
            dataIndex: 'novelTypeName',
            key: 'novelTypeName',
            className: 'column-center',
        }, {
            title: 'Stars',
            dataIndex: 'starCount',
            key: 'starCount',
            className: 'column-center',
            sorter: true,
            sortField: 'stars'
        }, {
            title: 'Heat',
            dataIndex: 'heat',
            key: 'heat',
            sorter: true,
            className: 'column-center',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <Link to={{pathname: `/content/chapterManager`, search: `?id=${record.novelId}`}}>view16</Link>;
            }
        }];
    }

    pastColumns = () => {
        const { data } = this.props;
        return [{
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
            className: 'column-center',
        }, {
            title: 'Novel Id',
            dataIndex: 'novelId',
            key: 'novelId',
            className: 'column-center',
        }, {
            title: 'Novel Name',
            dataIndex: 'novelName',
            key: 'novelName',
            className: 'column-center',
        }, {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            className: 'column-center',
        }, {
            title: 'Category',
            dataIndex: 'novelTypeName',
            key: 'novelTypeName',
            className: 'column-center',
        }, {
            title: 'Stars',
            dataIndex: 'starCount',
            key: 'starCount',
            className: 'column-center',
            sorter: true,
            sortField: 'stars'
        }, {
            title: 'Heat',
            dataIndex: 'heat',
            key: 'heat',
            sorter: true,
            className: 'column-center',
        }, {
            title: 'Bonus',
            dataIndex: 'bonus',
            key: 'bonus',
            className: 'column-center',
            render: (text: string) => (`Rp.${numFormat(text)}`)
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any) => {
                return (
                    <div>
                        <Link to={{pathname: `/content/chapterManager`, search: `?id=${record.novelId}`}}>view15</Link>
                        {
                            (data.gameStatus === 1 && record.rank < 11 ) &&
                            <Popconfirm
                                title="Are you sure delete this task?"
                                onConfirm={() => {this.removeList(record); }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <a style={{marginLeft: '15px'}}>Remove</a>
                            </Popconfirm>
                        }
                    </div>
                );
            }
        }];
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const { data } = this.props;

        const titles = getExportTitles(data.gameStatus !== 0 ? this.pastColumns() : this.columns());
        this.props.submitExport({...value, titles});
    }

    // page action

    changeMonth = (date: any, dateString: string) => {
        this.setState({searchMonth: dateString});
    }

    monthlyConfirm = () => {
        this.props.monthlyListConfirm();
    }

    render () {

        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const { data, getUpdatePage } = this.props;
        const { currentMonth } = this.state;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        return (
            <div>
                <Form>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="Month">
                                {getFieldDecorator('month', {
                                })(
                                    <MonthPicker format={monthFormat} onChange={this.changeMonth} {...input} placeholder="Select month" />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Category">
                                {getFieldDecorator('typeId', {
                                    initialValue: 'All'
                                })(
                                    <Select {...input}>
                                        <Option key="All">All</Option>
                                        {data.novelTypeList.map(item => <Option key={item.id}>{item.name}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Novel Name"
                            >
                                {getFieldDecorator('name')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Novel ID"
                            >
                                {getFieldDecorator('id')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Author Name"
                            >
                                {getFieldDecorator('author')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport} >
                    {
                        data.gameStatus === 1 &&
                        <Button
                            type="danger"
                            icon="check-circle"
                            style={{width: 95, margin: '8px 0', padding: 0}}
                            onClick={this.monthlyConfirm}
                        >
                            Confirm
                        </Button>

                    }
                </Toolbar>
                <Divider>Current month: {currentMonth}</Divider>
                <Table
                    rowKey="rank"
                    loading={data.loading}
                    bordered={true}
                    columns={data.gameStatus !== 0 ? this.pastColumns() : this.columns()}
                    style={{marginTop: '20px'}}
                    dataSource={data.monthlyList}
                    onChange={getUpdatePage}
                    pagination={{
                        defaultPageSize: 20,
                        total: data.total,
                        current: data.page.current,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '50', '80', '100'],
                        showTotal: total => {
                            return `Total:${data.total} items`;
                        },
                    }}
                />
                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />
            </div>
        );
    }
}

export default Form.create()(MonthlyList);