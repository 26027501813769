import  UserContent  from '../UserContent';
import { connect } from 'react-redux';
import * as userContentActions from '../../actions/userActions';

const mapStateToProps = (state: any) => ({
    data: state.user.userContent
});

const mapDispatchToProps = {
    initPage: userContentActions.initPage,
    updatePage: userContentActions.updatePage,
    requestExportList: userContentActions.requestExportList,
    userListSearch: userContentActions.userListSearch,
    changeUserStatus: userContentActions.changeUserStatus,
    exportModalVisible: userContentActions.exportModalVisible,
    submitExport: userContentActions.submitExport,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UserContent);