import { fork, all, put, call, takeEvery, select, take } from 'redux-saga/effects';
import novelTotalDataTypeKey from '../../components/data/actions/actionsTypeKey/novelTotalDataTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as dataServer from '../../servers/dataServer';
import * as novelTotalDataAction from '../../components/data/actions/novelTotalDataAction';

// 初始页面请求登录
function* initPage() {
    yield getNovelDataList();
    yield getTypeList();
}

function* getNovelDataList() {
    try {
        yield put(novelTotalDataAction.loading(true));
        const state = yield select();
        const data = state.data.novelTotalData;
        const param = {
            ...data.page,
            ...data.sorter,
            data: {...data.searchData}
        };
        yield put(novelTotalDataAction.loading(true));
        let { result } = yield call(dataServer.getNovelTotalData, param);
        yield put(novelTotalDataAction.getTotalData(result));
        yield put(novelTotalDataAction.loading(false));
    } catch (error) {
        openNotification('error', 'Failed to get novel data list.');
        yield put(novelTotalDataAction.loading(false));
    }
}

function* getTypeList() {
    try {
        let result = yield call(dataServer.getNovelTypeList);
        yield put(novelTotalDataAction.getTypeList(result.result));
    } catch (error) {
        openNotification('error', 'Failed to get type list.');
    }
}

function* watchExport() {
    while (true) {
        const { payload } = yield take(novelTotalDataTypeKey.SUBMIT_NOVEL_TOTAL_EXPORT);
        const state = yield select();
        const data = state.data.novelTotalData;

        payload.titles.map((o: any) => {
           if (o.index === 'novelType') {
               o.index = 'novelTypeExport';
           }

           if (o.index === 'signed') {
               o.index = 'signedExport';
           }
        });

        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            data: {...data.searchData},
            ...data.sorter
        };

        try {
            yield put(novelTotalDataAction.loading(true));
            yield call(dataServer.exportNovelTotalData, param);
            yield put(novelTotalDataAction.loading(false));
            yield put(novelTotalDataAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(novelTotalDataAction.loading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(novelTotalDataTypeKey.INIT_PAGE, initPage);
}

function* watchSearchData() {
    yield takeEvery(novelTotalDataTypeKey.SEARCH_DATA, getNovelDataList);
}

function* watchUpdatePageSize() {
    yield takeEvery(novelTotalDataTypeKey.UPDATE_PAGE, getNovelDataList);
}

export function* watchNovelTotalDataSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchUpdatePageSize),
        fork(watchSearchData),
        fork(watchExport),
    ]);
}