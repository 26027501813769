import  UserContentActionTypeKeys  from './actionsTypeKey/userActionTypeKey';
import { userList } from '../../../servers/responseTypes';

export function initPage() {
    return { type: UserContentActionTypeKeys.USER_CONTNET_INIT_PAGE };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: UserContentActionTypeKeys.UPDATE_PAGE, payload: { pagination, sorter } };
}

export function getUserList(value: {data: userList, total: number}) {
    return { type: UserContentActionTypeKeys.GET_USER_LIST, payload: value };
}

export function userListSearch(value: any) {
    return { type: UserContentActionTypeKeys.USER_LIST_DATA_SEARCH, payload: value };
}

export function requestExportList(value: any) {
    return { type: UserContentActionTypeKeys.REQUEST_EXPORT_LIST, payload: value };
}

export function changeUserStatus(value: any, checked: boolean) {
    return { type: UserContentActionTypeKeys.CHANGE_USER_STATUS, payload: { value, checked} };
}

export function loading(bool: boolean) {
    return { type: UserContentActionTypeKeys.LOADING, payload: bool };
}

export function exportModalVisible(value: boolean) {
    return { type: UserContentActionTypeKeys.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: UserContentActionTypeKeys.SUBMIT_EXPORT, payload: value };
}

export function getUserDetailId(id: string) {
    return { type: UserContentActionTypeKeys.GET_USER_DETAIL_ID, payload: id };
}

export function getUserDetailMsg(param: any) {
    return { type: UserContentActionTypeKeys.GET_USER_DETAIL_MSG, payload: param };
}