import authorDetailActionsTypeKey from '../actions/actionsTypeKey/authorDetailActionsTypeKey';
import {  ActionTypes } from '../actions/actionsType/authorDetailActionsType';

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    page: PageData;
    sorter: any;
    loading: boolean;
    total: number;
    authorId: string;
    authorDetail: any;
    authorDetailNovelList: any[];
}

const initialState: StoreState = {
    loading: false,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    total: 0,
    authorId: '',
    authorDetail: {},
    authorDetailNovelList: []
};

export const authorDetailReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {

        case authorDetailActionsTypeKey.INIT_PAGE:
            return initialState;

        case authorDetailActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case authorDetailActionsTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case authorDetailActionsTypeKey.GET_AUTHOR_DETAIL_ID:
            return Object.assign({}, state, {
               authorId: action.payload
            });

        case authorDetailActionsTypeKey.GET_AUTHOR_DETAIL:
            return Object.assign({}, state, {
                authorDetail: action.payload
            });

        case authorDetailActionsTypeKey.GET_AUTHOR_DETAIL_NOVEL_LIST:
            return Object.assign({}, state, {
                authorDetailNovelList:  action.payload.data,
                total: action.payload.total
            });

        default:
            return state;
    }
};