import { all, fork, take, call, put, select, takeEvery } from 'redux-saga/effects';
import authorDetailKey from '../../components/user/actions/actionsTypeKey/authorDetailActionsTypeKey';
import * as authorDetailAction from '../../components/user/actions/authorDetailAction';
import * as authorServer from '../../servers/authorServer';
import { openNotification } from '../../components/widgets/Notification';

function* getAuthorDetail() {
    while (true) {
        const { payload } = yield take(authorDetailKey.GET_AUTHOR_DETAIL_ID);
        try {
            const { result } = yield call(authorServer.getAuthorDetailById, payload);
            yield put(authorDetailAction.getAuthorDetail(result));
            yield getAuthorNovel();
        } catch (err) {
            openNotification('error', 'Get Author Detail Failed');
        }
    }
}

function* getAuthorNovel() {

    const data = yield select();
    const authorDetail = data.user.authorDetail;
    const param = {
        current: authorDetail.page.current,
        pageSize: authorDetail.page.pageSize,
        data: authorDetail.authorId,
        ...authorDetail.sorter
    };

    try {
        yield put(authorDetailAction.loading(true));
        const { result } = yield call(authorServer.getAuthorDetailNovelList, param);
        yield put(authorDetailAction.getAuthorDetailNovelList(result));
        yield put(authorDetailAction.loading(false));
    } catch (err) {
        yield put(authorDetailAction.loading(false));
        openNotification('error', 'Get Author Novel List Failed');
    }

}

function* watchUpdatePage() {
    yield takeEvery(authorDetailKey.UPDATE_PAGE, getAuthorNovel);
}

export function* watchAuthorDetailSaga() {
    yield all([
        fork(getAuthorDetail),
        fork(watchUpdatePage)
    ]);
}