import * as React from 'react';
import { Col, Form, Row, Select, Input, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { getExportTitles } from '../../../base/getExportTitles';
import { Link } from 'react-router-dom';

const FormItem = Form.Item;
const Option = Select.Option;

interface Props extends FormComponentProps {
    data: any;
    initPage: () => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    searchDate: (value: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
}

class PaidNovelManager extends React.Component<Props, any> {

    state = {
        chargeStatus: ['Unopened', 'Opened', 'Author Application', 'Opened']
    };

    componentDidMount() {
        this.props.initPage();
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    clear = () => {
        this.setState({Time: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            shelfStatus: value.status,
            id: value.id,
            name: value.name,
            chargeStatus: value.payment,
            typeId: value.typeId,
            author: value.author
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }

        this.props.searchDate(param);
    }

    columns = () => {
        const { chargeStatus } = this.state;
        return [
            {
                title: 'Novel ID',
                dataIndex: 'id',
                key: 'id',
                className: 'column-center',
                sorter: true,
                width: 110,
                sortField: 'id',
            },
            {
                title: 'Novel Name',
                dataIndex: 'name',
                key: 'name',
                className: 'column-center',
                width: 250,
            },
            {
                title: 'Author',
                dataIndex: 'author',
                key: 'author',
                className: 'column-center',
            },
            {
                title: 'Category',
                dataIndex: 'typeName',
                key: 'typeName',
                className: 'column-center',
                render: (text: string) => (<span>{text ? text : '--'}</span>)
            },
            {
                title: 'Chapter Number',
                dataIndex: 'chapterCount',
                key: 'chapterCount',
                className: 'column-center',
                sorter: true,
                sortField: 'chapterCount',
                render: (text: number) => (<span>{text ? text : '--'}</span>)
            },
            {
                title: 'Opening Mode',
                dataIndex: 'chargeResult',
                key: 'chargeResult',
                className: 'column-center',
                render: (text: number | string) => (<span>{text === 0 ? 'Platform Opening' : text === 1 ? 'Author\'s Application for Opening' : '--'}</span>)
            },
            {
                title: "Pay Apply Time",
                dataIndex: "paidApplicationAtValue",
                key: "paidApplicationAtValue",
                className: 'column-center',
                render: (text: number) => (<span>{text}</span>)
            },
            {
                title: 'Status',
                dataIndex: 'shelfStatus',
                key: 'shelfStatus',
                className: 'column-center',
                render: (text: number) => (<span>{text ? 'Show' : 'Hide'}</span>)
            },
            {
                title: 'Payment Situation',
                dataIndex: 'chargeStatus',
                key: 'chargeStatus',
                className: 'column-center',
                render: (text: number) => (<span>{chargeStatus[text]}</span>)
            },
            {
                title: 'Setting',
                dataIndex: 'Setting',
                key: 'Setting',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return <Link to={{pathname: '/content/chapterManager', search: `?id=${record.id}&key=pay&status=${record.chargeStatus}` }}>view14</Link>;
                }
            },
        ];
    }

    render() {

        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const { data, updatePage } = this.props;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const statusList = [
            {key: 'All', value: 'All'},
            {key: '1', value: 'Show'},
            {key: '0', value: 'Hide'}
        ];

        const payStatus = [
            {key: 'All', value: 'All'},
            {key: '0', value: 'Unopened'},
            {key: '1', value: 'Opened'},
            {key: '2', value: 'Author Application'}
        ];

        return (
            <div>
                <Form>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="Status">
                                {getFieldDecorator('status', {
                                    initialValue: 'All'
                                })(
                                    <Select {...input}>
                                        {statusList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Category">
                                {getFieldDecorator('typeId', {
                                    initialValue: 'All'
                                })(
                                    <Select {...input}>
                                        <Option key="All">All</Option>
                                        {
                                            data.typeList.map((item: any) => {
                                                return <Option key={item.id}>{item.name}</Option>;
                                            })
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Payment Situation">
                                {getFieldDecorator('payment', {
                                    initialValue: 'All'
                                })(
                                    <Select {...input}>
                                        {payStatus.map(item => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Novel ID">
                                {getFieldDecorator('id')(
                                    <Input />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Novel Name">
                                {getFieldDecorator('name')(
                                    <Input />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Author Name">
                                {getFieldDecorator('author')(
                                    <Input />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                <Toolbar
                    showExport={true}
                    onSearch={this.onSearch}
                    onClear={this.clear}
                    onExport={this.handleExport}
                />
                <Table
                    loading={data.loading}
                    bordered={true}
                    rowKey="id"
                    columns={this.columns()}
                    style={{marginTop: '20px'}}
                    dataSource={data.dataList}
                    onChange={updatePage}
                    pagination={{
                        defaultPageSize: 20,
                        total: data.total,
                        current: data.page.current,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '50', '80', '100'],
                        showTotal: total => {
                            return `Total: ${total} items`;
                        },
                    }}
                />
                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />
            </div>
        );
    }
}

export default Form.create()(PaidNovelManager);