import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 获取推荐小说列表
 */
export function getRecommendList(param: any = {}) {
    return postJson('/recommend/getRecommendList', param);
}

/**
 * 获取类型列表
 */
export function getNovelTypeList(param?: any) {
    return postJson('/noveltype/getNovelTypeList', param);
}

/**
 * 获取楼层列表
 */
export function getFloorList(param?: any) {
    return postJson('/floor/getFloorList', param);
}

/**
 * 添加楼层
 */
export function addFloor(param: any) {
    return postJson('/floor/addFloor', param);
}

/**
 * 修改楼层位置
 */
export function modifyFloorLocation(param: any) {
    return postJson('/floor/modifyFloorLocation', param);
}

/**
 * 修改楼层
 */
export function modifyFloor(param: any) {
    return postJson('/floor/modifyFloor', param);
}

/**
 * 修改楼层上下架
 */
export function modifyFloorStatus(param: any) {
    return postJson('/floor/modifyFloorStatus', param);
}

/**
 * 删除楼层
 */
export function deleteFloor(id: string|number) {
    return postJson('/floor/deleteFloor', id);
}

/**
 * 搜索小说列表
 */
export function getNovelListByType(param: any) {
    return postJson('/novel/getNovelListByType', param);
}

/**
 * 添加推荐小说
 */
export function addRecommend(id: number) {
    return postJson('/recommend/addRecommend', id);
}

/**
 * 更换推荐小说
 */
export function changeRecommend(param: any) {
    return postJson('/recommend/changeRecommend', param);
}

/**
 * 删除推荐小说
 */
export function deleteRecommendBatch(id: any) {
    return postJson('/recommend/deleteRecommendBatch', id);
}

/**
 * 修改推荐小说排序
 */
export function modifyRecommendLocation(param: any) {
    return postJson('/recommend/modifyRecommendLocation', param);
}

/**
 * 导出推荐小说
 */
export function exportRecommendList(param: any) {
    return postJsonForExport('/recommend/getRecommendList/export', param, 'recommend.xlsx');
}

/**
 * 获取楼层小说数据 - new
 */
export function getFloorNovelList(param: any) {
    return postJson('/floor/getFloorNovelList', param);
}

/**
 * 修改楼层小说数据 - new
 */
export function modifyFloorNovel(param: any) {
    return postJson('/floor/modifyFloorNovel', param);
}

/**
 * 删除楼层小说数据 - new
 */
export function deleteFloorNovel(param: any) {
    return postJson('/floor/deleteFloorNovel', param);
}

/**
 * 修改楼层小说样式 - new
 */
export function modifyFloorstyle(param: any) {
    return postJson('/floor/modifyFloorStyle', param);
}

/**
 * 导出楼层小说列表 - new
 */
export function exportFloorNovelList(param: any) {
    return postJsonForExport('/floor/exportFloorNovelList', param, 'recommend.xlsx');
}

// Leaderboard modal
export function getRankList() {
    return postJson('/rank/getRankList');
}

export function changeStarSort(param: any) {
    return postJson('/rank/modifyRankLocation', param);
}

export function changeStarName(param: any) {
    return postJson('/rank/modifyRank', param);
}

export function changeStarStatus(param: any) {
    return postJson('/rank/modifyRankStatus', param);
}

export function getLimitFreeSetting(param: any) {
    return postJson('/floor/getLimitFreeSetting', param);
}

export function modifyLimitFreeSetting(param: any) {
    return postJson('/floor/modifyLimitFreeSetting', param);
}