import { fork, all, takeEvery, call, put, take } from 'redux-saga/effects';
import RecommendActionsTypeKey from '../../components/creation/action/actionsTypeKey/recommendLocationActionsTypeKey';
import * as recommendLocationServer from '../../servers/recommendLocationServer';
import * as recommendLocationAction from '../../components/creation/action/recommendLocationAction';
import { openNotification } from '../../components/widgets/Notification';

function* initPage() {
    yield getFloorList();
}

function* getFloorList() {
    try {
        yield put(recommendLocationAction.isLoading(true));
        const { result } = yield call(recommendLocationServer.getRecommendLocationList);
        yield put(recommendLocationAction.getFloorList(result));
        yield put(recommendLocationAction.isLoading(false));
    } catch (err) {
        openNotification('error', 'Failed to get floor list.');
    }
}

function* watchDeleteFloorDataList() {
    while (true) {
        const { payload } = yield take(RecommendActionsTypeKey.DELETE_FLOOR);
        try {
            yield put(recommendLocationAction.isLoading(true));
            yield call(recommendLocationServer.deleteRecommendLocation, payload);
            yield getFloorList();
            yield put(recommendLocationAction.isLoading(false));
            openNotification('success', 'Delete success.');
        } catch (err) {
            openNotification('error', 'Delete failed.');
            yield put(recommendLocationAction.isLoading(false));
        }
    }
}

function* watchAddFloorDataList() {
    while (true) {
        const { payload } = yield take(RecommendActionsTypeKey.ADD_FLOOR_DATA);
        try {
            yield put(recommendLocationAction.isLoading(true));
            yield call(recommendLocationServer.addRecommendLocation, payload);
            yield getFloorList();
            yield put(recommendLocationAction.isLoading(false));
            yield put(recommendLocationAction.openCloseFloorModal(false));
            openNotification('success', 'Add success.');
        } catch (err) {
            openNotification('error', 'Add failed.');
            yield put(recommendLocationAction.isLoading(false));
        }
    }
}

function* watchEditFloorDataList() {
    while (true) {
        const { payload } = yield take(RecommendActionsTypeKey.EDIT_FLOOR_DATA);
        try {
            yield put(recommendLocationAction.isLoading(true));
            // Number(payload.id) < 100 ?
            //     yield call(recommendServer.modifyFloorstyle, payload)
            //     :
            yield call(recommendLocationServer.modifyRecommendLocation, payload);

            yield getFloorList();
            yield put(recommendLocationAction.isLoading(false));
            yield put(recommendLocationAction.openCloseFloorModal(false));
            openNotification('success', 'Edit success.');
        } catch (err) {
            openNotification('error', 'Edit failed.');
            yield put(recommendLocationAction.isLoading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(RecommendActionsTypeKey.INIT_PAGE, initPage);
}

export function* watchRecommendLocationSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchDeleteFloorDataList),
        fork(watchAddFloorDataList),
        fork(watchEditFloorDataList),
    ]);
}