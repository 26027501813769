import * as React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

interface LinkType {
    icon: string;
    key: string;
    linkTo: string;
    value: string;
}

type ContentProps = {
    linkList?: LinkType[];
    children: React.ReactNode;
};

export default class Contents extends React.Component<ContentProps, {}> {

    render() {
        const { children } = this.props;

        // const pageHeight = Number(document.body.clientHeight) - 170;

        return (
            <div style={{height: '100%'}} className="layoutContainer">
                <Layout style={{background: '#fff', height: '100%'}}>
                    <Content style={{ padding: '24px'}}>
                        {children}
                    </Content>
                </Layout>
            </div>
        );
    }
}