import { fork, all, put, call, takeEvery, select, take } from 'redux-saga/effects';
import novelDataActionsTypeKey from '../../components/data/actions/actionsTypeKey/novelDataActionsTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as dataServer from '../../servers/dataServer';
import * as novelDataAction from '../../components/data/actions/novelDataAction';

// 初始页面请求登录
function* initPage() {
    yield getNovelDataList();
    yield getTypeList();
    // yield getTagList();
}

function* getNovelDataList() {
    try {
        yield put(novelDataAction.loading(true));
        const state = yield select();
        const data = state.data.novelData;
        const param = {
            ...data.page,
            ...data.sorter,
            data: {...data.searchData}
        };
        yield put(novelDataAction.loading(true));
        let result = yield call(dataServer.getNovelData, param);
        yield put(novelDataAction.getNovelDataList({data: result.result.data, total: result.result.total}));
        yield put(novelDataAction.loading(false));
    } catch (error) {
        openNotification('error', 'Failed to get novel data list.');
        yield put(novelDataAction.loading(false));
    }
}

function* getTypeList() {
    try {
        let result = yield call(dataServer.getNovelTypeList);
        yield put(novelDataAction.getTypeList(result.result));
    } catch (error) {
        openNotification('error', 'Failed to get type list.');
    }
}

// function* getTagList() {
//     try {
//         let result = yield call(dataServer.getNovelTagList);
//         yield put(novelDataAction.getNovelTagList(result.result));
//     } catch (error) {
//         openNotification('error', 'Failed to get tag list.');
//     }
// }

function* watchExport() {
    while (true) {
        const { payload } = yield take(novelDataActionsTypeKey.SUBMIT_NOVEL_DATA_EXPORT);
        const state = yield select();
        const data = state.data.novelData;

        payload.titles.map((o: any) => {
           if (o.index === 'novelType') {
               o.index = 'novelTypeExport';
           }

           if (o.index === 'signed') {
               o.index = 'signedExport';
           }
        });

        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            data: {...data.searchData},
            ...data.sorter
        };

        try {
            yield put(novelDataAction.loading(true));
            yield call(dataServer.exportNovelData, param);
            yield put(novelDataAction.loading(false));
            yield put(novelDataAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(novelDataAction.loading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(novelDataActionsTypeKey.NOVEL_DATA_PAGE_INIT, initPage);
}
function* watchSearchData() {
    yield takeEvery(novelDataActionsTypeKey.NOVEL_DATA_LIST_DATA_SEARCH, getNovelDataList);
}

function* watchUpdatePageSize() {
    yield takeEvery(novelDataActionsTypeKey.UPDATE_NOVEL_DATA_TABLE_PAGE_SIZE, getNovelDataList);
}

export function* watchNovelDataRootSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchUpdatePageSize),
        fork(watchSearchData),
        fork(watchExport),
    ]);
}