import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { FinancialManager } from './views/Manager';
import VipOrder from './views/containers/vipOrderContainers';
import RewardOrder from './views/containers/rewardOrderContainers';
import currencyOrder from './views/containers/currencyOrderContainers';

export const financialRouters = (
    <FinancialManager>
        <Switch>
            <Route path="/financialManage/vipOrder" component={VipOrder}/>
            <Route path="/financialManage/rewardOrder" component={RewardOrder}/>
            <Route path="/financialManage/currencyOrder" component={currencyOrder}/>
            <Redirect to="/financialManage/vipOrder"/>
        </Switch>
    </FinancialManager>
);
