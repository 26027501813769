import * as React from 'react';
import { Row, Col, Divider, Checkbox, Button, Popconfirm, Spin } from 'antd';
import { Link } from 'react-router-dom';

const CheckboxGroup = Checkbox.Group;

interface MapToProps {
    match: any;
    location: any;
    data: any;
    initPage: () => void;
    getTagId: (id: string) => void;
    getTypeId: (id: string) => void;
    getDelIds: (param: any) => void;
}

interface TypeDetailState {
    tagName: string;
    checkedValues: any[];
    indeterminate: boolean;
    checkAll: boolean;
    delDisabled: boolean;
    isEdit: boolean;
}

class TypeDetail extends React.Component <MapToProps, TypeDetailState> {

    state: TypeDetailState = {
        tagName: '',
        checkedValues: [],
        indeterminate: true,
        checkAll: false,
        delDisabled: true,
        isEdit: true
    };

    componentDidMount() {
        let id = this.props.match.params.id;
        let search = this.props.location.search.split('?')[1].split('=')[1];
        this.setState({tagName: search});
        this.props.initPage();
        this.props.getTagId(id);

    }

    onChange = (checkedValues: any) => {
        checkedValues.length > 0 ? this.setState({delDisabled: false}) : this.setState({delDisabled: true});
        this.setState({
            checkedValues,
        });
    }

    editCategory = () => {
        let isEdit = this.state.isEdit;
        this.setState({
            isEdit: !isEdit,
            delDisabled: true,
        });
    }

    getCategoryId = (id: string) => {
        this.props.getTypeId(id);
    }

    delCategory = () => {
        let param = {
            tagId: this.props.match.params.id,
            typeList: this.state.checkedValues
        };
        this.props.getDelIds(param);
    }

    render() {

        const { data } = this.props;
        const { delDisabled, isEdit, tagName } = this.state;

        return (
            <div>
                <Row>
                    <Col>
                        <h2>Tag: {tagName}</h2>
                        <Divider />
                    </Col>
                    <Col>
                        <Row>
                            <Col span={5} style={{borderRight: '1px solid #e8e8e8', marginRight: '15px'}}>
                                <div style={{ borderBottom: '1px solid #E9E9E9' }}>
                                    <Row>
                                        <Col span={12} style={{padding: '12px 0'}}>
                                            <Button type="primary" onClick={this.editCategory}>{isEdit ? 'Edit' : 'Cancel'}</Button>
                                        </Col>
                                        <Col span={12} style={{padding: '12px 5px', textAlign: 'right'}}>
                                            <Popconfirm
                                                title="Are you sure delete this category?"
                                                onConfirm={this.delCategory}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button
                                                    disabled={delDisabled}
                                                    type="danger"
                                                >
                                                    Delete
                                                </Button>
                                            </Popconfirm>

                                        </Col>
                                    </Row>
                                </div>
                                <br />
                                {
                                    isEdit ?
                                        <ul style={{padding: 0}}>
                                            {
                                                data.typeList.map((item: any, idx: number) => {
                                                    return <li
                                                        onClick={(e) => {this.getCategoryId(item.id); }}
                                                        style={{listStyle: 'none', marginBottom: '15px', cursor: 'pointer'}}
                                                        key={idx}
                                                    >
                                                        {item.name}
                                                    </li>;
                                                })
                                            }
                                        </ul>
                                        :
                                        <CheckboxGroup
                                            onChange={this.onChange}
                                        >
                                            <Row>
                                                {
                                                    data.typeList.map((item: any, idx: number) => {
                                                        return <Col style={{marginBottom: '15px'}}  key={idx}>
                                                            <Checkbox value={item.id}>{item.name}</Checkbox>
                                                        </Col>;
                                                    })
                                                }
                                            </Row>
                                        </CheckboxGroup>
                                }
                            </Col>
                            <Col span={18}>
                                <Spin spinning={data.loading}>
                                    <ul>
                                        {
                                            data.novelList.length > 0 ?
                                                data.novelList.map((item: any, idx: number) => {
                                                    return <li
                                                        key={idx}
                                                        style={{
                                                            listStyle: 'none',
                                                            padding: '12px 24px',
                                                            borderBottom: '1px solid #e8e8e8'
                                                        }}
                                                    >
                                                        <Link
                                                            to={{pathname: `/content/chapterManager`, search: `?id=${item.id}`}}
                                                            style={{color: '#2392FC'}}
                                                        >
                                                            {item.name}
                                                        </Link>

                                                    </li>;
                                                }) :
                                                <p style={{textAlign: 'center', color: '#888888'}}>no data</p>
                                        }
                                    </ul>
                                </Spin>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default TypeDetail;
