import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Table, Form, Row, Col, Spin, Button, Popconfirm, Select } from 'antd';

import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../reducers/versionContentreducer';
import { getExportTitles } from '../../../base/getExportTitles';
import { AddVersionModals } from './AddVersionModals';

const FormItem = Form.Item;
const Option = Select.Option;

interface VersionContentState {
    deleteList: any[];
}

interface VersionContentProps {
    data: StoreState;
    initPage: () => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    versionListSearch: (value: any) => void;
    exportModalVisible: (value: boolean) => void;
    addVersionModalVisible: (value: boolean) => void;
    addVersionData: (value: any) => void;
    submitExport: (value: any) => void;
    changeVersionStatus: (value: any) => void;
    deleteVersionData: (value: string[]) => void;
}

class VersionContent extends React.Component<FormComponentProps & VersionContentProps, VersionContentState> {
    state: VersionContentState = {
        deleteList: [],
    };

    componentWillMount() {
        this.props.initPage();
    }

    columns = () => {
        return[
            {
                title: 'Version Code',
                dataIndex: 'versionCode',
                key: 'versionCode',
                className: 'column-center',
            },
            {
                title: 'Version Name',
                dataIndex: 'versionName',
                key: 'versionName',
                className: 'column-center',
            },
            {
                title: 'Device',
                dataIndex: 'systemTypeName',
                key: 'systemTypeName',
                className: 'column-center',
                render: (value: any, record: any) => ( record.systemType === 0 ? 'Android' : 'iOS'),
            },
            {
                title: 'Date',
                dataIndex: 'createdAtDateValue',
                key: 'createdAtDateValue',
                className: 'column-center',
            },
            {
                title: 'Time',
                dataIndex: 'createdAtTimeValue',
                key: 'createdAtTimeValue',
                className: 'column-center',
            },
            {
                title: 'Content',
                dataIndex: 'updateNote',
                key: 'updateNote',
                width: 280,
                className: 'column-center',
                render: (value: string) =>
                    <div dangerouslySetInnerHTML={{__html: value.replace(/\r?\n/g, '<br />')}}/>,
            },
            {
                title: 'Available',
                dataIndex: 'available',
                key: 'available',
                className: 'column-center',
                render: (value: any, record: any) => ( record.available === 0
                    ?
                    (
                        <Popconfirm
                            title="Are you sure change this version?"
                            onConfirm={() => this.changeVersionStatus(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div style={{color: '#2392FC', cursor: 'pointer'}}>No</div>
                        </Popconfirm>
                    )
                    :
                    (
                        <Popconfirm
                            title="Are you sure change this version?"
                            onConfirm={() => this.changeVersionStatus(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div style={{color: '#2392FC', cursor: 'pointer'}}>Yes</div>
                        </Popconfirm>
                    )
                ),
            },
        ];
    }

    changeVersionStatus = (value: any) => {
        let param = {
            id: value.id,
            available: value.available
        };
        this.props.changeVersionStatus(param);
    }

    showModal = () => {
        this.onClear();
        this.props.addVersionModalVisible(true);
    }

    delete = () => {
        this.props.deleteVersionData(this.state.deleteList);
        this.setState({deleteList: []});
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    onClear = () => {
        this.props.form.resetFields();
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param = {
            systemType: value.systemType_search,
        };
        for (let item in param) {
            if (param[item] === 'All' || param[item] === 'Android' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.versionListSearch(param);
    }

    render() {
        const { getFieldDecorator,  getFieldsValue } = this.props.form;
        const { data, updatePage, addVersionModalVisible, addVersionData } = this.props;

        const input = { style: {width: '80%', color: '#000'}};

        const rowSelection = {
            onChange: (selectedRowKeys: any) => {
                console.log(selectedRowKeys);
                this.setState({deleteList: selectedRowKeys});
            },
        };

        const typeList = [
            {key: 'All', value: 'All'},
            {key: 0, value: 'Android'},
            {key: 1, value: 'iOS'}
        ];

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Row>
                        <Col span={8}>
                            <FormItem label="Device">
                                {getFieldDecorator('systemType_search', {
                                    initialValue: 'All'
                                })(
                                    <Select {...input}>
                                        {typeList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Toolbar
                        showExport={true}
                        hideClearButton={false}
                        hideSearchButton={false}
                        onExport={this.handleExport}
                        onSearch={this.onSearch}
                        onClear={this.onClear}
                    >
                        <Button icon="plus" type="primary" onClick={() => this.showModal()} style={{width: '97px', margin: '10px 15px 10px 0'}}>Add</Button>
                        <Popconfirm
                            title="Are you sure delete?"
                            onConfirm={this.delete}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button icon="delete" type="danger" disabled={this.state.deleteList.length === 0}>Delete</Button>
                        </Popconfirm>
                    </Toolbar>
                    <Table
                        rowKey="id"
                        bordered={true}
                        onChange={updatePage}
                        columns={this.columns()}
                        rowSelection={rowSelection}
                        style={{marginTop: '20px'}}
                        dataSource={data.getVersionList}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total: ${total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        loading={data.loading}
                        getFieldsValue={getFieldsValue}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                        visible={data.exportModalVisible}
                        getFieldDecorator={getFieldDecorator}
                    />
                    <AddVersionModals
                        loading={data.loading}
                        getFieldsValue={getFieldsValue}
                        visible={data.versionModalVisible}
                        addVersionData={addVersionData}
                        getFieldDecorator={getFieldDecorator}
                        closeModal={() => addVersionModalVisible(false)}
                    />
                </Spin>
            </div>
        );
    }
}

export default Form.create()(VersionContent);
