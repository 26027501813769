import { fork, all, select, takeEvery, put, call, take } from 'redux-saga/effects';
import categoryTypeKey from '../../components/creation/action/actionsTypeKey/categoryTypeKey';
import * as categoryAction from '../../components/creation/action/categoryAction';
import * as typeServer from '../../servers/categoryServer';
import { openNotification } from '../../components/widgets/Notification';

function* getTypeData() {
    const state = yield select();
    const type = state.creation.category;
    const param = type.searchData;
    try {
        yield put(categoryAction.loading(true));
        const {result} = yield call(typeServer.getTypeList, param);
        yield put(categoryAction.loading(false));
        yield put(categoryAction.getTypeList(result));
    } catch (err) {
        yield put(categoryAction.loading(false));
        openNotification('error', 'Fail to get Category List');
    }
}

function* getNovelTagList() {
    try {
        const {result} = yield call(typeServer.getTagList);
        yield put(categoryAction.getTagList(result));
    } catch (err) {
        openNotification('error', 'Fail to get Category List');
    }
}

function* addEditType() {
    while (true) {

        const { payload } = yield take(categoryTypeKey.GET_ADD_EDIT_TYPE);
        const data = yield select();
        payload.tagList = data.creation.category.hasTagList;
        let device = data.creation.category.defaultDevice;

        try {
            device.includes('web') ? payload.ugcEnabled = 1 : payload.ugcEnabled = 0;
            device.includes('app') ? payload.appEnabled = 1 : payload.appEnabled = 0;
            yield call(typeServer.addEditType, payload);
            yield getTypeData();
        } catch (err) {
            if (err.code === 205) {
                openNotification('error', 'The novel Category no more than 20');
            } else if (err.code === 206) {
                openNotification('error', 'The novel Category does exist');
            } else {
                openNotification('error', 'Fail to add/edit type.');
            }

        }
    }
}

function* getHasTagList() {
    while (true) {
        let deviceArr = [];
        const { payload } = yield take(categoryTypeKey.GET_TYPE_ID);
        try {
            yield put(categoryAction.loading(true));
            const { result } = yield call(typeServer.getHasTag, payload);
            if (result.appEnabled === 1) {
                deviceArr.push('app');
            }
            if (result.ugcEnabled === 1) {
                deviceArr.push('web');
            }
            result.deviceArr = deviceArr;
            yield put(categoryAction.getHasTagList(result));

            yield put(categoryAction.loading(false));
        } catch (err) {
            openNotification('error', 'Fail to get novel tag list');
        }
    }
}

function* exportModel() {
    while (true) {
        const { payload } = yield take(categoryTypeKey.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);
        payload.titles.splice(0, 0, {title: 'ID', index: 'id'});
        const state = yield select();
        const type = state.creation.type;
        const param = {
            current: 1,
            pageSize: 20,
            titles: payload.titles,
            data: {
                name: type.searchData
            },
        };
        try {
            yield put(categoryAction.loading(true));
            yield call(typeServer.typeExport, param);
            yield put(categoryAction.loading(false));
            yield put(categoryAction.exportModalVisible(false));
        } catch (err) {
            yield put(categoryAction.loading(false));
            yield put(categoryAction.exportModalVisible(false));
            openNotification('error', 'Export failed.');
        }
    }
}

function* betchDelType() {
    while (true) {
        const { payload } = yield take(categoryTypeKey.GET_BETCH_DEL_TYPE_ID);
        try {
            yield call(typeServer.betchDelType, payload);
            openNotification('success', 'Del Success');
            yield getTypeData();
        } catch (err) {
            if (err.code === 207) {
                openNotification('error', 'The novel type has novels');
            } else {
                openNotification('error', 'Failed to del type');
            }
        }
    }
}

function* betchAddNovelIds() {
    while (true) {
        const { payload } = yield take(categoryTypeKey.GET_BETCH_ADD_NOVEL_ID);
        try {
            yield call(typeServer.batchAddNovelId, payload);
            openNotification('success', 'Add Success');
            yield getTypeData();
        } catch (err) {
            if (err.code === 207) {
                openNotification('error', 'The novel type has novels');
            } else {
                openNotification('error', 'Failed to add novels');
            }
        }
    }
}

function* initPage() {
    yield getTypeData();
    yield getNovelTagList();
}

function* watchInitPage() {
    yield takeEvery(categoryTypeKey.CATEGORY_INIT_PAGE, initPage);
}

function* watchSearch() {
    yield takeEvery(categoryTypeKey.GET_TYPE_SEARCH_PARAM, getTypeData);
}

function* watchUpdatePageSize() {
    yield takeEvery(categoryTypeKey.UPDATE_PAGE, getTypeData);
}

export function* watchCategorySaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearch),
        fork(addEditType),
        fork(getHasTagList),
        fork(exportModel),
        fork(betchDelType),
        fork(betchAddNovelIds),
        fork(watchUpdatePageSize)
    ]);
}