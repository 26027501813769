import HelpCenter  from '../Help';
import { connect } from 'react-redux';
import * as helpCenterAction from '../../actions/helpCenterActions';

const mapStateToProps = (state: any) => ({
    data: state.webManager.webHelpCenter
});

const mapDispatchToProps = {
    initPage: helpCenterAction.initPage,
    getTitleModalStatus: helpCenterAction.getTitleModalStatus,
    getLocationModalStatus: helpCenterAction.getLocationModalStatus,
    getChangeHelpModalSortMsg: helpCenterAction.getChangeHelpModalSortMsg,
    addHelpModal: helpCenterAction.addHelpModal,
    editHelpModal: helpCenterAction.editHelpModal,
    delHelpModal: helpCenterAction.delHelpModal,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(HelpCenter);