import * as React from 'react';
import { Row, Col, Button, Popconfirm, Table, Modal, Form, Input, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { openNotification } from '../../widgets/Notification';
import { Redirect } from 'react-router-dom';
import '../../../style/settingTopic.css';

const FormItem = Form.Item;
const { Option } = Select;

interface Props extends FormComponentProps {
    data: any;
    initPage: () => void;
    openCloseFloorLocationModal: (bol: boolean) => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    openCloseFloorModal: (bol: boolean) => void;
    getLocationSort: (param: any) => void;
    delFloor: (id: number | string) => void;
    addFloorDataList: (value: any) => void;
    editFloorDataList: (value: any) => void;
}

type State = {
    isEditFloor: boolean;
    floorId: any;
    detailItem: any;
    toDetail: boolean;
    starId: number | string;
};

class RecommendManager extends React.Component<Props, State> {

    state: State = {
        isEditFloor: false,
        floorId: -1,
        detailItem: {},
        toDetail: false,
        starId: 0
    };

    componentDidMount(): void {
        this.props.initPage();
    }

    floorColumn = (): any[] => {
        const { delFloor } = this.props;
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text: string, record: any) => {
                    return (
                        <span
                            style={{color: '#1890ff', cursor: 'pointer'}}
                            onClick={() => {this.toFloorDetail(record); }}
                        >
                            {text}
                        </span>
                    );
                }
            },
            {
                title: 'Identify',
                dataIndex: 'identify'
            },
            {
                title: 'Remark',
                dataIndex: 'remark',
                key: 'remark'
            },
            {
                title: 'Operating',
                dataIndex: 'setting',
                key: 'setting',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            <span
                                style={{marginRight: '15px', color: '#1890ff',  cursor: 'pointer'}}
                                onClick={() => {this.floorModalShow(true, record); }}
                            >
                                Edit
                            </span>

                            <span
                                style={{ color: 'red', cursor: 'pointer'}}
                            >
                                 <Popconfirm
                                     title="Are you sure delete this task?"
                                     onConfirm={() => delFloor(record.id)}
                                     okText="Yes"
                                     cancelText="No"
                                 >
                                Delete
                                 </Popconfirm>
                            </span>
                        </div>
                    );
                }
            },
        ];
    }

    toFloorDetail = (item: any) => {
        this.setState({detailItem: item, toDetail: true});
    }

    floorModalShow = (bol: boolean, item?: any) => {
        this.setState({isEditFloor: bol, floorId: item && item.id});
        this.props.openCloseFloorModal(true);
        if (bol) {
            this.props.form.setFieldsValue({'name': item.name, 'identify': item.identify, 'remark': item.remark });
        } else {
            this.props.form.resetFields(['name', 'identify', 'remark']);
        }
    }

    floorAddFloorModal = () => {
        const value: any = this.props.form.getFieldsValue();
        const param = {
            name: value.name,
            identify: value.identify,
            remark: value.remark
        };
        if (!param.name || param.name.length > 20) {
            openNotification('error', 'Recommend Location name no more than 20 characters cannot be null.');
        } if (!param.identify) {
            openNotification('error', 'Recommend Identify cannot be null.');
        } else {
            this.props.addFloorDataList(param);
        }
    }

    floorEditFloorModal = () => {
        const value: any = this.props.form.getFieldsValue();
        const param = {
            id: this.state.floorId,
            name: value.name,
            identify: value.identify,
            remark: value.remark
        };
        if (param.id === -1) {
            param.id = this.props.data.floorList[0].id;
        }
        if (!param.name || param.name.length > 20) {
            openNotification('error', 'Recommend Location name no more than 20 characters cannot be null.');
        } if (!param.identify) {
            openNotification('error', 'Recommend Identify cannot be null.');
        } else {
            this.props.editFloorDataList(param);
        }
    }

    floorModalCancel = () => {
      this.props.openCloseFloorModal(false);
    }

    getIdentifyOptions = (floorList) => {
        const identify = this.props.form.getFieldValue('identify');
        const identifies = floorList.map(item => item.identify);
        const options = [
            { key: 'bookshelf', name: 'Bookshelf'},
            { key: 'search_placeholder', name: 'Search Place Holer'},
            { key: 'search_trending', name: 'Search Trending'},
            { key: 'search_recommend', name: 'Search Recommend'},
            { key: 'book_detail_trending', name: 'Book Detail Trending'}
        ];
        return options.filter(item => item.key === identify || identifies.indexOf(item.key) < 0).map(item => (
            <Option key={item.key} value={item.key}>{item.name}</Option>
        ));
    }

    render() {

        const { data, updatePage } = this.props;
        const { getFieldDecorator } = this.props.form;

        const { isEditFloor, detailItem, toDetail } = this.state;

        if (toDetail) {
            return <Redirect push={true} to={{pathname: `/creationManage/recommendLocationDetail`, search: `?id=${detailItem.id}&name=${detailItem.name}`}}/>;
        }

        return (
          <div>
                <Row style={{marginBottom: '20px'}}>
                    <Col>
                        <Button type="primary" style={{float: 'right'}} onClick={() => {this.floorModalShow(false); }}>Add Recommend Location</Button>
                    </Col>
                </Row>
                <Table
                    rowKey={'id'}
                    loading={data.loading}
                    bordered={true}
                    dataSource={data.floorList}
                    columns={this.floorColumn()}
                    onChange={updatePage}
                    pagination={false}
                />

          {/*  add floor  */}
              <Modal
                  title={isEditFloor ? 'Edit Floor' : 'Add Floor'}
                  visible={data.floorModalVisible}
                  onOk={isEditFloor ? this.floorEditFloorModal : this.floorAddFloorModal}
                  onCancel={this.floorModalCancel}
                  confirmLoading={data.floorModalLoading}
                  width={500}
                  maskClosable={false}
              >
                  <Form>
                      <FormItem
                          label="Name"
                      >
                          {getFieldDecorator('name', {
                              rules: [
                                  {
                                      required: true,
                                      message: 'Please input Name!',
                                  }
                              ]
                          })(
                              <Input />
                          )}
                      </FormItem>
                      <FormItem label="Identify">
                          {getFieldDecorator('identify', {
                              rules: [{ required: true }]
                          })(
                              <Select>
                                  {this.getIdentifyOptions(data.floorList)}
                              </Select>
                          )}
                        </FormItem>
                        <FormItem
                            label="Remark"
                        >
                            {getFieldDecorator('remark')(
                                <Input />
                            )}
                        </FormItem>
                  </Form>
              </Modal>
        
          </div>
        );
    }
}

export default Form.create()(RecommendManager);