import { fork, all, put, call, take } from 'redux-saga/effects';
import ChapterActionTypeKeys from '../../components/content/actions/actionsTypeKey/chapterActionsTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as  contentServer from '../../servers/contentServer';
import * as chapterAction from '../../components/content/actions/chapterAction';

function* watchGetChapterList() {
    while (true) {
        let {payload} = yield take(ChapterActionTypeKeys.SEND_CHAPTER_ID);
        try {
            yield put(chapterAction.loading(true));
            let result = yield call(contentServer.getChapterListById, payload);
            yield put(chapterAction.getChapterList(result.result));
            yield put(chapterAction.loading(false));
        } catch (error) {
            openNotification('error', 'Fail to get chapter list.');
            yield put(chapterAction.loading(false));
        }
    }
}

function* watchChapterSort() {
    while (true) {
        let { payload } = yield take(ChapterActionTypeKeys.CHAPTER_SORT);
        try {
            yield put(chapterAction.loading(true));
            let result = yield call(contentServer.modifyChapterSort, payload);
            yield put(chapterAction.getChapterList(result.result));
            yield put(chapterAction.loading(false));
        } catch (err) {
            openNotification('error', 'Sort failed.');
            yield put(chapterAction.loading(false));
        }
    }
}

function* watchGetNovelListByName() {
    while (true) {
        let { payload } = yield take(ChapterActionTypeKeys.SEND_NOVEL_VALUE);
        try {
            let result = yield call(contentServer.getNovelName, payload);
            let arr: any = [];
            result.result.map((item: any) => {
                arr.push(item.name);
            });
            yield put(chapterAction.getNovelList(arr));
            yield put(chapterAction.getAllNovelList(result.result));
        } catch (err) {
            openNotification('error', 'Get novel name list failed.');
            yield put(chapterAction.loading(false));
        }
    }
}

function* watchModalVisible() {
    while (true) {
        let { payload } = yield take(ChapterActionTypeKeys.MODAL_VISIBLE);
        try {
            yield put(chapterAction.modalVisible(payload));
        } catch (err) {
            yield put(chapterAction.modalVisible(false));
        }
    }
}

function* watchDumpChapter() {
    while (true) {
        let { payload } = yield take(ChapterActionTypeKeys.DUMP_CHAPTER);
        const url = window.location.href;
        let param = url.split('?')[1].split('=')[1].split('&')[0];
        try {
            yield put(chapterAction.loading(true));
            yield call(contentServer.redepositChapter, payload);
            let result = yield call(contentServer.getChapterListById, param);
            yield put(chapterAction.getChapterList(result.result));
            yield put(chapterAction.loading(false));
            yield put(chapterAction.modalVisible(false));
            openNotification('success', 'Dump success.');
        } catch (err) {
            openNotification('error', 'Dump failed.');
            yield put(chapterAction.loading(false));
        }
    }
}

function* watchDownloadChapter() {
    while (true) {
        let { payload } = yield take(ChapterActionTypeKeys.DOWNLOAD_CHAPTER);
        try {
            yield call(contentServer.downloadNovelChapter, {url: payload.url} , payload.name);
        } catch (err) {
            openNotification('error', 'Download failed.');
        }
    }
}

export function* watchChapterRootSaga() {
    yield all([
        fork(watchGetChapterList),
        fork(watchChapterSort),
        fork(watchDumpChapter),
        fork(watchGetNovelListByName),
        fork(watchModalVisible),
        fork(watchDownloadChapter),
    ]);
}