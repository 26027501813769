import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { FinancialManager } from './views/Manager';
import BlockWord from './views/containers/blockWordContainers';
import AdBanner from './views/containers/adBannerContainers';
import WebManager from './views/containers/webManagerContainers';
import AddWeb from './views/containers/addWebContainers';
import HelpCenter from './views/containers/helpCenterContainers';
import HelpDetail from './views/containers/helpDetailContainers';

export const webManagerRouters = (
    <FinancialManager>
        <Switch>
            <Route path="/webManager/webManager" component={WebManager}/>
            <Route path="/webManager/adBanner" component={AdBanner}/>
            <Route path="/webManager/blockWord" component={BlockWord}/>
            <Route path="/webManager/addWeb" component={AddWeb}/>
            <Route path="/webManager/help" component={HelpCenter}/>
            <Route path="/webManager/helpDetail" component={HelpDetail}/>
            <Redirect to="/webManager/blockWord"/>
        </Switch>
    </FinancialManager>
);
