import { postJson } from '@iliewen/js-common';

/**
 * 获取banner列表
 */
export function getBannerList(param: any) {
    return postJson('/banner/getBannerList', param);
}

/**
 * 修改Banner
 */
export function modifyBanner(param: any) {
    return postJson('/banner/modifyBanner', param);
}

/**
 * 修改banner上架状态
 */
export function modifyShelfStatus(param: any) {
    return postJson('/banner/modifyShelfStatus', param);
}

/**
 * 批量删除Banner
 */
export function deleteBannerBatch(param: any) {
    return postJson('/banner/deleteBannerBatch', param);
}

/**
 * 添加Banner
 */
export function addBanner(param: any) {
    return postJson('/banner/addBanner', param);
}

/**
 * 修改推荐小说排序
 */
export function modifyBannerSort(param: any) {
    return postJson('/banner/modifyBannerSort', param);
}
