import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Form, Row, Col, Button, Upload, Icon, Modal, Spin } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { Prompt } from 'react-router-dom';
import { openNotification } from '../../widgets/Notification';
import { StoreState } from '../reducers/addWebManagerReducer';

const FormItem = Form.Item;
const { TextArea } = Input;

interface Props {
    data: StoreState;
    addDataList: (value: any) => void;
}

interface State {
    content: any;
    previewVisible: boolean;
    fileChange: boolean;
    isPrompt: boolean;
    fileList: any;
    previewImage: any;
}

class AddWeb extends React.Component<Props & FormComponentProps, State> {

    state: State = {
        content: '',
        previewVisible: false,
        fileChange: false,
        isPrompt: true, // 为了添加跳转页面不弹出框
        fileList: [],
        previewImage: '',
    };

    add = () => {
        const value: any = this.props.form.getFieldsValue();
        const {content} = this.state;

        let param: any = {
            title: value.name,
        };
        try {
            if (content) {
                param.contents = content;
            } else {
                param.contents = value.img;
                param.contents.file ? param.contents = param.contents.file.originFileObj : param.contents = value.img;
            }
            if (param.title && param.contents) {
                this.setState({isPrompt: false});
                this.props.addDataList(param);
            } else {
                openNotification('error', 'Announcement name or content is required.');
            }
        } catch (error) {
            openNotification('error', 'Announcement name or content is required.');
        }
    }

    goBack = () => {
        window.history.go(-1);
    }

    handleEditorChange = (e: any) => {
        this.setState({content: e.target.getContent()});
    }

    fileChange = ({file, fileList}: any) => {
        if (file.status === 'error') {
            file.status = 'done';
        }
        this.setState({fileList});
    }

    beforeUpload = (file: any) => {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJPG && (file.size > 819200)) {
            openNotification('error', 'You can only upload more than 800K JPG/PNG file!');
        }
        return isJPG;
    }

    handlePreview = (file: any) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleCancel = () => {
        this.setState({previewVisible: false});
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const { data } = this.props;
        const {content, fileList, previewVisible, previewImage, isPrompt} = this.state;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Prompt
                        when={!!content && isPrompt}
                        message="Are you sure you want to leave the current page?"
                    />
                    <Form>
                        <Row>
                            <Col>
                                <FormItem label="Announcement Name">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {required: true, message: 'Please enter the name of the announcement'},
                                            {max: 100, message: 'No more than 100 characters'},
                                        ],
                                    })(
                                        <TextArea {...input}/>
                                    )}
                                </FormItem>
                                <FormItem
                                    label="Content(You can only upload text or pictures, only one)"
                                >
                                    {getFieldDecorator('img')(
                                        <Upload
                                            listType="picture-card"
                                            disabled={content.length !== 0}
                                            fileList={fileList}
                                            onChange={this.fileChange}
                                            beforeUpload={this.beforeUpload}
                                            onPreview={(file) => this.handlePreview(file)}
                                            onRemove={() => this.setState({fileChange: true})}
                                        >
                                            {
                                                this.state.fileList.length >= 1 ? null : <div>
                                                    <Icon type="plus"/>
                                                    <div className="ant-upload-text">Upload</div>
                                                </div>
                                            }
                                        </Upload>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                    <Editor
                        initialValue="<p></p>"
                        init={{
                            plugins: [
                                'textcolor',
                            ],
                            toolbar: 'undo redo | formatselect | bold italic backcolor',
                        }}
                        disabled={fileList.length !== 0}
                        onChange={this.handleEditorChange}
                    />
                    <Button style={{margin: '30px 20px 0 0'}} type="primary" onClick={this.add}>Save</Button>
                    <Button  style={{marginTop: '30px'}} onClick={this.goBack}>Cancel</Button>
                    <Modal visible={previewVisible} footer={null} onCancel={() => this.handleCancel()}>
                        <img alt="example" style={{width: '100%'}} src={previewImage}/>
                    </Modal>
                </Spin>
            </div>
        );
    }
}

export default Form.create()(AddWeb);
