enum novelReviewTypeKey {
    INIT_PAGE = 'NOVEL_REVIEW_INIT_PAGE',
    UPDATE_PAGE = 'NOVEL_REVIEW_UPDATE_PAGE',
    EXPORT_MODAL_VISIBLE = 'NOVEL_REVIEW_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'NOVEL_REVIEW_SUBMIT_EXPORT',
    LOADING = 'NOVEL_REVIEW_LOADING',
    GET_SEARCH_DATA = 'GET_NOVEL_REVIEW_SEARCH_DATA',
    GET_TYPE_LIST = 'NOVEL_REVIEW_GET_TYPE_LIST',
    GET_TABS_KEY = 'NOVEL_REVIEW_GET_TABS_KEY',
    GET_NOVEL_REVIEW_LIST = 'GET_NOVEL_REVIEW_LIST'
}

export default novelReviewTypeKey;