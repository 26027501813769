import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { UserManage } from './views/UserManage';
import UserContent from './views/containers/userContainers';
import UserDetail from './views/containers/userDetailContainers';
import Author from './views/containers/authorContainers';
import AuthorDetail from './views/containers/authorDetailContainers';

export const userContentRouters = (
    <UserManage>
        <Switch>
            <Route path="/userManage/user" component={UserContent}/>
            <Route path="/userManage/userDetail/:id" component={UserDetail}/>
            <Route path="/userManage/author" component={Author}/>
            <Route path="/userManage/authorDetail/:id" component={AuthorDetail} />
            <Redirect to="/userManage/user"/>
        </Switch>
    </UserManage>
);