enum withdrawalTypeKey {
    INIT_PAGE = 'WITHDRAWAL_INIT_PAGE',
    UPDATE_PAGE = 'WITHDRAWAL_UPDATE_PAGE',
    EXPORT_MODAL_VISIBLE = 'WITHDRAWAL_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'WITHDRAWAL_SUBMIT_EXPORT',
    LOADING = 'WITHDRAWAL_LOADING',
    GET_SEARCH_DATA = 'GET_WITHDRAWAL_SEARCH_DATA',
    GET_TYPE_LIST = 'WITHDRAWAL_GET_TYPE_LIST',
    GET_TABS_KEY = 'WITHDRAWAL_GET_TABS_KEY',
    GET_WITHDRAWAL_TOTAL = 'GET_WITHDRAWAL_TOTAL',
    GET_WITHDRAWAL_LIST = 'GET_WITHDRAWAL_LIST',
    GET_WITHDRAWAL_DETAIL_ID = 'GET_WITHDRAWAL_DETAIL_ID',
    GET_WITHDRAWAL_DETAIL = 'GET_WITHDRAWAL_DETAIL',
    GET_WITHDRAWAL_CHANGE_STATUS = 'GET_WITHDRAWAL_CHANGE_STATUS'
}

export default withdrawalTypeKey;