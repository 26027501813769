import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Table, Form, Row, Col, Spin, DatePicker, Select } from 'antd';
// import * as moment from 'moment';

import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../reducers/floorDataReducer';
import { changeToStamp, timeReverseWithNoHour } from '../../../base/dealWithTime';
import { getExportTitles } from '../../../base/getExportTitles';

const FormItem = Form.Item;
const Option = Select.Option;

interface Props {
    data: StoreState;
    initPage: () => void;
    dataListSearch: (value: any) => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
}

interface State  {
    startTime: string;
    endTime: string;
}

class FloorData extends React.Component<Props & FormComponentProps, State> {

    state: State = {
        startTime: '',
        endTime: '',
    };

    componentDidMount() {
        this.props.initPage();
    }

    columns = () => {
        return [
            {
                title: 'Floor',
                dataIndex: 'name',
                key: 'name',
                className: 'column-center',
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                className: 'column-center',
            },
            {
                title: 'Recommend ID',
                dataIndex: 'recommendLocation',
                key: 'recommendLocation',
                className: 'column-center',
                sorter: true,
                sortField: 'recommendLocation',
            },
            {
                title: 'Click',
                dataIndex: 'clickCount',
                key: 'clickCount',
                className: 'column-center',
                sorter: true,
                sortField: 'clickCount',
            },
        ];
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param = {
            startTime: this.state.startTime.length === 0 ? undefined : changeToStamp(this.state.startTime) / 1000,
            endTime: this.state.endTime.length === 0 ? undefined : changeToStamp(this.state.endTime) / 1000,
            device: value.device,
            floorId: value.floor_id
        };
        if (param.floorId === this.props.data.floorList[0].name) {
            param.floorId = this.props.data.floorList[0].id;
        }
        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.dataListSearch(param);
    }

    onClear = () => {
        this.props.form.resetFields();
        this.setState({startTime: '', endTime: ''});
    }

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: timeReverseWithNoHour(startTime) + ' 00:00:00'});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: timeReverseWithNoHour(endTime) + ' 23:59:59'});
        } else {
            this.setState({endTime});
        }
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    render() {
        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const {data, updatePage } = this.props;

        const form = {
            style: {margin: '10px 30px'}
        };

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormItem label="Date" {...form}>
                                    {getFieldDecorator('startTime')(
                                        <DatePicker
                                            allowClear={true}
                                            format={'DD-MM-YYYY'}
                                            placeholder={'Start Time'}
                                            style={{width : '48%'}}
                                            onChange={this.startTimeChange}
                                        />
                                    )}
                                    {getFieldDecorator('endTime')(
                                        <DatePicker
                                            allowClear={true}
                                            format={'DD-MM-YYYY'}
                                            placeholder={'End Time'}
                                            style={{width : '48%', marginLeft : '4%'}}
                                            onChange={this.endTimeChange}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Device"
                                    {...form}
                                >
                                    {getFieldDecorator('device', {
                                        initialValue: 'All'
                                    })(
                                        <Select>
                                            <Option value="All">All</Option>
                                            <Option value="1">iOS</Option>
                                            <Option value="0">Android</Option>

                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Floor"
                                    {...form}
                                >
                                    {getFieldDecorator('floor_id', {
                                        initialValue: data.floorList.length > 0 ? data.floorList[0].name : ''
                                        }
                                    )(
                                        <Select>
                                            {
                                                data.floorList.map((item: any, index: number) => {
                                                    return  <Option key={index} value={item.id}>{item.name}</Option>;
                                                })
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>

                    <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.onClear} onExport={this.handleExport}/>
                    <Table
                        bordered={true}
                        rowKey="id"
                        columns={this.columns()}
                        style={{marginTop: '20px'}}
                        dataSource={data.dataList}
                        onChange={updatePage}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total: ${total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={getFieldsValue}
                        visible={data.exportModalVisible}
                        loading={data.loading}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                    />
                </Spin>
            </div>
        );
    }
}

export default Form.create()(FloorData);
