import { fork, all, takeEvery, call, put, select, take } from 'redux-saga/effects';
import monthlyListKey from '../../components/starList/actions/actionsTypeKey/monthlyListTypeKey';
import * as monthlyListAction from '../../components/starList/actions/monthlyActions';
import * as starServer from '../../servers/starServer';
import { getNovelTypeList } from '../../servers/novelServer';
import { openNotification } from '../../components/widgets/Notification';

import { getNowTime, timeReverseWithMonth } from '../../base/dealWithTime';

function* initPage() {
    yield getTypeList();
    yield getMonthlyList();
}

function* getTypeList(param?: any) {
    try {
        const {result} = yield call(getNovelTypeList, param);
        yield put(monthlyListAction.getNovelTypeList(result));
    } catch (err) {
        openNotification('error', 'Failed to get type list.');
    }
}

function* getMonthlyList() {
    const data = yield select();
    const manager = data.star.monthlyList;
    manager.searchData.typeId = manager.searchData.typeId === 'all' ? '' : manager.searchData.typeId;
    manager.searchData.month = manager.searchData.month ? manager.searchData.month : timeReverseWithMonth(getNowTime());
    const param = {
        ...manager.page,
        ...manager.sorter,
        'data': {...manager.searchData},
    };
    yield put(monthlyListAction.getMonthlyListData({}));
    try {
        yield put(monthlyListAction.loadingStatus(true));
        const { result } = yield call(starServer.getMonthlyList, param);
        yield put(monthlyListAction.getMonthlyListData(result));
        yield put(monthlyListAction.loadingStatus(false));
    } catch (err) {
        yield put(monthlyListAction.loadingStatus(false));
        openNotification('error', 'Failed to get monthly list.');
    }
}

function* exportModel() {
    while (true) {
        const { payload } = yield take(monthlyListKey.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);

        const state = yield select();
        const manager = state.star.monthlyList;

        manager.searchData.month = manager.searchData.month ? manager.searchData.month : timeReverseWithMonth(getNowTime());

        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            ...manager.sorter,
            data: {...manager.searchData},
        };

        try {
            yield put(monthlyListAction.loadingStatus(true));
            yield call(starServer.exportMonthList, param);
            yield put(monthlyListAction.loadingStatus(false));
            openNotification('success', 'Export success.');
            yield put(monthlyListAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(monthlyListAction.loadingStatus(false));
        }
    }
}

function* monthlyListConfirm() {
    while (true) {
        yield take(monthlyListKey.MONTYLY_LIST_CONFIRM);
        const state = yield select();
        const manager = state.star.monthlyList;
        try {
            yield call(starServer.starConfirm, manager.monthlyId);
            yield getMonthlyList();
            openNotification('success', 'Truncated Success');
        } catch (err) {
            if (err.code === 311) {
                openNotification('error', 'The list has been cut off');
            } else {
                openNotification('error', 'Confirm Failed.');
            }
        }
    }
}

function* monthlyRemoveRank() {
    while (true) {
        const { payload } = yield take(monthlyListKey.REMOVE_RANK);
        try {
            yield call(starServer.removeRank, payload);
            yield getMonthlyList();
            openNotification('success', 'Remove is Success');
        } catch (e) {
            openNotification('error', 'Remove is Failed');
        }
    }
}

function* watchSearchData() {
    yield takeEvery(monthlyListKey.GET_SEARCH_DATA, getMonthlyList);
}

function* watchInitPage() {
    yield takeEvery(monthlyListKey.INIT_PAGE, initPage);
}

function* watchUpdatePage() {
    yield takeEvery(monthlyListKey.UPDATE_PAGE, getMonthlyList);
}

export function* watchMonthlyListSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearchData),
        fork(watchUpdatePage),
        fork(exportModel),
        fork(monthlyListConfirm),
        fork(monthlyRemoveRank)
    ]);
}