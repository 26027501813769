import { fork, all, takeEvery, select, put, call, take } from 'redux-saga/effects';
import withdrawalKey from '../../components/audit/actions/actionsTypeKey/withdrawalTypeKey';
import * as withdrawalAction from '../../components/audit/actions/withdrawalAction';
import * as withdrawalServer from '../../servers/withdrawalServer';
import { openNotification } from '../../components/widgets/Notification';

function* initPage() {
    yield getWithdrawalList();
    yield getWithdrawTotal();
}

function* getWithdrawTotal() {
    try {
        const { result } = yield call(withdrawalServer.getWithdrawTotal);
        yield put(withdrawalAction.getWithdrawalTotal(result));
    } catch (err) {
        openNotification('error', 'Failed to get withdraw total.');
    }
}

function* getWithdrawalList() {
    const data = yield select();
    const manager = data.audit.withdrawal;
    const param = {
        ...manager.page,
        ...manager.sorter,
        'data': {...manager.searchData},
    };

    param.data.status = manager.tabsKey;
    yield put(withdrawalAction.getWithdrawalList([]));
    try {
        yield put(withdrawalAction.loading(true));
        const { result } = yield call(withdrawalServer.getWithdrawList, param);
        yield put(withdrawalAction.getWithdrawalList(result));
        yield put(withdrawalAction.loading(false));
    } catch (err) {
        yield put(withdrawalAction.loading(false));
        openNotification('error', 'Failed to get list.');
    }
}

function* exportModel() {
    while (true) {
        const { payload } = yield take(withdrawalKey.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);

        const state = yield select();
        const manager = state.audit.withdrawal;

        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            exportName: '',
            ...manager.sorter,
            data: {...manager.searchData},
        };

        param.data.status = manager.tabsKey;

        if (manager.tabsKey === '0') {
            param.exportName = 'withdrawListPendingReview.xlsx';
        } else if (manager.tabsKey === '1') {
            param.exportName = 'withdrawListPassed.xlsx';
        } else if (manager.tabsKey === '2') {
            param.exportName = 'withdrawListAuditFailure.xlsx';
        } else {
            param.exportName = 'withdrawListPaid.xlsx';
        }

        try {
            yield put(withdrawalAction.loading(true));
            yield call(withdrawalServer.exportWithdrawList, param);
            yield put(withdrawalAction.loading(false));
            openNotification('success', 'Export success.');
            yield put(withdrawalAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(withdrawalAction.loading(false));
        }
    }
}

function* getWithdrawalDetail() {
    while (true) {
        const { payload } = yield take(withdrawalKey.GET_WITHDRAWAL_DETAIL_ID);
        try {
            yield put(withdrawalAction.getWithdrawalDetail({}));
            yield put(withdrawalAction.loading(true));
            const { result } = yield call(withdrawalServer.getWithdrawDetailById, payload);
            yield put(withdrawalAction.loading(false));
            yield put(withdrawalAction.getWithdrawalDetail(result));
        } catch (err) {
            yield put(withdrawalAction.loading(false));
            openNotification('error', 'Failed to get Withdrawal Detail');
        }
    }
}

function* changeWithdrawalDetailStatus() {
    while (true) {
        const { payload } = yield take(withdrawalKey.GET_WITHDRAWAL_CHANGE_STATUS);
        try {
            yield call(withdrawalServer.modifyStatus, payload);
            if (payload.status === '2') {
                openNotification('success', 'Success to release');
            } else if (payload.status === '3') {
                openNotification('success', 'Success to confuse');
            } else {
                openNotification('success', 'Success to paid');
            }

            yield getWithdrawalList();
        } catch (err) {
            // console.log(err.code, err.message);
            if (payload.status === '2') {
                openNotification('error', 'Fail to release');
            } else if (payload.status === '3') {
                openNotification('error', 'fail to confuse');
            } else if (err.code === 313) {
                openNotification('error', 'insufficient User Balance!');
            } else {
                openNotification('error', 'fail to paid');
            }
        }
    }
}

function* watchSearchData() {
    yield takeEvery(withdrawalKey.GET_SEARCH_DATA, getWithdrawalList);
}

function* watchChangeTabsKey() {
    yield takeEvery(withdrawalKey.GET_TABS_KEY, getWithdrawalList);
}

function* watchInitPage() {
    yield takeEvery(withdrawalKey.INIT_PAGE, initPage);
}

function* watchUpdatePage() {
    yield takeEvery(withdrawalKey.UPDATE_PAGE, getWithdrawalList);
}

export function* watchWithdrawalSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchChangeTabsKey),
        fork(watchSearchData),
        fork(getWithdrawalDetail),
        fork(changeWithdrawalDetailStatus),
        fork(watchUpdatePage),
        fork(exportModel)
    ]);
}