import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Row, Col, Input, Table, DatePicker, Tabs, Select, Tooltip } from 'antd';
// import * as moment from 'moment';

import { FormComponentProps } from 'antd/lib/form/Form';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { getExportTitles } from '../../../base/getExportTitles';
import { ReportManagerState } from '../reducers/reportManagerReducer';
import { changeToStamp, timeReverseWithNoHour } from '../../../base/dealWithTime';

const Option = Select.Option;
const FormItem = Form.Item;
const TabPane = Tabs.TabPane;

interface ReportManagerProps extends FormComponentProps {
    data: ReportManagerState;
    initPage: () => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getSearchData: (param: any) => void;
    getReportTabsKey: (value: string) => void;
}

interface ReportManagerInitialState {
    startTime: string;
    endTime: string;
    novelType: any[];
    reportType: any[];
    toDetail: boolean;
    reportId: number;
    keyToDetail: string;
}

class ReportManager extends React.Component<ReportManagerProps, ReportManagerInitialState> {

    state: ReportManagerInitialState = {
        startTime: '',
        endTime: '',
        toDetail: false,
        reportId: -1,
        keyToDetail: '1',
        novelType: ['Original', 'Reprint', 'PGC', 'Star Novel'],
        reportType: ['konten mengandung  pornografi, kekerasan dan rasisme', 'konten mengandung  agama dan politik', 'kontenl memplagiat tulisan lain']
    };

    componentDidMount() {
        this.props.initPage();
    }

    // public action

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    clear = () => {
        this.setState({startTime: ''});
        this.setState({endTime: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            gtTime: this.state.startTime.length === 0 ? undefined : changeToStamp(this.state.startTime) / 1000,
            ltTime: this.state.endTime.length === 0 ? undefined : changeToStamp(this.state.endTime) / 1000,
            name: value.name,
            id: value.id,
            novelType: value.novelType,
            type: value.reportType
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }

        this.props.getSearchData(param);
    }
    columns = () => {
        return [{
            title: 'Novel ID',
            dataIndex: 'novelId',
            key: 'novelId',
            width: 100,
            fixed: true,
            className: 'column-center',
        }, {
            title: 'Novel Name',
            dataIndex: 'novelName',
            key: 'novelName',
            className: 'column-center',
        }, {
            title: 'Chapter title',
            dataIndex: 'chapterName',
            key: 'chapterName',
            className: 'column-center',
        }, {
            title: 'Category',
            dataIndex: 'novelTypeName',
            key: 'novelTypeName',
            className: 'column-center',
        }, {
            title: 'Type',
            dataIndex: 'novelType',
            key: 'novelType',
            className: 'column-center',
            render: (text: string) => (this.state.novelType[text])
        }, {
            title: 'Informer',
            dataIndex: 'informer',
            key: 'informer',
            className: 'column-center',
        }, {
            title: 'Informer ID',
            dataIndex: 'informerId',
            key: 'informerId',
            className: 'column-center',
        }, {
            title: 'Report type',
            dataIndex: 'reportType',
            key: 'reportType',
            className: 'column-center',
            render: (text: number, record: any) => {
                if (text === 4) {
                    return (
                        <Tooltip title={record.reportRemark}>
                            <span style={{color: '#1890ff'}}>Lainnya</span>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title={record.reportRemark}>
                            <span style={{color: '#1890ff'}}>{this.state.reportType[text - 1]}</span>
                        </Tooltip>
                    );
                }
            }
        }, {
            title: 'Report Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: true,
            sortField: 'createdAt',
            className: 'column-center',
        }, {
            title: 'Report Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
            className: 'column-center',
        }, {
            title: 'Reported times',
            dataIndex: 'times',
            key: 'times',
            className: 'column-center',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            width: 80,
            fixed: 'right' as 'right',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <a onClick={(e: any) => {this.toDetail(record); }} href="javascript:void(0)">View1</a>;
            }
        }];
    }

    resultColumns = () => {
        return [{
            title: 'Novel ID',
            dataIndex: 'novelId',
            key: 'novelId',
            width: 100,
            fixed: true,
            className: 'column-center',
        }, {
            title: 'Novel Name',
            dataIndex: 'novelName',
            key: 'novelName',
            className: 'column-center',
        }, {
            title: 'Chapter title',
            dataIndex: 'chapterName',
            key: 'chapterName',
            className: 'column-center',
        }, {
            title: 'Category',
            dataIndex: 'novelTypeName',
            key: 'novelTypeName',
            className: 'column-center',
        }, {
            title: 'Type',
            dataIndex: 'novelType',
            key: 'novelType',
            className: 'column-center',
            render: (text: string) => (this.state.novelType[text])
        }, {
            title: 'Informer',
            dataIndex: 'informer',
            key: 'informer',
            className: 'column-center',
        }, {
            title: 'Informer ID',
            dataIndex: 'informerId',
            key: 'informerId',
            className: 'column-center',
        }, {
            title: 'Report type',
            dataIndex: 'reportType',
            key: 'reportType',
            className: 'column-center',
            render: (text: number, record: any) => {
                if (text === 4) {
                    return (
                        <Tooltip title={record.reportRemark}>
                            <span style={{color: '#1890ff'}}>Lainnya</span>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title={record.reportRemark}>
                            <span style={{color: '#1890ff'}}>{this.state.reportType[text - 1]}</span>
                        </Tooltip>
                    );
                }
            }
        }, {
            title: 'Report Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: true,
            sortField: 'createdAt',
            className: 'column-center',
        }, {
            title: 'Report Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
            className: 'column-center',
        }, {
            title: 'Reported times',
            dataIndex: 'times',
            key: 'times',
            className: 'column-center',
        }, {
            title: 'Reviewer',
            dataIndex: 'operatorName',
            key: 'operatorName',
            className: 'column-center',
        }, {
            title: 'Review Date',
            dataIndex: 'operatorDate',
            key: 'operatorDate',
            sorter: true,
            sortField: 'operatorAt',
            className: 'column-center',
        }, {
            title: 'Review Time',
            dataIndex: 'operatorTime',
            key: 'operatorTime',
            className: 'column-center',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            width: 80,
            fixed: 'right' as 'right',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <a onClick={(e: any) => {this.toDetail(record); }} href="javascript:void(0)">View2</a>;
            }
        }];
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        let { data } = this.props;

        const titles = data.tabsKey === '1' ? getExportTitles(this.columns()) : getExportTitles(this.resultColumns());
        this.props.submitExport({...value, titles});
    }

    // page action

    toDetail = (record: any) => {
        window.sessionStorage.setItem('reportUrl', record.chapterContentUrl);
        this.setState({toDetail: true, reportId: record.id});
    }

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: timeReverseWithNoHour(startTime) + ' 00:00:00'});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: timeReverseWithNoHour(endTime) + ' 23:59:59'});
        } else {
            this.setState({endTime});
        }
    }

    tabsChange = (key: string) => {
        this.clear();
        this.setState({keyToDetail: key});
        this.props.getReportTabsKey(key);
    }

    render() {

        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const input = {
            style: {width: '100%', color: '#000'}
        };
        const { data, getUpdatePage } = this.props;
        const { toDetail, reportId, keyToDetail } = this.state;

        const novelType = [
            {key: 'All', value: 'All'},
            {key: '0', value: 'Original'},
            {key: '1', value: 'Reprint'},
            {key: '2', value: 'PGC'},
            {key: '3', value: 'Star Novel'},
        ];

        const reportList = [
            {key: 'All', value: 'All'},
            {key: '1', value: 'pornografi, kekerasan dan rasisme'},
            {key: '2', value: 'agama dan politik'},
            {key: '3', value: 'tulisan lain'},
            {key: '4', value: 'Lainnya'}
        ];

        if (toDetail) {
            return <Redirect push={true} to={{pathname: `/auditManage/reportDetail`, search: `?id=${reportId}&tabsKey=${keyToDetail}`}}/>;
        }

        return (
            <div>
                <Form>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="Date">
                                {getFieldDecorator('startTime')(
                                    <DatePicker
                                        allowClear={true}
                                        format={'DD-MM-YYYY'}
                                        placeholder={'Start Time'}
                                        style={{width : '48%'}}
                                        onChange={this.startTimeChange}
                                    />
                                )}
                                {getFieldDecorator('endTime')(
                                    <DatePicker
                                        allowClear={true}
                                        format={'DD-MM-YYYY'}
                                        placeholder={'End Time'}
                                        style={{width : '48%', marginLeft : '4%'}}
                                        onChange={this.endTimeChange}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Novel Name"
                            >
                                {getFieldDecorator('name')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Type"
                            >
                                {getFieldDecorator('novelType', {
                                    initialValue: 'All'
                                })(
                                    <Select>
                                        {novelType.map(item => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Novel ID"
                            >
                                {getFieldDecorator('id')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Report type"
                            >
                                {getFieldDecorator('reportType', {
                                    initialValue: 'All'
                                })(
                                    <Select>
                                        {reportList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>

                <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport} />

                <Tabs defaultActiveKey="review" onChange={this.tabsChange}>
                    <TabPane tab="Pending review" key="1">
                        <p><span style={{marginRight: '5px'}}>Pending review:</span>{data.total}</p>
                        <Table
                            rowKey="id"
                            loading={data.loading}
                            bordered={true}
                            columns={this.columns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.reportList}
                            onChange={getUpdatePage}
                            scroll={{ x: 1900 }}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Ignored" key="3">
                        <p><span style={{marginRight: '5px'}}>Ignored:</span>{data.total}</p>
                        <Table
                            rowKey="id"
                            loading={data.loading}
                            bordered={true}
                            columns={this.resultColumns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.reportList}
                            onChange={getUpdatePage}
                            scroll={{ x: 2100 }}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                    <TabPane tab="locked" key="2">
                        <p><span style={{marginRight: '5px'}}>locked:</span>{data.total}</p>
                        <Table
                            rowKey="id"
                            loading={data.loading}
                            bordered={true}
                            columns={this.resultColumns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.reportList}
                            onChange={getUpdatePage}
                            scroll={{ x: 2100 }}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                </Tabs>
                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />
            </div>
        );
    }
}

export default Form.create()(ReportManager);