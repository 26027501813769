import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { VersionManage } from './views/VersionManage';
import VersionContent from './views/containers/containers';

export const settingContentRouters = (
    <VersionManage>
        <Switch>
            <Route path="/settingManage/version" component={VersionContent}/>
            <Redirect to="/settingManage/version"/>
        </Switch>
    </VersionManage>
);