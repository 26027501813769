import helpCenterManagerKeys from './actionsTypeKey/helpCenterActionTypeKey';

export function initPage() {
    return {type: helpCenterManagerKeys.INIT_PAGE};
}

export function isLoading(bol: boolean) {
    return { type: helpCenterManagerKeys.LOADING, payload: bol };
}

export function getTitleModalStatus(bol: boolean) {
    return {type: helpCenterManagerKeys.OPEN_CLOSE_TITLE_MODAL, payload: bol};
}

export function getLocationModalStatus(bol: boolean) {
    return {type: helpCenterManagerKeys.OPEN_CLOSE_LOCATION_MODAL, payload: bol};
}

export function getHelpModalList(param: any[]) {
    return { type: helpCenterManagerKeys.GET_HELP_MODAL_LIST, payload: param };
}

export function getChangeHelpModalSortMsg(param: any) {
    return { type: helpCenterManagerKeys.GET_CHANGE_HElP_MODAL_SORT, payload: param };
}

export function addHelpModal(param: any) {
    return { type: helpCenterManagerKeys.ADD_HELP_MODAL, payload: param };
}

export function editHelpModal(param: any) {
    return { type: helpCenterManagerKeys.EDIT_HELP_MODAL, payload: param };
}

export function delHelpModal(id: number | string) {
    return { type: helpCenterManagerKeys.DEL_HELP_MODAL, payload: id };
}
