import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 获取版本列表
 */
export function getVersionList(param: any) {
    return postJson('/version/getAppVersionList', param);
}

/**
 * 删除版本列表
 */
export function deleteVersionData(param: string[]) {
    return postJson('/version/deleteAppVersion', param);
}

/**
 * 添加版本列表
 */
export function addVersionData(param: string[]) {
    return postJson('/version/addAppVersion', param);
}

/**
 * 启用版本
 */
export function enableVersion(param: number) {
    return postJson('/version/enableVersion', param);
}

/**
 * 禁用版本
 */
export function disableVersion(param: number) {
    return postJson('/version/disableVersion', param);
}

/**
 * 导出版本列表
 */
export function exportVersionList(param: any) {
    return postJsonForExport('/version/getAppVersionList/export', param, 'version.xlsx');
}