import novelTotalDataTypeKey from './actionsTypeKey/novelTotalDataTypeKey';

export function initPage() {
    return {type: novelTotalDataTypeKey.INIT_PAGE};
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: novelTotalDataTypeKey.UPDATE_PAGE, payload: {pagination, sorter} };
}

export function searchDate(value: any) {
    return { type: novelTotalDataTypeKey.SEARCH_DATA, payload: value };
}

export function loading(bol: boolean) {
    return { type: novelTotalDataTypeKey.LOADING, payload: bol };
}

export function exportModalVisible(value: boolean) {
    return { type: novelTotalDataTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: novelTotalDataTypeKey.SUBMIT_NOVEL_TOTAL_EXPORT, payload: value };
}

export function getTypeList(value: {data: any, total: number}) {
    return { type: novelTotalDataTypeKey.GET_TYPE_DATA_LIST, payload: value };
}

export function getTotalData(value: any) {
    return { type: novelTotalDataTypeKey.GET_TOTAL_DATE, payload: value };
}