enum TypeDetailActionsTypeKey {
    INIT_PAGE = 'TAG_DETAIL_INIT_PAGE', // 初始化
    GET_TAG_ID = 'TAG_GET_TAG_ID', // 获取tagId
    GET_TYPE_LIST = 'TAG_GET_TYPE_LIST', // 获取typeList
    GET_TYPE_ID = 'TAG_GET_TYPE_ID', // 获取类型id
    GET_NOVEL_LIST = 'TAG_GET_NOVEL_LIST', // 根据类型id获取小说列表
    GET_DEL_TAG_IDS = 'GET_DEL_TAG_IDS', // 获取删除id
    TYPE_LIST_LOADING = 'TYPE_LIST_LOADING'
}

export default TypeDetailActionsTypeKey;