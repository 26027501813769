import { take, fork, all, put, call, takeEvery, select } from 'redux-saga/effects';
import UserContentActionTypeKeys from '../../components/user/actions/actionsTypeKey/userActionTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as userContentServer from '../../servers/userServer';
import * as userContentAction from '../../components/user/actions/userActions';

// 初始页面请求登录
function* initPage() {
    yield getUserList();
}

function* getUserList() {
    try {
        yield put(userContentAction.loading(true));
        const state = yield select();
        const data = state.user.userContent;
        const param = {
            ...data.page,
            ...data.sorter,
            data: {...data.searchData}
        };
        let result = yield call(userContentServer.getUserList, param);
        yield put(userContentAction.getUserList({data: result.result.data, total: result.result.total}));
        yield put(userContentAction.loading(false));
    } catch (error) {
        openNotification('error', 'Failed to get user list.');
        yield put(userContentAction.loading(false));
    }
}

function* watchExport() {
    while (true) {
        const { payload } = yield take(UserContentActionTypeKeys.SUBMIT_EXPORT);
        const state = yield select();
        const data = state.user.userContent;
        payload.titles.splice(-1, 1);
        payload.titles.forEach((o: any, idx: any) => {
            if (o.index === 'enabled') {
                o.index = 'enabledValue';
            }

            if (o.index === 'author') {
                o.index = 'authorValue';
            }

            if (o.index === 'device') {
                o.index = 'deviceValue';
            }

            if (o.index === 'login') {
                o.index = 'socialType';
            }

            if (o.index === 'account') {
                o.index = 'socialAccount';
            }

            if (o.index === 'vipType') {
                o.index = 'vipTypeExport';
            }
        });
        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            data: {...data.searchData},
            ...data.sorter
        };
        try {
            yield put(userContentAction.loading(true));
            yield call(userContentServer.exportUserList, param);
            yield put(userContentAction.loading(false));
            yield put(userContentAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(userContentAction.loading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(UserContentActionTypeKeys.USER_CONTNET_INIT_PAGE, initPage);
}

function* watchChangeUserStatus() {
    while (true) {
        try {
            const {payload} = yield take(UserContentActionTypeKeys.CHANGE_USER_STATUS);
            yield put(userContentAction.loading(true));
            if (payload.checked === 0) {
                yield call(userContentServer.enableUser, payload.value);
            } else {
                yield call(userContentServer.disableUser, payload.value);
            }
            yield put(userContentAction.loading(false));
            yield getUserList();
            openNotification('success', 'Change success');
        } catch (err) {
            if (err.code === 530) {
                openNotification('error', 'Clearing user cache failed');
            } else if (err.code === 224) {
                openNotification('error', 'Clear user cache error');
            } else {
                openNotification('error', 'Change Failed.');
            }
            yield put(userContentAction.loading( false));
        }
    }
}

function* getUserDetailMsg() {
    while (true) {
        const { payload } = yield take(UserContentActionTypeKeys.GET_USER_DETAIL_ID);
        try {
            yield put(userContentAction.loading(true));
            let { result } = yield call(userContentServer.getUserDetailById, payload);

            yield put(userContentAction.getUserDetailMsg(result));
            yield put(userContentAction.loading(false));
        } catch (err) {
            yield put(userContentAction.loading(false));
            openNotification('error', 'Get Detail Failed.');
        }
    }
}

// function* watchChangeAuthorStatus() {
//     while (true) {
//         try {
//             const {payload} = yield take(UserContentActionTypeKeys.CHANGE_AUTHOR_STATUS);
//             yield call(userContentServer.modifyAuthorizationStatus, payload);
//             yield getUserList();
//             openNotification('success', 'Authorization success');
//         } catch (err) {
//             if (err.code === 208) {
//                 openNotification('error', 'The user is not exist.');
//             } else if (err.code === 209) {
//                 openNotification('error', 'The user already author.');
//             } else if (err.code === 219) {
//                 openNotification('error', 'The user is disabled and cannot be authorized.');
//             } else {
//                 openNotification('error', 'Change Failed.');
//             }
//             yield put(userContentAction.loading( false));
//         }
//     }
// }

function* watchSearchData() {
    yield takeEvery(UserContentActionTypeKeys.USER_LIST_DATA_SEARCH, getUserList);
}

function* watchUpdatePageSize() {
    yield takeEvery(UserContentActionTypeKeys.UPDATE_PAGE, initPage);
}

export function* watchUserContentRootSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchChangeUserStatus),
        // fork(watchChangeAuthorStatus),
        fork(watchUpdatePageSize),
        fork(watchSearchData),
        fork(watchExport),
        fork(getUserDetailMsg)
    ]);
}