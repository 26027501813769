import UserContentActionTypeKeys from '../actions/actionsTypeKey/userActionTypeKey';
import {  actionTypes } from '../actions/actionsType/userActionTypes';
import { userList } from '../../../servers/responseTypes';

interface PageData {
    current: number;
    pageSize: number;
}

interface ExportData {
    titles: any;
}

export interface StoreState {
    page: PageData;
    sorter: any;
    getUserList: userList[];
    loading: boolean;
    total: number;
    changeUserStatus: any;
    searchData: any;
    exportTitle: ExportData;
    exportModalVisible: boolean;
    userDetailMsg: any;
}

const initialState: StoreState = {
    loading: false,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {
    },
    total: 0,
    exportTitle: {
        titles: []
    },
    getUserList: [],
    searchData: {},
    changeUserStatus: {},
    exportModalVisible: false,
    userDetailMsg: {}
};

export const userContentReducer = (state = initialState, action: actionTypes) => {
    switch (action.type) {
        case UserContentActionTypeKeys.USER_CONTNET_INIT_PAGE:
            return initialState;

        case UserContentActionTypeKeys.GET_USER_LIST:
            return Object.assign({}, state, {
                getUserList: action.payload.data,
                total: action.payload.total
            });

        case UserContentActionTypeKeys.CHANGE_USER_STATUS:
            return Object.assign({}, state, {
                changeUserStatus: action.payload,
            });

        case UserContentActionTypeKeys.LOADING:
            return Object.assign({}, state, {
                loading: action.payload,
            });

        case UserContentActionTypeKeys.REQUEST_EXPORT_LIST:
            return Object.assign({}, state, {
                exportTitle: {
                    titles: action.payload
                },
            });

        case UserContentActionTypeKeys.USER_LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload,
            });

        case UserContentActionTypeKeys.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize,
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case UserContentActionTypeKeys.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload
            });

        case UserContentActionTypeKeys.GET_USER_DETAIL_MSG:
            return Object.assign({}, state, {
                userDetailMsg: action.payload
            });

        default:
            return state;
    }
};