import { postJson } from '@iliewen/js-common';
// import { postJsonForExport } from '../base/base';

export function getRecommendList(param: any) {
    return postJson('/bookshelf/getRecommendList', param);
}

export function addRecommendNovel(id: string | number) {
    return postJson('/bookshelf/addRecommend', id);
}

export function changeRecommendNovel(param: any) {
    return postJson('/bookshelf/changeRecommend', param);
}

export function delRecommendNovel(id: string | number) {
    return postJson('/bookshelf/deleteRecommend', id);
}

export function modifySort(param: any) {
    return postJson('/bookshelf/modifyRecommendLocation', param);
}

export function getNovelListByTypeForShelf(param: any) {
    return postJson('/novel/getNovelListByTypeForShelf', param);
}