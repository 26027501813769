import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Form, Row, Col, Spin, Modal, Button, Icon, Tooltip, Card, Popconfirm, Upload, Select, AutoComplete, Divider } from 'antd';

import { StoreState } from '../reducers/bannerReducer';
import { default as Sortable } from 'sortablejs';
import { openNotification } from '../../widgets/Notification';

const FormItem = Form.Item;
const Option = Select.Option;

interface ContentBannerProps extends FormComponentProps {
    data: StoreState;
    initPage: () => void;
    bannerListSearch: (value: any) => void;
    modifyBannerStatus: (value: any) => void;
    addBanner: (value: any) => void;
    sendNovelValue: (value: any) => void;
    modalVisible: (value: boolean) => void;
    bannerSortSubmit: (value: any[]) => void;
    deleteBanner: (value: any[]) => void;
    exportBannerList: (value: any) => void;
}

interface ContentBannerState {
    previewVisible: boolean;
    fileChange: boolean;
    showUrl: boolean;
    showNovelId: boolean;
    sortDisabled: boolean;
    sortIds: any[];
    edit: boolean;
    type: any;
    fileList: any[];
    previewImage: string;
    bannerId: number;
    novelId: number;
    novelName: string;
    link: string;
}

class BannerManager extends React.Component<ContentBannerProps, ContentBannerState> {

    state: ContentBannerState = {
        previewVisible: false,
        fileChange: false,
        showUrl: false,
        showNovelId: false,
        sortDisabled: true,
        sortIds: [],
        edit: false,
        type: '',
        fileList: [],
        previewImage: '',
        bannerId: -1,
        novelId: -1,
        novelName: '',
        link: '',
    };

    componentWillMount() {
        this.props.initPage();
    }

    componentDidMount() {
        this.props.form.setFieldsValue({'theme_search': 'novel'});
        let list: any = document.getElementById('my-banner-card-list');
        new Sortable(list, {
            onEnd: (evt) => this.sortEnd(evt)
        });
    }

    sortEnd(evt: any) {
        const childNodes = evt.to.childNodes;
        let sortIds = [];
        for (let item of childNodes) {
            sortIds.push(item.id);
        }
        this.setState({sortIds: sortIds, sortDisabled: false});
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param = {
            theme: value.theme_search,
            name: value.bannerName_search,
        };
        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.bannerListSearch(param);
    }

    onClear = () => {
        this.resetFormFields();
    }

    submitExport = () => {
        const value: any = this.props.form.getFieldsValue();
        let param = {
            'current': 1,
            'pageSize': 20,
            'data': {
                name: value.bannerName_search,
            },
            'titles': [
            {
                title: 'NO',
                index: 'sort'
            },
            {
                title: 'Novel Id',
                index: 'novelId'
            },
            {
                title: 'Banner Content',
                index: 'novelName'
            },
            {
                title: 'clickTime',
                index: 'clickTime'
            },
            {
                title: 'Time',
                index: 'shelvedAt',
                type: 'datetime',
                timeZone: 'Asia/Jakarta'
            }]
        };
        this.props.exportBannerList(param);
    }

    addBannerModalShow = (edit?: string, value?: any) => {
        this.resetFormFields();
        this.setState({novelName: '', link: '', showNovelId: false, showUrl: false, fileList: []});
        if (edit) {
            this.setState({edit: true,
                link: value.link,
                novelId: value.novelId,
                novelName: value.novelName + ' (ID: ' + value.novelId + ')',
                bannerId: value.id, fileList: [{uid: '11', name: '1', status: 'done', url: value.objectUrl}]});
            if (value.type === 0) {
                this.setState({showNovelId: true, showUrl: false});
            } else {
                this.setState({showNovelId: false, showUrl: true});
            }
            this.props.form.setFieldsValue({
                theme: value.theme,
                type: value.type === 0 ? 'Novel' : 'Url',
                name: value.name,
                banner: value.objectKey,
            });

        } else {
            this.setState({edit: false});
        }
        this.props.modalVisible(true);

    }

    resetFormFields = () => {
        const keys = Object.keys(this.props.form.getFieldsValue()).filter(item => item !== 'theme_search');
        this.props.form.resetFields(keys);
    }

    addBannerModalHide = () => {
        this.resetFormFields();
        this.props.modalVisible(false);
        this.setState({novelName: '', link: '', showNovelId: false, showUrl: false, fileList: []});
    }

    bannerSortSubmit = () => {
        this.props.bannerSortSubmit(this.state.sortIds);
        this.setState({sortDisabled: true});
    }

    changeBannerStatus = (value: string, id: number) => {
        let param = {
            id: id,
            status: 1
        };
        if (value === 'hide') {
            param.status = 0;
        }
        this.props.modifyBannerStatus(param);
    }

    deleteBanner = (id: number) => {
        let param = [id];
        this.props.deleteBanner(param);
    }

    fileChange = ({file, fileList}: any) => {
        if (file.status === 'error') {
            file.status = 'done';
        }
        this.setState({fileList});
    }

    beforeUpload = (file: any) => {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJPG && (file.size > 819200)) {
            openNotification('error', 'You can only upload more than 800K JPG/PNG file!');
        }
        return isJPG;
    }

    handleStatus = (value: any, id: number) => {
        if (value) {
            return (
                <Popconfirm
                    title="Are you sure hide this banner?"
                    onConfirm={() => this.changeBannerStatus('hide', id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <span className="paramHide">Hide</span>
                </Popconfirm>
            );
        } else {
            return (
                <Popconfirm
                    title="Are you sure show this banner?"
                    onConfirm={() => this.changeBannerStatus('show', id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <span className="paramHide">Show</span>
                </Popconfirm>
            );
        }
    }

    handelDelete = (id: number) => {
        return (
            <Popconfirm
                title="Are you sure delete this banner?"
                onConfirm={() => this.deleteBanner(id)}
                okText="Yes"
                cancelText="No"
            >
                <span className="paramShow">Delete</span>
            </Popconfirm>
        );
    }

    handlePreview = (file: any) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleCancel = () => {
        this.setState({previewVisible: false});
    }

    changeTypeDisable = (value: any) => {
        if (value === '0') {
            this.setState({showNovelId: true, showUrl: false, novelName: '', link: ''});
        } else if (value === '1') {
            this.setState({showNovelId: false, showUrl: true, novelName: '', link: ''});
        }
    }

    switchType = (value: any) => {
        switch (value) {
            case 'Url':
                return '1';
            case 'Novel':
                return '0';
            default:
                return value;
        }
    }

    editBanner = () => {
        const value: any = this.props.form.getFieldsValue();
        // let s = document.getElementsByTagName('img');
        // let imgWidth = s[s.length - 1].naturalWidth;
        // let imgHeight = s[s.length - 1].naturalHeight;
        let novelId = null;
        if (value.novel_id) {
            novelId = /\(ID: (\d+)\)/.exec(value.novel_id)[1];
        }
        const param: any = {
            id:  this.state.bannerId,
            theme: value.theme,
            name: value.name,
            type: this.switchType(value.type),
            link: value.url,
            novelId: value.novel_id === this.state.novelName ? this.state.novelId : novelId,
            objectKeys: value.banner,
        };

        const novelAllList = this.props.data.allNovelList;
        for (let i = 0; i < novelAllList.length; i++) {
            if (novelAllList[i].name === param.novelId) {
                param.novelId = novelAllList[i].id;
            }
        }
        for (let item in param) {
            if (param[item] === undefined || param[item] === '') {
                delete param[item];
            }
        }
        if (typeof param.objectKeys !== 'undefined' && param.name && param.type && (param.link || param.novelId)) {
            param.objectKeys.file ? param.objectKeys = param.objectKeys.file.originFileObj : param.objectKeys = value.banner;
            this.props.addBanner(param);
            // if (imgWidth !== 984 || imgHeight !== 480) {
            //     openNotification('error', 'Banner image must be 984*480!');
            // } else if (imgWidth === 984 && imgHeight === 480) {
            //     if (param.name.length > 50) {
            //         openNotification('error', 'Banner name no more than 50 words.');
            //     } else {
            //         this.props.addBanner(param);
            //     }
            // }
        } else {
            openNotification('error', 'Information is not complete.');
        }
    }

    addBanner = () => {
        const value: any = this.props.form.getFieldsValue();
        // let s = document.getElementsByTagName('img');
        // let imgWidth = s[s.length - 1].naturalWidth;
        // let imgHeight = s[s.length - 1].naturalHeight;
        let novelId = null;
        if (value.novel_id) {
            novelId = /\(ID: (\d+)\)/.exec(value.novel_id)[1];
        }
        const param: any = {
            name: value.name,
            theme: value.theme,
            type: value.type,
            link: value.url,
            novelId: novelId,
            objectKeys: value.banner,
        };
        const novelAllList = this.props.data.allNovelList;
        for (let i = 0; i < novelAllList.length; i++) {
            if (novelAllList[i].name === param.novelId) {
                param.novelId = novelAllList[i].id;
            }
        }
        for (let item in param) {
            if (param[item] === undefined) {
                delete param[item];
            }
        }
        if (typeof param.objectKeys !== 'undefined' && param.name && param.type && (param.link || param.novelId)) {
            param.objectKeys = param.objectKeys.file.originFileObj;
            this.props.addBanner(param);
            // if (imgWidth !== 984 || imgHeight !== 480) {
            //     openNotification('error', 'Banner image must be 984*480!');
            // } else if (imgWidth === 984 && imgHeight === 480) {
            //     if (param.name.length > 50) {
            //         openNotification('error', 'Banner name no more than 50 words.');
            //     } else {
            //         this.props.addBanner(param);
            //     }
            // }
        } else {
            openNotification('error', 'Information is not complete.');
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data } = this.props;
        const { edit, showNovelId, showUrl } = this.state;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const form = {
            col: 4,
            // style: {margin: '10px 10px'}
        };

        const typeList = [
            {key: 0, value: 'Novel'},
            {key: 1, value: 'Url'}
        ];

        const dividerStyle = {
            style: {padding: '30px 0'}
        };

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Button
                                    type="primary"
                                    onClick={() => this.addBannerModalShow()}
                                    className="bannerButton"
                                >
                                    <Icon type="file-add"/>Add
                                </Button>
                                <Tooltip title="Save the sort.">
                                    <Button
                                        type="primary"
                                        className="bannerButton"
                                        disabled={this.state.sortDisabled}
                                        onClick={() => this.bannerSortSubmit()}
                                    >
                                        <Icon type="save"/>Submit Sort
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col span={5} style={{marginTop: 8}}>
                                <FormItem
                                    label="Theme"
                                    {...form}
                                >
                                    {getFieldDecorator('theme_search')(
                                        <Select>
                                            <Option value="novel">Novel</Option>
                                            <Option value="free">Free</Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={5} style={{marginTop: 8}}>
                                <FormItem
                                    label="Banner Name"
                                    {...form}
                                >
                                    {getFieldDecorator('bannerName_search')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <Button className="bannerButton" type="primary" onClick={() => this.onSearch()}>Search</Button>
                                <Button className="bannerClearButton" type="primary" onClick={() => this.onClear()}>Clear</Button>
                                {/*<Button className="bannerClearButton" type="primary" onClick={() => this.submitExport()}>Export</Button>*/}
                            </Col>
                        </Row>
                    </Form>
                    <div id="my-banner-card-list">
                        {
                            data.getBannerList.map((item: any, index: number) => {
                                if (item.status) {
                                    return (
                                        <Card
                                            key={index}
                                            id={item.id.toString()}
                                            style={{ width: 350, float: 'left', margin: 10 }}
                                            cover={<img alt="example" src={item.objectUrl} />}
                                            actions={
                                                [this.handleStatus(item.status, item.id), this.handelDelete(item.id),
                                                    <Icon key={2} onClick={() => this.addBannerModalShow('edit' , item)} type="edit" />
                                                ]}
                                        >
                                            <div className="paramItem"><span>Name:</span><span className="param">{item.name}</span></div>
                                            <div className="paramItem"><span>Theme:</span><span className="param">{item.theme}</span></div>
                                            <div className="paramItem"><span>Time:</span><span className="param">{item.shelvedAt === 0 ? '-' : `${item.shelvedAtDateValue} ${item.shelvedAtTimeValue}`}</span></div>
                                            <div className="paramItem"><span>Novel ID:</span><span className="param">{item.type ? '-' : item.novelId}</span></div>
                                            <div className="paramItem"><span>Click Times:</span><span className="param">{item.clickTime}</span></div>
                                            <div className="paramItem"><span>Status:</span>{item.status ? <span className="paramShow">Show</span> : <span className="paramHide">Hide</span>}</div>
                                        </Card>
                                    );
                                }
                                return null;
                            })
                        }
                    </div>
                    <Divider {...dividerStyle}>Dividing Line</Divider>
                    {
                        data.getBannerList.map((item: any, index: number) => {
                            if (!item.status) {
                                return (
                                    <Card
                                        key={index}
                                        id={item.id.toString()}
                                        style={{ width: 350, float: 'left', margin: 10 }}
                                        cover={<img alt="example" src={item.objectUrl} />}
                                        actions={
                                            [this.handleStatus(item.status, item.id), this.handelDelete(item.id),
                                                <Icon key={2} onClick={() => this.addBannerModalShow('edit' , item)} type="edit" />
                                            ]}
                                    >
                                        <div className="paramItem"><span>Name:</span><span className="param">{item.name}</span></div>
                                        <div className="paramItem"><span>Time:</span><span className="param">{item.shelvedAt === 0 ? '-' : `${item.shelvedAtDateValue} ${item.shelvedAtTimeValue}`}</span></div>
                                        <div className="paramItem"><span>Novel ID:</span><span className="param">{item.type ? '-' : item.novelId}</span></div>
                                        <div className="paramItem"><span>Click Times:</span><span className="param">{item.clickTime}</span></div>
                                        <div className="paramItem"><span>Status:</span>{item.status ? <span className="paramShow">Show</span> : <span className="paramHide">Hide</span>}</div>
                                    </Card>
                                );
                            }
                            return null;
                        })
                    }
                </Spin>
                <Modal
                    title={edit ? 'Edit Banner' : 'Add Banner'}
                    visible={data.modalVisible}
                    onCancel={() => this.addBannerModalHide()}
                    footer={null}
                    maskClosable={false}
                >
                    <Spin spinning={data.modalLoading}>
                        <Form>
                            <FormItem label="Name">
                                {getFieldDecorator('name', {
                                    rules: [{
                                        required: true, message: 'please input name',
                                    }]
                                })(
                                    <Input/>
                                )}
                            </FormItem>
                            <FormItem label="Theme">
                                {getFieldDecorator('theme', {
                                    rules: [{
                                        required: true,
                                    }]
                                })(
                                    <Select>
                                        <Option value="novel">Novel</Option>
                                        <Option value="free">Free</Option>
                                    </Select>
                                )}
                            </FormItem>
                            <FormItem label="Type">
                                {getFieldDecorator('type', {
                                    rules: [{
                                        required: true, message: 'please input type',
                                    }]
                                })(
                                    <Select
                                        onChange={(option: any) => this.changeTypeDisable(option)}
                                    >
                                        {typeList.map((item: any) => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                            {
                                showUrl ? <FormItem label="Url">
                                    {getFieldDecorator('url', {
                                        initialValue: this.state.link,
                                        rules: [{
                                            required: true, message: 'please input url',
                                        }]
                                    })(
                                        <Input/>
                                    )}
                                </FormItem> : null
                            }
                            {
                                showNovelId ? <FormItem
                                    label="Novel (Input name or ID for search)"
                                >
                                    {
                                        getFieldDecorator('novel_id', {
                                            initialValue: this.state.novelName,
                                            rules: [{
                                                required: true, message: 'please input novel name or id',
                                            }]
                                        })(
                                            <AutoComplete
                                                dataSource={data.novelList}
                                                disabled={false}
                                                onChange={(value: any) => {
                                                    this.props.sendNovelValue(value);
                                                }}
                                            />
                                        )
                                    }
                                </FormItem> : null
                            }
                            <FormItem
                                label="Banner(984*480)"
                            >
                                {getFieldDecorator('banner', {
                                    rules: [{
                                        required: true,
                                        message: 'Please upload Image',
                                    }],
                                })(
                                    <Upload
                                        listType="picture-card"
                                        fileList={this.state.fileList}
                                        onChange={this.fileChange}
                                        beforeUpload={this.beforeUpload}
                                        onPreview={(file) => this.handlePreview(file)}
                                        onRemove={() => this.setState({fileChange: true})}
                                    >
                                        {
                                            this.state.fileList.length >= 1 ? null : <div>
                                                <Icon type="plus"/>
                                                <div className="ant-upload-text">Upload</div>
                                            </div>
                                        }
                                    </Upload>
                                )}
                            </FormItem>
                            <FormItem>
                                <Popconfirm
                                    placement="topLeft"
                                    title="Are you sure submit ?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={edit ? this.editBanner : this.addBanner}
                                >
                                    <Button type="primary" icon="plus">Submit</Button>
                                </Popconfirm>
                            </FormItem>
                        </Form>
                    </Spin>
                </Modal>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handleCancel()}>
                    <img alt="example" style={{width: '100%'}} src={this.state.previewImage}/>
                </Modal>
            </div>
    );
    }
}

export default Form.create()(BannerManager);
