import * as React from 'react';
import { Table, Modal, Input } from 'antd';

import { StoreState } from '../reducers/voteReducer';

interface VoteProps {
    data: StoreState;
    initPage: () => void;
    getChangeParam: (param: any) => void;
}

interface InitialState {
    changeModal: boolean;
    changeId: number;
    quantity: number;
}

class Vote extends React.Component<VoteProps, InitialState> {

    state: InitialState = {
        changeModal: false,
        changeId: -1,
        quantity: -1
    };

    componentDidMount() {
        this.props.initPage();
    }

    colums = () => {
        return [{
            title: 'Position',
            dataIndex: 'sort',
            key: 'sort',
        }, {
            title: 'Quantity setting',
            dataIndex: 'amount',
            key: 'amount',
        }, {
            title: 'Operating',
            dataIndex: 'operating',
            key: 'operating',
            render: (text: any, record: any) => {
                return <a href="javascript:void(0)" onClick={(e: any) => {this.changeModalShow(record); }}>Edit</a>;
            }
        }];
    }

    changeModalShow = (record: any) => {
        this.setState({changeModal: true, changeId: record.id, quantity: record.amount});
    }

    changeModalOk = () => {
        this.changeModalCancel();
        const { changeId, quantity } = this.state;
        let param = {
            id: changeId,
            amount: quantity
        };

        this.props.getChangeParam(param);
    }

    changeModalCancel = () => {
        this.setState({changeModal: false});
    }

    quantityChange = (e: any) => {
        var reg = /^[1-9]\d*$/;
        if (reg.test(e.target.value)) {
            this.setState({quantity: e.target.value});
        }

    }

    render() {

        const { data } = this.props;

        const { changeModal, quantity } = this.state;

        return (
            <div>
                <Table
                    dataSource={data.voteList}
                    columns={this.colums()}
                />

                <Modal
                    title="Change Quantity"
                    visible={changeModal}
                    onOk={this.changeModalOk}
                    onCancel={this.changeModalCancel}
                    maskClosable={false}
                >
                    <Input value={quantity} onChange={(e: any) => {this.quantityChange(e); }}/>
                </Modal>
            </div>
        );
    }
}

export default Vote;