import { postJson } from '@iliewen/js-common';

interface Window {
    OSS?: any;
    sessionStorage?: any;
}

declare var window: Window;

/**
 * 获取图片url
 * @param {*}
 */
export async function getImageUrl(name: string, process: any = null) {
    if (name == null) {
        return null;
    }

    if (/^http.*/.test(name) || /^blob:/.test(name)) {
        return name;
    }

    let result = null;
    try {
        const key = await getSTSReadAccessKeyFromCache();
        const client = getOSSClient(key.result);
        result = client.signatureUrl(name, {
            expires: 3600,
            process: process
        });
    } catch (err) {
        console.error(err.stack);
        throw new Error('get image url failed');
    }
    return result;
}

/**
 * 上传文件
 * @param {*} file
 * @param {*} dirName
 * @param {*} name
 */
export async function uploadFile(dirName: any, file: any) {
    if (dirName == null) { throw new Error('argument dirName is null'); }
    if (file == null) { throw new Error('argument file is null'); }

    if (!(file instanceof File)) {
        return file.url;
    }

    try {
        const key = await getSTSWriteAccessKeyFromCache(dirName);
        const client = getOSSClient(key.result);
        const filename = createUploadFilename(key.result.objectName, file);

        await client.multipartUpload(filename, file);
        return filename;
    } catch (err) {
        console.error(err.stack);
        throw new Error('upload image failed');
    }
}

export async function uploadImages(dirName: any, files: any) {
    if (dirName == null) { throw new Error('argument dirName is null'); }
    if (files == null) { throw new Error('argument files is null'); }

    try {
        const result: any[] = [];
        for (let file of files) {
            if (!(file instanceof File)) {
                result.push(file.url);
                continue;
            }

            const key = await getSTSWriteAccessKeyFromCache(dirName);
            const client = getOSSClient(key);
            const filename = createUploadFilename(key.objectName, file);
            await client.multipartUpload(filename, file);
            result.push(filename);
        }
        return result;
    } catch (err) {
        console.error(err.stack);
        throw new Error('upload image failed');
    }
}

function createUploadFilename(accessDir: any, file: any) {
    const fileExt = file.name.replace(/.+\./, '').toLowerCase();
    return accessDir.split('*')[0] + Date.now() + '.' + fileExt;
}

/**
 * 获取oss只读权限
 */
function getSTSReadAccessKey() {
    return postJson('/sts/getSTSReadAccessKey');
}

/**
 * 获取oss只写权限
 * @param {*} dirName
 */
function getSTSWriteAccessKey(dirName: any) {
    const param = {
        dirName
    };
    return postJson('/sts/getSTSWriteAccessKey',  param);
}

/**
 * 从缓存读取只读权限
 */
async function getSTSReadAccessKeyFromCache() {
    const value = window.sessionStorage.getItem('OSS_READ_KEY');
    if (value == null) {
        return await refreshAccessReadKey();
    }

    const key = JSON.parse(value);

    const expiration = new Date(key.expiration);
    if (expiration.getTime() < new Date().getTime()) {
        return await refreshAccessReadKey();
    }

    return key;
}

/**
 * 从缓存读取只写权限
 */
async function getSTSWriteAccessKeyFromCache(dirName: any) {
    const value = window.sessionStorage.getItem(`OSS_WRITE_KEY-${dirName}`);
    if (value == null) {
        return await refreshAccessWriteKey(dirName);
    }

    const key = JSON.parse(value);

    const expiration = new Date(key.expiration);
    if (expiration.getTime() < new Date().getTime()) {
        return await refreshAccessWriteKey();
    }

    return key;
}

function getOSSClient(key: any) {
    const region = 'oss-' + getRegionByEndpoint(key.endpoint);
    return new window.OSS.Wrapper(
        {
        region: region,
        accessKeyId: key.accessKeyId,
        accessKeySecret: key.accessKeySecret,
        stsToken: key.securityToken,
        bucket: key.bucket,
    });
}

function getRegionByEndpoint(endpoint: string) {
    if (endpoint == null) {
        throw new Error('No endpoint provided');
    }

    switch (endpoint) {

        case 'oss-ap-southeast-5.aliyuncs.com':
            // 亚太东南5 （雅加达）
            return 'ap-southeast-5';

        case 'oss-cn-hangzhou.aliyuncs.com':
            // 华东 1
            return 'cn-hangzhou';

        case 'oss-cn-shanghai.aliyuncs.com':
            // 华东 2
            return 'cn-shanghai';

        case 'oss-cn-qingdao.aliyuncs.com':
            // 华北 1
            return 'cn-qingdao';

        case 'oss-cn-beijing.aliyuncs.com':
            // 华北 2
            return 'cn-beijing';

        case 'oss-cn-zhangjiakou.aliyuncs.com':
            // 华北 3
            return 'cn-zhangjiakou';

        case 'oss-cn-shenzhen.aliyuncs.com':
            // 华南 1
            return 'cn-shenzhen';
        case 'oss-cn-hongkong.aliyuncs.com':
            // 香港
            return 'cn-hongkong';

        case 'oss-us-west-1.aliyuncs.com':
            // 美国西部 1 （硅谷）
            return 'us-west-1';

        case 'oss-us-east-1.aliyuncs.com':
            // 美国东部 1 （弗吉尼亚）
            return 'us-east-1';

        case 'oss-ap-southeast-1.aliyuncs.com':
            // 亚太东南 1 （新加坡）
            return 'ap-southeast-1';

        case 'oss-ap-southeast-2.aliyuncs.com':
            // 亚太东南 2 （悉尼）
            return 'ap-southeast-2';

        case 'oss-ap-northeast-1.aliyuncs.com':
            // 亚太东北 1 （日本）
            return 'ap-northeast-1';

        case 'oss-eu-central-1.aliyuncs.com':
            // 欧洲中部 1 （法兰克福）
            return 'eu-central-1';

        case 'oss-me-east-1.aliyuncs.com':
            // 中东东部 1 （迪拜）
            return 'me-east-1';

        default:
            return null;
    }
}

async function refreshAccessReadKey() {
    const key = await getSTSReadAccessKey();
    window.sessionStorage.setItem('OSS_READ_KEY', JSON.stringify(key));
    return key;
}

async function refreshAccessWriteKey(dirName?: any) {
    const key = await getSTSWriteAccessKey(dirName);
    window.sessionStorage.setItem(`OSS_READ_KEY-${dirName}`, JSON.stringify(key));
    return key;
}