import  BannerActionsTypeKey  from './actionsTypeKey/bannerActionsTypeKey';

export function initPage() {
    return { type: BannerActionsTypeKey.INIT_PAGE };
}

export function getBannerList(value: any) {
    return { type: BannerActionsTypeKey.GET_CONTENT_BANNER_LIST, payload: value };
}

export function bannerListSearch(value: any) {
    return { type: BannerActionsTypeKey.LIST_DATA_SEARCH, payload: value };
}

export function addBanner(value: any) {
    return { type: BannerActionsTypeKey.ADD_BANNER, payload: value };
}

export function modifyBannerStatus(value: any) {
    return { type: BannerActionsTypeKey.MODIFY_BANNER_STATUS, payload: value };
}

export function deleteBanner(value: any) {
    return { type: BannerActionsTypeKey.DELETE_BANNER, payload: value };
}

export function sendNovelValue(value: any) {
    return { type: BannerActionsTypeKey.SEND_NOVEL_VALUE, payload: value };
}

export function getNovelListByName(value: any) {
    return { type: BannerActionsTypeKey.GET_NOVEL_LIST, payload: value };
}

export function getAllNovelListByName(value: any) {
    return { type: BannerActionsTypeKey.GET_ALL_NOVEL_LIST, payload: value };
}

export function exportBannerList(value: any) {
    return { type: BannerActionsTypeKey.EXPORT_BANNER_LIST, payload: value };
}

export function loading(bool: boolean) {
    return { type: BannerActionsTypeKey.LOADING, payload: bool };
}

export function modalLoading(bool: boolean) {
    return { type: BannerActionsTypeKey.MODAL_LOADING, payload: bool };
}

export function modalVisible(bool: boolean) {
    return { type: BannerActionsTypeKey.MODAL_VISIBLE, payload: bool };
}

export function bannerSortSubmit(value: any[]) {
    return { type: BannerActionsTypeKey.BANNER_SORT, payload: value };
}