import { fork, all, take, call, put, select, takeEvery } from 'redux-saga/effects';
import recommendDetailActionsTypeKey from '../../components/creation/action/actionsTypeKey/recommendLocationDetailActionsTypeKey';
import * as recommendLocationServer from '../../servers/recommendLocationServer';
import * as recommendServer from '../../servers/recommendServer';
import * as recommendDetailAction from '../../components/creation/action/recommendLocationDetailAction';
import { openNotification } from '../../components/widgets/Notification';

function* initPage() {
    while (true) {
        yield take(recommendDetailActionsTypeKey.INIT_PAGE);
        try {
            yield put(recommendDetailAction.isLoading(true));
            const { result } = yield call(recommendLocationServer.getRecommendLocationList);
            yield put(recommendDetailAction.getFloorList(result));
            yield getFloorNovel();
            yield getTypeList();
        } catch (e) {
            openNotification('error', 'get floor list Fail');
        }
    }
}

function* getTypeList() {
    try {
        const { result } = yield call(recommendServer.getNovelTypeList);
        yield put(recommendDetailAction.getNovelTypeList(result));
    } catch (err) {
        openNotification('error', 'Failed to get type list.');
    }
}

function* getFloorNovel() {
    const store = yield select();
    const reducer = store.creation.recommendLocationDetail;
    const param = {
        ...reducer.page,
        ...reducer.sorter,
        data: reducer.floorId
    };
    try {
        yield put(recommendDetailAction.isLoading(true));
        const { result } =  yield call(recommendLocationServer.getRecommendBookList, param);
        yield put(recommendDetailAction.getFloorNovelList(result));
        yield put(recommendDetailAction.isLoading(false));
    } catch (e) {
        openNotification('error', 'get floor novel list Fail');
    }
}

function* changeFloorToGetNovelList() {
    while (true) {
        yield take(recommendDetailActionsTypeKey.CHANGE_FLOOR_ID);
        try {
            yield put(recommendDetailAction.isLoading(true));
            yield getFloorNovel();
        } catch (e) {
            openNotification('error', 'get floor novel list Fail');
        }
    }
}

function* watchDeleteFloorNovel() {
    while (true) {
        const { payload } = yield take(recommendDetailActionsTypeKey.GET_DEL_FLOOR_NOVEL_MSG);
        try {
            yield put(recommendDetailAction.isLoading(true));
            yield call(recommendLocationServer.removeFromRecommendLocation, payload.recordId);
            // 为了不在reducer里面多加参数，直接先存id到reducer然后再请求楼层
            yield put(recommendDetailAction.getFloorIdToRequest(payload.locationId));
            yield getFloorNovel();
            yield put(recommendDetailAction.isLoading(false));
            openNotification('success', 'Delete success.');
        } catch (err) {
            if (err.code === 231) {
                openNotification('error', 'Please take off the floor and delete the novels.');
            } else {
                openNotification('error', 'Delete failed.');
            }
            yield put(recommendDetailAction.isLoading(false));
        }
    }
}

function* watchSearchNovelList() {
    while (true) {
        const { payload } = yield take(recommendDetailActionsTypeKey.GET_SEARCH_NOVEL_PARAM);
        payload.typeId = payload.typeId === 'All' ? '' : payload.typeId;
        try {
            yield put(recommendDetailAction.isLoading(true));
            const { result } = yield call(recommendServer.getNovelListByType, payload);
            yield put(recommendDetailAction.getSearchNovelList(result));
            yield put(recommendDetailAction.isLoading(false));
        } catch (err) {
            openNotification('error', 'Failed to get novel list');
        }
    }
}

function* addRecommendNovel() {
    while (true) {
        const { payload } = yield take(recommendDetailActionsTypeKey.ADD_RECOMMEND_NOVEL);
        try {
            yield put(recommendDetailAction.isLoading(true));
            yield call(recommendLocationServer.addBookToRecommendLocation, payload);
            yield put(recommendDetailAction.openAddEditFloorNovelModal(false));

            // 为了不在reducer里面多加参数，直接先存id到reducer然后再请求楼层
            yield put(recommendDetailAction.getFloorIdToRequest(payload.locationId));
            yield getFloorNovel();
            yield put(recommendDetailAction.isLoading(false));
            openNotification('success', 'Add success');
        } catch (err) {
            if (err.code === 201) {
                openNotification('error', 'The recommend no more than 30');
            } else if (err.code === 203) {
                openNotification('error', 'The recommend novel is exist');
            } else if (err.code === 204) {
                openNotification('error', 'The novel does not exist');
            } else {
                openNotification('error', 'Add failed');
            }
            yield put(recommendDetailAction.isLoading(false));
        }
    }
}

function* removeRecommendNovel() {
    while (true) {
        const { payload } = yield take(recommendDetailActionsTypeKey.EDIT_RECOMMEND_NOVEL);
        try {
            yield put(recommendDetailAction.isLoading(true));

            payload.id = Number(payload.floor) < 100 ? '' : payload.id;
            yield call(recommendLocationServer.removeFromRecommendLocation, payload);
            yield put(recommendDetailAction.openAddEditFloorNovelModal(false));
            // 为了不在reducer里面多加参数，直接先存id到reducer然后再请求楼层
            yield put(recommendDetailAction.getFloorIdToRequest(payload.locationId));
            yield getFloorNovel();
            yield put(recommendDetailAction.isLoading(false));
            openNotification('success', 'Change success');
        } catch (err) {
            if (err.code === 201) {
                openNotification('error', 'The recommend no more than 30');
            } else if (err.code === 203) {
                openNotification('error', 'The recommend novel is exist');
            } else if (err.code === 204) {
                openNotification('error', 'The novel does not exist');
            } else {
                openNotification('error', 'Change failed');
            }
            yield put(recommendDetailAction.isLoading(false));
        }
    }
}

function* changeNovelLocation() {
    while (true) {
        const { payload } = yield take(recommendDetailActionsTypeKey.GET_CHANG_NOVEL_LOCATOIN_MSG);
        try {
            yield put(recommendDetailAction.isLoading(true));
            yield call(recommendLocationServer.changeRecommendBookSort, payload);

            // 为了不在reducer里面多加参数，直接先存id到reducer然后再请求楼层
            yield put(recommendDetailAction.getFloorIdToRequest(payload.locationId));
            yield getFloorNovel();
            yield put(recommendDetailAction.isLoading(false));
            openNotification('success', 'Sort success.');
        } catch (err) {
            openNotification('error', 'Failed to sort.');
            yield put(recommendDetailAction.isLoading(false));
        }

    }
}

function* watchUpdatePageSize() {
    yield takeEvery(recommendDetailActionsTypeKey.UPDATE_PAGE, getFloorNovel);
}

export function* watchRecommendLocationDetailSaga() {
    yield all([
        fork(initPage),
        fork(changeFloorToGetNovelList),
        fork(watchDeleteFloorNovel),
        fork(watchSearchNovelList),
        fork(addRecommendNovel),
        fork(removeRecommendNovel),
        fork(watchUpdatePageSize),
        fork(changeNovelLocation)
    ]);
}