import TypeManager  from '../Type';
import { connect } from 'react-redux';
import * as typeAction from '../../action/typeAction';

const mapStateToProps = (state: any) => ({
    data: state.creation.type
});

const mapDispatchToProps = {
    initPage: typeAction.initPage,
    changeTagPage: typeAction.changeTagPage,
    getTypeSearchParam: typeAction.getTypeSearchParam,
    exportModalVisible: typeAction.exportModalVisible,
    submitExport: typeAction.submitExport,
    addEditType: typeAction.addEditType,
    getBetchDelTypeId: typeAction.getBetchDelTypeId,
    settingRuleBtn: typeAction.settingRuleBtn,
    getChangeSorterId: typeAction.getChangeSorterId,
    getRuleSelected: typeAction.getRuleSelected,
    changeTagStatus: typeAction.changeTagStatus,
    changeTagSort: typeAction.changeTagSort
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TypeManager);