import  BlockWord  from '../BlockWord';
import { connect } from 'react-redux';
import * as blockWordActions from '../../actions/blockWordActions';

const mapStateToProps = (state: any) => ({
    data: state.webManager.blockWordData,
});

const mapDispatchToProps = {
    initPage: blockWordActions.initPage,
    getSearchParam: blockWordActions.getSearchParam,
    exportModalVisible: blockWordActions.exportModalVisible,
    submitExport: blockWordActions.submitExport,
    getUpdatePage: blockWordActions.getUpdatePage,
    getDeleteId: blockWordActions.getDeleteId,
    getAddWord: blockWordActions.getAddWord
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BlockWord);