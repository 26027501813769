import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

export function getPaidNovelList(param: any) {
    return postJson('/novel/getSignedNovelList', param);
}

export function ingorePaidNovel(param: any) {
    return postJson('/novel/ignorePaymentRequest', param);
}

export function payMentSetting(param: any) {
    return postJson('/novel/paymentSetting', param);
}

/**
 * 获取付费设置
 */
export function getPaymentInfo(id: number | string) {
    return postJson('/novel/getPaymentInfo', id);
}

/**
 * 获取付费设置 - 无分成权限
 */
export function getPaymentSort(id: number | string) {
    return postJson('/novel/getPaymentSort', id);
}

export function exportPaidNovel(param: any) {
    return postJsonForExport('/novel/getSignedNovelList/export', param, 'paidNovelList.xlsx');
}
