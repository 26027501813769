import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Form, Spin, Button, Icon, Modal, Checkbox, AutoComplete, Badge } from 'antd';

import { StoreState } from '../reducers/chapterReducer';
import { getImageUrl } from '../../../servers/ossServer';
import { Link } from 'react-router-dom';

const FormItem = Form.Item;

interface ChapterProps {
    data: StoreState;
    initPage: () => void;
    sendChapterId: (value: any) => void;
    chapterSort: (value: any) => void;
    dumpChapter: (value: any) => void;
    sendNovelValue: (value: any) => void;
    modalVisible: (value: boolean) => void;
    downloadChapter: (value: any) => void;
}

interface ChapterState {
    checkboxStatus: boolean;
    clearInput: boolean;
    name: string;
    novelId: number| string;
    spiderNovelId: number| string;
    spiderChapterId: number| string;
}

class Chapter extends React.Component<ChapterProps & FormComponentProps, ChapterState> {

    state: ChapterState = {
        name: '',
        novelId: -1,
        spiderNovelId: -1,
        spiderChapterId: -1,
        checkboxStatus: false,
        clearInput: false,
    };

    componentDidMount() {
        const query = window.location.href.split('?')[1];
        const param = query.split('=');
        this.setState({name: decodeURIComponent(param[2]), spiderNovelId: param[1].split('&')[0]});
        this.props.sendChapterId(param[1].split('&')[0]);
    }

    showNovelModal = (id: any) => {
        this.props.form.resetFields(['novel_id']);
        this.setState({spiderChapterId: id});
        if (this.state.checkboxStatus) {
            let param = {
                spiderChapterId: id,
                novelId: this.state.novelId
            };
            this.props.dumpChapter(param);
        } else {
            this.props.modalVisible(true);
        }
        let dumpDataList: any = localStorage.getItem('dumpChapterListData');
        let parseDumpDataList = JSON.parse(dumpDataList);
        if (parseDumpDataList !== null) {
            parseDumpDataList.forEach((item: any) => {
                if (item.spiderId === id) {
                    this.props.form.setFieldsValue({
                        novel_id: item.name
                    });
                }
            });
        }
    }

    hideNovelModal = () => {
        this.props.form.resetFields(['novel_id']);
        this.props.modalVisible(false);
    }

    downloadChapter = (value: any, name: any) => {
        getImageUrl(value).then(res => {
            let param = {
                url: res,
                name: name,
            };
            this.props.downloadChapter(param);
        });
    }

    changeChapterSort = (value?: any) => {
        let param = {
            novelId: this.state.spiderNovelId,
            sequence: value === 'up' ? 'ASC' : 'DESC'
        };
        this.props.chapterSort(param);
    }

    setCheckboxStatus = () => {
        this.setState({checkboxStatus: !this.state.checkboxStatus});
    }

    dumpNovel = () => {
        const value: any = this.props.form.getFieldsValue();
        let param = {
            spiderChapterId: this.state.spiderChapterId,
            novelId: -1
        };
        const novelAllList = this.props.data.allNovelList;
        let dumpDataList: any = localStorage.getItem('dumpChapterListData');
        let parseDumpDataList = JSON.parse(dumpDataList);
        for (let i = 0; i < novelAllList.length; i++) {
            if (novelAllList[i].name === value.novel_id) {
                param.novelId = novelAllList[i].id;
            }
        }
        if (param.novelId === -1) {
            for (let i = 0; i < parseDumpDataList.length; i++) {
                if (parseDumpDataList[i].name === value.novel_id) {
                    param.novelId = parseDumpDataList[i].id;
                }
            }
        }
        this.setState({novelId: param.novelId});
        if (parseDumpDataList !== null) {
            let data = {spiderId: this.state.spiderChapterId, id: param.novelId, name: value.novel_id};
            let isFirst = true;
            parseDumpDataList.map((item: any) => {
                if (item.spiderId !== data.spiderId && item.id !== data.id && item.name !== data.name && isFirst) {
                    isFirst = false;
                    parseDumpDataList.push(data);
                    let stringDumpDataList = JSON.stringify(parseDumpDataList);
                    localStorage.setItem('dumpChapterListData', stringDumpDataList);
                }
            });
        } else {
            let data = [{spiderId: this.state.spiderChapterId, id: param.novelId, name: value.novel_id}];
            let stringData = JSON.stringify(data);
            localStorage.setItem('dumpChapterListData', stringData);
        }
        this.props.dumpChapter(param);
    }

    goBack = () => {
        window.sessionStorage.setItem('isInit', 'true');
    }

    render() {
        const { data } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <Spin spinning={data.loading}>
                    <div className="showNovelList">
                        <h3 onClick={() => this.goBack()} style={{marginBottom: '30px', color: '#2392FC'}}>
                            <Link to={{pathname: '/content/novel'}}>
                                <Icon type="arrow-left" />{this.state.name}(total: {data.getChapterList.length})
                            </Link>
                        </h3>
                        <div >
                            <Icon onClick={() => this.changeChapterSort('up')} type="caret-up" style={{position: 'absolute', top: 0, right: '30px'}}/>
                            <Icon onClick={() => this.changeChapterSort()} type="caret-down" style={{position: 'absolute', top: '8px', right: '30px'}} />
                        </div>
                        {
                            data.getChapterList.length > 0 ?
                            data.getChapterList.map((item: any, index: number) => {
                                return (
                                    <p key={index} className="showNovelListP" style={{borderBottom: '1px solid #ddd', paddingBottom: '30px'}}>
                                        <Badge status={item.updated ? 'error' : 'default'}>
                                                <a style={{color: '#222', textDecoration: 'none'}}>{item.name}</a>
                                        </Badge>
                                        <Button disabled={item.uploaded} onClick={() => this.showNovelModal(item.id)} style={{ float: 'right'}}>Dump</Button>
                                        <Button onClick={() => this.downloadChapter(item.contentOssKey, item.name)} style={{margin: '0 10px', float: 'right'}}>Download</Button>
                                    </p>
                                );
                            }) : <div style={{textAlign: 'center'}}><Icon type="warning" style={{fontSize: '25px'}} /> There is no content</div>
                        }
                    </div>
                </Spin>
                <Modal
                    title="Chapter"
                    visible={data.modalVisible}
                    footer={null}
                    onCancel={() => this.hideNovelModal()}
                    maskClosable={false}
                    mask={false}
                >
                    <Form layout="vertical">
                        <FormItem
                            label="Novel"
                        >
                            {
                                getFieldDecorator('novel_id')(
                                    <AutoComplete
                                        dataSource={this.state.clearInput ? [] : data.novelList}
                                        disabled={false}
                                        onChange={(value: any) => {
                                            this.setState({clearInput: false});
                                            this.props.sendNovelValue(value);
                                        }}
                                    />
                                )
                            }
                        </FormItem>
                        <Checkbox.Group style={{width: '100%', margin: '15px'}} onChange={() => this.setCheckboxStatus()}>
                           <Checkbox value="A">All sections of this novel remain this option</Checkbox>
                        </Checkbox.Group>
                    </Form>
                    <Button onClick={() => this.dumpNovel()} type="primary" style={{marginTop: '20px'}}>Submit</Button>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(Chapter);
