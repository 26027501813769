import * as React from 'react';
import { Form, Row, Col, Input, Table, Select, Button, Modal, Upload, Popconfirm } from 'antd';

import { FormComponentProps } from 'antd/lib/form/Form';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { getExportTitles } from '../../../base/getExportTitles';
import { BlockWordState } from '../reducers/blockWordReducers';
import axios from 'axios';
import { openNotification } from '../../widgets/Notification';

const FormItem = Form.Item;
const Option = Select.Option;

interface  BlockWordProps extends FormComponentProps {
    data: BlockWordState;
    initPage: () => void;
    getSearchParam: (param: any) => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getMonthlyListParam: (param: any) => void;
    getDeleteId: (id: number) => void;
    getAddWord: (param: any) => void;
}

interface BlockWordInitialState {
   addModal: boolean;
   addType: string;
   fileList: any[];
}

class BlockWord extends React.Component<BlockWordProps, BlockWordInitialState> {

    state: BlockWordInitialState = {
        addModal: false,
        addType: 'add',
        fileList: [],
    };

    componentDidMount() {
        this.props.initPage();
    }

    // public action

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    clear = () => {
        this.props.form.resetFields();
    }

    onSearch = () => {

        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            content: value.blockWords,
            level: value.searchLevel,
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.getSearchParam(param);
    }

    columns = () => {
        return [{
            title: 'Blocked words',
            dataIndex: 'content',
            key: 'content',
            className: 'column-center',
        }, {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
            className: 'column-center',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any) => {
                return (
                    <Popconfirm title="Are you sure delete ?" onConfirm={(e: any) => {this.delWord(record.id); }} okText="Yes" cancelText="No">
                        <a style={{color: 'red'}} href="javascript:void(0)">Delete</a>
                    </Popconfirm>
                );
            }
        }];
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    // page action
    delWord = (id: any) => {
        this.props.getDeleteId(id);
    }

    addModalShow = (type: string) => {
        this.setState({addModal: true, addType: type});
        this.setState({fileList: []});
        this.props.form.setFieldsValue({
            modalLevel: 'Level 1',
            modalWord: ''
        });
    }

    addModalOk = () => {
        let addType = this.state.addType;
        const value: any = this.props.form.getFieldsValue();
        value.modalLevel = value.modalLevel === 'Level 1' ? 1 : value.modalLevel;
        let param;
        if (addType === 'add') {
            param = {
                content: value.modalWord.trim(),
                level: value.modalLevel,
                addType: addType
            };
            if (param.content) {
                this.props.getAddWord(param);
                this.addModalCancel();
            } else {
                openNotification('error', 'Please enter sensitive words');
            }
        } else {
            this.uploadExcel();
            this.addModalCancel();
        }
    }

    addModalCancel = () => {
        this.setState({ addModal: false });
    }

    uploadBefore = (file: any, fileList: any) => {
        return false;
    }

    uploadChange = ({fileList}: any) => {
        fileList.map((o: any, i: any) => {
            if (o.status === 'error') {
                o.status = 'undefined';
            }
        });
        this.setState({ fileList });
    }

    uploadExcel = async () => {
        let form = new FormData(), token = window.localStorage.getItem('TOKEN');
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Auth-Token': token
            }
        };

        form.append('file', this.state.fileList[0]);
        const {data} = await axios.post('/api/web/sensitive/importSensitiveList', form, config);
        if (data.code === 0) {
            openNotification('success', 'Import Success');
            this.props.initPage();
        } else {
            openNotification('error', 'Import Failed');
        }
    }

    render() {

        const { addModal, addType, fileList } = this.state;
        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const { data, getUpdatePage } = this.props;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const button = { style: {width: 95, margin : '8px'}};

        return (
            <div>
                <Form>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem
                                label="Block words"
                            >
                                {getFieldDecorator('blockWords')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="level"
                            >
                                {getFieldDecorator('searchLevel')(
                                    <Select>
                                        <Option key="1">Level 1</Option>
                                        <Option key="2">Level 2</Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>

                <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport}>
                    <Button onClick={(e: any) => {this.addModalShow('add'); }} type="primary" {...button}>Add</Button>
                    <Button onClick={(e: any) => {this.addModalShow('import'); }} type="primary" {...button}>Import</Button>
                </Toolbar>

                <Table
                    rowKey="id"
                    loading={data.loading}
                    bordered={true}
                    columns={this.columns()}
                    style={{marginTop: '20px'}}
                    dataSource={data.blockWordList}
                    onChange={getUpdatePage}
                    pagination={{
                        defaultPageSize: 20,
                        total: data.total,
                        current: data.page.current,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '50', '80', '100'],
                        showTotal: total => {
                            return `Total:${data.total} items`;
                        },
                    }}
                />

                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />

                <Modal
                    title="Add Sensitive Words"
                    visible={addModal}
                    onOk={this.addModalOk}
                    onCancel={this.addModalCancel}
                    maskClosable={false}
                >
                    {
                        addType === 'add' ?
                            <Form>
                                <FormItem
                                    label="level"
                                >
                                    {getFieldDecorator('modalLevel')(
                                        <Select {...input}>
                                            <Option key="1">Level 1</Option>
                                            <Option key="2">Level 2</Option>
                                        </Select>
                                    )}
                                </FormItem>
                                <FormItem
                                    label="word"
                                >
                                    {getFieldDecorator('modalWord')(
                                        <Input />
                                    )}
                                </FormItem>
                            </Form>
                            :
                            <Upload
                                fileList={fileList}
                                onChange={this.uploadChange}
                                beforeUpload={this.uploadBefore}
                            >
                                <Button>Import</Button>
                            </Upload>
                    }
                </Modal>
            </div>
        );
    }
}

export default Form.create()(BlockWord);