import vipOrderKey from '../actions/actionsTypeKey/vipOrderActionsTypeKey';

// public action

export function initPage(type: number | string) {
    return { type: vipOrderKey.INIT_PAGE, payload: type };
}

export function loading(value: boolean) {
    return { type: vipOrderKey.LOADING, payload: value };
}
export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: vipOrderKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: vipOrderKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: vipOrderKey.SUBMIT_EXPORT, payload: value };
}

export function getTotalVipOrder(param: any) {
    return { type: vipOrderKey.GET_TOTAL_VIP_ORDER, payload: param };
}

export function getVipOrderList(list: any) {
    return { type: vipOrderKey.GET_VIP_ORDER_LIST, payload: list };
}

export function getSearchDataParam(param: any) {
    return { type: vipOrderKey.SEARCH_DATA, payload: param };
}

export function getVipAllDay(param: any[]) {
    return { type: vipOrderKey.GET_VIP_DAY_ALL, payload: param };
}