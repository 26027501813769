import { fork, all, takeEvery, call, put, select, take } from 'redux-saga/effects';
import quarterlyListKey from '../../components/starList/actions/actionsTypeKey/quarterlyListTypeKey';
import * as quarterlyListAction from '../../components/starList/actions/quarterlyActions';
import * as starServer from '../../servers/starServer';
import { getNovelTypeList } from '../../servers/novelServer';
import { openNotification } from '../../components/widgets/Notification';

function* initPage() {
    yield getTypeList();
    yield getCompetitionList();
    yield getQuarterlyList();
}

function* getTypeList(param?: any) {
    try {
        const {result} = yield call(getNovelTypeList, param);
        yield put(quarterlyListAction.getNovelTypeList(result));
    } catch (err) {
        openNotification('error', 'Failed to get type list.');
    }
}

function* getCompetitionList() {
    try {
        const param = {
            type: '2'
        };
        const { result } = yield call(starServer.getCompetitionList, param);
        yield put(quarterlyListAction.getCompetitionList(result));
    } catch (err) {
        openNotification('error', 'Failed to get monthly list.');
    }
}

function* getQuarterlyList() {
    const data = yield select();
    const manager = data.star.quarterlyList;
    let searchData = manager.searchData, competitionList = manager.competitionList;

    searchData.typeId = searchData.typeId === 'all' ? '' : searchData.typeId;
    searchData.id = searchData.id ? searchData.id : competitionList[0].id;

    yield put(quarterlyListAction.getQuarterData({}));

    try {

        const param = {
            ...manager.page,
            ...manager.sorter,
            'data': {...searchData},
        };

        yield put(quarterlyListAction.loadingStatus(true));
        const { result } = yield call(starServer.getQuarterlyList, param);
        yield put(quarterlyListAction.getQuarterData(result));
        yield put(quarterlyListAction.loadingStatus(false));

    } catch (err) {
        yield put(quarterlyListAction.loadingStatus(false));
        if (err.code === 308) {
            openNotification('error', 'endMonth minus startMonth must greater than 2!');
        } else {
            openNotification('error', 'Failed to get quarter list.');
        }

    }
}

function* exportModel() {
    while (true) {
        const { payload } = yield take(quarterlyListKey.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);

        const state = yield select();
        const manager = state.star.quarterlyList;
        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            ...manager.sorter,
            data: {...manager.searchData},
        };

        try {
            yield put(quarterlyListAction.loadingStatus(true));
            yield call(starServer.exportQuarterlyList, param);
            yield put(quarterlyListAction.loadingStatus(false));
            openNotification('success', 'Export success.');
            yield put(quarterlyListAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(quarterlyListAction.loadingStatus(false));
        }
    }
}

function* quarterlyListConfirm() {
    while (true) {
        yield take(quarterlyListKey.QUARTERLY_LIST_CONFIRM);
        const state = yield select();
        const manager = state.star.quarterlyList;
        try {
            yield call(starServer.starConfirm, manager.quarterlyId);
            yield getQuarterlyList();
            openNotification('success', 'Truncated Success');
        } catch (err) {
            if (err.code === 311) {
                openNotification('error', 'The list has been cut off');
            } else {
                openNotification('error', 'Confirm Failed.');
            }
        }
    }
}

function* quarterlyRemoveRank() {
    while (true) {
        const { payload } = yield take(quarterlyListKey.REMOVE_RANK);
        try {
            yield call(starServer.removeRank, payload);
            yield getQuarterlyList();
            openNotification('success', 'Remove is Success');
        } catch (e) {
            openNotification('error', 'Remove is Failed');
        }
    }
}

function* watchSearchData() {
    yield takeEvery(quarterlyListKey.GET_SEARCH_DATA, getQuarterlyList);
}

function* watchInitPage() {
    yield takeEvery(quarterlyListKey.INIT_PAGE, initPage);
}

function* watchUpdatePage() {
    yield takeEvery(quarterlyListKey.UPDATE_PAGE, getQuarterlyList);
}

export function* watchQuarterlyListSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearchData),
        fork(watchUpdatePage),
        fork(exportModel),
        fork(quarterlyListConfirm),
        fork(quarterlyRemoveRank)
    ]);
}