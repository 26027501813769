enum QuarterlyListTypeKey {
    INIT_PAGE = 'QUARTERLY_INIT_PAGE',
    UPDATE_PAGE = 'QUARTERLY_UPDATE_PAGE',
    EXPORT_MODAL_VISIBLE = 'QUARTERLY_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'QUARTERLY_SUBMIT_EXPORT',
    LOADING = 'QUARTERLY_LOADING',
    GET_SEARCH_DATA = 'GET_QUARTERLY_SEARCH_DATA',
    GET_TYPE_LIST = 'QUARTERLY_GET_TYPE_LIST',
    GET_QUARTERLY_LIST_DATA = 'GET_QUARTERLY_DATA',
    GET_COMPETITION_LIST = 'QUARTERLY_GET_COMPETITION_LIST',
    QUARTERLY_LIST_CONFIRM = 'QUARTERLY_LIST_CONFIRM',
    REMOVE_RANK = 'QUARTERLY_REMOVE_RANK'
}

export default QuarterlyListTypeKey;