import { fork, all, put, call, takeEvery, select, take } from 'redux-saga/effects';
import FloorDataActionsTypeKey from '../../components/data/actions/actionsTypeKey/floorDataActionsTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as  dataServer from '../../servers/dataServer';
import * as floorDataAction from '../../components/data/actions/floorDataAction';

function* initPage() {
    yield getFloorList();
    yield getFloorDataList();
}

function* getFloorDataList() {
    try {
        yield put(floorDataAction.loading(true));
        const state = yield select();
        const data = state.data.floorData;
        // console.log(data);
        const search = {
            floorId : data.floorList[0].id
        };
        const param = {
            ...data.page,
            ...data.sorter,
            data: {...search, ...data.searchData}
        };

        let result = yield call(dataServer.getFloorData, param);
        yield put(floorDataAction.getDataList({data: result.result.data, total: result.result.total}));
        yield put(floorDataAction.loading(false));
    } catch (error) {
        openNotification('error', 'Failed to get floor data list.');
        yield put(floorDataAction.loading(false));
    }
}

function* getFloorList() {
    try {
        yield put(floorDataAction.loading(true));
        let result = yield call(dataServer.getFloorList);
        yield put(floorDataAction.getFloorList(result.result));
        yield put(floorDataAction.loading(false));
    } catch (error) {
        openNotification('error', 'Failed to get floor name list.');
        yield put(floorDataAction.loading(false));
    }
}

function* watchExport() {
    while (true) {
        const { payload } = yield take(FloorDataActionsTypeKey.SUBMIT_EXPORT);
        const state = yield select();
        const data = state.data.floorData;
        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            data: {...data.searchData},
            ...data.sorter
        };
        try {
            yield put(floorDataAction.loading(true));
            yield call(dataServer.exportFloorData, param);
            yield put(floorDataAction.loading(false));
            yield put(floorDataAction.exportModalVisible(false));
        } catch (error) {
            openNotification('error', 'Export failed.');
            yield put(floorDataAction.loading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(FloorDataActionsTypeKey.PAGE_INIT, initPage);
}

function* watchSearchData() {
    yield takeEvery(FloorDataActionsTypeKey.DATA_LIST_SEARCH, getFloorDataList);
}

function* watchUpdatePageSize() {
    yield takeEvery(FloorDataActionsTypeKey.UPDATE_PAGE, getFloorDataList);
}

export function* watchFloorDataRootSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearchData),
        fork(watchExport),
        fork(watchUpdatePageSize),
    ]);
}
