enum MonthlyListTypeKey {
    INIT_PAGE = 'MONTHLY_INIT_PAGE',
    UPDATE_PAGE = 'MONTHLY_UPDATE_PAGE',
    EXPORT_MODAL_VISIBLE = 'MONTHLY_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'MONTHLY_SUBMIT_EXPORT',
    LOADING = 'MONTHLY_LOADING',
    GET_SEARCH_DATA = 'GET_MONTHLY_SEARCH_DATA',
    GET_TYPE_LIST = 'MONTHLY_GET_TYPE_LIST',
    GET_MONTHLY_LIST_DATA = 'GET_MONTHLY_DATA',
    MONTYLY_LIST_CONFIRM = 'MONTYLY_LIST_CONFIRM',
    REMOVE_RANK = 'MONTYLY_REMOVE_RANK'
}

export default MonthlyListTypeKey;