import identityKey from '../actions/actionsTypeKey/identityTypeKey';
import {  ActionType } from '../actions/actionsType/identityType';
// import { versionList } from '../../../servers/responseTypes';

interface PageData {
    current: number;
    pageSize: number;
}

interface ExportData {
    titles: any;
}

export interface IdentityState {
    page: PageData;
    sorter: any;
    loading: boolean;
    total: number;
    export: ExportData;
    searchData: any;
    versionModalVisible: boolean;
    exportModalVisible: boolean;
    authorIdentityList: any[];
    tabsKey: string;
    identityDetailMsg: any;
    toList: boolean;
}

const initialState: IdentityState = {
    toList: false,
    loading: false,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    total: 0,
    export: {
        titles: []
    },
    searchData: {},
    versionModalVisible: false,
    exportModalVisible: false,
    tabsKey: '1',
    identityDetailMsg: {},
    authorIdentityList: []
};

export const identityReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case identityKey.INIT_PAGE:
            return initialState;

        case identityKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload
            });

        case identityKey.GET_SEARCH_DATA:
            return Object.assign({}, state, {
               searchData: action.payload
            });

        case identityKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case identityKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case identityKey.GET_IDENTITY_TABS_KEY:
            return Object.assign({}, state, {
                tabsKey: action.payload,
                searchData: {},
                page: {
                    current: 1,
                    pageSize: 20
                },
            });

        case identityKey.GET_IDENTITY_LIST:
            return Object.assign({}, state, {
                authorIdentityList: action.payload.data,
                total: action.payload.total,
            });

        case identityKey.GET_IDENTITY_DETAIL_MSG:
            return Object.assign({}, state, {
                identityDetailMsg: action.payload
            });

        case identityKey.IDENTITY_DETAIL_TO_LIST:
            return Object.assign({}, state, {
               toList: action.payload
            });

        default:
            return state;
    }
};