import ReportManager  from '../ReportDetail';
import { connect } from 'react-redux';
import * as reportManagerAction from '../../actions/reportManagerAction';

const mapStateToProps = (state: any) => ({
    data: state.audit.reportManager
});

const mapDispatchToProps = {
    getChapterUrl: reportManagerAction.getChapterUrl,
    getChapterChangeStatus: reportManagerAction.getChapterChangeStatus,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReportManager);