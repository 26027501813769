import AdBanner  from '../AdBanner';
import { connect } from 'react-redux';
import * as adBannerActions from '../../actions/adBannerActions';

const mapStateToProps = (state: any) => ({
    data: state.webManager.adBannerData
});

const mapDispatchToProps = {
    initPage: adBannerActions.initPage,
    bannerListSearch: adBannerActions.bannerListSearch,
    addBanner: adBannerActions.addBanner,
    modalVisible: adBannerActions.modalVisible,
    bannerSortSubmit: adBannerActions.bannerSortSubmit,
    modifyBannerStatus: adBannerActions.modifyBannerStatus,
    deleteBanner: adBannerActions.deleteBanner,
    sendNovelValue: adBannerActions.sendNovelValue,
    exportBannerList: adBannerActions.exportBannerList,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AdBanner);