enum ChapterDataActionsTypeKey {
    GET_TYPE_DATA_LIST = 'GET_TYPE_DATA_LIST_CHAPTER_DATA',
    PAGE_INIT = 'CHAPTER_DATA_PAGE_INIT_NOVEL',
    SUBMIT_EXPORT = 'SUBMIT_CHAPTER_DATA_EXPORT',
    EXPORT_MODAL_VISIBLE = 'EXPORT_MODAL_CHAPTER_DATA_VISIBLE',
    LOADING = 'CHAPTER_DATA_LIST_PAGE_LOADING',
    GET_DATA_LIST = 'GET_CHAPTER_DATA_LIST',
    LIST_DATA_SEARCH = 'CHAPTER_DATA_LIST_DATA_SEARCH',
    UPDATE_PAGE = 'UPDATE_CHAPTER_DATA_TABLE_PAGE_SIZE',
}

export default ChapterDataActionsTypeKey;