import { all, fork } from 'redux-saga/effects';

import { watchRecommendSaga } from './sagas/reommendSaga';
import { watchRecommendLocationSaga } from './sagas/reommendLocationSaga';
import { watchRecommendDetailSaga } from './sagas/reommendDetailSaga';
import { watchRecommendLocationDetailSaga } from './sagas/recommendLocationDetailSaga';
import { watchNovelRootSaga } from './sagas/novelContentSaga';
import { watchUserContentRootSaga } from './sagas/userContentSaga';
import { watchDayDataRootSaga } from './sagas/dayDataSaga';
import { watchChapterRootSaga } from './sagas/chapterContentSaga';
import { watchNovelDataRootSaga } from './sagas/novelDataSaga';
import { watchVersionManageRootSaga } from './sagas/versionSaga';
import { watchNovelSaga } from './sagas/novelManagerSaga';
import { watchAuthorSaga } from './sagas/authorManagerSaga';
import { watchTypeSaga } from './sagas/typeManagerSaga';
import { watchTypeDetailSaga } from './sagas/typeDetailManagerSaga';
import { watchChapterSaga } from './sagas/chapterManagerSage';
import { watchBannerRootSaga } from './sagas/bannerContentSaga';
import { watchBannerDataRootSaga } from './sagas/bannerDataSaga';
import { watchCategorySaga } from './sagas/categorySaga';
import { watchRecommendDataRootSaga } from './sagas/recommendDataSaga';
import { watchWebManagerRootSaga } from './sagas/webManagerSaga';
import { watchAddWebManagerRootSaga } from './sagas/addWebManagerSaga';
import { watchActivitySaga } from './sagas/activitySaga';
import { watchFloorDataRootSaga } from './sagas/floorDataSaga';
import { watchSearchManagerSaga } from './sagas/searchSaga';
import { watchMonthlyListSaga } from './sagas/monthlyListSaga';
import { watchQuarterlyListSaga } from './sagas/quarterlyListSaga';
import { watchNovelReviewSaga } from './sagas/novelReviewSaga';
import { watchAdBannerRootSaga } from './sagas/adBannerSaga';
import { watchChapterDetailSaga } from './sagas/chapterDetailSaga';
import { watchAuthorDetailSaga } from './sagas/authorDetailSaga';
import { watchVipOrderSaga } from './sagas/vipOrderSaga';
import { watchRewardOrderSaga } from './sagas/rewardOrderSaga';
import { watchCurrencyOrderSaga } from './sagas/currencyOrderSaga';
import { watchBlockWordSaga } from './sagas/blockWordSaga';
import { watchVoteSaga } from './sagas/voteSaga';
import { watchIdentitySaga } from './sagas/identitySaga';
import { watchReportSaga } from './sagas/reportManagerSaga';
import { watchWithdrawalSaga } from './sagas/withdrawalSaga';
import { watchNovelTotalDataSaga } from './sagas/novelTotalDataSaga';
import { watchNovelUpdateDataSaga } from './sagas/novelUpdateDataSaga';
import { watchPaidNovelSaga } from './sagas/paidNovelSaga';
import { watchBookShelfRootSaga } from './sagas/bookShelfSaga';
import { watchAppHelpCenterSaga } from './sagas/helpSaga';
import { watchAppHelpDetailSaga } from './sagas/helpDetailSaga';
import { watchWebHelpCenterSaga } from './sagas/webHelpSaga';
import { watchWebHelpDetailSaga } from './sagas/webHelpDetailSaga';

export default function* rootSaga() {
    yield all([
        fork(watchRecommendSaga),
        fork(watchRecommendLocationSaga),
        fork(watchRecommendDetailSaga),
        fork(watchRecommendLocationDetailSaga),
        fork(watchNovelRootSaga),
        fork(watchUserContentRootSaga),
        fork(watchDayDataRootSaga),
        fork(watchNovelDataRootSaga),
        fork(watchBannerRootSaga),
        fork(watchVersionManageRootSaga),
        fork(watchNovelSaga),
        fork(watchAuthorSaga),
        fork(watchChapterRootSaga),
        fork(watchTypeSaga),
        fork(watchTypeDetailSaga),
        fork(watchChapterSaga),
        fork(watchBannerDataRootSaga),
        fork(watchCategorySaga),
        fork(watchRecommendDataRootSaga),
        fork(watchActivitySaga),
        fork(watchFloorDataRootSaga),
        fork(watchSearchManagerSaga),
        fork(watchWebManagerRootSaga),
        fork(watchAddWebManagerRootSaga),
        fork(watchActivitySaga),
        fork(watchMonthlyListSaga),
        fork(watchQuarterlyListSaga),
        fork(watchNovelReviewSaga),
        fork(watchAdBannerRootSaga),
        fork(watchChapterDetailSaga),
        fork(watchAuthorDetailSaga),
        fork(watchVipOrderSaga),
        fork(watchRewardOrderSaga),
        fork(watchCurrencyOrderSaga),
        fork(watchBlockWordSaga),
        fork(watchVoteSaga),
        fork(watchIdentitySaga),
        fork(watchReportSaga),
        fork(watchWithdrawalSaga),
        fork(watchNovelTotalDataSaga),
        fork(watchNovelUpdateDataSaga),
        fork(watchPaidNovelSaga),
        fork(watchBookShelfRootSaga),
        fork(watchAppHelpCenterSaga),
        fork(watchAppHelpDetailSaga),
        fork(watchWebHelpCenterSaga),
        fork(watchWebHelpDetailSaga)
    ]);
}
