import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ContentManage } from './views/ContentManage';
import Novel from './views/containers/novelContainers';
import Chapter from './views/containers/chapterContainers';
import ChapterManager from './views/containers/chapterManagerContainers';
import ChapterDetail from './views/containers/chapterDetailContainers';
import AddNovel from './views/containers/addNovelContainers';
import NovelManager from './views/containers/novelManagerContainers';
import PaidNovelManager from './views/containers/paidNovelMangerContainers';

export const contentRouters = (
    <ContentManage>
        <Switch>
            <Route path="/content/novelManager" component={NovelManager}/>
            <Route path="/content/novel" component={Novel}/>
            <Route path="/content/chapter" component={Chapter}/>
            <Route path="/content/chapterDetail" component={ChapterDetail}/>
            <Route path="/content/novelAdd" component={AddNovel}/>
            <Route path="/content/chapterManager" component={ChapterManager}/>
            <Route path="/content/paidNovelManager" component={PaidNovelManager} />
            <Redirect to="/content/novelManager"/>
        </Switch>
    </ContentManage>
);