import recommendDetailActionsTypeKey from './actionsTypeKey/recommendLocationDetailActionsTypeKey';

export function initPage() {
    return { type: recommendDetailActionsTypeKey.INIT_PAGE};
}

export function getFloorIdToRequest(id: number | string) {
    return { type: recommendDetailActionsTypeKey.GET_FLOOR_ID_TO_REQUEST, payload: id };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: recommendDetailActionsTypeKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function isLoading(bol: boolean) {
    return { type: recommendDetailActionsTypeKey.LOADING, payload: bol };
}

export function getFloorList(param: any[]) {
    return { type: recommendDetailActionsTypeKey.GET_FLOOR_LIST, payload: param };
}

export function getFloorNovelList(param: any) {
    return { type: recommendDetailActionsTypeKey.GET_FLOOR_NOVEL_LIST, payload: param };
}

export function changeFloorId(id: number | string) {
    return { type: recommendDetailActionsTypeKey.CHANGE_FLOOR_ID, payload: id };
}

export function getDelNovelMsg(param: any) {
    return { type: recommendDetailActionsTypeKey.GET_DEL_FLOOR_NOVEL_MSG, payload: param };
}

export function openAddEditFloorNovelModal(bol: boolean) {
    return { type: recommendDetailActionsTypeKey.ADD_EDIT_FLOOR_NOVEL_MODAL, payload: bol };
}

export function getNovelTypeList(list: any[]) {
    return { type: recommendDetailActionsTypeKey.GET_NOVEL_TYPE_LIST, payload: list };
}

export function getSearchNovelParam(param: any) {
    return { type: recommendDetailActionsTypeKey.GET_SEARCH_NOVEL_PARAM, payload: param };
}

export function getSearchNovelList(list: any[]) {
    return { type: recommendDetailActionsTypeKey.GET_SEARCH_NOVEL_LIST, payload: list };
}

export function addRecommendNovel(param: any) {
    return { type: recommendDetailActionsTypeKey.ADD_RECOMMEND_NOVEL, payload: param };
}

export function editRecommendNovel(param: any) {
    return { type: recommendDetailActionsTypeKey.EDIT_RECOMMEND_NOVEL, payload: param };
}

export function getChangeNovelLocationMsg(param: any) {
    return { type: recommendDetailActionsTypeKey.GET_CHANG_NOVEL_LOCATOIN_MSG, payload: param };
}