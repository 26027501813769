import * as React from 'react';
import { Row, Col, Select, Table, Popconfirm, Button, Modal, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { StoreState } from '../reducers/helpDetailReducers';
// import { Editor } from '@tinymce/tinymce-react/lib/cjs';
import { getParameter } from '../../../base/getUrlParam';
import { isInt } from '../../../base/regular';
import { openNotification } from '../../widgets/Notification';

const Option = Select.Option;
const FormItem = Form.Item;
const { TextArea } = Input;

interface Props extends FormComponentProps {
    data: StoreState;
    initPage: (id: number | string) => void;
    openCloseQuestionModal: (bol: boolean) => void;
    openCloseIdModal: (bol: boolean) => void;
    getAddQuestionMsg: (param: any) => void;
    getEditQuestionMsg: (param: any) => void;
    getDelQuestionMsg: (param: any) => void;
    getChangeModalQuestion: (id: number | string) => void;
    getChangeQuestionSortMsg: (param: any) => void;
}

interface State {
    questionModalTitle: string;
    content: any;
    helpModalId: string | number;
    helpModalName: string;
    isAddQuestion: boolean;
    questionItem: any;
    answerModal: boolean;
}

class HelpDetail extends React.Component<Props, State> {

    state: State = {
        questionModalTitle: 'Add Question',
        content: '111',
        helpModalId: 0,
        helpModalName: '',
        isAddQuestion: false,
        questionItem: {},
        answerModal: false
    };

    componentWillMount(): void {
        let name = getParameter('name');
        this.setState({helpModalName: name});
    }

    componentDidMount(): void {
        let id = getParameter('id');
        this.setState({helpModalId: id});
        this.props.initPage(id);
    }

    column = () => {
        return [
            {
                title: 'Question ID',
                dataIndex: 'sort',
                key: 'sort',
                render: (text: number | string, item: any) => {
                    return (
                        <span
                            style={{ color: '#1890ff',  cursor: 'pointer' }}
                            onClick={() => {this.idModalShow(item); }}
                        >
                            {text}
                        </span>);
                }
            },
            {
                title: 'Question',
                dataIndex: 'name',
                key: 'name',
                render: (text: string, record: any) => {
                    return (
                        <span
                            style={{ color: '#1890ff',  cursor: 'pointer' }}
                            onClick={() => {this.answerModalShow(record); }}
                        >
                            {text}
                        </span>
                    );
                }
            },
            {
                title: 'Setting',
                dataIndex: 'setting',
                key: 'setting',
                render: (text: any, item: any) => {
                    return (
                        <div>
                            <span
                                style={{marginRight: '15px', color: '#1890ff',  cursor: 'pointer'}}
                                onClick={() => {this.questionModalShow('Edit Question', item); }}
                            >
                                Edit
                            </span>
                            <span
                                style={{ color: 'red', cursor: 'pointer'}}
                            >
                                 <Popconfirm
                                     title="Are you sure delete this task?"
                                     onConfirm={() => {this.delTask(item.id); }}
                                     okText="Yes"
                                     cancelText="No"
                                 >
                                Delete
                                 </Popconfirm>
                            </span>
                        </div>
                    );
                }
            },
        ];
    }

    delTask = (id: number | string) => {
        let param = {
            moduleId: this.state.helpModalId,
            id: id
        };
        this.props.getDelQuestionMsg(param);
    }

    questionModalShow = (str: string, item?: any) => {
        this.setState({questionModalTitle: str, questionItem: item});
        if (item) {
            this.props.form.setFieldsValue({'name': item.name, 'content': item.content });
            this.setState({content: item.content, isAddQuestion: false});
        } else {
            this.props.form.resetFields(['name', 'content']);
            this.setState({content: '', isAddQuestion: true});
        }
        this.props.openCloseQuestionModal(true);
    }

    questionModalCancel = () => {
        this.props.openCloseQuestionModal(false);
        this.props.form.resetFields(['name', 'content']);
    }

    questionModalOk = () => {
        const { isAddQuestion, helpModalId, questionItem } = this.state;
        this.props.form.validateFields(['name', 'content'], (err, values) => {
            if (!err) {
                if (isAddQuestion) {
                    let param = {
                        moduleId: helpModalId,
                        name: values.name,
                        content: values.content
                    };
                    this.props.getAddQuestionMsg(param);
                } else {
                    let param = {
                        moduleId: helpModalId,
                        id: questionItem.id,
                        name: values.name,
                        content: values.content
                    };
                    this.props.getEditQuestionMsg(param);
                }
            }

        });

    }

    idModalShow = (item: any) => {
        this.setState({questionItem: item});
        this.props.form.setFieldsValue({'id': item.sort });
        this.props.openCloseIdModal(true);
    }

    idModalCancel = () => {
        this.props.form.resetFields(['id']);
        this.props.openCloseIdModal(false);
    }

    idModalOk = () => {

        const { data } = this.props;
        this.props.form.validateFields(['id'], (err, values) => {
            if (!err) {
                if (Number(values.id) <= data.questionList.length) {
                    let param = {
                        moduleId: this.state.helpModalId,
                        id: this.state.questionItem.id,
                        sort: values.id
                    };
                    this.props.getChangeQuestionSortMsg(param);
                } else {
                    openNotification('error', 'The serial number cannot exceed the total number of questions');
                }
            }
        });

    }

    handleEditorChange = (e: any) => {
        this.setState({content: e.target.getContent()});
    }

    changeQuestionList = (value: any) => {
        this.setState({helpModalId: value.key});
        this.props.getChangeModalQuestion(value.key);
    }

    answerModalShow = (item: any) => {
        this.setState({answerModal: true, questionItem: item});
    }

    answerModalCancel = () => {
        this.setState({answerModal: false, questionItem: {}});
    }

    render() {

        const { data } = this.props;
        const { getFieldDecorator } = this.props.form;

        const { questionModalTitle, helpModalId, helpModalName, answerModal, questionItem } = this.state;

        let questionContentHtml = questionItem && questionItem.content ? questionItem.content.replace(/\r\n|\r|\n/g, '</br>') : '';

        let pram = {
            key: helpModalId,
            label: helpModalName
        };

        return (
            <div>
                <Row style={{marginBottom: '20px'}}>
                    <Col span={3} style={{float: 'right'}}>
                        <Button type="primary" onClick={() => {this.questionModalShow('Add Question'); }}>Add Question</Button>
                    </Col>
                    <Col span={5} style={{float: 'left'}}>
                        <FormItem label="Question List">
                            {getFieldDecorator('password', {
                                initialValue: pram,
                            })(
                                <Select
                                    labelInValue={true}
                                    style={{width: '300px', marginBottom: '20px'}}
                                    onChange={this.changeQuestionList}
                                >
                                    {
                                        data.helpModalList.map((o: any, i: number) => {
                                            return <Option key={o.id} value={o.id}>{o.title}</Option>;
                                        })
                                    }
                                </Select>
                            )}
                        </FormItem>
                    </Col>
                </Row>

                <Table
                    rowKey={'id'}
                    loading={data.loading}
                    dataSource={data.questionList}
                    columns={this.column()}
                    pagination={false}
                />

                <Modal
                    title={questionModalTitle}
                    visible={data.openCloseQuestionModal}
                    onOk={this.questionModalOk}
                    onCancel={this.questionModalCancel}
                    maskClosable={false}
                    width={'60%'}
                    confirmLoading={data.loading}
                >
                    <Form>
                        <Form.Item label="Question Name">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input question name!',
                                    },
                                    {
                                        max: 200,
                                        message: 'Cannot exceed 200 characters'
                                    }
                                ],
                            })(<Input style={{width: '500px'}} />)}
                        </Form.Item>
                    </Form>

                    <Form.Item label="Question Content">
                        {getFieldDecorator('content', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input question content!',
                                },
                                {
                                    max: 2000,
                                    message: 'Cannot exceed 2000 characters'
                                }
                            ],
                        })(<TextArea rows={4} />)}
                    </Form.Item>
                </Modal>

                <Modal
                    title="Question ID"
                    visible={data.openCloseIdModal}
                    onOk={this.idModalOk}
                    onCancel={this.idModalCancel}
                    maskClosable={false}
                    confirmLoading={data.loading}
                >
                    <Form>
                        <Form.Item label="Question ID">
                            {getFieldDecorator('id', {
                                rules: [
                                    {validator: isInt}
                                ],
                            })(<Input />)}
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title={questionItem && questionItem.name}
                    visible={answerModal}
                    onCancel={this.answerModalCancel}
                    footer={[
                        <Button key="ok" type="primary" onClick={this.answerModalCancel}>
                            OK
                        </Button>
                    ]}
                    maskClosable={false}
                >
                    <p dangerouslySetInnerHTML={{ __html: questionContentHtml}} />
                </Modal>

            </div>
        );
    }
}

export default Form.create()(HelpDetail);