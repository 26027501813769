import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col, Divider, Button, Modal, Popconfirm, Input, Spin, Card, Icon } from 'antd';
import { openNotification } from '../../widgets/Notification';
import { getParameter } from '../../../base/getUrlParam';

interface IdentityDetailProps {
    data: any;
    match: any;
    initPage: () => void;
    getIdentityId: (id: string) => void;
    getIdentityDetailMsg: (msg: any) => void;
    getIdentityDetailModifyParam: (param: any) => void;
}

interface IdentityInitialState {
    imgModal: boolean;
    imgUrl: string;
    refuseModal: boolean;
    refuseText: string;
    urlId: number;
    userName: string;
}

class AuthorDetail extends React.Component<IdentityDetailProps, IdentityInitialState> {

    state: IdentityInitialState = {
        imgModal: false,
        imgUrl: '',
        refuseModal: false,
        refuseText: '',
        urlId: -1,
        userName: ''
    };

    componentWillMount() {
        // 清空已有数据
        let msg = {};
        this.props.getIdentityDetailMsg(msg);
    }

    componentDidMount() {
        let id: any = getParameter('id'), userName: any = getParameter('userName');
        this.setState({urlId: id, userName: userName});
        this.props.getIdentityId(id);
    }

    imgModalShow = (e: any) => {

        this.setState({imgModal: true, imgUrl: e.target.src});
    }

    imgModalOk = () => {
        this.imgModalCancel();
    }

    imgModalCancel = () => {
        this.setState({imgModal: false});
    }

    changeStatus = (status: string) => {
        const { urlId, refuseText } = this.state;
        let param = {
            id: urlId,
            message: refuseText,
            status: status
        };

        this.props.getIdentityDetailModifyParam(param);
    }

    confirm = () => {
        this.setState({refuseModal: true, refuseText: ''});
    }

    confirmModalOk = () => {
        let { refuseText } = this.state;
        if (refuseText === '') {
            openNotification('error', 'Please enter the reason for the rejection');
        } else {
            this.changeStatus('3');
            this.confirmModalCancel();
        }
    }

    confirmModalCancel = () => {
        this.setState({refuseModal: false});
    }

    rejection = (e: any) => {
        if (e.target.value.length > 200) {
            openNotification('error', 'Reason for rejection cannot exceed 200 characters');
        } else {
            this.setState({refuseText: e.target.value});
        }

    }

    render() {

        const { imgModal, imgUrl, refuseModal, refuseText, userName } = this.state;

        const { data } = this.props;

        let msgList = {
            style: {
                fontSize: '16px',
                marginBottom: '15px',
                verticalAlign: 'top'
            }
        };

        let imgStyle = {
            style: {
                width: '200px',
                height: '104px',
                margin: '0 8px 8px 0',
                verticalAlign: 'top',
                marginLeft: '15px',
                padding: '8px',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                cursor: 'pointer'
            }
        };

        if (data.toList) {
            return <Redirect push={true} to="/auditManage/identity" />;
        }

        let detailMsg = data.identityDetailMsg;
        let inforMsg = detailMsg.authenticationInfo;

        return (
            <Spin spinning={data.loading}>
                <Card
                    title={<span><Icon style={{fontSize: '22px', marginRight: '10px'}} type="user" />{userName}</span>}
                    bordered={false}
                >
                    <div style={{marginBottom: '15px'}}>
                        <p style={{fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>User Message</p>
                        <Row>
                            <Col span={12} {...msgList}>Creation time: {detailMsg.createdDate || '--'}</Col>
                            <Col span={12} {...msgList}>User ID: {detailMsg.userId}</Col>
                            <Col span={12} {...msgList}>Member status: {detailMsg.vipType === 1 ? 'Yes' : 'No'}</Col>
                            <Col span={12} {...msgList}>Member Expire date: {detailMsg.vipExpiredDate || '--'}</Col>
                        </Row>
                    </div>
                    <Divider />
                    <div style={{marginBottom: '15px'}}>
                        <div style={{fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>
                            Certification Information
                            {data.tabsKey === '1' &&
                                <Row>
                                    <Col offset={20} span={2}>
                                        <Button onClick={(e: any) => {this.changeStatus('2'); }}>release</Button>
                                    </Col>
                                    <Col span={2}>
                                        <Popconfirm title="Are you sure refuse" onConfirm={this.confirm} okText="Yes" cancelText="No">
                                            <Button type="danger">refuse</Button>
                                        </Popconfirm>

                                    </Col>
                                </Row>
                            }
                        </div>

                        <Row style={{marginTop: '16px'}}>
                            <Col span={12} {...msgList}>Name: {inforMsg ? `${inforMsg.givenName ? inforMsg.givenName : ''}` + `  ${inforMsg.familyName ? inforMsg.familyName : ''}` :  '--'}</Col>
                            <Col span={12} {...msgList}>identity number: {inforMsg ? inforMsg.cardNumber : '--'}</Col>
                            <Col span={24} {...msgList}>Age: {detailMsg.adult ? 'Adult' : 'Minor'}</Col>
                            <Col span={12} {...msgList}>ID card (front photo):
                                {
                                    inforMsg && inforMsg.cardPic ?
                                        <img onClick={(e: any) => {this.imgModalShow(e); }} {...imgStyle} src={inforMsg.cardPic} alt=""/> : '--'
                                }

                            </Col>
                            <Col span={12} {...msgList}>Hand-held identity card:
                                {
                                    inforMsg && inforMsg.cardHandPic ?
                                        <img onClick={(e: any) => {this.imgModalShow(e); }} {...imgStyle} src={inforMsg.cardHandPic} alt=""/>
                                        : '--'
                                }

                            </Col>
                        </Row>
                    </div>
                </Card>
                <Modal
                    title="Large Picture"
                    visible={imgModal}
                    onOk={this.imgModalOk}
                    onCancel={this.imgModalCancel}
                    maskClosable={false}
                >
                    <img style={{width: '400px'}} src={imgUrl} alt=""/>
                </Modal>
                <Modal
                    title="Reason for the rejection"
                    visible={refuseModal}
                    onOk={this.confirmModalOk}
                    onCancel={this.confirmModalCancel}
                    maskClosable={false}
                >
                    <Input value={refuseText} onChange={this.rejection} placeholder="Please enter the reason for the rejection"/>
                </Modal>
            </Spin>
        );
    }
}

export default AuthorDetail;