import { fork, all, put, call, takeEvery, select, take } from 'redux-saga/effects';
import RecommendDataActionsTypeKey from '../../components/data/actions/actionsTypeKey/recommendDataActionsTypeKey';
import { openNotification } from '../../components/widgets/Notification';

import * as  dataServer from '../../servers/dataServer';
import * as recommendDataAction from '../../components/data/actions/recommendDataAction';

function* initPage() {
    yield getDataList();
}

function* getDataList() {
    try {
        yield put(recommendDataAction.loading(true));
        const state = yield select();
        const data = state.data.recommendData;
        const param = {
            ...data.page,
            ...data.sorter,
            data: {...data.searchData}
        };
        let result = yield call(dataServer.getRecommendData, param);
        yield put(recommendDataAction.getDataList({data: result.result.data, total: result.result.total}));
        yield put(recommendDataAction.loading(false));
    } catch (error) {
        openNotification('error', 'Fail to get recommend data list.');
        yield put(recommendDataAction.loading(false));
    }
}

function* watchExport() {
    while (true) {
        const { payload } = yield take(RecommendDataActionsTypeKey.SUBMIT_EXPORT);
        const state = yield select();
        const data = state.data.recommendData;
        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            data: {...data.searchData},
            ...data.sorter
        };
        try {
            yield put(recommendDataAction.loading(true));
            yield call(dataServer.exportRecommendData, param);
            yield put(recommendDataAction.loading(false));
            yield put(recommendDataAction.exportModalVisible(false));
        } catch (error) {
            openNotification('error', 'export fail.');
            yield put(recommendDataAction.loading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(RecommendDataActionsTypeKey.PAGE_INIT, initPage);
}

function* watchSearchData() {
    yield takeEvery(RecommendDataActionsTypeKey.LIST_DATA_SEARCH, getDataList);
}

function* watchUpdatePageSize() {
    yield takeEvery(RecommendDataActionsTypeKey.UPDATE_PAGE, getDataList);
}

export function* watchRecommendDataRootSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearchData),
        fork(watchExport),
        fork(watchUpdatePageSize),
    ]);
}