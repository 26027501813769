import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Table, Form, Row, Col, Input, Modal, Popconfirm, List, Select } from 'antd';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../reducers/authorReducer';
import { getExportTitles } from '../../../base/getExportTitles';
import { getThousandNum } from '../../../base/base';

const FormItem = Form.Item;
const Option = Select.Option;

interface AuthorProps extends FormComponentProps {
    data: StoreState;
    initPage: () => void;
    authorListSearch: (param: any) => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    addFinancialModalVisible: (value: boolean) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getAuthorId: (id: number) => void;
    getAuthorStateChangeParam: (param: any) => void;
}

type AuthorState = {
    novelNum: boolean;
    certification: any[];
};

class Author extends React.Component<AuthorProps, AuthorState> {

    state: AuthorState = {
        novelNum: false,
        certification: ['not certified', 'In certification', 'verified', 'certification failed']
    };

    componentWillMount() {
        this.props.initPage();
    }

    getTable = () => {
        return [
            {
                title: 'Status',
                dataIndex: 'enabled',
                key: 'enabled',
                className: 'column-center',
                render: (text: number) => {
                    if (text === 0) {
                      return <span>Freeze</span>;
                    } else {
                       return <span>Enable</span>;
                    }
                }
            },
            {
                title: 'Author',
                dataIndex: 'penName',
                key: 'penName',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return <span>{text === '' ? record.userName : text}</span>;
                }
            },
            {
                title: 'Author ID',
                dataIndex: 'id',
                key: 'id',
                className: 'column-center',
            },
            {
                title: 'User ID',
                dataIndex: 'userId',
                key: 'userId',
                className: 'column-center',
            },
            {
                title: 'Novel Count',
                dataIndex: 'novelCount',
                key: 'novelCount',
                className: 'column-center',
                render: (text: number, record: any) => {
                    return <a onClick={(e) => {this.novalNumModalShow(record); }}>{text}</a>;
                }
            },
            {
                title: 'Certification',
                dataIndex: 'authenticationStatus',
                key: 'authenticationStatus',
                className: 'column-center',
                render: (text: string) => {
                    return (
                        <span>{this.state.certification[text]}</span>
                    );
                }
            },
            {
                title: 'Click',
                dataIndex: 'clickCount',
                key: 'clickCount',
                className: 'column-center',
                sorter: true,
                sortField: 'clickCount',
                render: (text: number) => {
                    return (
                        <span>{getThousandNum(text)}</span>
                    );
                }
            },
            {
                title: 'Subscribe',
                dataIndex: 'subscriptionCount',
                key: 'subscriptionCount',
                className: 'column-center',
                sorter: true,
                sortField: 'subscriptionCount',
                render: (text: number) => (
                    <span>{getThousandNum(text)}</span>
                )
            },
            {
                title: 'Date',
                dataIndex: 'authorizedAtDateValue',
                key: 'authorizedAtDateValue',
                className: 'column-center',
                sorter: true,
                sortField: 'authorizedAt',
            },
            {
                title: 'Time',
                dataIndex: 'authorizedAtTimeValue',
                key: 'authorizedAtTimeValue',
                className: 'column-center',
            },
            {
                title: 'Setting',
                dataIndex: 'edit',
                key: 'edit',
                className: 'column-center',
                render: (text: any, record: any) => {
                    if (record.enabled === 0) {
                        return (
                            <div>
                                <Link to={`/userManage/authorDetail/${record.id}`} style={{marginRight: '15px'}}>View6</Link>
                                <Popconfirm
                                    title="Are you sure change this status?"
                                    cancelText="No"
                                    onConfirm={(e: any) => {this.authorStateChange(record, 'show'); }}
                                >
                                    <a>Enable</a>
                                </Popconfirm>
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                <Link to={`/userManage/authorDetail/${record.id}`} style={{marginRight: '15px'}}>View7</Link>
                                <Popconfirm
                                    title="Are you sure change this status?"
                                    cancelText="No"
                                    onConfirm={(e: any) => {this.authorStateChange(record, 'hide'); }}
                                >
                                    <a>Freeze</a>
                                </Popconfirm>

                            </div>
                        );
                    }
                },
            }
        ];
    }

    authorStateChange = (record: any, type: string) => {
        let param = {
            id: record.id,
            type: type
        };
        this.props.getAuthorStateChangeParam(param);
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.getTable());
        this.props.submitExport({...value, titles});
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        let param = {
            name: value.name,
            authorId: value.authorId,
            userId: value.userId,
            authenticationStatus: value.certification
        };
        for (let key in param) {
            if (param[key] === 'All' || param[key] === '') {
                delete param[key];
            }
        }
        this.props.authorListSearch(param);
    }

    onClear = () => {
       this.props.form.resetFields();
    }

    novalNumModalShow = (record: any) => {
        this.setState({novelNum: true});
        this.props.getAuthorId(record.id);
    }

    novalNumModalClose = () => {
        this.setState({novelNum: false});
    }

    render() {
        const {getFieldDecorator, getFieldsValue} = this.props.form;
        const { novelNum } = this.state;
        const { data, updatePage} = this.props;

        return (
            <div>
                    <Form>
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormItem label="Author Name">
                                    {getFieldDecorator('name')(
                                        <Input />
                                    )}

                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="Author ID">
                                    {getFieldDecorator('authorId')(
                                        <Input />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="User ID">
                                    {getFieldDecorator('userId')(
                                        <Input />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Certification"
                                >
                                    {getFieldDecorator('certification', {
                                        initialValue: 'All'
                                    })(
                                        <Select>
                                            <Option value="All">All</Option>
                                            <Option value="0">not certified</Option>
                                            <Option value="1">In certification</Option>
                                            <Option value="2">verified</Option>
                                            <Option value="3">certification failed</Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>

                    <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.onClear} onExport={this.handleExport} />

                    <Table
                        rowKey="id"
                        loading={data.loading}
                        bordered={true}
                        columns={this.getTable()}
                        dataSource={data.getAuthorList}
                        style={{marginTop: '20px'}}
                        onChange={updatePage}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total: ${data.total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={getFieldsValue}
                        visible={data.exportModalVisible}
                        loading={data.loading}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                    />
                <Modal
                    visible={novelNum}
                    onCancel={this.novalNumModalClose}
                    footer={null}
                    maskClosable={false}
                >
                    <List
                        dataSource={data.authorNovelList}
                        renderItem={(item: any) => (
                            <List.Item style={{wordBreak: 'break-all'}} extra={''}>
                                <Link
                                    to={{pathname: '/content/chapterManager', search: `?id=${item.id}` }}
                                    style={{color: '#2392FC'}}
                                >
                                    {item.id} - {item.name}
                                </Link>
                            </List.Item>
                        )}

                    />
                </Modal>
            </div>
        );
    }
}

export default Form.create()(Author);