import { fork, all, call, takeEvery, put, select, take } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import novelKey from '../../components/content/actions/actionsTypeKey/novelManagerActionsTypeKey';
import * as novelAction from '../../components/content/actions/novelManagerAction';
import * as novelServer from '../../servers/novelServer';
import { openNotification } from '../../components/widgets/Notification';
import { uploadFile } from '../../servers/ossServer';

function* novelList() {
    const data = yield select();
    const manager = data.content.novelManager;
    manager.searchData.typeId = manager.searchData.typeId === 'all' ? '' : manager.searchData.typeId;
    const param = {
        ...manager.page,
        ...manager.sorter,
        'data': {...manager.searchData},
    };
    try {
        yield put(novelAction.novelLoading(true));
        const {result} = yield call(novelServer.getNovelList, param);
        yield put(novelAction.novelLoading(false));
        yield put(novelAction.getNovelList(result));
    } catch (err) {
        yield put(novelAction.novelLoading(false));
        openNotification('error', 'Fail to get novel list');
    }

}

function* initPage() {
    yield novelList();
    yield getTypeList();
    yield getNovelCount();

}

function* getTypeList(param?: any) {
    try {
        const {result} = yield call(novelServer.getNovelTypeList, param);
        yield put(novelAction.getNovelTypeList(result));
    } catch (err) {
        openNotification('error', 'Failed to get type list.');
    }
}

function* getNovelCount() {
    try {
        const result = yield call(novelServer.getNotReleaseUgcNovelCount);
        localStorage.setItem('count', result.result);
    } catch (err) {
        openNotification('error', 'Failed to get not release ugc novel count.');
    }
}

function* exportModel() {
    while (true) {
        const { payload } = yield take(novelKey.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);
        payload.titles.forEach((o: any, i: number) => {
            if (o.index === 'completedStatus') {
                o.index = 'completedStatusValue';
            }

            if (o.index === 'novelType') {
                o.index = 'novelTypeExport';
            }

            if (o.index === 'signed') {
                o.index = 'signedExport';
            }

            if  (o.index === 'chargeStatus') {
                o.index = 'chargeExport';
            }

            // if (o.index === 'addedDate') {
            //     o.index = '';
            // }
            //
            // if (o.index === 'addedTime') {
            //     o.index = '';
            // }
        });

        const state = yield select();
        const manager = state.content.novelManager;
        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            ...manager.sorter,
            data: {...manager.searchData},
        };
        try {
            yield put(novelAction.novelLoading(true));
            yield call(novelServer.exportNavelList, param);
            yield put(novelAction.novelLoading(false));
            openNotification('success', 'Export success.');
            yield put(novelAction.exportModalVisible(false));
        } catch (err) {
            console.error(err);
            openNotification('error', 'Export Failed.');
            yield put(novelAction.novelLoading(false));
        }
    }
}

function* novelChangeState() {
    while (true) {
        try {
            const { payload } = yield take(novelKey.GET_CHANGE_NOVEL_STATE_PARAM);
            yield call(novelServer.novelChangeState, payload);
            openNotification('success', 'Change success.');
            yield novelList();
        } catch (err) {
            if (err.code === 214) {
                openNotification('error', 'There are no chapters in the novel.');
            } else if (err.code === 220) {
                openNotification('error', 'Please remove the banner.');
            } else if (err.code === 221) {
                openNotification('error', 'Please remove the dashboard.');
            } else {
                openNotification('error', 'Change failed.');
            }
        }
    }
}

function* getNovelMsgToId() {
    while (true) {
        const { payload } = yield take(novelKey.GET_NOVEL_ID);
        try {
            yield put(novelAction.novelLoading(true));
            const { result } = yield call(novelServer.getMsgToId, payload);
            console.log("mmmmmm", payload);
            yield put(novelAction.getNovelMsgToId(result));
            yield put(novelAction.getNovelCouverUrl(result.coverUrl));
            yield put(novelAction.novelLoading(false));
        } catch (err) {
            yield put(novelAction.novelLoading(false));
            openNotification('error', 'Fail to get novel msg');
        }
    }
}

function* addNovel() {
    while (true) {
        const { payload } = yield take(novelKey.GET_ADD_NOVEL_PARAM);

        try {
            if (payload.ossKey) {
                if (payload.ossKey.file) {
                    payload.ossKey = yield uploadFile('cover', payload.ossKey.file);
                }
            }
            if (payload.id) {
                yield call(novelServer.editNovel, payload);
                openNotification('success', 'edit success');
            } else {
                yield call(novelServer.addNovel, payload);
                openNotification('success', 'add success');
            }

            if (payload.ischapter === 'true')  {
                yield put(novelAction.goNovelDetail(true));
            } else {
                yield put(novelAction.goNovelList(true));
            }
            yield delay(1500);
            yield put(novelAction.goNovelDetail(false));
            yield put(novelAction.goNovelList(false));

        } catch (err) {
            if (err.code === 217) {
                openNotification('error', 'The oss key does not exist');
            } else if (err.code === 225) {
                openNotification('error', 'The url is exist');
            } else if (err.code === 318) {
                openNotification('error', 'Please process the withdrawal request for the novel first');
            } else {
                openNotification('error', 'Fail to add novel');
            }

        }
    }
}

function* watchDelNovel() {
    while (true) {
        const { payload } = yield take(novelKey.GET_DEL_NOVEL_ID);
        try {
            yield call(novelServer.novelDelete, payload);
            openNotification('success', 'Delete success.');
            yield novelList();
        } catch (err) {
            if (err.code === 221) {
                openNotification('error', 'Please remove the dashobard');
            } else {
                openNotification('error', 'Fail to add novel');
            }
        }

    }
}

function* watchSearchAuthor() {
    while (true) {
        const { payload } = yield take(novelKey.GET_SEARCH_AUTHOR_KEY);
        try {
            const { result } = yield call(novelServer.getKeyAuthor, payload);
            yield put(novelAction.getKeyAuthorList(result));
        } catch (err) {
            openNotification('error', 'Fail to get author list');
        }
    }
}

function* changeNovelUpdateStatus() {
    while (true) {
        const { payload } = yield take(novelKey.CHANGE_NOVEL_UPDATE_STATUS);
        try {
            yield call(novelServer.changeNovelUPdateStatus, payload);
        } catch (err) {
            openNotification('error', 'Fail to change novel updateStatus');
        }
    }
}

function* watchInitPage() {
    yield takeEvery(novelKey.INIT_NOVEL_PAGE, initPage);
}

function * watchNovelSearch() {
    yield takeEvery(novelKey.GET_NOVEL_SEARCH_PARAM, novelList );
}

function* watchUpdatePage() {
    yield takeEvery(novelKey.NOVEL_UPDATE_PAGE, novelList);
}

function* watchKeyType() {
    while (true) {
        const { payload } = yield take(novelKey.GET_SEARCH_TYPE_KEY);
        yield getTypeList(payload);
    }
}

export function* watchNovelSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchNovelSearch),
        fork(watchUpdatePage),
        fork(exportModel),
        fork(novelChangeState),
        fork(watchDelNovel),
        fork(watchSearchAuthor),
        fork(watchKeyType),
        fork(getNovelMsgToId),
        fork(addNovel),
        fork(changeNovelUpdateStatus),
    ]);
}