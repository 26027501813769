import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Form, Spin, Row, Col, Upload, Icon, Button, Select } from 'antd';
import { StoreState } from '../reducers/novelManagerReducer';
import { Redirect } from 'react-router-dom';
import { IsURL } from '../../../base/regular';
import { isLightApp } from '../../../util/packageUtil';
import '../../../style/novel.css';

const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;

interface AddNovelProps {
    data: StoreState;
    location: any;
    getEditNovelId: (id: string) => void;
    getSearchAuthorKey: (key: any) => void;
    getSearchTypeKey: (key: any) => void;
    getAddNovelParam: (param: any) => void;
    getDelNovelId: (id: number) => void;
    getKeyAuthorList: (param: any[]) => void;
    getNovelCouverUrl: (url: string) => void;
    goNovelList: (bool: boolean) => void;
    goNovelDetail: (bool: boolean) => void;
}

interface AddNovelState {
    loading: boolean;
    authorSelId: string;
    typeSelId: string;
    novelId: string;
    isChapter: boolean;
    novelType: any[];
    signed: any[];

}

function getBase64(img: any, callback: (value: any) => void) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

class AddNovel extends React.Component<AddNovelProps & FormComponentProps, AddNovelState> {

    state: AddNovelState = {
        loading: false,
        authorSelId: '',
        typeSelId: '',
        novelId: '',
        isChapter: false,
        novelType: ['Original', 'Reprint', 'PGC', 'Star Novel', 'Translate'],
        signed: ['Kontrak digital', 'Kontrak tertulis']
    };

    componentDidMount() {
        let hasId = this.props.location.search;
        if (hasId) {
            const search = hasId.split('&');
            const id = search[0].split('=')[1];
            const ischapter = search[1].split('=')[1];
            this.setState({
                novelId: id,
                isChapter: ischapter
            });
            this.props.getEditNovelId(id);
        }
    }

    authorSearch = (value: any) => {
        if (value) {
            this.props.getSearchAuthorKey(value);
        } else {
            this.props.getKeyAuthorList([]);
        }
    }

    authorChange = (e: any) => {
        this.props.data.keyAuthor.map(item => {
             if (item.userName === e) {
                 this.setState({
                     authorSelId: item.id
                 });
             }
        });
    }

    typeSearch = (value: any) => {
        if (value) {
            this.props.getSearchTypeKey(value);
        }
    }

    typeChange = (e: any) => {
        this.props.data.typeList.map(item => {
            if (item.name === e) {
                this.setState({
                    typeSelId: item.id
                });
            }
        });
    }

    beforeUpload = (file: any) => {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        return isJPG && false;
    }

    handleChange = (info: any) => {
        const isJPG = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
        if (isJPG) {
            getBase64(info.file, imageUrl => {
                this.props.getNovelCouverUrl(imageUrl);
                this.setState({
                    loading: false,
                });
            });
        }
    }

    handleSubmit = () => {
        const { typeSelId, authorSelId, novelType, signed } = this.state;
        const { novelMsg } = this.props.data;
        this.props.form.validateFields((err: any, values: any) => {
            if (!err) {
                values.typeId = values.typeId === novelMsg.typeName ? novelMsg.typeId : typeSelId;

                values.authorId = values.authorId === novelMsg.author ? novelMsg.authorId : authorSelId;

                values.ossKey = values.ossKey ? values.ossKey : novelMsg.ossKey;

                values.languageType = values.languageType === 'Indonesia' ? 2 : (values.languageType === 'English' ? 1 : values.languageType);

                values.novelType = values.novelType === novelType[novelMsg.novelType] ? novelMsg.novelType : values.novelType;

                values.signed = values.signed === signed[novelMsg.signed] ? novelMsg.signed : values.signed;

                if (novelMsg && novelMsg.id) {
                    values.id = novelMsg.id;
                }

                if (values.completedStatus === 'Update' || values.completedStatus === 'Completed') {
                    values.completedStatus = novelMsg.completedStatus;
                }

                values.ischapter = this.state.isChapter;

                // console.log(values, values.authorId, novelMsg, authorSelId);
                this.props.getAddNovelParam({...values});
            }
        });
    }

    goList = () => {
        if (this.state.isChapter) {
            this.props.goNovelDetail(true);
        } else {
            this.props.goNovelList(true);
        }
    }

    componentWillUnmount() {
        this.props.goNovelDetail(false);
        this.props.goNovelList(false);
    }

    render() {
        const {getFieldDecorator} = this.props.form;

        const { novelType, signed, isChapter } = this.state;

        const { data } = this.props;

        const lightApp = isLightApp();
        console.log('lightApp', lightApp);

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const form = {
            style: {margin: '10px 30px'}
        };

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        const languageList = [
            {key: 1, value: 'English'},
            {key: 2, value: 'Indonesia'}
        ];

        const worksTypeList = [
            {key: '0', value: 'Original'},
            {key: '1', value: 'Reprint'},
            {key: '2', value: 'PGC'},
            {key: '3', value: 'Star Novel'},
            {key: '4', value: 'Translate'},
        ];

        const signingList = [
            {key: '1', value: 'Kontrak tertulis'},
            {key: '0', value: 'Kontrak digital'}
        ];

        if (data.goNovelList === true) {
            return <Redirect push={true} to={{pathname: `/content/novelManager`}}/>;
        }

        if (data.goNovelDetail === true) {
            return <Redirect push={true} to={{pathname: `/content/chapterManager`, search: `?id=${this.state.novelId}`}}/>;
        }

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Form style={{width: '50%'}}>
                        <Row>
                            <Col >
                                <FormItem label="Novel Name" {...form}>
                                    {getFieldDecorator('name', {
                                        validateFirst: true,
                                        initialValue: data.novelMsg.name || '',
                                        rules: [
                                            {required: true, message: 'Please enter the name of the novel'},
                                            {max: 100, message: 'No more than 100 characters'},
                                        ],
                                    })(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col>
                                <FormItem label="Author" {...form}>
                                    {getFieldDecorator('authorId', {
                                        initialValue: data.novelMsg.author || '',
                                        rules: [{required: true, message: 'Please select author'}],
                                    })(
                                        <Select
                                            disabled={isChapter ? true : false}         
                                            showSearch={true}
                                            defaultActiveFirstOption={false}
                                            placeholder="Select Author"
                                            notFoundContent={null}
                                            filterOption={false}
                                            onSearch={this.authorSearch}
                                            onChange={(e) => {this.authorChange(e); }}
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                data.keyAuthor.map((item: any, idx: number) => {
                                                    return <Option key={item.userName}>{item.userName}</Option>;
                                                })
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col>
                                <FormItem label="Type" {...form}>
                                    {getFieldDecorator('novelType', {
                                        initialValue:  novelType[data.novelMsg.novelType] || '',
                                        rules: [{required: true, message: 'Please choose novelType'}]
                                    })(
                                        <Select
                                            disabled={lightApp}>
                                            {worksTypeList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col>
                                <FormItem label="isSigning" {...form}>
                                    {getFieldDecorator('signed', {
                                        initialValue:  signed[data.novelMsg.signed] || '',
                                        rules: [{required: true, message: 'Please choose signed'}]
                                    })(
                                        <Select
                                            disabled={lightApp}>
                                            {signingList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col>
                                <FormItem label="Category" {...form}>
                                    {getFieldDecorator('typeId', {
                                        initialValue: data.novelMsg.typeName || '',
                                        rules: [{required: true, message: 'Please choose the type'}]
                                    })(
                                        <Select
                                            disabled={lightApp}
                                            showSearch={true}
                                            defaultActiveFirstOption={false}
                                            placeholder="Select Type"
                                            notFoundContent={null}
                                            filterOption={false}
                                            onSearch={this.typeSearch}
                                            onChange={(e) => {this.typeChange(e); }}
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                data.typeList.map((item: any, idx: number) => {
                                                    return <Option key={item.name}>{item.name}</Option>;
                                                })
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col>
                                <FormItem
                                    label="Language"
                                    {...form}
                                >
                                    {getFieldDecorator('languageType', {
                                        initialValue: data.novelMsg.languageType === 1 ? 'English' : (data.novelMsg.languageType === 2  ? 'Indonesia' : '') || '',
                                        rules: [{required: true, message: 'Please choose the language'}]
                                    })(
                                        <Select disabled={lightApp} {...input}>
                                            {
                                                languageList.map((item: any) => {
                                                    return <Option key={item.key}>{item.value}</Option>;
                                                })
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col>
                                <FormItem label="Update" {...form}>
                                    {getFieldDecorator('completedStatus', {
                                        initialValue:  data.novelMsg.completedStatusValue || ''
                                    })(
                                        <Select disabled={lightApp}>
                                            <Option value="0">Update</Option>
                                            <Option value="1">Completed</Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col>
                                <FormItem label="Url" {...form}>
                                    {getFieldDecorator('url', {
                                        initialValue: data.novelMsg.url || '',
                                        rules: [
                                            {validator: IsURL }
                                        ],
                                    })(
                                        <Input disabled={lightApp} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col>
                                <FormItem label="Introduction" {...form}>
                                    {getFieldDecorator('brief', {
                                        initialValue: data.novelMsg.brief || '',
                                        rules: [
                                            {max: 1000, message: 'No more than 1000 characters'}
                                        ],
                                    })(
                                        <TextArea rows={4} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col className="upload">
                                <FormItem label="Picture(240*336 or 296*414)" {...form}>
                                    {getFieldDecorator('ossKey', {
                                        initialValue: data.novelMsg.ossKey || '',
                                        rules: [
                                            {required: true, message: 'Please upload image'},
                                        ],
                                    })(
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="novel-uploader"
                                            showUploadList={false}
                                            beforeUpload={this.beforeUpload}
                                            onChange={this.handleChange}
                                        >
                                            {data.novelCoverUrl ? <img src={data.novelCoverUrl} alt="avatar" /> : uploadButton}
                                        </Upload>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                    <div style={{textAlign: 'center'}}>
                        <Button type="primary" style={{marginRight: '15px'}} onClick={this.handleSubmit}>{lightApp ? 'Save to Novelaku' : 'Submit'}</Button>
                        {/*<Popconfirm*/}
                            {/*title="Are you sure delete this novel?"*/}
                            {/*onConfirm={this.handleDelete}*/}
                            {/*okText="Yes"*/}
                            {/*cancelText="No"*/}
                        {/*>*/}
                            {/*<Button*/}
                                {/*type="danger"*/}
                                {/*style={{marginRight: '15px'}}*/}
                            {/*>*/}
                                {/*Delete*/}
                            {/*</Button>*/}
                        {/*</Popconfirm>*/}
                        <Button onClick={this.goList}>Cancel</Button>
                    </div>
                </Spin>
            </div>
        );
    }
}

export default Form.create()(AddNovel);
