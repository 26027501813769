import monthlyListKey from '../actions/actionsTypeKey/monthlyListTypeKey';

// public action

export function initPage() {
    return { type: monthlyListKey.INIT_PAGE };
}

export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: monthlyListKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: monthlyListKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: monthlyListKey.SUBMIT_EXPORT, payload: value };
}

// page action

export function loadingStatus(value: boolean) {
    return { type: monthlyListKey.LOADING, payload: value };
}

export function getMonthlySearchData(param: any) {
    return { type: monthlyListKey.GET_SEARCH_DATA, payload: param };
}

export function getNovelTypeList(param: any) {
    return { type: monthlyListKey.GET_TYPE_LIST, payload: param };
}

export function getMonthlyListData(data: any) {
    return { type: monthlyListKey.GET_MONTHLY_LIST_DATA, payload: data };
}

export function monthlyListConfirm() {
    return { type: monthlyListKey.MONTYLY_LIST_CONFIRM };
}

export function monthlyRemoveRank(param: any) {
    return { type: monthlyListKey.REMOVE_RANK, payload: param };
}