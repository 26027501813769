import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 获取需要上传小说列表
 */
export function getNovelList(param: any) {
    return postJson('/content/getNovelList', param);
}

/**
 * 获取章节列表
 */
export function getChapterList(param: any) {
    return postJson('/content/getChapterList', param);
}

/**
 * 上传章节
 */
export function uploadChapter(param: any) {
    return postJson('/content/uploadChapter', param);
}

/**
 * 导出需要上传小说类型列表
 */
export function exportNovelList(param: any) {
    return postJsonForExport('/content/getNovelList/export', param, 'content.xlsx');
}

/**
 * Novel Server
 * 获取爬虫小说列表
 */
export function getSpiderNovelList(param: any) {
    return postJson('/spider/getNovelList', param);
}

/**
 * 下载小说
 */
export function downloadNovel(param: any, name: any) {
    return postJsonForExport('/spider/downloadNovel', param, `${name}.zip`);
}

/**
 * 下载小说
 */
export function exportBannerList(param: any) {
    return postJsonForExport('/banner/getBannerList/export', param, `Banner.xlsx`);
}

/**
 * 下载小说
 */
export function downloadNovelChapter(param: any, name: any) {
    return postJsonForExport('/spider/downloadNovelChapter', param, `${name}.txt`);
}

/**
 * 小说转存
 */
export function redepositNovel(param: any) {
    return postJson('/spider/redepositNovel', param);
}

/**
 * 章节转存
 */
export function redepositChapter(param: any) {
    return postJson('/spider/redepositChapter', param);
}

/**
 * 根据Id获取简介
 */
export function getBriefById(param: any) {
    return postJson('/spider/getBriefById', param);
}

/**
 * 根据Id获取章节
 */
export function getChapterListById(param: any) {
    return postJson('/spider/getChapterList', param);
}

/**
 * 启动爬虫
 */
export function spiderStart() {
    return postJson('/spider/start');
}

/**
 * 查询爬虫状态
 */
export function checkStarted() {
    return postJson('/spider/checkStarted');
}

/**
 * 根据小说名称获取小说列表
 */
export function getNovelListByName(param: any) {
    return postJson('/novel/getNovelListByName', param);
}

/**
 * 根据小说名称获取小说列表
 */
export function getNovelName(param: any) {
    return postJson('/novel/getNovelName', param);
}

/**
 * 根据小说名称获取小说列表
 */
export function modifyChapterSort(param: any) {
    return postJson('/spider/modifyChapterSort', param);
}

/**
 * 获取章节的url地址
 */
export function accessibleUrl(param: any) {
    return postJson('/content/accessibleUrl', param);
}