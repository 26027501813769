import { all, fork, takeEvery, call, put, take } from 'redux-saga/effects';
import voteKey from '../../components/creation/action/actionsTypeKey/voteActionTypekey';
import * as voteServer from '../../servers/voteServer';
import * as voteAction from '../../components/creation/action/voteAction';
import { openNotification } from '../../components/widgets/Notification';

function* initPage() {
    yield getVoteList();
}

function* getVoteList() {
    try {
        const { result } = yield call(voteServer.getVoteList);
        yield put(voteAction.getVoteList(result));
    } catch (err) {
        openNotification('error', 'Fail to get List');
    }
}

function* changeVoteAmount() {
    while (true) {
        const { payload } = yield take(voteKey.GET_CHANGE_PARAM);
        try {
            yield call(voteServer.changeVoteNumber, payload);
            openNotification('success', 'Success to Edit');
            yield getVoteList();
        } catch (err) {
            openNotification('error', 'Fail to Edit');
        }

    }
}

function* watchInitPage() {
    yield takeEvery(voteKey.INIT_PAGE, initPage);
}

export function* watchVoteSaga() {
    yield all([
        fork(watchInitPage),
        fork(changeVoteAmount),
    ]);
}