import chapterActionsTypeKey from '../actions/actionsTypeKey/chapterManagerActionsTypeKey';
import {  ActionTypes } from '../actions/actionsType/chapterManagerActionsType';

interface Page {
    current: number;
    pageSize: number;
}

export interface StoreState {
    novelMsg: any;
    loading: boolean;
    novelId: any;
    searchData: any;
    chapterList: any[];
    chapterNovelName: any;
    novelList: any;
    page: Page;
    total: number;
    sorter: any;
    btnLoading: boolean;
    uploadModalOpen: boolean;
    paidModal: boolean;
    showPaidBtn: boolean;
    isOpen: string;
    chapterSort: number;
    chapterName: string;
    chapterMinRatio: any;
    chapterMaxRatio: any;
}

const initialState: StoreState = {
    novelMsg: {},
    loading: false,
    novelId: '',
    searchData: {},
    novelList: [],
    chapterList: [],
    chapterNovelName: '',
    page: {
        current: 1,
        pageSize: 20
    },
    total: 1,
    sorter: {},
    btnLoading: false,
    uploadModalOpen: false,
    paidModal: false,
    showPaidBtn: false,
    isOpen: '1',
    chapterSort: 0,
    chapterName: '--',
    chapterMinRatio: 0,
    chapterMaxRatio: 0
};

export const chapterManagerContentReducer = (state = initialState, action: ActionTypes) => {

console.log("mmm11-11",action);

    switch (action.type) {
        case chapterActionsTypeKey.CHAPTET_INIT_PAGE:
            return initialState;

        case chapterActionsTypeKey.CHAPTET_NOVEL_ID:
            console.log("mmmmmm1111", action);
            return Object.assign({}, state, {
                novelId: action.payload,
            });

        case chapterActionsTypeKey.GET_NOVEL_MSG:
            return Object.assign({}, state, {
                novelMsg: action.payload,
            });

        case chapterActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload,
            });

        case chapterActionsTypeKey.GET_CHAPTER_LIST:
            return Object.assign({}, state, {
                chapterList: action.payload.data,
                total: action.payload.total
            });

        case chapterActionsTypeKey.CHAPTEL_TABLE_CHANGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case chapterActionsTypeKey.GET_CHAPTER_SEARCH_PARAM:
            return Object.assign({}, state, {
               searchData: action.payload
            });

        case chapterActionsTypeKey.GET_NOVEL_LIST:
            return Object.assign({}, state, {
                novelList: action.payload
            });

        case chapterActionsTypeKey.UPLOAD_OPEN:
            return Object.assign({}, state, {
                uploadModalOpen: action.payload
            });

        case chapterActionsTypeKey.BTN_LOADING:
            return Object.assign({}, state, {
                btnLoading: action.payload
            });

        case chapterActionsTypeKey.PAID_MODAL:
            return Object.assign({}, state, {
                paidModal: action.payload
            });

        case chapterActionsTypeKey.SHOW_PAID_BUTTON:
            return Object.assign({}, state, {
               showPaidBtn: action.payload
            });

        case chapterActionsTypeKey.IS_SETTING_OPEN:
            return Object.assign({}, state, {
                isOpen: action.payload
            });
        
        case chapterActionsTypeKey.GET_SETTING_SORT:
            return Object.assign({}, state, {
                chapterSort: action.payload
            });

        case chapterActionsTypeKey.GET_SETTING_NAME:
            return Object.assign({}, state, {
                chapterName: action.payload
            });

        case chapterActionsTypeKey.GET_MIN_RATIO:
            return Object.assign({}, state, {
                chapterMinRatio: action.payload
            });

        case chapterActionsTypeKey.GET_MAX_RATIO:
            return Object.assign({}, state, {
                chapterMaxRatio: action.payload
            });

        case chapterActionsTypeKey.SETTING_MODAL_CANCEL:
            return Object.assign({}, state, {
                isOpen: '1',
                chapterSort: 0,
                chapterName: '--',
                chapterMinRatio: 0,
                chapterMaxRatio: 0,
            });

        default:
            return state;
    }
};