enum novelDataActionsTypeKey {
    GET_TYPE_DATA_LIST = 'GET_TYPE_DATA_LIST',
    GET_TAG_DATA_LIST = 'GET_TAG_DATA_LIST',
    NOVEL_DATA_PAGE_INIT = 'NOVEL_DATA_PAGE_INIT_NOVEL',
    SUBMIT_NOVEL_DATA_EXPORT = 'SUBMIT_NOVEL_DATA_EXPORT',
    EXPORT_MODAL_VISIBLE = 'EXPORT_MODAL_NOVEL_DATA_VISIBLE',
    PAGE_LOADING = 'NOVEL_DATA_LIST_PAGE_LOADING',
    GET_NOVEL_DATA_LIST = 'GET_NOVEL_DATA_LIST',
    NOVEL_DATA_LIST_DATA_SEARCH = 'NOVEL_DATA_LIST_DATA_SEARCH',
    UPDATE_NOVEL_DATA_TABLE_PAGE_SIZE = 'UPDATE_NOVEL_DATA_TABLE_PAGE_SIZE',
}

export default novelDataActionsTypeKey;