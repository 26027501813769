import typeDetailActionsTypeKey from '../action/actionsTypeKey/typeDetailActionsTypeKey';
import {  ActionTypes } from '../action/actionsType/typeDetailActionsType';

export interface DetailState {
    tagId: any;
    typeList: any[];
    novelList: any[];
    loading: boolean;

}

const initialState: DetailState = {
    tagId: '',
    typeList: [],
    novelList: [],
    loading: false
};

export const typeDetailReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {

        case typeDetailActionsTypeKey.INIT_PAGE:
            return initialState;

        case typeDetailActionsTypeKey.GET_TAG_ID:
            return Object.assign({}, state, {
                tagId: action.payload
            });

        case typeDetailActionsTypeKey.GET_TYPE_LIST:
            return Object.assign({}, state, {
                typeList: action.payload
            });

        case typeDetailActionsTypeKey.GET_NOVEL_LIST:
            return Object.assign({}, state, {
                novelList: action.payload
            });

        case typeDetailActionsTypeKey.TYPE_LIST_LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        default:
            return state;
    }
};