import voteKey from './actionsTypeKey/voteActionTypekey';

export function initPage() {
    return { type: voteKey.INIT_PAGE };
}

export function getVoteList(list: any) {
    return { type: voteKey.GET_VOTE_LIST, payload: list };
}

export function getChangeParam(param: any) {
    return { type: voteKey.GET_CHANGE_PARAM, payload: param };
}