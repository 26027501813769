import currencyOrderKey from '../actions/actionsTypeKey/currencyOrderActionsTypeKey';
import {  ActionType } from '../actions/actionsType/currencyOrderActionsType';

interface PageData {
    current: number;
    pageSize: number;
}

interface ExportData {
    titles: any;
}

export interface CurrencyOrderState {
    page: PageData;
    sorter: any;
    loading: boolean;
    total: number;
    export: ExportData;
    searchData: any;
    versionModalVisible: boolean;
    exportModalVisible: boolean;
    currencyOrderList: any[];
    currencyTotalData: any;
    rechargeList: any[];
}

const initialState: CurrencyOrderState = {
    loading: false,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    total: 0,
    export: {
        titles: []
    },
    searchData: {},
    versionModalVisible: false,
    exportModalVisible: false,
    currencyTotalData: {},
    currencyOrderList: [],
    rechargeList: []
};

export const currencyOrderDataReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case currencyOrderKey.INIT_PAGE:
            return initialState;

        case currencyOrderKey.LOADING:
            return Object.assign({}, state, {
               loading: action.payload
            });

        case currencyOrderKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload
            });

        case currencyOrderKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case currencyOrderKey.GET_TOTAL_CURRENCY_ORDER:
            return Object.assign({}, state, {
                currencyTotalData: action.payload
            });

        case currencyOrderKey.GET_CURRENCY_ORDER_LIST:
            return Object.assign({}, state, {
                currencyOrderList: action.payload.data,
                total: action.payload.total
            });

        case currencyOrderKey.SEARCH_DATA:
            return Object.assign({}, state, {
                searchData: action.payload
            });
            
        case currencyOrderKey.GET_CURRENCY_RECHARGE_LIST:
            return Object.assign({}, state, {
                rechargeList: action.payload
            });

        default:
            return state;
    }
};