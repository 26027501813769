import withdrawalKey from '../actions/actionsTypeKey/withdrawalTypeKey';

// public action

export function initPage() {
    return { type: withdrawalKey.INIT_PAGE };
}

export function loading(value: boolean) {
    return { type: withdrawalKey.LOADING, payload: value };
}

export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: withdrawalKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: withdrawalKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: withdrawalKey.SUBMIT_EXPORT, payload: value };
}

export function getSearchData(param: any) {
    return { type: withdrawalKey.GET_SEARCH_DATA, payload: param };
}

// page action
export function getTabsKey(key: string) {
    return { type: withdrawalKey.GET_TABS_KEY, payload: key };
}

export function getWithdrawalTotal(param: any) {
    return { type: withdrawalKey.GET_WITHDRAWAL_TOTAL, payload: param };
}

export function getWithdrawalList(param: any) {
    return { type: withdrawalKey.GET_WITHDRAWAL_LIST, payload: param };
}

export function getWithdrawalId(id: string) {
    return { type: withdrawalKey.GET_WITHDRAWAL_DETAIL_ID, payload: id };
}

export function getWithdrawalDetail(msg: any) {
    return { type: withdrawalKey.GET_WITHDRAWAL_DETAIL, payload: msg };
}

export function getWithdrawalDetailChangeStatus(param: any) {
    return { type: withdrawalKey.GET_WITHDRAWAL_CHANGE_STATUS, payload: param };
}