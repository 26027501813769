import ChapterDetail  from '../ChapterDetail';
import { connect } from 'react-redux';
import * as chapterDetailAction from '../../actions/chapterDetailAction';

const mapStateToProps = (state: any) => ({
    data: state.content.chapterDetail
});

const mapDispatchToProps = {
    initPage: chapterDetailAction.initPage,
    getEditContent: chapterDetailAction.getEditContent,
    changeShowContent: chapterDetailAction.changeShowContent
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ChapterDetail);