import * as React from 'react';
import { Modal, Form, Input } from 'antd';

const FormItem = Form.Item;

interface ExportModalProps {
    getFieldDecorator: any;
    getFieldsValue: any;
    visible: boolean;
    loading: boolean;
    onCancel: () => void;
    submitExport: (value: any) => void;
}

export class ExportModal extends React.Component<ExportModalProps, {}> {

    submit = () => {
        const value = this.props.getFieldsValue(['page', 'pageSize']);
        this.props.submitExport({
            page: value.page,
            pageSize: value.pageSize
        });
    }

    render() {
        const formItemLayout = {
            style: {width: '60%', marginLeft: '20px'}
        };
        const {getFieldDecorator} = this.props;
        return (
            <Modal
                title="Export Data"
                visible={this.props.visible}
                confirmLoading={this.props.loading}
                onCancel={() => this.props.onCancel()}
                maskClosable={false}
                onOk={this.submit}
            >
                <Form>
                    <FormItem label="Page" {...formItemLayout}>
                        {
                            getFieldDecorator('page', {
                                initialValue: 1,
                                rules: [{required: true, message: 'Please input page!'}],
                            })
                            (
                                <Input placeholder="input page...." />
                            )
                        }
                    </FormItem>
                    < FormItem label="PageSize" {...formItemLayout}>
                        {
                            getFieldDecorator('pageSize', {
                                initialValue: 50,
                                rules: [{required: true, message: 'Please input pageSize!'}],
                            })
                            (
                                <Input placeholder="input pageSize...." />
                            )
                        }
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}
