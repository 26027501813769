import { fork, all, take, call, put, select, takeEvery } from 'redux-saga/effects';
import recommendDetailActionsTypeKey from '../../components/creation/action/actionsTypeKey/recommendDetailActionsTypeKey';
import * as recommendServer from '../../servers/recommendServer';
import * as recommendDetailAction from '../../components/creation/action/recommendDetailAction';
import { openNotification } from '../../components/widgets/Notification';

function* initPage() {
    while (true) {
        yield take(recommendDetailActionsTypeKey.INIT_PAGE);
        try {
            yield put(recommendDetailAction.isLoading(true));
            const { result } = yield call(recommendServer.getFloorList);
            yield put(recommendDetailAction.getFloorList(result));
            yield getLimitFreeSetting();
            yield getFloorNovel();
            yield getTypeList();
        } catch (e) {
            openNotification('error', 'get floor list Fail');
        }
    }
}

function* getTypeList() {
    try {
        const { result } = yield call(recommendServer.getNovelTypeList);
        yield put(recommendDetailAction.getNovelTypeList(result));
    } catch (err) {
        openNotification('error', 'Failed to get type list.');
    }
}

function* getFloorNovel() {
    const store = yield select();
    const reducer = store.creation.recommendDetail;
    const param = {
        ...reducer.page,
        ...reducer.sorter,
        data: {floorId: reducer.floorId}
    };
    try {
        yield put(recommendDetailAction.isLoading(true));
        const { result } = Number(param.data.floorId) < 100 ?
            yield call(recommendServer.getFloorNovelList, param)
            :
            yield call(recommendServer.getRecommendList, param);
        yield put(recommendDetailAction.getFloorNovelList(result));
        yield put(recommendDetailAction.isLoading(false));
    } catch (e) {
        openNotification('error', 'get floor novel list Fail');
    }
}

function* getLimitFreeSetting() {
    try {
        yield put(recommendDetailAction.isModifyLoading(true));
        const store = yield select();
        const floorId = store.creation.recommendDetail.floorId;
        const { result } = yield call(recommendServer.getLimitFreeSetting, floorId);
        yield put(recommendDetailAction.updateLimitFreeSetting(result));
        yield put(recommendDetailAction.isModifyLoading(false));
    } catch (e) {
        openNotification('error', 'get floor free setting Fail');
    }
}

function* submitLimitFreeSetting() {
    while (true) {
        const {payload} = yield take (recommendDetailActionsTypeKey.SUBMIT_LIMIT_FREE_SETTING);
        try {
            yield put(recommendDetailAction.isModifyLoading(true));
            yield call(recommendServer.modifyLimitFreeSetting, payload);
            yield put(recommendDetailAction.setLimitFreeModalShow(false));
            yield getLimitFreeSetting();
        } catch (e) {
            openNotification('error', 'submit floor free setting Fail');
        }
    }
}

function* changeFloorToGetNovelList() {
    while (true) {
        yield take(recommendDetailActionsTypeKey.CHANGE_FLOOR_ID);
        try {
            yield put(recommendDetailAction.isLoading(true));
            yield getFloorNovel();
            yield getLimitFreeSetting();
        } catch (e) {
            openNotification('error', 'get floor novel list Fail');
        }
    }
}

function* watchDeleteFloorNovel() {
    while (true) {
        const { payload } = yield take(recommendDetailActionsTypeKey.GET_DEL_FLOOR_NOVEL_MSG);
        try {
            yield put(recommendDetailAction.isLoading(true));
            yield call(recommendServer.deleteRecommendBatch, [payload.recordId]);
            // 为了不在reducer里面多加参数，直接先存id到reducer然后再请求楼层
            yield put(recommendDetailAction.getFloorIdToRequest(payload.floorId));
            yield getFloorNovel();
            yield put(recommendDetailAction.isLoading(false));
            openNotification('success', 'Delete success.');
        } catch (err) {
            if (err.code === 231) {
                openNotification('error', 'Please take off the floor and delete the novels.');
            } else {
                openNotification('error', 'Delete failed.');
            }
            yield put(recommendDetailAction.isLoading(false));
        }
    }
}

function* watchSearchNovelList() {
    while (true) {
        const { payload } = yield take(recommendDetailActionsTypeKey.GET_SEARCH_NOVEL_PARAM);
        payload.typeId = payload.typeId === 'All' ? '' : payload.typeId;
        try {
            yield put(recommendDetailAction.isLoading(true));
            const { result } = yield call(recommendServer.getNovelListByType, payload);
            yield put(recommendDetailAction.getSearchNovelList(result));
            yield put(recommendDetailAction.isLoading(false));
        } catch (err) {
            openNotification('error', 'Failed to get novel list');
        }
    }
}

function* addRecommendNovel() {
    while (true) {
        const { payload } = yield take(recommendDetailActionsTypeKey.ADD_RECOMMEND_NOVEL);
        try {
            yield put(recommendDetailAction.isLoading(true));
            yield call(recommendServer.addRecommend, payload);
            yield put(recommendDetailAction.openAddEditFloorNovelModal(false));

            // 为了不在reducer里面多加参数，直接先存id到reducer然后再请求楼层
            yield put(recommendDetailAction.getFloorIdToRequest(payload.floorId));
            yield getFloorNovel();
            yield put(recommendDetailAction.isLoading(false));
            openNotification('success', 'Add success');
        } catch (err) {
            if (err.code === 201) {
                openNotification('error', 'The recommend no more than 30');
            } else if (err.code === 203) {
                openNotification('error', 'The recommend novel is exist');
            } else if (err.code === 204) {
                openNotification('error', 'The novel does not exist');
            } else {
                openNotification('error', 'Add failed');
            }
            yield put(recommendDetailAction.isLoading(false));
        }
    }
}

function* changeRecommendNovel() {
    while (true) {
        const { payload } = yield take(recommendDetailActionsTypeKey.EDIT_RECOMMEND_NOVEL);
        try {
            yield put(recommendDetailAction.isLoading(true));

            payload.id = Number(payload.floor) < 100 ? '' : payload.id;

            Number(payload.floorId) < 100
                ?
                yield call(recommendServer.modifyFloorNovel, payload)
                :
                yield call(recommendServer.changeRecommend, payload);

            yield put(recommendDetailAction.openAddEditFloorNovelModal(false));
            // 为了不在reducer里面多加参数，直接先存id到reducer然后再请求楼层
            yield put(recommendDetailAction.getFloorIdToRequest(payload.floorId));
            yield getFloorNovel();
            yield put(recommendDetailAction.isLoading(false));
            openNotification('success', 'Change success');
        } catch (err) {
            if (err.code === 201) {
                openNotification('error', 'The recommend no more than 30');
            } else if (err.code === 203) {
                openNotification('error', 'The recommend novel is exist');
            } else if (err.code === 204) {
                openNotification('error', 'The novel does not exist');
            } else {
                openNotification('error', 'Change failed');
            }
            yield put(recommendDetailAction.isLoading(false));
        }
    }
}

function* changeNovelLocation() {
    while (true) {
        const { payload } = yield take(recommendDetailActionsTypeKey.GET_CHANG_NOVEL_LOCATOIN_MSG);
        try {
            yield put(recommendDetailAction.isLoading(true));
            yield call(recommendServer.modifyRecommendLocation, payload);

            // 为了不在reducer里面多加参数，直接先存id到reducer然后再请求楼层
            yield put(recommendDetailAction.getFloorIdToRequest(payload.floorId));
            yield getFloorNovel();
            yield put(recommendDetailAction.isLoading(false));
            openNotification('success', 'Sort success.');
        } catch (err) {
            openNotification('error', 'Failed to sort.');
            yield put(recommendDetailAction.isLoading(false));
        }

    }
}

function* watchUpdatePageSize() {
    yield takeEvery(recommendDetailActionsTypeKey.UPDATE_PAGE, getFloorNovel);
}

export function* watchRecommendDetailSaga() {
    yield all([
        fork(initPage),
        fork(changeFloorToGetNovelList),
        fork(watchDeleteFloorNovel),
        fork(watchSearchNovelList),
        fork(addRecommendNovel),
        fork(changeRecommendNovel),
        fork(watchUpdatePageSize),
        fork(changeNovelLocation),
        fork(submitLimitFreeSetting),
    ]);
}