import AddWebActionsTypeKey from '../actions/actionsTypeKey/addWebManagerActionsTypeKey';
import {  ActionTypes } from '../actions/actionsType/addWebActionsType';

export interface StoreState {
    loading: boolean;
}

const initialState: StoreState = {
    loading: false,
};

export const addWebManagerReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case AddWebActionsTypeKey.INIT_PAGE:
            return initialState;

        case AddWebActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        default:
            return state;
    }
};