enum RecommendLocationDetailActionsTypeKey {
    INIT_PAGE = 'RECOMMEND_LOCATION_DETAIL_INIT_PAGE',
    GET_FLOOR_ID_TO_REQUEST = 'RECOMMEND_LOCATION_DETAIL_GET_FLOOR_ID_TO_REQUEST',
    GET_FLOOR_LIST = 'RECOMMEND_LOCATION_DETAIL_GET_FLOOR_LIST',
    UPDATE_PAGE = 'RECOMMEND_LOCATION_DETAIL_UPDATE_PAGE',
    LOADING = 'RECOMMEND_LOCATION_DETAIL_LOADING',
    GET_FLOOR_NOVEL_LIST = 'RECOMMEND_LOCATION_DETAIL_GET_FLOOR_NOVEL_LIST',
    CHANGE_FLOOR_ID = 'RECOMMEND_LOCATION_DETAIL_CHANGE_FLOOR_ID',
    GET_DEL_FLOOR_NOVEL_MSG = 'RECOMMEND_LOCATION_DETAIL_GET_DEL_FLOOR_NOVEL_MSG',
    ADD_EDIT_FLOOR_NOVEL_MODAL = 'RECOMMEND_LOCATION_DETAIL_ADD_EDIT_FLOOR_NOVEL_MODAL',
    GET_NOVEL_TYPE_LIST = 'RECOMMEND_LOCATION_DETAIL_GET_NOVEL_TYPE_LIST',
    GET_SEARCH_NOVEL_PARAM = 'RECOMMEND_LOCATION_DETAIL_GET_SEARCH_NOVEL_PARAM',
    GET_SEARCH_NOVEL_LIST = 'RECOMMEND_LOCATION_DETAIL_GET_SEARCH_NOVEL_LIST',
    ADD_RECOMMEND_NOVEL = 'RECOMMEND_LOCATION_DETAIL_ADD_RECOMMEND_NOVEL',
    EDIT_RECOMMEND_NOVEL = 'RECOMMEND_LOCATION_DETAIL_EDIT_RECOMMEND_NOVEL',
    GET_CHANG_NOVEL_LOCATOIN_MSG = 'RECOMMEND_LOCATION_DETAIL_GET_CHANG_NOVEL_LOCATOIN_MSG'
}

export default RecommendLocationDetailActionsTypeKey;