import { fork, all, take, call, put, takeEvery } from 'redux-saga/effects';
import typeDetailKey from '../../components/creation/action/actionsTypeKey/typeDetailActionsTypeKey';
import * as typeDetailAction from '../../components/creation/action/typeDetailAction';
import * as tagServer from '../../servers/typeServer';
import { openNotification } from '../../components/widgets/Notification';

function* initPage() {

    const { payload } = yield take(typeDetailKey.GET_TAG_ID);
    yield getTagList(payload);
}

function* getTagList(id: any) {
    try {
        const { result } = yield call(tagServer.getTypeListByTag, id);
        yield put(typeDetailAction.getTypeList(result));
    } catch (err) {
        openNotification('error', 'Fail to get category list');
    }

}

function* getNovelList() {
    while (true) {
        const { payload } = yield take(typeDetailKey.GET_TYPE_ID);
        try {
            yield put(typeDetailAction.loading(true));
            const { result } =  yield call(tagServer.getNovelListByType, payload);
            yield put(typeDetailAction.getNovelList(result));
            yield put(typeDetailAction.loading(false));
        } catch (err) {
            openNotification('error', 'Fail to get novel list');
        }
    }
}

function* delTags() {
    while (true) {
        const { payload } = yield take(typeDetailKey.GET_DEL_TAG_IDS);
        try {
            yield call(tagServer.betchRemoveType, payload);
            openNotification('success', 'success Del');
            yield getTagList(payload.tagId);
        } catch (err) {
            openNotification('error', 'Fail to del tag');
        }
    }
}

function* watchInitPage() {
    yield takeEvery(typeDetailKey.INIT_PAGE, initPage );
}

export function* watchTypeDetailSaga() {
    yield all([
        fork(watchInitPage),
        fork(getNovelList),
        fork(delTags),
    ]);
}
