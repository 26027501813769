import VersionContentActionTypeKeys from '../actions/actionsTypeKey/actionTypeKey';
import {  ActionTypes } from '../actions/actionsType/actionTypes';
import { versionList } from '../../../servers/responseTypes';

interface PageData {
    current: number;
    pageSize: number;
}

interface ExportData {
    titles: any;
}

export interface StoreState {
    page: PageData;
    sorter: any;
    getVersionList: versionList[];
    loading: boolean;
    total: number;
    export: ExportData;
    searchData: any;
    versionModalVisible: boolean;
    exportModalVisible: boolean;
}

const initialState: StoreState = {
    loading: false,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    total: 0,
    export: {
        titles: []
    },
    getVersionList: [],
    searchData: {},
    versionModalVisible: false,
    exportModalVisible: false,
};

export const versionContentReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case VersionContentActionTypeKeys.VERSION_CONTNET_INIT_PAGE:
            return initialState;

        case VersionContentActionTypeKeys.GET_VERSION_LIST:
            return Object.assign({}, state, {
                getVersionList: action.payload.data,
                total: action.payload.total
            });

        case VersionContentActionTypeKeys.VERSION_LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload,
            });

        case VersionContentActionTypeKeys.LOADING:
            return Object.assign({}, state, {
                loading: action.payload,
            });

        case VersionContentActionTypeKeys.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize,
                },
                sorter: {
                    sortField: 'created_at',
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case VersionContentActionTypeKeys.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload
            });

        case VersionContentActionTypeKeys.VERSION_MODAL_VISIBLE:
            return Object.assign({}, state, {
                versionModalVisible: action.payload
            });
        default:
            return state;
    }
};