import RecommendLocationManager from '../RecommendLocationManager';
import { connect } from 'react-redux';
import * as recommendLocationAction from '../../action/recommendLocationAction';

const mapStateToProps = (state: any) => ({
    data: state.creation.recommendLocation
});

const mapDispatchToProps = {
    initPage: recommendLocationAction.initPage,
    openCloseFloorLocationModal: recommendLocationAction.openCloseFloorLocationModal,
    updateFloorPage: recommendLocationAction.updateFloorPage,
    openCloseFloorModal: recommendLocationAction.openCloseFloorModal,
    delFloor: recommendLocationAction.delFloor,
    addFloorDataList: recommendLocationAction.addFloorDataList,
    editFloorDataList: recommendLocationAction.editFloorDataList
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RecommendLocationManager);