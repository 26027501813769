import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import {
    Form,
    Row,
    Col,
    Spin,
    Button,
    Popconfirm,
    Divider,
    Table,
    Upload,
    Modal,
    Input,
    message,
    Badge,
    Select,
    InputNumber
} from 'antd';
import { Redirect } from 'react-router-dom';
import { uploadFile } from '../../../servers/ossServer';
import { uploadChapter } from '../../../servers/chapterServer';
import { isInt } from '../../../base/regular';
import { getParameter } from '../../../base/getUrlParam';
import { changeStampToDate } from '../../../base/dealWithTime';
import { numFormat } from '../../../base/regular';

import { StoreState } from '../reducers/chapterManagerReducer';
import '../../../style/novel.css';
import { openNotification } from '../../widgets/Notification';

const FormItem = Form.Item;
const Option = Select.Option;

interface ChapterContentProps {
    data: StoreState;
    match: any;
    getNovelId: (param: any) => void;
    uploadOpen: (value: boolean) => void;
    getChapterTableChange: (pagination: any, filters: any, sorter: any) => void;
    getChapterSearchParam: (param: any) => void;
    getDelChapterId: (ids: any[]) => void;
    getChapterList: (param: any) => void;
    chapterSortChange: (param: any) => void;
    btnLoading: (value: boolean) => void;
    getChapterId: (record: any) => void;
    goNovelList: (bool: boolean) => void;
    goNovelDetail: (bool: boolean) => void;
    updateChapterList: () => void;
    changeNovelReviewStatus: (param: any) => void;
    downloadCover: (param: any) => void;
    paidModal: (bol: boolean) => void;
    getIgnoreBtn: (id: string) => void;
    getPaidSetting: (param: any) => void;
    downloadNovel: () => void;
    isSettingOpen: (param: string) => void;
    getSettingSort: (param: number) => void;
    getSettingName: (param: string) => void;
    getPaidChapterSettingMsg: (param: any) => void;
    getMinRatio: (param: number) => void;
    getMaxRatio: (param: number) => void;
    SettingModalCancel: () => void;
    getSettingMsg: (id: number | string) => void;
}

interface ChapterContentState {
    checkAll: boolean;
    sortDisabled: boolean;
    autoChange: boolean;
    checkedList: any;
    selectedRowKeys: any[];
    delDisabled: boolean;
    sorterModal: boolean;
    fileList: any[];
    chapterId: number;
    locationId: number;
    toEdit: boolean;
    tabPansKey: string;
    toDetail: boolean;
    detailId: string;
    novelId: string;
    dismissedModal: boolean;
    dismissedId: number;
    rejectionText: string;
    btnPermission: any[];
    novelType: any[];
    paidStatus: string;
    firstCommit: string | number;
}

class ChapterManager extends React.Component<ChapterContentProps & FormComponentProps, ChapterContentState> {

    state: ChapterContentState = {
        checkAll: false,
        sortDisabled: true,
        autoChange: true,
        checkedList: [],
        selectedRowKeys: [],
        delDisabled: true,
        sorterModal: false,
        chapterId: 0,
        locationId: 0,
        fileList: [],
        toEdit: false,
        toDetail: false,
        detailId: '',
        tabPansKey: '',
        novelId: '',
        dismissedModal: false,
        dismissedId: -1,
        rejectionText: '',
        btnPermission: [],
        novelType: ['Original', 'Reprint', 'PGC', 'Star Novel', 'Translate'],
        paidStatus: '0',
        firstCommit: 0
    };

    componentWillMount() {
        let key = getParameter('key');
        this.setState({tabPansKey: key});
        // 获取功能权限
        const value = window.sessionStorage.getItem('btnPermission');
        let sessionBtnPermission: any =  value == null ? [] : JSON.parse(value);
        this.setState({btnPermission: sessionBtnPermission});
    }

    componentDidMount() {
        let id = getParameter('id'), key = getParameter('key'), status = getParameter('status');
        this.setState({novelId: id, paidStatus: status});
        let param = {
            id: id,
            key: key
        };
        this.props.getNovelId(param);
    }

    delChapter = () => {
        this.props.getDelChapterId(this.state.selectedRowKeys);
        this.setState({selectedRowKeys: []});
    }

    uploadModal = () => {
        this.props.uploadOpen(true);
        this.setState({fileList: []});
        // this.setState({fileList: []});
    }

    async uploadModalOk () {
        let that = this;
        const { fileList } = this.state;
        that.props.btnLoading(true);
        for (let i = 0; i < fileList.length; i++) {
            if (fileList.length > 0 ) {
                fileList[i].status = 'uploading';
                that.setState({fileList});
                let ossKey = await uploadFile('novel', fileList[i].originFileObj);
                let json: any = [{
                    'novelId': this.props.data.novelMsg.id,
                    'chapterName': fileList[i].name.replace(/(.*\/)*([^.]+).*/ig, '$2'),
                    'contentUrl': ossKey,
                }];
                await uploadChapter(json).then(res => {
                    message.success(`${fileList[i].name} Upload Success!`);
                    fileList[i].status = 'done';
                    that.setState({fileList});
                }).catch(error => {
                    message.error(error.message);
                    fileList[i].status = 'error';
                    that.setState({fileList});
                });
            }
        }
        that.props.btnLoading(false);
    }

    uploadModalCancel = () => {
        this.props.uploadOpen(false);
        this.props.updateChapterList();
    }

    uploadChange = ({fileList}: any) => {
        fileList.map((o: any, i: any) => {
            if (o.status === 'error') {
                o.status = 'undefined';
            }
        });
        this.setState({ fileList });
    }

    onSelectChange = (selectedRowKeys: any) => {
        if (selectedRowKeys.length === 0) {
            this.setState({ delDisabled: true });
        } else {
            this.setState({ delDisabled: false });
        }
        this.setState({ selectedRowKeys });
    }

    sorterModalOpen = (record: any) => {
        this.setState({chapterId: record.id, locationId: record.sort, sorterModal: true});
    }

    sorterModalOk = () => {
        let sort = this.props.form.getFieldValue('sorterId');
        let param = {
            chapterId: this.state.chapterId,
            location: Number(sort)
        };
        this.props.chapterSortChange(param);
        this.sorterModalCancel();
    }

    sorterModalCancel = () => {
        this.setState({ sorterModal: false });
    }

    toEdit = () => {
        this.props.goNovelDetail(false);
        this.props.goNovelList(false);
        this.setState({toEdit: true});
    }

    toDetail = (record: any) => {
        this.toPreview(record);
        window.sessionStorage.setItem('chapterMsg', JSON.stringify(record));
        this.setState({toDetail: true, detailId: record.id, firstCommit: record.firstCommit});
    }

    toPreview = (record: any) => {
        this.props.getChapterId(record);
    }

    // 审核所用函数
    release = (record: any) => {
        this.setState({dismissedId: record.id}, () => {
            this.changeStatus('4');
        });

    }

    dismissedModalShow = (record: any) => {
        this.setState({rejectionText: '', dismissedModal: true, dismissedId: record.id});
    }

    dismissedModalOk = () => {
        if (this.state.rejectionText === '') {
            openNotification('error', 'Please enter the reason for the rejection');
        } else {
            this.changeStatus('5');
            this.dismissedModalCancel();
        }
    }

    dismissedModalCancel = () => {
        this.setState({dismissedModal: false});
    }

    rejectionText = (e: any) => {
        if (e.target.value.length > 200) {
            openNotification('error', 'Reason for rejection cannot exceed 200 characters');
        } else {
            this.setState({rejectionText: e.target.value});
        }
    }

    changeStatus = (status: string) => {
        let { dismissedId, rejectionText } = this.state;
        let param = {
            id: dismissedId,
            status: status,
            message: rejectionText
        };
        this.props.changeNovelReviewStatus(param);
    }

    downloadCover = () => {
        const { data } = this.props;
        window.open(window.location.origin + '/api/novel/downloadCover?key=' + data.novelMsg.ossKey);
    }

    columns = () => {
        let  { btnPermission } = this.state;
        return [
            {
                title: 'Location',
                dataIndex: 'sort',
                key: 'sort',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            {(record.locked === 1 && record.charge === 1) && <img style={{width: '15px', marginRight: '5px'}} src="http://novelme-img.novelme.id/novelme/author/pic/ban_icon.jpg" alt=""/>}
                            {(record.locked === 1 && record.charge === 0) && <img style={{width: '15px', marginRight: '5px'}} src="http://novelme-img.novelme.id/novelme/author/pic/ban_icon.jpg" alt=""/>}
                            {(record.locked === 0 && record.charge === 1) && <img style={{width: '15px', marginRight: '5px'}} src="https://webcdn.novelme.com/novelme/author/pic/paid_icon_manager.png" alt=""/>}

                            {
                                btnPermission.indexOf('novel-manager-extra') >= 0 ?
                                    <span style={{color: '#1890ff', cursor: 'pointer'}} onClick={(e: any) => {this.sorterModalOpen(record); }}>{text}</span>
                                    :
                                    <span style={{color: '#1890ff', cursor: 'pointer'}} >{text}</span>
                            }

                        </div>
                    );

                }
            },
            {
                title: 'Chapter Name',
                dataIndex: 'name',
                key: 'name',
                className: 'column-center',
                render: (text: any, record: any) => {
                    // console.log(name);
                    return <Badge dot={record.updatedStatus === 1}><a  style={{color: '#1890ff'}}  href="javascript:void(0)" onClick={(e: any) => {this.toDetail(record); }}>{text}</a></Badge>;
                }
            },
            {
                title: 'Chapter Words',
                dataIndex: 'wordCount',
                key: 'wordCount',
                className: 'column-center',
                render: (text: any) => (<span>{numFormat(text) || '--'}</span>)
            },
            {
                title: 'Chapter ID',
                dataIndex: 'id',
                key: 'id',
                className: 'column-center'
            },
            {
                title: 'Update Date',
                dataIndex: 'updatedAt',
                key: 'updatedDate',
                sorter: true,
                sortField: 'updatedAt',
                className: 'column-center',
                render: (text: number) => {
                    return <span>{changeStampToDate(text, 'YYYY-MM-DD')}</span>;
                }
            },
            {
                title: 'Update Time',
                dataIndex: 'updatedAt',
                key: 'updatedTime',
                className: 'column-center',
                render: (text: number) => {
                    return <span>{changeStampToDate(text, 'HH:mm:ss')}</span>;
                }
            }
        ];
    }

    reviewColumns = () => {
        return [
            {
                title: 'Serial number',
                dataIndex: 'sort',
                key: 'sort',
                className: 'column-center',
                render: (text: any, record: any, index: any) => {
                    return <span>{index + 1}</span>;
                }
            },
            {
                title: 'Chapter Name',
                dataIndex: 'chapterName',
                key: 'chapterName',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            {(record.locked === 1 && record.charge === 1) && <img style={{width: '15px', marginRight: '5px'}} src="http://novelme-img.novelme.id/novelme/author/pic/ban_icon.jpg" alt=""/>}
                            {(record.locked === 1 && record.charge === 0) && <img style={{width: '15px', marginRight: '5px'}} src="http://novelme-img.novelme.id/novelme/author/pic/ban_icon.jpg" alt=""/>}
                            {(record.locked === 0 && record.charge === 1) && <img style={{width: '15px', marginRight: '5px'}} src="https://webcdn.novelme.com/novelme/author/pic/paid_icon_manager.png" alt=""/>}

                            <a href="javascript:void(0)" onClick={(e: any) => {this.toDetail(record); }}>{text}</a>
                        </div>
                    );
                }
            },
            {
                title: 'Submit Status',
                dataIndex: 'firstCommit',
                key: 'firstCommit',
                className: 'column-center',
                render: (text: number | string, record: any) => (text !== 0 || record.locked === 1 ? 're-edit' : 'First Submission')
            },
            {
                title: 'Update Date',
                dataIndex: 'updatedDate',
                key: 'updatedDate',
                sorter: true,
                sortField: 'updatedAt',
                className: 'column-center',
            },
            {
                title: 'Update Time',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                className: 'column-center',
            },
            {
                title: 'Reason for rejection',
                dataIndex: 'failMessage',
                key: 'failMessage',
                className: 'column-center',
            },
            {
                title: 'Setting',
                dataIndex: 'setting',
                key: 'setting',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <p>
                            <Popconfirm
                                title="Confirm release article?"
                                onConfirm={(e: any) => {this.release(record); }}
                                okText="Confirm"
                                cancelText="Cancel"
                            >
                                <a href="javascript:void(0)" style={{marginRight: '15px'}}>Release</a>
                            </Popconfirm>

                            <Popconfirm
                                title="Confirm dismissed article?"
                                onConfirm={(e: any) => {this.dismissedModalShow(record); }}
                                okText="Confirm"
                                cancelText="Cancel"
                            >
                                <a href="javascript:void(0)" style={{color: 'red'}}>Dismissed</a>
                            </Popconfirm>
                    </p>
                    );
                }
            }
        ];
    }

    passedColumns = () => {
        return [
            {
                title: 'Serial number',
                dataIndex: 'sort',
                key: 'sort',
                className: 'column-center',
                render: (text: any, record: any, index: any) => {
                    return <span>{index + 1}</span>;
                }
            },
            {
                title: 'Chapter Name',
                dataIndex: 'chapterName',
                key: 'chapterName',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            {(record.locked === 1 && record.charge === 1) && <img style={{width: '15px', marginRight: '5px'}} src="http://novelme-img.novelme.id/novelme/author/pic/ban_icon.jpg" alt=""/>}
                            {(record.locked === 1 && record.charge === 0) && <img style={{width: '15px', marginRight: '5px'}} src="http://novelme-img.novelme.id/novelme/author/pic/ban_icon.jpg" alt=""/>}
                            {(record.locked === 0 && record.charge === 1) && <img style={{width: '15px', marginRight: '5px'}} src="https://webcdn.novelme.com/novelme/author/pic/paid_icon_manager.png" alt=""/>}

                            <a href="javascript:void(0)" onClick={(e: any) => {this.toDetail(record); }}>{text}</a>
                        </div>
                    );
                }
            },
            {
                title: 'Update Date',
                dataIndex: 'updatedDate',
                key: 'updatedDate',
                sorter: true,
                sortField: 'updatedAt',
                className: 'column-center',
            },
            {
                title: 'Update Time',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                className: 'column-center',
            },
            {
                title: 'Reviewer',
                dataIndex: 'operatorName',
                key: 'operatorName',
                className: 'column-center',
                render: (text: any, record: any) => {
                    if (record.status === 3) {
                        return <span>system</span>;
                    } else {
                        return <span>{text}</span>;
                    }
                }
            },
            {
                title: 'Review Date',
                dataIndex: 'operatedDate',
                key: 'operatedDate',
                sorter: true,
                sortField: 'operatorAt',
                className: 'column-center',
            },
            {
                title: 'Review Time',
                dataIndex: 'operatedTime',
                key: 'operatedTime',
                className: 'column-center',
            },
        ];
    }

    failureColumns = () => {
        return [
            {
                title: 'Serial number',
                dataIndex: 'sort',
                key: 'sort',
                className: 'column-center',
                render: (text: any, record: any, index: any) => {
                    return <span>{index + 1}</span>;
                }
            },
            {
                title: 'Chapter Name',
                dataIndex: 'chapterName',
                key: 'chapterName',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            {(record.locked === 1 && record.charge === 1) && <img style={{width: '15px', marginRight: '5px'}} src="http://novelme-img.novelme.id/novelme/author/pic/ban_icon.jpg" alt=""/>}
                            {(record.locked === 1 && record.charge === 0) && <img style={{width: '15px', marginRight: '5px'}} src="http://novelme-img.novelme.id/novelme/author/pic/ban_icon.jpg" alt=""/>}
                            {(record.locked === 0 && record.charge === 1) && <img style={{width: '15px', marginRight: '5px'}} src="https://webcdn.novelme.com/novelme/author/pic/paid_icon_manager.png" alt=""/>}

                            <a href="javascript:void(0)" onClick={(e: any) => {this.toDetail(record); }}>{text}</a>
                        </div>
                    );
                }
            },
            {
                title: 'Update Date',
                dataIndex: 'updatedDate',
                key: 'updatedDate',
                sortField: 'updatedAt',
                sorter: true,
                className: 'column-center',
            },
            {
                title: 'Update Time',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                className: 'column-center',
            },
            {
                title: 'Reviewer',
                dataIndex: 'operatorName',
                key: 'operatorName',
                className: 'column-center',
            },
            {
                title: 'Review Date',
                dataIndex: 'operatedDate',
                key: 'operatedDate',
                sorter: true,
                sortField: 'operatorAt',
                className: 'column-center',
            },
            {
                title: 'Review Time',
                dataIndex: 'operatedTime',
                key: 'operatedTime',
                className: 'column-center',
            },
            {
                title: 'Reason for rejection',
                dataIndex: 'failMessage',
                key: 'failMessage',
                className: 'column-center',
            },
        ];
    }

    tableColumns = () => {
        let key = this.state.tabPansKey;
        switch (key) {
            case '2':
                return this.reviewColumns();
            case '4':
                return this.passedColumns();
            case '5':
                return this.failureColumns();
            default:
                return this.columns();
        }
    }

    // 申请付费功能

    downloadNovel = () => {
        this.props.downloadNovel();
    }

    ignorePayNovel = () => {
        const { novelId } = this.state;
        this.props.getIgnoreBtn(novelId);
    }

    paidNovelSetting = () => {
        this.props.paidModal(true);
        const { novelId } = this.state;
        this.props.getSettingMsg(novelId);
    }

    paidSettingOk = () => {
        const { novelId } = this.state;
        const { data } = this.props;
        if (data.isOpen === '0') {
            let param: any = {
                novelId: novelId
            };
            this.props.getPaidSetting(param);
        } else {
            if (data.chapterSort !== 0) {
                let param: any = {
                    novelId: novelId
                };
                this.props.getPaidSetting(param);
            }
        }
        
    }

    paidSettingCancel = () => {
        this.props.paidModal(false);
        this.props.SettingModalCancel();
    }

    changePaid = (value: any) => {
        this.props.isSettingOpen(value);
    }

    chapterSort = (value: any) => {
        this.props.getSettingSort(value);

        let param = {
            novelId: this.state.novelId,
            sort: value
        };
        this.props.getPaidChapterSettingMsg(param);
    }

    changeMinRatio = (value: any) => {
        this.props.getMinRatio(Math.round(value));
        this.props.getMaxRatio(Math.round(100 - value));
    }

    changeMaxRatio = (value: any) => {
        this.props.getMaxRatio(value);
    }

    render() {
        const { data, getChapterTableChange } = this.props;
        const { fileList,
            sorterModal, locationId, toEdit, tabPansKey, toDetail,
            detailId , novelId, dismissedModal, rejectionText, btnPermission,
            novelType, firstCommit,  delDisabled, selectedRowKeys } = this.state; //

        const { getFieldDecorator } = this.props.form;

        const rowSelection = tabPansKey === '' ?  {
            selectedRowKeys,
            onChange: this.onSelectChange,
        } : undefined;

        if (toEdit) {
            return <Redirect push={true} to={{pathname: `/content/novelAdd`, search: `?id=${novelId}&chapter=true`}}/>;
        }

        if (toDetail) {
            return <Redirect push={true}  to={{pathname: `/content/chapterDetail`, search: `?id=${detailId}&key=${tabPansKey}&commit=${firstCommit}`}}/>;
        }

        const downUrlBtn = {
            style: {
                textAlign: 'center',
                cursor: 'pointer',
                border: '1px solid transparent',
                whiteSpace: 'nowrap',
                lineHeight: '32px',
                padding: '0 15px',
                fontSize: '14px',
                borderRadius: '4px',
                height: '32px',
                color: 'rgba(0, 0, 0, 0.65)',
                backgroundColor: '#fff',
                borderColor: '#d9d9d9',
                margin: '15px 30px',
                display: 'inline-block'
            }
        } as any;

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Row gutter={16} style={{marginBottom: '20px'}}>
                        {
                            (btnPermission.indexOf('novel-manager-extra') >= 0 && tabPansKey === '') &&
                            <Col style={{textAlign: 'right'}}>
                                <Button type="primary" onClick={this.toEdit}>Edit</Button>
                            </Col>
                        }
                        <Col style={{textAlign: 'right'}}>
                            {
                                tabPansKey === 'pay' &&
                                <Button type="primary" onClick={this.paidNovelSetting}>Paid Setting</Button>
                            }

                            {
                                (tabPansKey === 'pay' && data.showPaidBtn && data.novelMsg.chargeStatus === 2) &&
                                <Popconfirm
                                    title="Whether to ignore the author's current application?"
                                    onConfirm={this.ignorePayNovel}
                                    okText="Confirm"
                                    cancelText="Cancel"
                                >
                                    <Button type="danger" style={{marginLeft: '15px'}}>Ignore</Button>
                                </Popconfirm>
                            }
                        </Col>

                        <Col span={4}>
                            <img
                                src={data.novelMsg.coverUrl}
                                alt=""
                                style={{width: '200px', height: '300px'}}
                            />
                            {
                                tabPansKey === '' &&
                                <div>
                                    <Button style={{margin: '15px 30px'}} onClick={this.downloadCover}>Download Cover</Button>
                                    {
                                        data.novelMsg.downUrl &&
                                        <a href={data.novelMsg.downUrl} download="novel" {...downUrlBtn}>Download Novel</a>
                                    }
                                    
                                </div>
                            }

                        </Col>
                        <Col offset={1} span={19}>
                            <Row className="chapterMsg">
                                <Col span={24}>
                                    <div>Novel Name:<span>{data.novelMsg.name}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Novel ID: <span>{data.novelMsg.id || '--'}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Author ID: <span>{data.novelMsg.authorId || '--'}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Type: <span>{novelType[data.novelMsg.novelType]}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Signing: <span>{data.novelMsg.signed ? 'Kontrak tertulis' : 'Kontrak digital'}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Category: <span>{data.novelMsg.typeName  || '--'}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Language: <span>{data.novelMsg.language || '--'}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Author: <span>{data.novelMsg.author || '--'}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Added Time: <span>{data.novelMsg.releaseAt ? changeStampToDate(data.novelMsg.releaseAt, 'DD-MM-YYYY HH:mm:ss') : '--'}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Update: <span>{data.novelMsg.completedStatusValue || '--'}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Status: <span>{data.novelMsg.shelfStatusValue || '--'}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Creation Time: <span>{data.novelMsg.createdAt ? changeStampToDate(data.novelMsg.createdAt, 'DD-MM-YYYY HH:mm:ss') : '--'}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Update Time: <span>{data.novelMsg.updatedAt ? changeStampToDate(data.novelMsg.updatedAt, 'DD-MM-YYYY HH:mm:ss') : '--'}</span></div>
                                </Col>
                                <Col span={8}>
                                    <div>Total Words: <span>{numFormat(data.novelMsg.wordCount) || '--'}</span></div>
                                </Col>
                                {
                                    data.novelMsg.paidApplication !== '0' &&
                                    <Col span={8}>
                                        <div>Paid application time: <span>{data.novelMsg.paidApplication}</span></div>
                                    </Col>
                                }
                               {
                                   data.novelMsg.paidOpening !== '0' &&
                                    <Col span={8}>
                                        <div>Paid opening time: <span>{data.novelMsg.paidOpening || '--'}</span></div>
                                    </Col>
                               }

                                <Col span={24}>
                                    <div>Url: <span>{data.novelMsg.url || '--'}</span></div>
                                </Col>
                                <Col span={24}>
                                    <div>Introduction: <span>{data.novelMsg.brief || '--'}</span></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    {
                        tabPansKey === '' &&
                        <Row style={{marginBottom: '20px'}}>
                            <Col span={18} style={{fontWeight: 'bold', fontSize: '18px'}}>
                                Chapter View({data.total})
                            </Col>

                            <Col span={5} style={{textAlign: 'right'}}>
                                {
                                    btnPermission.indexOf('novel-manager-extra') >= 0 &&
                                        <Popconfirm
                                            title="Are you sure delete this chapter?"
                                            onConfirm={(e: any) => {this.delChapter(); }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button disabled={delDisabled} type="danger" style={{marginRight: '15px'}}>
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                }
                                {
                                    btnPermission.indexOf('novel-manager-extra') >= 0 &&
                                    <Button onClick={this.uploadModal} type="primary">Add</Button>
                                }
                            </Col>
                        </Row>
                    }
                    <Table
                        rowSelection={rowSelection}
                        rowKey="id"
                        bordered={true}
                        columns={this.tableColumns()}
                        style={{marginTop: '20px'}}
                        dataSource={data.chapterList}
                        onChange={getChapterTableChange}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total:${data.total} items`;
                            },
                        }}
                    />
                    <Modal
                        title="Add Chapter"
                        visible={data.uploadModalOpen}
                        onOk={() => {this.uploadModalOk(); }}
                        onCancel={this.uploadModalCancel}
                        confirmLoading={data.btnLoading}
                        className="addChapter"
                        okText="Upload"
                        cancelText="Close"
                    >
                        <Row>
                            <Col>
                                Upload:
                                <Upload
                                    fileList={fileList}
                                    multiple={true}
                                    onChange={this.uploadChange}
                                    style={{marginLeft: '15px'}}
                                >
                                    <Button>Add</Button>
                                </Upload>
                            </Col>
                        </Row>
                    </Modal>
                    <Modal
                        title="Chapter Sorter"
                        visible={sorterModal}
                        maskClosable={false}
                        onOk={this.sorterModalOk}
                        onCancel={this.sorterModalCancel}
                    >
                        <Form>
                            <FormItem>
                                {getFieldDecorator('sorterId', {
                                    initialValue: locationId,
                                    rules: [
                                        {validator: isInt}
                                    ]
                                })(
                                    <Input />
                                )}
                            </FormItem>
                        </Form>
                    </Modal>
                    <Modal
                        title="Reason for the rejection"
                        visible={dismissedModal}
                        maskClosable={false}
                        onOk={this.dismissedModalOk}
                        onCancel={this.dismissedModalCancel}
                    >
                        <Input value={rejectionText} onChange={this.rejectionText} placeholder="Please enter the reason for the rejection" />
                    </Modal>
                </Spin>
                <Modal
                    title="Paid Setting"
                    visible={data.paidModal}
                    maskClosable={false}
                    onOk={this.paidSettingOk}
                    onCancel={this.paidSettingCancel}
                >
                    <Spin spinning={data.loading}>
                        <div style={{marginBottom: '30px'}}>
                            <p>Whether to open the payment:</p>
                            <Select style={{width: '60%'}} value={data.isOpen} onChange={this.changePaid}>
                                <Option key="0">Close</Option>
                                <Option key="1">Open</Option>
                            </Select>
                        </div>
                        {
                            data.isOpen === '1' &&
                            <div>
                                <div style={{marginBottom: '30px'}}>
                                    <p>Please enter the starting payment chapter number (not chapter id):</p>
                                    <InputNumber
                                        min={0}
                                        value={data.chapterSort}
                                        formatter={value => `${value}`.replace(/[^\d]/g, '')}
                                        onChange={this.chapterSort}
                                        style={{width: '20%', marginRight: '10px', marginLeft: '10px'}}
                                    />
                                    <span style={{marginLeft: '15px'}}>Chapter Name: {data.chapterName}</span>
                                    <p style={{marginTop: '10px', color: '#999999'}}>Rp.100/1000 words</p>
                                </div>
                                {
                                    btnPermission.indexOf('paid-novel-manager-extra') >= 0 &&
                                    <div style={{marginBottom: '30px'}}>
                                        <p>Please set up a split ratio(Author : Platform):</p>
                                        <div>
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={1}
                                                formatter={value => `${value}`.replace(/[^\d]/g, '')}
                                                value={data.chapterMinRatio}
                                                onChange={this.changeMinRatio}
                                                style={{width: '20%', marginRight: '10px'}}
                                            />
                                            :
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={1}
                                                disabled={true}
                                                value={data.chapterMaxRatio}
                                                onChange={this.changeMaxRatio}
                                                style={{width: '20%', marginRight: '10px', marginLeft: '10px'}}
                                            />
                                        </div>
                                    </div>
                                }

                            </div>
                        }
                    </Spin>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(ChapterManager);
