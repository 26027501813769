import { combineReducers } from 'redux';
import { novelReducer } from './novelReducer';
import { chapterReducer } from './chapterReducer';
import { novelManagerReducer } from './novelManagerReducer';
import { chapterManagerContentReducer } from './chapterManagerReducer';
import { chapterDetailReducer } from './chapterDetailReducer';
import { paidNovelReducer } from './paidNovelReducer';

export default combineReducers({
    novel: novelReducer,
    chapter: chapterReducer,
    chapterDetail: chapterDetailReducer,
    novelManager: novelManagerReducer,
    chapterManager: chapterManagerContentReducer,
    paidNovelReducer: paidNovelReducer
});