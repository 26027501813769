import  NovelTotalData  from '../NovelTotalData';
import { connect } from 'react-redux';
import * as novelTotalDataAction from '../../actions/novelTotalDataAction';

const mapStateToProps = (state: any) => ({
   data: state.data.novelTotalData
});

const mapDispatchToProps = {
    initPage: novelTotalDataAction.initPage,
    updatePage: novelTotalDataAction.updatePage,
    searchDate: novelTotalDataAction.searchDate,
    exportModalVisible: novelTotalDataAction.exportModalVisible,
    submitExport: novelTotalDataAction.submitExport
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NovelTotalData);