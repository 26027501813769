import React from 'react';
import { Row, Col, Button, Icon } from 'antd';

interface ToolbarProps {
    showExport?: boolean;
    hideSearchButton?: boolean;
    hideClearButton?: boolean;
    onSearch?: () => void;
    onClear?: () => void;
    onExport?: () => void;
}

export const Toolbar: React.SFC<ToolbarProps> = (props) => {
    const { showExport, hideSearchButton, hideClearButton, onSearch, onClear, onExport } = props;
    const button = { style: {width: 95, margin : '8px', float: 'right' }} as any;

    const handleSearchClick = () => {
        if (onSearch) {
            onSearch();
        }
    };

    const handleClearClick = () => {
        if (onClear) {
            onClear();
        }
    };

    const handleExportClick = () => {
        if (onExport) {
            onExport();
        }
    };

    return (
        <Row>
            <Col span={6}>
                {props.children}
            </Col>
            <Col span={12} offset={6}>
                {showExport && <Button {...button} type="primary" onClick={() => handleExportClick()}><Icon type="export"/>Export</Button>}
                {!hideClearButton && <Button {...button} type="primary" onClick={() => handleClearClick()}><Icon type="sync"/>Clear</Button>}
                {!hideSearchButton && <Button  {...button} type="primary" onClick={() => handleSearchClick()}><Icon type="search"/>Search</Button>}

            </Col>
        </Row>
    );
};
