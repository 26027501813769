import { fork, all, select, takeEvery, put, call, take } from 'redux-saga/effects';
import typeKey from '../../components/creation/action/actionsTypeKey/typeActionsTypeKey';
import * as typeAction from '../../components/creation/action/typeAction';
import * as tagServer from '../../servers/typeServer';
import { openNotification } from '../../components/widgets/Notification';

function* tagList() {
    const state = yield select();
    const type = state.creation.type;
    const param = type.searchData;
    try {
        yield put(typeAction.loading(true));
        const {result} = yield call(tagServer.getNovelTagList, param);
        yield put(typeAction.loading(false));
        yield put(typeAction.getTypeList(result));
    } catch (err) {
        yield put(typeAction.loading(false));
        openNotification('error', 'Fail to get type List');
    }

}

function* addEditTagFn() {
    while (true) {
        const { payload } = yield take(typeKey.GET_ADD_EDIT_TYPE);
        try {
            yield call(tagServer.addEditTag, payload);
            yield tagList();
        } catch (err) {
            if (err.code === 206) {
                openNotification('error', 'The novel type does exist');
            } else {
                openNotification('error', 'Fail to add/edit type.');
            }
        }
    }
}

function* changeTagShelfStatus() {
    while (true) {
        const { payload } = yield take(typeKey.CHANGE_TAG_STATUS);
        try {
            yield call(tagServer.changeTagStatus, payload);
            yield tagList();
        } catch (err) {
            if (err.code === 206) {
                openNotification('error', 'The novel type does exist');
            } else if (err.code === 227) {
                openNotification('error', 'The novel tag is more than 20');
            } else {
                openNotification('error', 'Fail to Change.');
            }
        }
    }
}

function* exportModel() {
    while (true) {
        const { payload } = yield take(typeKey.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);
        // payload.titles.splice(0, 0, {title: 'ID', index: 'id'});
        const state = yield select();
        const type = state.creation.type;
        const param = {
            current: payload.current,
            pageSize: payload.pageSize,
            titles: payload.titles,
            data: {
                name: type.searchData
            },
        };
        try {
            yield put(typeAction.loading(true));
            yield call(tagServer.exportTagList, param);
            yield put(typeAction.loading(false));
            yield put(typeAction.exportModalVisible(false));
        } catch (err) {
            yield put(typeAction.loading(false));
            yield put(typeAction.exportModalVisible(false));
            openNotification('error', 'Export failed.');
        }
    }
}

function* betchDelType() {
    while (true) {
        const { payload } = yield take(typeKey.GET_BETCH_DEL_TYPE_ID);
        try {
            yield call(tagServer.delTag, payload);
            yield tagList();
        } catch (err) {
            if (err.code === 207) {
                openNotification('error', err.message);
            } else if (err.code === 228) {
                openNotification('error', err.message);
            } else {
                openNotification('error', 'Failed to del type');
            }
        }
    }
}

function* getSorterRule() {
    while (true) {
         try {
             yield take(typeKey.SETTING_RULE);
             const { result } = yield call(tagServer.getSorter);

             for (let value of result ) {
                 if (value.selected === 1) {
                    yield put(typeAction.getRuleSelected(value.id)) ;
                 }
             }

             yield put(typeAction.getSorterRule(result));
         } catch (err) {
             openNotification('error', 'Failed get rule list');
         }
    }
}

function* changeSorterRule() {
    while (true) {
        yield take(typeKey.GET_SORTER_CHANGE_ID);
        const state = yield select();
        const type = state.creation.type;
        const ruleSelected = type.ruleSelected;
        try {
            yield call(tagServer.changeSorter, ruleSelected);
            openNotification('success', 'setting rule success');
        } catch (err) {
            if (err.code === 215) {
                openNotification('error', 'The rule does not exist');
            } else {
                openNotification('error', 'Failed to setting rule');
            }
        }
    }
}

function* changeTagSort() {
    while (true) {
        const { payload } = yield take(typeKey.CHANGE_TAG_SORT);
        try {
            yield call(tagServer.changeLocation, payload);
            yield tagList();
        } catch (err) {
            openNotification('error', 'Failed to setting rule');
        }
    }
}

function* watchInitPage() {
    yield takeEvery(typeKey.TYPE_INIT_PAGE, tagList);
}

function* watchSearch() {
    yield takeEvery(typeKey.GET_TYPE_SEARCH_PARAM, tagList);
}

function* watchUpdatePageSize() {
    yield takeEvery(typeKey.UPDATE_PAGE, tagList);
}

export function* watchTypeSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearch),
        fork(addEditTagFn),
        fork(changeTagShelfStatus),
        fork(exportModel),
        fork(betchDelType),
        fork(getSorterRule),
        fork(changeSorterRule),
        fork(changeTagSort),
        fork(watchUpdatePageSize),
    ]);
}
