import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Table, Form, Row, Col, Select, Spin, Popconfirm, Button, Icon, Badge } from 'antd';
import { Redirect } from 'react-router-dom';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../reducers/novelManagerReducer';
import { getExportTitles } from '../../../base/getExportTitles';
import { changeStampToDate } from '../../../base/dealWithTime';
import { numFormat } from '../../../base/regular';

const FormItem = Form.Item;
const Option = Select.Option;

interface NovelProps {
    data: StoreState;
    initNovelPage: () => void;
    getNovelSearchParam: (param: any) => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getDelNovelId: (id: number) => void;
    goNovelList: (bool: boolean) => void;
    getNovelChangeStateParam: (param: any) => void;
    changeNovelUpdateStatus: (id: number) => void;
}

interface NovelState {
    Time: string;
    liveType: any;
    toEdit: boolean;
    toDetail: boolean;
    detailId: number;
    btnPermission: any[];
}

class NovelManager extends React.Component<NovelProps & FormComponentProps, NovelState> {

    state: NovelState = {
        Time: '',
        liveType: null,
        toEdit: false,
        toDetail: false,
        detailId: 0,
        btnPermission: []
    };

    componentWillMount() {
        // 获取功能权限
        const value = window.sessionStorage.getItem('btnPermission');
        let sessionBtnPermission: any =  value == null ? [] : JSON.parse(value);
        this.setState({btnPermission: sessionBtnPermission});
    }

    componentDidMount() {
        this.props.initNovelPage();
    }

    columns = () => {
        let  { btnPermission } = this.state;
        return [
            {
                title: 'Novel ID',
                dataIndex: 'id',
                key: 'id',
                className: 'column-center',
                sorter: true,
                fixed: true,
                width: 110,
                sortField: 'id',
            },
            {
                title: 'Novel Name',
                dataIndex: 'name',
                key: 'name',
                className: 'column-center',
                width: 250,
                render: (text: any, record: any) => {
                    return <Badge dot={record.updatedStatus === 1}>{text}</Badge>;
                }
            },
            {
                title: 'Author',
                dataIndex: 'author',
                key: 'author',
                className: 'column-center',
            },
            {
                title: 'Category',
                dataIndex: 'typeName',
                key: 'typeName',
                className: 'column-center',
            },
            {
                title: 'Chapter Number',
                dataIndex: 'chapterCount',
                key: 'chapterCount',
                className: 'column-center',
                sorter: true,
                sortField: 'chapterCount',
                render: (text: any) => (<span>{text ? text : '0'}</span>)
            },
            {
                title: 'Payment Situation',
                dataIndex: 'chargeStatus',
                key: 'chargeStatus',
                className: 'column-center',
                render: (text: any) => {
                    if (text === 0 || text === 2) {
                        return <span>Unopened</span>;
                    } else {
                        return <span>Opened</span>;
                    }
                }
            },
            {
                title: 'Initial Payment Chapter',
                dataIndex: 'sort',
                key: 'sort',
                className: 'column-center',
                render: (text: any) => (<span>{text ? text : '--'}</span>)
            },
            {
                title: 'Total Words',
                dataIndex: 'wordCount',
                key: 'wordCount',
                sorter: true,
                sortField: 'wordCount',
                className: 'column-center',
                render: (text: any) => (<span>{numFormat(text)  || '--'}</span>)
            },
            {
                title: 'Type',
                dataIndex: 'novelType',
                key: 'novelType',
                className: 'column-center',
                render: (text: any) => {
                    return (
                        (() => {
                            switch (text) {
                                case 0:
                                    return <span>Original</span>;
                                case 1:
                                    return <span>Reprint</span>;
                                case 2:
                                    return <span>PGC</span>;
                                case 3:
                                    return <span>Star Novel</span>;
                                case 4:
                                    return <span>Translate</span>
                                default:
                                    return <span />;
                            }
                        })()
                    );
                }
            },
            {
                title: 'Signing',
                dataIndex: 'signed',
                key: 'signed',
                className: 'column-center',
                render: (text: number, record: any) => {
                    if (record.novelType === 3) {
                        return <span>{text === 0 ? 'Kontrak digital' : 'Kontrak tertulis'}</span>;
                    } else {
                       return '--' ;
                    }
                }
            },
            {
                title: 'Update',
                dataIndex: 'completedStatus',
                key: 'completedStatus',
                className: 'column-center',
                width: 100,
                render: (text: number) => {
                    if (text === 0) {
                        return <span>Update</span>;
                    } else {
                        return <span>Completed</span>;
                    }
                }
            },
            {
                title: 'Update Date',
                dataIndex: 'updatedAtDateValue',
                key: 'updatedAtDateValue',
                sorter: true,
                sortField: 'updatedAt',
                className: 'column-center',
            },
            {
                title: 'Update Time',
                dataIndex: 'updatedAtTimeValue',
                key: 'updatedAtTimeValue',
                className: 'column-center',
            },
            {
                title: 'Added Date',
                dataIndex: 'releaseAt',
                key: 'addedDate',
                sorter: true,
                sortField: 'releaseAt',
                className: 'column-center',
                type: 'dd-MM-yyyy',
                render: (text: string, record: any) => {
                    if (text) {
                        return <span>{changeStampToDate(text, 'DD-MM-YYYY')}</span>;
                    } else {
                        return <span>--</span>;
                    }

                }
            },
            {
                title: 'Added Time',
                dataIndex: 'releaseAt',
                key: 'addedTime',
                className: 'column-center',
                type: 'HH:mm:ss',
                render: (text: string, record: any) => {
                    if (text) {
                        return <span>{changeStampToDate(text, 'HH:mm:ss')}</span>;
                    } else {
                        return <span>--</span>;
                    }

                }
            },
            {
                title: 'Status',
                dataIndex: 'shelfStatusValue',
                key: 'shelfStatusValue',
                className: 'column-center',
                render: (text: string, record: any) => {
                    return <Badge dot={record.ugc === 1 && record.shelfStatus === 3}>{text}</Badge>;
                }
            },
            {
                title: 'Setting',
                dataIndex: 'setting',
                key: 'setting',
                width: 180,
                fixed: 'right' as 'right',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            <span style={{marginRight: '15px', color: '#1890ff', cursor: 'pointer'}} onClick={() => {this.goDetail(record); }}>View3</span>
                            {
                                btnPermission.indexOf('novel-manager-extra') >= 0 &&
                                <Popconfirm
                                    title="Are you sure change this novel status?"
                                    onConfirm={() => {this.novelChangeState(record); }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <a style={{marginRight: '15px'}}>
                                        {
                                            record.shelfStatus === 1 ? 'Hide' : 'Show'
                                        }
                                    </a>
                                </Popconfirm>
                            }
                            {
                                record.shelfStatus === 3 ? <Popconfirm
                                    title="Are you sure change this novel status?"
                                    onConfirm={() => {this.props.getNovelChangeStateParam({id: record.id, shelfStatus: 4}); }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <a style={{marginRight: '15px'}}>Refuse</a>
                                </Popconfirm> : null
                            }
                            {
                                (record.novelType === 2 && btnPermission.indexOf('novel-manager-extra') >= 0) &&
                                <Popconfirm
                                    title="Are you sure delete this novel?"
                                    onConfirm={() => {this.handleDel(record.id); }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <a style={{color: 'red'}}>Delete</a>
                                </Popconfirm>
                            }

                        </div>
                    );
                },
            }
        ];
    }

    handleDel = (id: number) => {
       this.props.getDelNovelId(id);
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    clear = () => {
        this.setState({Time: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            typeId: value.typeId,
            id: value.id,
            name: value.name,
            signed: value.signed,
            novelType: value.novelType,
            completedStatus: value.completedStatus,
            shelfStatus: value.shelfStatus,
            author: value.author
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.getNovelSearchParam(param);
    }

    novelChangeState = (record: any) => {
        if (record.shelfStatus === 0 || record.shelfStatus === 3) {
            this.props.getNovelChangeStateParam({id: record.id, shelfStatus: 1});
        } else {
            this.props.getNovelChangeStateParam({id: record.id, shelfStatus: 0});
        }
    }

    goDetail = (record: any) => {
        console.log("click-view3",record);
        this.props.goNovelList(false);
        this.props.changeNovelUpdateStatus(record.id);
        this.setState({
            toDetail: true,
            detailId: record.id
        });
    }

    goAdd = () => {
        this.props.goNovelList(false);
        this.setState({
            toEdit: true
        });
    }

    render() {
        const {getFieldDecorator, getFieldsValue} = this.props.form;
        const { getUpdatePage, data } = this.props;
        const { toEdit, toDetail, detailId, btnPermission } = this.state;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const statusTypeList = [
            {key: 'All', value: 'All'},
            {key: '1', value: 'Show'},
            {key: '0', value: 'Hide'}
        ];

        const upDateTypeList = [
            {key: 'All', value: 'All'},
            {key: '0', value: 'Update'},
            {key: '1', value: 'Completed'}
        ];

        const novelTYpeList = [
            {key: 'All', value: 'All'},
            {key: '0', value: 'Original'},
            {key: '1', value: 'Reprint'},
            {key: '2', value: 'PGC'},
            {key: '3', value: 'Star Novel'},
            {key: '4', value: 'Translate'}
        ];

        const signingList = [
            {key: 'All', value: 'All'},
            {key: '1', value: 'Kontrak tertulis'},
            {key: '0', value: 'Kontrak digital'}
        ];

        if (toEdit === true) {
            return <Redirect push={true} to={{pathname: `/content/novelAdd`}}/>;
        }

        if (toDetail === true) {
            return <Redirect push={true} to={{pathname: `/content/chapterManager`, search: `?id=${detailId}`}}/>;
        }

        return (
            <div>
                <Spin spinning={false}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={6}>
                                <FormItem label="Category">
                                    {getFieldDecorator('typeId', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            <Option key="All">All</Option>
                                            {data.typeList.map(item => <Option key={item.id}>{item.name}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label="Status">
                                    {getFieldDecorator('shelfStatus', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {statusTypeList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Update"
                                >
                                    {getFieldDecorator('completedStatus', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {upDateTypeList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Type"
                                >
                                    {getFieldDecorator('novelType', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {novelTYpeList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Signing"
                                >
                                    {getFieldDecorator('signed', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {signingList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Novel Name"
                                >
                                    {getFieldDecorator('name')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Novel ID"
                                >
                                    {getFieldDecorator('id')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Author Name"
                                >
                                    {getFieldDecorator('author')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                    <Toolbar
                        showExport={btnPermission.indexOf('novel-manager-extra') >= 0 ? true : false}
                        onSearch={this.onSearch}
                        onClear={this.clear}
                        onExport={this.handleExport}
                    >
                        {
                            btnPermission.indexOf('novel-manager-extra') >= 0 &&
                            <Button type="primary" onClick={this.goAdd}><Icon type="plus" />Add</Button>
                        }

                    </Toolbar>
                    <Table
                        rowKey="id"
                        loading={data.loading}
                        bordered={true}
                        columns={this.columns()}
                        style={{marginTop: '20px'}}
                        onChange={getUpdatePage}
                        dataSource={data.data}
                        scroll={{ x: 2600 }}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total:${data.total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={getFieldsValue}
                        visible={data.exportModalVisible}
                        loading={data.loading}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                    />
                </Spin>
            </div>
        );
    }
}

export default Form.create()(NovelManager);