enum ChapterActionTypeKeys {
    CHAPTET_INIT_PAGE = 'CHAPTER_INIT_PAGE',
    CHAPTET_NOVEL_ID = 'CHAPTER_NOVEL_ID', // 获取novelid
    GET_NOVEL_MSG = 'CHAPTER_GET_NOVEL_MSG', // 获取novel 信息
    GET_CHAPTER_LIST = 'GET_CHAPTER_LIST_MANAGER_PAGE', // 获取章节列表
    GET_CHAPTER_SEARCH_PARAM = 'GET_CHAPTER_SEARCH_PARAM', // 获取搜索参数
    GET_DEL_CHAPTER_ID = 'GET_DEL_CHAPTER_ID', // 获取删除章节id
    CHAPTEL_TABLE_CHANGE = 'CHAPTER_TABLE_CHANGE',
    CHAPTER_MANAGER_SORT = 'CHAPTER_MANAGER_SORT',  // 修改章节顺序
    GET_CHAPER_ID = 'GET_CHAPTER_ID', // 获取章节id
    LOADING = 'LOADING',
    UPLOAD_OPEN = 'CHAPTER_UPLOAD_OPEN',
    BTN_LOADING = 'CHAPTER_BTN_LOADING',
    GET_NOVEL_LIST = 'GET_NOVEL_LIST_CHAPTER_MANAGER',
    UPDATE_CHAPTER_LIST = 'UPDATE_CHAPTER_LIST',
    CHANGE_NOVEL_REVIEW_STATUS = 'CHANGE_NOVEL_REVIEW_STATUS',
    DOWNLOAD_COVER = 'CHAPTER_MANAGER_DOWNLOAD_COVER',
    PAID_MODAL = 'CHAPTER_MANAGER_PAID_MODAL',
    IGNORE_NOVEL = 'CHAPTER_MANAGER_IGNORE_NOVEL',
    SHOW_PAID_BUTTON = 'CHAPTER_MANAGER_SHOW_PAID_BUTTON',
    GET_PAID_SETTING = 'CHAPTER_MANAGER_GET_PAID_SETTING',
    DOWNLOAD_NOVEL = 'CHAPTER_MANAGER_DOWNLOAD_NOVEL',
    IS_SETTING_OPEN = 'CHAPTER_MANAGER_IS_SETTING_OPEN',
    GET_SETTING_SORT = 'CHAPTER_MANAGER_GET_SETTING_SORT',
    GET_SETTING_NAME = 'CHAPTER_MANAGET_GET_SETTING_NAME',
    GET_MIN_RATIO = 'CHAPTER_MANAGER_GET_MIN_RATIO',
    GET_MAX_RATIO = 'CHAPTER_MANAGER_GET_MAX_RATIO',
    SETTING_MODAL_CANCEL = 'CHAPTER_MANAGER_SETTING_MODAL_CANCEL',
    GET_SETTING_MSG = 'CHAPTER_MANAGER_GET_SETTING_MSG',
    GET_PAID_CHAPTER_SETTING_MSG = 'CHAPTER_MANAGER_GET_PAID_CHAPTER_SETTING_MSG'
}

export default ChapterActionTypeKeys;