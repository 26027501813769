import { fork, all, put, call, takeEvery, select, take } from 'redux-saga/effects';
import BannerActionsTypeKey from '../../components/creation/action/actionsTypeKey/bannerActionsTypeKey';

import { openNotification } from '../../components/widgets/Notification';
import { uploadFile } from '../../servers/ossServer';

import * as  bannerServer from '../../servers/bannerServer';
import * as  contentServer from '../../servers/contentServer';
import * as bannerAction from '../../components/creation/action/bannerAction';

function* initPage() {
    yield getBannerList();
}

function* getBannerList() {
    try {
        yield put(bannerAction.loading(true));
        const state = yield select();
        const data = state.creation.banner;
        const param = {
            ...data.page,
            data: {...data.searchData}
        };
        let result = yield call(bannerServer.getBannerList, param);
        yield put(bannerAction.getBannerList(result.result));
        yield put(bannerAction.loading(false));
    } catch (error) {
        openNotification('error', 'Fail to get banner list.');
        yield put(bannerAction.loading(false));
    }
}

function* watchAddBanner() {
    while (true) {
        let { payload } = yield take(BannerActionsTypeKey.ADD_BANNER);
        payload.objectKeys.name ? payload.objectKey = yield uploadFile('banner', payload.objectKeys) : payload.objectKey = payload.objectKeys;
        delete payload.objectKeys;
        try {
            yield put(bannerAction.modalLoading(true));
            if (payload.id !== undefined) {
                yield call(bannerServer.modifyBanner, payload);
                yield put(bannerAction.modalLoading(false));
                yield put(bannerAction.modalVisible(false));
                yield getBannerList();
                openNotification('success', 'Edit success.');
            } else {
                yield call(bannerServer.addBanner, payload);
                yield put(bannerAction.modalLoading(false));
                yield put(bannerAction.modalVisible(false));
                yield getBannerList();
                openNotification('success', 'Add success.');
            }
        } catch (err) {
            if (err.code === 211) {
                openNotification('error', 'The banner does not exist.');
            } else if (err.code === 217) {
                openNotification('error', 'The oss key does not exist.');
            } else if (err.code === 998) {
                openNotification('error', 'Banner name no more than 50 words.');
            } else {
                openNotification('error', 'failed.');
            }
            yield put(bannerAction.modalLoading(false));
        }
    }
}

function* watchSortBanner() {
    while (true) {
        let { payload } = yield take(BannerActionsTypeKey.BANNER_SORT);
        try {
            yield put(bannerAction.loading(true));
            yield call(bannerServer.modifyBannerSort, payload);
            yield put(bannerAction.loading(false));
            openNotification('success', 'Sort success.');
        } catch (err) {
            openNotification('error', 'Sort failed.');
            yield put(bannerAction.loading(false));
        }
    }
}

function* watchModifyBannerStatus() {
    while (true) {
        let { payload } = yield take(BannerActionsTypeKey.MODIFY_BANNER_STATUS);
        try {
            yield put(bannerAction.loading(true));
            yield call(bannerServer.modifyShelfStatus, payload);
            yield put(bannerAction.loading(false));
            yield getBannerList();
            openNotification('success', 'Change success.');
        } catch (err) {
            if (err.code === 211) {
                openNotification('error', 'The banner does not exist.');
            } else if (err.code === 212) {
                openNotification('error', 'The banner no more than 5.');
            } else if (err.code === 222) {
                openNotification('error', 'The novel is off the shelf.');
            } else {
                openNotification('error', 'Change failed.');
            }
            yield put(bannerAction.loading(false));
        }
    }
}

function* watchDeleteBanner() {
    while (true) {
        let { payload } = yield take(BannerActionsTypeKey.DELETE_BANNER);
        try {
            yield put(bannerAction.loading(true));
            yield call(bannerServer.deleteBannerBatch, payload);
            yield put(bannerAction.loading(false));
            yield getBannerList();
            openNotification('success', 'Delete success.');
        } catch (err) {
            openNotification('error', 'Delete failed.');
            yield put(bannerAction.loading(false));
        }
    }
}

function* watchGetNovelListByName() {
    while (true) {
        let { payload } = yield take(BannerActionsTypeKey.SEND_NOVEL_VALUE);
        try {
            let result = yield call(contentServer.getNovelListByName, payload);
            let arr: any = [];
            result.result.map((item: any) => {
                item.name = item.name + ' (ID: ' + item.id + ')';
                arr.push(item.name);
            });
            yield put(bannerAction.getNovelListByName(arr));
            yield put(bannerAction.getAllNovelListByName(result.result));
        } catch (err) {
            openNotification('error', 'Get novel name list failed.');
            yield put(bannerAction.loading(false));
        }
    }
}

function* watchExportBannerList() {
    while (true) {
        const { payload } = yield take(BannerActionsTypeKey.EXPORT_BANNER_LIST);
        // console.log(payload);
        try {
            yield put(bannerAction.loading(true));
            yield call(contentServer.exportBannerList, payload);
            yield put(bannerAction.loading(false));
            openNotification('success', 'Export success');
        } catch (err) {
            yield put(bannerAction.loading(false));
            openNotification('error', 'Export failed');
        }
    }
}

function* watchInitPage() {
    yield takeEvery(BannerActionsTypeKey.INIT_PAGE, initPage);
}

function* watchSearchData() {
    yield takeEvery(BannerActionsTypeKey.LIST_DATA_SEARCH, getBannerList);
}

export function* watchBannerRootSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearchData),
        fork(watchAddBanner),
        fork(watchSortBanner),
        fork(watchModifyBannerStatus),
        fork(watchDeleteBanner),
        fork(watchGetNovelListByName),
        fork(watchExportBannerList),
    ]);
}