import WithdrawalReview  from '../WithdrawalReview';
import { connect } from 'react-redux';
import * as withdrawalAction from '../../actions/withdrawalAction';

const mapStateToProps = (state: any) => ({
    data: state.audit.withdrawal
});

const mapDispatchToProps = {
    initPage: withdrawalAction.initPage,
    exportModalVisible: withdrawalAction.exportModalVisible,
    submitExport: withdrawalAction.submitExport,
    getUpdatePage: withdrawalAction.getUpdatePage,
    getSearchData: withdrawalAction.getSearchData,
    getTabsKey: withdrawalAction.getTabsKey,
    getWithdrawalId: withdrawalAction.getWithdrawalId,
    getWithdrawalDetailChangeStatus: withdrawalAction.getWithdrawalDetailChangeStatus
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(WithdrawalReview);