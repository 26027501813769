import chapterDetailKey from '../actions/actionsTypeKey/chapterDetailActionTypeKey';
import {  ActionTypes } from '../actions/actionsType/chapterDetailActionsType';

export interface StoreState {
    detailData: string;
    detailDataForEditor: string;
    originData: string;
    loading: boolean;
    isDiff: boolean;
}

const initialState: StoreState = {
    detailData: '',
    detailDataForEditor: '',
    originData: '',
    loading: false,
    isDiff: false
};

export const chapterDetailReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case chapterDetailKey.INIT_PAGE:
            return initialState;

        case chapterDetailKey.GET_CHAPTER_DETAIL_DATA:
            return Object.assign({}, state, {
                detailData: action.payload
            });

        case chapterDetailKey.GET_CHAPTER_EDIT_CONTENT_FOR_EDITOR:
            return Object.assign({}, state, {
                detailDataForEditor: action.payload
            });

        case chapterDetailKey.GET_CHAPTER_DETAIL_ORIGIN_DATA:
            return Object.assign({}, state, {
                originData: action.payload
            });

        case chapterDetailKey.GET_CHAPTER_DETAIL_LOADING:
            return Object.assign({}, state, {
               loading: action.payload
            });

        case chapterDetailKey.GET_CHAPTER_DETAIL_DIFF:
            return Object.assign({}, state, {
                isDiff: action.payload
            });

        default:
            return state;
    }
};