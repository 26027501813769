import { all, select, takeEvery, fork, put, call, take } from 'redux-saga/effects';
import  chapterActionsTypeKey  from '../../components/content/actions//actionsTypeKey/chapterManagerActionsTypeKey';
import * as chapterAction from '../../components/content/actions/chapterManagerAction';
import * as chapterServer from '../../servers/chapterServer';
import * as paidServer from '../../servers/paidNovelServer';
import { getAuditChapterList, modifyAuditStatus } from '../../servers/novelReviewServer';
import { openNotification } from '../../components/widgets/Notification';

function* getNovelMsg() {
    while (true) {
        const {payload} = yield take(chapterActionsTypeKey.CHAPTET_NOVEL_ID);
        console.log("mmmmmmm3331", payload.id);
        yield put(chapterAction.getNovelMsg([]));
        yield put(chapterAction.getChapterList([]));
        try {
            yield put(chapterAction.loading(true));
            console.log("mmmmmmm3332", payload.id);
            const { result } = yield call(chapterServer.getNovelMsg, payload.id);
            result.urlKey = payload.key;
            const downUrl = yield downloadNovel(result);
            result.downUrl = downUrl;
            yield put(chapterAction.getNovelMsg(result));
            yield put(chapterAction.showPaidButton(true));
            yield chapterList();
            yield put(chapterAction.loading(false));
        } catch (err) {
            openNotification('error', 'Fail to get novel msg');
        }
    }
}

function* getNovelMsgToRefresh(novelMsg: any) {
    try {
        yield put(chapterAction.loading(true));
        const { result } = yield call(chapterServer.getNovelMsg, novelMsg.id);
        yield put(chapterAction.getNovelMsg(result));
        yield put(chapterAction.showPaidButton(true));
        result.urlKey = novelMsg.urlKey;
        const downUrl = yield downloadNovel(result);
        result.downUrl = downUrl;
        yield chapterList();
        yield put(chapterAction.loading(false));
    } catch (err) {
        openNotification('error', 'Fail to get novel msg');
    }
}

function* chapterList() {
    const state = yield select();
    const chapter = state.content.chapterManager;
    const novelMsg = {...chapter.novelMsg};
    const page = {...chapter.page};
    try {
        let param = {
            data: '',
            ...page,
            ...chapter.sorter
        };
        //2021-02-06修改
        //param.data = novelMsg.urlKey === '' || novelMsg.urlKey === 'pay' ? novelMsg.id : {novelId: novelMsg.id, status: novelMsg.urlKey};
        param.data = novelMsg.urlKey === '' || novelMsg.urlKey === 'pay' ? {novelId: novelMsg.id, status: novelMsg.urlKey} : {novelId: novelMsg.id, status: novelMsg.urlKey};
        console.log("2021-02-06修改", param);
        yield put(chapterAction.loading(true));
        const {result} = novelMsg.urlKey === '' || novelMsg.urlKey === 'pay' ? yield call(chapterServer.getChapterList, param)  : yield call(getAuditChapterList, param);
        yield put(chapterAction.loading(false));
        yield put(chapterAction.getChapterList(result));

    } catch (err) {
        yield put(chapterAction.loading(false));
        openNotification('error', 'Failed to get chapter list');
    }
}

function* delChapter() {
    while (true) {
        const { payload } = yield take(chapterActionsTypeKey.GET_DEL_CHAPTER_ID);
        try {
            yield call(chapterServer.delChapter, payload);
            yield chapterList();
            openNotification('success', 'Delete success');
        } catch (err) {
            openNotification('error', 'Delete failed');
        }
    }
}

function* sortChange() {
    while (true) {
       const { payload } = yield take(chapterActionsTypeKey.CHAPTER_MANAGER_SORT);
       try {
           yield call(chapterServer.changeChapterSorter, payload);
           yield chapterList();
       } catch (err) {
           openNotification('error', err.message);
       }
    }
}

function* changeChapterUpdateStatus() {
    while (true) {
        const { payload } = yield take(chapterActionsTypeKey.GET_CHAPER_ID);
        try {
            yield call(chapterServer.changeChapterStatus, payload.id);
            yield chapterList();
        } catch (err) {
            openNotification('error', err.message);
        }
    }
}

function* changeNovelReviewStatus() {
    while (true) {
        const { payload } = yield take(chapterActionsTypeKey.CHANGE_NOVEL_REVIEW_STATUS);
        try {
            yield call(modifyAuditStatus, payload);
            payload === '4' ?
                openNotification('success', 'Success to release')
                :
                openNotification('success', 'Success to dismissed');
            yield chapterList();
        } catch (err) {
            payload === '4' ?
                openNotification('error', 'Failed to release')
                :
                openNotification('error', 'Failed to dismissed');
        }
    }
}

function* downCover() {
    while (true) {
        const { payload } = yield take(chapterActionsTypeKey.DOWNLOAD_COVER);
        try {
            yield call(chapterServer.downloadCover, payload);
            openNotification('success', 'Success to download');
        } catch (e) {
            openNotification('error', 'Failed to download');
        }
    }
}

function* ignorePaidNovel() {
    while (true) {
        const { payload } = yield take(chapterActionsTypeKey.IGNORE_NOVEL);
        try {
            yield call(paidServer.ingorePaidNovel, payload);
            openNotification('success', 'Success to ignore');
            yield put(chapterAction.showPaidButton(false));
        } catch (e) {
            openNotification('error', 'Failed to ignore');
        }
    }
}

function* getPaidSettingCon() {
    while (true) {
        const { payload } = yield take(chapterActionsTypeKey.GET_SETTING_MSG);
        try {
            const value = window.sessionStorage.getItem('btnPermission');
            let sessionBtnPermission: any =  value == null ? [] : JSON.parse(value);
            const state = yield select();
            const chapter = state.content.chapterManager;
            yield put(chapterAction.loading(true));
            let data;
            if (sessionBtnPermission.indexOf('paid-novel-manager-extra') >= 0 ) {
                const result =  yield call(paidServer.getPaymentInfo, payload );
                data = result.result;

                yield put(chapterAction.getMinRatio(data.incomeSharig));
                yield put(chapterAction.getMaxRatio(Math.round(100 - data.incomeSharig)));
            } else {
                const result =  yield call(paidServer.getPaymentSort, payload );
                data = result.result;
                yield put(chapterAction.getMinRatio(null));
                yield put(chapterAction.getMaxRatio(null));
            }

            let getNameParam = {
                novelId: chapter.novelId.id,
                sort: data.sort
            };

            const { result: name } = yield call(chapterServer.getChapterNameForSort, getNameParam);
            if (name && name.name) {
               yield put(chapterAction.getSettingName(name.name));
            } else {
               yield put(chapterAction.getSettingName('--'));
            }

            yield put(chapterAction.getSettingSort(data.sort));

            yield put(chapterAction.loading(false));
        } catch (e) {
            yield put(chapterAction.loading(false));
            openNotification('error', 'Failed to get paid setting');
        }
    }
}

function* setPaidSetting() {
    while (true) {
        const { payload } = yield take(chapterActionsTypeKey.GET_PAID_SETTING);
        const state = yield select();
        const chapter = state.content.chapterManager;
        const { novelMsg } = chapter;
        const value = window.sessionStorage.getItem('btnPermission');
        let sessionBtnPermission: any =  value == null ? [] : JSON.parse(value);
        let param;
        if (chapter.isOpen === '0') {
            param = {
                novelId: payload.novelId, 
                openOrShutDown: chapter.isOpen,
            };
        } else {
            param = {
                novelId: payload.novelId, 
                openOrShutDown: chapter.isOpen,
                startChapter: chapter.chapterSort,
                authorProportion: 0,
                platformProportion: 0
            };
            if (sessionBtnPermission.indexOf('paid-novel-manager-extra') >= 0 ) {
                param.authorProportion = chapter.chapterMinRatio;
                param.platformProportion = chapter.chapterMaxRatio;
            } else {
                if (novelMsg.chargeStatus === 0 || novelMsg.chargeStatus === 2) {
                    param.authorProportion = 20;
                    param.platformProportion = 80;
                } else {
                    param.authorProportion = chapter.chapterMinRatio;
                    param.platformProportion = chapter.chapterMaxRatio;
                }
            }
        }
        try {
            yield put(chapterAction.loading(true));
            yield call(paidServer.payMentSetting, param);
            yield put(chapterAction.paidModal(false));
            yield put(chapterAction.showPaidButton(false));
            yield put(chapterAction.SettingModalCancel());
            yield getNovelMsgToRefresh(novelMsg);
            yield put(chapterAction.loading(false));
            openNotification('success', 'Success to setting');
        } catch (e) {
            openNotification('error', 'Failed to setting');
        }
    }
}

function* downloadNovel(novelMsg: any) {
    let param = {
        novelId: novelMsg.id
    };
    try {
        const { result } =  yield call(chapterServer.downNovelZip, param);
        return result.packDownloadUrl;
    } catch (e) {
        // 防止接口报错获取不到信息,所以再次请求写一遍
        yield put(chapterAction.getNovelMsg(novelMsg));
        yield put(chapterAction.showPaidButton(true));
        yield chapterList();
        yield put(chapterAction.loading(false));
        // openNotification('error', 'Failed to get download url');
    }
}

function* getPaidChapterNameBySort() {
    while (true) {
        const { payload } = yield take(chapterActionsTypeKey.GET_PAID_CHAPTER_SETTING_MSG);
        try {
            const { result } = yield call(chapterServer.getChapterNameForSort, payload);
            yield put(chapterAction.getSettingName(result.name));
        } catch (e) {
            yield put(chapterAction.getSettingName('--'));
        }
    }
}

function* watchUpdataChapterLit() {
    yield takeEvery(chapterActionsTypeKey.UPDATE_CHAPTER_LIST, chapterList);
}

function* watchUpdatePage() {
    yield takeEvery(chapterActionsTypeKey.CHAPTEL_TABLE_CHANGE, chapterList);
}

export function* watchChapterSaga() {
    yield all([
        fork(getNovelMsg),
        fork(watchUpdatePage),
        fork(sortChange),
        fork(delChapter),
        fork(changeChapterUpdateStatus),
        fork(watchUpdataChapterLit),
        fork(changeNovelReviewStatus),
        fork(downCover),
        fork(ignorePaidNovel),
        fork(setPaidSetting),
        fork(getPaidSettingCon),
        fork(getPaidChapterNameBySort)
    ]);
}