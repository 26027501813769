import TypeDetail  from '../TypeDetail';
import { connect } from 'react-redux';
import * as typeDetailAction from '../../action/typeDetailAction';

const mapStateToProps = (state: any) => ({
    data: state.creation.typeDetail
});

const mapDispatchToProps = {
    initPage: typeDetailAction.initPage,
    getTagId: typeDetailAction.getTagId,
    getTypeId: typeDetailAction.getTypeId,
    getDelIds: typeDetailAction.getDelIds
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TypeDetail);