import RecommendManager from '../RecommendManager';
import { connect } from 'react-redux';
import * as dashboardAction from '../../action/recommendAction';

const mapStateToProps = (state: any) => ({
    data: state.creation.dashboard
});

const mapDispatchToProps = {
    initPage: dashboardAction.initPage,
    floorListSearch: dashboardAction.floorListSearch,
    openCloseFloorLocationModal: dashboardAction.openCloseFloorLocationModal,
    updateFloorPage: dashboardAction.updateFloorPage,
    openCloseFloorModal: dashboardAction.openCloseFloorModal,
    getLocationSort: dashboardAction.getLocationSort,
    changeFloorStatus: dashboardAction.changeFloorStatus,
    delFloor: dashboardAction.delFloor,
    addFloorDataList: dashboardAction.addFloorDataList,
    editFloorDataList: dashboardAction.editFloorDataList,
    OpenCloseStarIdModal: dashboardAction.OpenCloseStarIdModal,
    OpenCloseStarNameModal: dashboardAction.OpenCloseStarNameModal,
    changeStarListSort: dashboardAction.changeStarListSort,
    changeStarStatus: dashboardAction.changeStarStatus,
    editStarName: dashboardAction.editStarName
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RecommendManager);