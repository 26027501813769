enum ChapterDetailActionTypeKey {
    INIT_PAGE =  'CHAPTER_DETAIL_INIT_PAGE',
    GET_CHAPTER_DETAIL_DATA = 'GET_CHAPTER_DETAIL_DATA',
    GET_CHAPTER_EDIT_CONTENT = 'GET_CHAPTER_DETAIL_EDIT_CONTENT',
    GET_CHAPTER_EDIT_CONTENT_FOR_EDITOR = 'GET_CHAPTER_EDIT_CONTENT_FOR_EDITOR',
    GET_CHAPTER_DETAIL_ORIGIN_DATA = 'GET_CHAPTER_DETAIL_ORIGIN_DATA',
    GET_CHAPTER_DETAIL_LOADING = 'GET_CHAPTER_DETAIL_LOADING',
    GET_CHAPTER_DETAIL_DIFF = 'GET_CHAPTER_DETAIL_DIFF',
    GET_CHAPTER_DETAIL_CHANGE = 'GET_CHAPTER_DETAIL_CHANGE'
}

export default ChapterDetailActionTypeKey;