import NovelReview  from '../NovelReview';
import { connect } from 'react-redux';
import * as novelReviewAction from '../../actions/novelReviewAction';

const mapStateToProps = (state: any) => ({
    data: state.audit.novelReview
});

const mapDispatchToProps = {
    initPage: novelReviewAction.initPage,
    getSearchData: novelReviewAction.getSearchData,
    exportModalVisible: novelReviewAction.exportModalVisible,
    submitExport: novelReviewAction.submitExport,
    getUpdatePage: novelReviewAction.getUpdatePage,
    getTabsKey: novelReviewAction.getTabsKey
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NovelReview);