
export function getExportTitles (func: any[]) {
    return func.filter(item => item.title != null && item.dataIndex != null).map(item => {
        return {
            title: item.title,
            index: item.dataIndex,
            type: item.type,
            timeZone: (item.type === 'date' || item.type === 'time' || item.type === 'datetime' || item.type === 'dd-MM-yyyy' || item.type === 'HH:mm:ss') && 'Asia/Jakarta',
        };
    });
}
// (item.type === 'date' || item.type === 'time' || item.type === 'datetime') &&