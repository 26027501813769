import BannerManager  from '../BannerManager';
import { connect } from 'react-redux';
import * as bannerAction from '../../action/bannerAction';

const mapStateToProps = (state: any) => ({
    data: state.creation.banner
});

const mapDispatchToProps = {
    initPage: bannerAction.initPage,
    bannerListSearch: bannerAction.bannerListSearch,
    addBanner: bannerAction.addBanner,
    modalVisible: bannerAction.modalVisible,
    bannerSortSubmit: bannerAction.bannerSortSubmit,
    modifyBannerStatus: bannerAction.modifyBannerStatus,
    deleteBanner: bannerAction.deleteBanner,
    sendNovelValue: bannerAction.sendNovelValue,
    exportBannerList: bannerAction.exportBannerList,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BannerManager);