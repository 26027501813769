import * as React from 'react';
import { Form, Row, Col, Input, Table, DatePicker, Tabs, Modal, Divider, Icon, Button, Badge, Spin } from 'antd';
// import * as moment from 'moment';

import { FormComponentProps } from 'antd/lib/form/Form';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { getExportTitles } from '../../../base/getExportTitles';
import { WithdrawalReviewState } from '../reducers/withdrawalReducer';
import { changeToStamp, timeReverseWithNoHour } from '../../../base/dealWithTime';

import { openNotification } from '../../widgets/Notification';
import { numFormat } from '../../../base/regular';

import '../../../style/withdrawal.css';

const FormItem = Form.Item;
const TabPane = Tabs.TabPane;

interface WithdrawalReviewProps extends FormComponentProps {
    data: WithdrawalReviewState;
    initPage: () => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getMonthlyListParam: (param: any) => void;
    getSearchData: (param: any) => void;
    getTabsKey: (key: string) => void;
    getWithdrawalId: (id: string) => void;
    getWithdrawalDetailChangeStatus: (param: any) => void;
}

interface WithdrawalInitialState {
    withdrawalId: number;
    withdrawalModal: boolean;
    rejectionModal: boolean;
    rejectionModalText: string;
    startTime: string;
    endTime: string;
    reviewerType: any[];
    imgModal: boolean;
    imgUrl: string;
    btnPermission: any[];
}

class WithdrawalReview extends React.Component<WithdrawalReviewProps, WithdrawalInitialState> {

    state: WithdrawalInitialState = {
        withdrawalId: 0,
        withdrawalModal: false,
        rejectionModal: false,
        rejectionModalText: '',
        startTime: '',
        endTime: '',
        imgModal: false,
        imgUrl: '',
        reviewerType: ['Withdrawal application', 'Passed', 'Audit failure', 'Paid'],
        btnPermission: []
    };

    componentDidMount() {
        // 获取功能权限
        const value = window.sessionStorage.getItem('btnPermission');
        let sessionBtnPermission: any =  value == null ? [] : JSON.parse(value);
        this.setState({btnPermission: sessionBtnPermission});
        this.props.initPage();
    }

    // public action

    handleExport = () => {

        this.props.exportModalVisible(true);
    }

    clear = () => {
        this.setState({startTime: ''});
        this.setState({endTime: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            gtTime: this.state.startTime.length === 0 ? undefined : changeToStamp(this.state.startTime) / 1000,
            ltTime: this.state.endTime.length === 0 ? undefined : changeToStamp(this.state.endTime) / 1000,
            authorName: value.name,
            authorId: value.id,
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }

        this.props.getSearchData(param);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        let { data } = this.props;
        let titles;
        if (data.tabsKey === '0') {
            titles = getExportTitles(this.columns());
        } else if (data.tabsKey === '1' || data.tabsKey === '2') {
            titles = getExportTitles(this.operaColumns());
        } else {
            titles = getExportTitles(this.paidColumns());
        }

        this.props.submitExport({...value, titles});
    }

    // page action

    columns = () => {
        return [{
            title: 'Serial number',
            dataIndex: 'sn',
            key: 'sn',
            className: 'column-center',
        }, {
            title: 'Author Name',
            dataIndex: 'author',
            key: 'author',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <span>{text ? text : record.userName}</span>;
            }
        }, {
            title: 'Author ID',
            dataIndex: 'authorId',
            key: 'authorId',
            className: 'column-center',
        }, {
            title: 'Withdrawal Amount',
            dataIndex: 'amount',
            key: 'amount',
            className: 'column-center',
            render: (value: number | string) => (numFormat(value))
        }, {
            title: `Cardholder's Name`,
            dataIndex: 'userBankName',
            key: 'userBankName',
            className: 'column-center',
        }, {
            title: 'Bank name',
            dataIndex: 'bankName',
            key: 'bankName',
            className: 'column-center',
        }, {
            title: 'Bank card number',
            dataIndex: 'cardNumber',
            key: 'cardNumber',
            className: 'column-center',
        }, {
            title: 'NPWP.',
            dataIndex: 'taxNo',
            className: 'column-center',
        }, {
            title: 'Star Bonus.',
            dataIndex: 'starBonus',
            className: 'column-center',
        }, {
            title: 'Third Bonus.',
            dataIndex: 'thirdBonus',
            className: 'column-center',
        },{
            title: 'REWARD.',
            dataIndex: 'rewardBonus',
            className: 'column-center',
        }, {
            title: 'PERFECT ATTENDANCE AWARD.',
            dataIndex: 'attendanceBonus',
            className: 'column-center',
        }, {
            title: 'SALES.',
            dataIndex: 'salesBonus',
            className: 'column-center',
        }, {
            title: 'Application Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            className: 'column-center',
            sorter: true,
            sortField: 'updatedAt'
        }, {
            title: 'Application Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
            className: 'column-center',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <a href="javascript:void(0)" onClick={(e: any) => {this.withdrawalModal(record); }}>view10</a>;
            }
        }];
    }

    operaColumns = () => {
        let { data } = this.props;
        let { btnPermission } = this.state;
        return [{
            title: 'Serial number',
            dataIndex: 'sn',
            key: 'sn',
            className: 'column-center',
        }, {
            title: 'Author Name',
            dataIndex: 'author',
            key: 'author',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <span>{text ? text : record.userName}</span>;
            }
        }, {
            title: 'Author ID',
            dataIndex: 'authorId',
            key: 'authorId',
            className: 'column-center',
        }, {
            title: 'Withdrawal Amount',
            dataIndex: 'amount',
            key: 'amount',
            className: 'column-center',
            render: (value: number | string) => (numFormat(value))
        }, {
            title: `Cardholder's Name`,
            dataIndex: 'userBankName',
            key: 'userBankName',
            className: 'column-center',
        }, {
            title: 'Bank name',
            dataIndex: 'bankName',
            key: 'bankName',
            className: 'column-center',
        }, {
            title: 'Bank card number',
            dataIndex: 'cardNumber',
            key: 'cardNumber',
            className: 'column-center',
        }, {
            title: 'NPWP.',
            dataIndex: 'taxNo',
            className: 'column-center',
        }, {
            title: 'Star Bonus.',
            dataIndex: 'starBonus',
            className: 'column-center',
        }, {
            title: 'Third Bonus.',
            dataIndex: 'thirdBonus',
            className: 'column-center',
        }, {
            title: 'REWARD.',
            dataIndex: 'rewardBonus',
            className: 'column-center',
        }, {
            title: 'PERFECT ATTENDANCE AWARD.',
            dataIndex: 'attendanceBonus',
            className: 'column-center',
        }, {
            title: 'SALES.',
            dataIndex: 'salesBonus',
            className: 'column-center',
        }, {
            title: 'Review Date',
            dataIndex: 'operatedDate',
            key: 'operatedDate',
            className: 'column-center',
            sorter: true,
            sortField: 'operatedAt'
        }, {
            title: 'Review Time',
            dataIndex: 'operatedTime',
            key: 'operatedTime',
            className: 'column-center',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any) => {
                return (
                    <div>
                        {
                            data.tabsKey === '1' ?
                                <div>
                                    <a href="javascript:void(0)" style={{marginRight: '15px'}} onClick={(e: any) => {this.withdrawalModal(record); }}>view11</a>
                                    {
                                        btnPermission.indexOf('withdrawal-review-extra') >= 0 &&
                                        <a href="javascript:void(0)" onClick={(e: any) => {this.paidOrder(record); }}>paid</a>
                                    }
                                </div>
                                :
                                <a href="javascript:void(0)" onClick={(e: any) => {this.withdrawalModal(record); }}>view12</a>
                        }
                    </div>
                );
            }
        }];
    }

    paidColumns = () => {
        return [{
            title: 'Serial number',
            dataIndex: 'sn',
            key: 'sn',
            className: 'column-center',
        }, {
            title: 'Author Name',
            dataIndex: 'author',
            key: 'author',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <span>{text ? text : record.userName}</span>;
            }
        }, {
            title: 'Author ID',
            dataIndex: 'authorId',
            key: 'authorId',
            className: 'column-center',
        }, {
            title: 'Withdrawal Amount',
            dataIndex: 'amount',
            key: 'amount',
            className: 'column-center',
            render: (value: number | string) => (numFormat(value))
        }, {
            title: `Cardholder's Name`,
            dataIndex: 'userBankName',
            key: 'userBankName',
            className: 'column-center',
        }, {
            title: 'Bank name',
            dataIndex: 'bankName',
            key: 'bankName',
            className: 'column-center',
        }, {
            title: 'Bank card number',
            dataIndex: 'cardNumber',
            key: 'cardNumber',
            className: 'column-center',
        }, {
            title: 'NPWP.',
            dataIndex: 'taxNo',
            className: 'column-center',
        }, {
            title: 'Star Bonus.',
            dataIndex: 'starBonus',
            className: 'column-center',
        }, {
            title: 'Third Bonus.',
            dataIndex: 'thirdBonus',
            className: 'column-center',
        }, {
            title: 'REWARD.',
            dataIndex: 'rewardBonus',
            className: 'column-center',
        }, {
            title: 'PERFECT ATTENDANCE AWARD.',
            dataIndex: 'attendanceBonus',
            className: 'column-center',
        }, {
            title: 'SALES.',
            dataIndex: 'salesBonus',
            className: 'column-center',
        }, {
            title: 'Payment Date',
            dataIndex: 'operatedDate',
            key: 'operatedDate',
            className: 'column-center',
            sorter: true,
            sortField: 'operatedAt'
        }, {
            title: 'Payment Time',
            dataIndex: 'operatedTime',
            key: 'operatedTime',
            className: 'column-center',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <a href="javascript:void(0)" onClick={(e: any) => {this.withdrawalModal(record); }}>view13</a>;
            }
        }];
    }

    tabsChange = (key: string) => {
        this.clear();
        this.props.getTabsKey(key);
    }

    imgModalShow = (e: any) => {
        this.setState({imgModal: true, imgUrl: e.target.getAttribute('data-src')});
    }

    imgModalOk = () => {
        this.imgModalCancel();
    }

    imgModalCancel = () => {
        this.setState({imgModal: false, imgUrl: ''});
    }

    paidOrder = (record: any) => {
        this.setState({withdrawalId: record.id}, () => {
            this.changeStatus('3');
        });
    }

    release = () => {
        this.changeStatus('1');
        this.withdrawalModalCancel();
    }

    withdrawalModal = (record: any) => {
        this.setState({withdrawalModal: true, withdrawalId: record.id});
        this.props.getWithdrawalId(record.id);
    }

    withdrawalModalOk = () => {
        this.withdrawalModalCancel();
    }

    withdrawalModalCancel = () => {
        this.setState({withdrawalModal: false});
    }

    rejectionModal = () => {
        this.setState({ rejectionModal: true, rejectionModalText: '' });
    }

    rejectionModalOk = () => {
        if (this.state.rejectionModalText === '') {
            openNotification('error', 'Please enter the reason for the rejection');
        } else {
            this.changeStatus('2');
            this.rejectionModalCancel();
            this.withdrawalModalCancel();
        }
    }

    rejectionModalCancel = () => {
        this.setState({ rejectionModal: false });
    }

    rejectionModalTextChange = (e: any) => {
        if (e.target.value.length > 200) {
            openNotification('error', 'Reason for rejection cannot exceed 200 characters');
        } else {
            this.setState({rejectionModalText: e.target.value});
        }
    }

    changeStatus = (status: string) => {
        const { withdrawalId, rejectionModalText } = this.state;
        let param = {
            id: withdrawalId,
            status: status,
            message: rejectionModalText
        };

        this.props.getWithdrawalDetailChangeStatus(param);
    }

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: timeReverseWithNoHour(startTime) + ' 00:00:00'});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: timeReverseWithNoHour(endTime) + ' 23:59:59'});
        } else {
            this.setState({endTime});
        }
    }

    render() {

        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const input = {
            style: {width: '100%', color: '#000'}
        };
        const colBottom = {
            style: {marginBottom: '15px'}
        };
        const { data, getUpdatePage } = this.props;
        const { withdrawalModal, rejectionModal, rejectionModalText, imgModal, imgUrl, btnPermission } = this.state;
        let withdrawDetail = data.withdrawalDetail.withdrawDetail;

        return (
            <div>

                <Divider>Withdrawal Message</Divider>
                <Row>
                    <Col>
                        <Row>
                            <Col {...colBottom} span={6}>
                                <Badge
                                    status="default"
                                    text={`Amount to be audited: ${data.withdrawalTotal.pendingAmount ? numFormat(data.withdrawalTotal.pendingAmount) : 0}`}
                                />
                            </Col>
                            <Col {...colBottom} span={6}>
                                <Badge
                                    status="success"
                                    text={`Pass amount: ${data.withdrawalTotal.checkPassedAmount ? numFormat(data.withdrawalTotal.checkPassedAmount) : 0}`}
                                />
                            </Col>
                            <Col {...colBottom} span={6}>
                                <Badge
                                    status="error"
                                    text={`Rejection amount: ${data.withdrawalTotal.auditRejectionAmount ? numFormat(data.withdrawalTotal.auditRejectionAmount) : 0}`}
                                />
                            </Col>
                            <Col {...colBottom} span={6}>
                                <Badge
                                    status="success"
                                    text={`Paid amount: ${data.withdrawalTotal.paidAmount ? numFormat(data.withdrawalTotal.paidAmount) : 0}`}
                                />
                            </Col>
                            <Col {...colBottom} span={6}>
                                <Badge
                                    status="default"
                                    text={`Number of people reviewed: ${data.withdrawalTotal.pendingPeople ? numFormat(data.withdrawalTotal.pendingPeople) : 0}`}
                                />
                            </Col>
                            <Col {...colBottom} span={6}>
                                <Badge
                                    status="success"
                                    text={`Number of people passing: ${data.withdrawalTotal.checkPassedPeople ? numFormat(data.withdrawalTotal.checkPassedPeople) : 0}`}
                                />
                            </Col>
                            <Col {...colBottom} span={6}>
                                <Badge
                                    status="error"
                                    text={`Number of refusals: ${data.withdrawalTotal.auditRejectionPeople  ? numFormat(data.withdrawalTotal.auditRejectionPeople) : 0}`}
                                />
                            </Col>
                            <Col {...colBottom} span={6}>
                                <Badge
                                    status="success"
                                    text={`Number of people paid: ${data.withdrawalTotal.paidPeople ? numFormat(data.withdrawalTotal.paidPeople) : 0}`}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider>Table Message</Divider>
                <Form>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="Date">
                                {getFieldDecorator('startTime')(
                                    <DatePicker
                                        allowClear={true}
                                        format={'DD-MM-YYYY'}
                                        placeholder={'Start Time'}
                                        style={{width : '48%'}}
                                        onChange={this.startTimeChange}
                                    />
                                )}
                                {getFieldDecorator('endTime')(
                                    <DatePicker
                                        allowClear={true}
                                        format={'DD-MM-YYYY'}
                                        placeholder={'End Time'}
                                        style={{width : '48%', marginLeft : '4%'}}
                                        onChange={this.endTimeChange}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Author Name"
                            >
                                {getFieldDecorator('name')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Author ID"
                            >
                                {getFieldDecorator('id')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>

                <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport} />

                <Tabs defaultActiveKey="review" onChange={this.tabsChange}>
                    <TabPane tab="Withdrawal application" key="0">
                        <p><span style={{marginRight: '5px'}}>Withdrawal application:</span>{data.total}</p>
                        <Table
                            rowKey="id"
                            loading={data.loading}
                            bordered={true}
                            columns={this.columns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.withdrawalList}
                            onChange={getUpdatePage}
                            scroll={{ x: 2000 }}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Passed" key="1">
                        <p><span style={{marginRight: '5px'}}>Passed:</span>{data.total}</p>
                        <Table
                            rowKey="id"
                            loading={data.loading}
                            bordered={true}
                            columns={this.operaColumns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.withdrawalList}
                            onChange={getUpdatePage}
                            scroll={{ x: 2000 }}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Audit failure" key="2">
                        <p><span style={{marginRight: '5px'}}>Audit failure:</span>{data.total}</p>
                        <Table
                            rowKey="id"
                            loading={data.loading}
                            bordered={true}
                            columns={this.operaColumns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.withdrawalList}
                            onChange={getUpdatePage}
                            scroll={{ x: 2000 }}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Paid" key="3">
                        <p><span style={{marginRight: '5px'}}>Paid:</span>{data.total}</p>
                        <Table
                            rowKey="id"
                            loading={data.loading}
                            bordered={true}
                            columns={this.paidColumns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.withdrawalList}
                            onChange={getUpdatePage}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                </Tabs>

                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />

                <Modal
                    title="Withdrawal Order"
                    visible={withdrawalModal}
                    onOk={this.withdrawalModalOk}
                    onCancel={this.withdrawalModalCancel}
                    width={'50%'}
                    footer={null}
                    maskClosable={false}
                >
                    <Spin spinning={data.loading}>
                        {data.tabsKey === '1' &&
                        <div style={{textAlign: 'center'}}>
                            <Icon type="check-circle" style={{color: 'green', fontSize: '80px'}} />
                            <p style={{marginTop: '15px', fontSize: '26px'}}>Withdrawal Passed</p>
                        </div>}
                        {data.tabsKey === '2' &&
                        <div style={{textAlign: 'center'}}>
                            <Icon type="close-circle" style={{color: 'red', fontSize: '80px'}} />
                            <p style={{marginTop: '15px', fontSize: '26px'}}>Withdrawal Failure</p>
                        </div>}
                        <Row>
                            <Col>
                                <Divider>User  Basic Information</Divider>
                                <Row className="withdrawal_msg">
                                    <Col style={{fontWeight: 'bold', marginBottom: '15px'}}>
                                        <span className="withdrawal_msg-title">{data.withdrawalDetail.id}th</span>Withdrawal application
                                    </Col>
                                    <Col span={11}>
                                        <span className="withdrawal_msg-title">Author ID: </span>
                                        <span>{data.withdrawalDetail.authorId || '--'}</span>
                                    </Col>
                                    <Col span={11} offset={1}>
                                        <span className="withdrawal_msg-title">Author Name: </span>
                                        <span>{(data.withdrawalDetail.penName || data.withdrawalDetail.userName) || '--'}</span></Col>
                                    <Col span={11}>
                                        <span className="withdrawal_msg-title">Real Name: </span>
                                        <span>{data.withdrawalDetail.givenName}  {data.withdrawalDetail.familyName}</span>
                                    </Col>
                                    <Col span={11} offset={1}>
                                        <span className="withdrawal_msg-title">identity number :</span>
                                        <span>{data.withdrawalDetail.cardNumber  || '--'}</span>
                                    </Col>
                                    <Col span={11}>
                                        <span className="withdrawal_msg-title">Account Status :</span>
                                        <span>{data.withdrawalDetail.enabled === '1' ? 'Enable' : 'Disable'}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Divider>Withdrawal information</Divider>
                                <Row className="withdrawal_msg">
                                    <Col>
                                        <span className="withdrawal_msg-title">Transaction number:</span>
                                        <span>{data.withdrawalDetail.sn || '--'}</span>
                                    </Col>
                                    <Col span={11}>
                                        <span className="withdrawal_msg-title">Name:</span>
                                        <span>{data.withdrawalDetail.userBankName || '--'}</span>
                                    </Col>
                                    <Col span={11}  offset={1}>
                                        <span className="withdrawal_msg-title">Bank:</span>
                                        <span>{data.withdrawalDetail.bankName || '--'}</span>
                                    </Col>
                                    <Col span={11} >
                                        <span className="withdrawal_msg-title">Call:</span>
                                        <span>{data.withdrawalDetail.bankTitle || '--'}</span>
                                    </Col>
                                    <Col span={11} offset={1}>
                                        <span className="withdrawal_msg-title">Bank card:</span>
                                        <span>{data.withdrawalDetail.bankCardNumber || '--'}</span>
                                    </Col>
                                    <Col span={11} >
                                        <span className="withdrawal_msg-title">Nomer NPWP:</span>
                                        <span>{data.withdrawalDetail.bankTaskCardNo || '--'}</span>
                                    </Col>
                                    <Col span={11} offset={1}>
                                        <span className="withdrawal_msg-title">Foto NPWP:</span>
                                        <span>{data.withdrawalDetail.bankTaskCardPic ?
                                            <i
                                                data-src={data.withdrawalDetail.bankTaskCardPic}
                                                style={{fontStyle: 'normal', color: '#1890ff', cursor: 'pointer'}}
                                                onClick={(e: any) => {this.imgModalShow(e); }}
                                            >
                                                See
                                            </i>
                                            : '--'}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Divider />
                                <Row className="withdrawal_table">
                                    <Col>
                                        <Row>
                                            <Col span={4}>
                                                Bonus
                                            </Col>
                                            <Col span={4}>
                                                Withdrawal Amount
                                            </Col>
                                            <Col span={4}>
                                                Tax rate
                                            </Col>
                                            <Col span={4}>
                                                Taxes
                                            </Col>
                                            <Col span={4}>
                                                Handling fee
                                            </Col>
                                            <Col span={4}>
                                                Actual amount
                                            </Col>
                                        </Row>
                                    </Col>

                                    {
                                        withdrawDetail && withdrawDetail.map((o: any, i: number) => {
                                            return (
                                                <Col key={i}>
                                                    <Row className="withdrawal_table">
                                                        <Col span={4}>
                                                            {o.source === 0 && 'Star bonus'}
                                                            {o.source === 1 && 'Reward'}
                                                            {o.source === 2 && 'Perfect Attendance Award'}
                                                            {o.source === 3 && 'Sales'}
                                                            {o.source === 4 && 'Third'}
                                                        </Col>
                                                        <Col span={4}>
                                                            {numFormat(o.totalAmount)}
                                                        </Col>
                                                        <Col span={4}>
                                                            {o.taxRate * 100}%
                                                        </Col>
                                                        <Col span={4}>
                                                            {numFormat(o.taxFee) || '--'}
                                                        </Col>
                                                        <Col span={4}>--</Col>
                                                        <Col span={4}>--</Col>
                                                    </Row>
                                                </Col>
                                            );
                                        })
                                    }
                                    <Col>
                                        <Divider />
                                        <Row>
                                            <Col span={4}>
                                                Total
                                            </Col>
                                            <Col span={4}>
                                                {numFormat(data.withdrawalDetail.amount)}
                                            </Col>
                                            <Col span={4}>--</Col>
                                            <Col span={4}>
                                                {numFormat(data.withdrawalDetail.taxes)}
                                            </Col>
                                            <Col span={4}>
                                                {numFormat(data.withdrawalDetail.charges)}
                                            </Col>
                                            <Col span={4}>
                                                {numFormat(data.withdrawalDetail.realAmount)}
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Spin>
                    {data.tabsKey === '0' &&  <Row>
                        <Col span={7} offset={17}>
                            {
                                btnPermission.indexOf('withdrawal-review-extra') >= 0 &&
                                <Button style={{marginRight: '10px'}}  onClick={this.release}>Release</Button>
                            }
                            {
                                btnPermission.indexOf('withdrawal-review-extra') >= 0 &&
                                <Button type="danger" onClick={this.rejectionModal}>Confuse</Button>
                            }
                        </Col>
                    </Row>}

                </Modal>
                <Modal
                    title="Reason for the rejection"
                    visible={rejectionModal}
                    onOk={this.rejectionModalOk}
                    onCancel={this.rejectionModalCancel}
                    maskClosable={false}
                >
                    <Input value={rejectionModalText} onChange={this.rejectionModalTextChange} placeholder="Please enter the reason for the rejection"/>
                </Modal>
                <Modal
                    title="Large Picture"
                    visible={imgModal}
                    onOk={this.imgModalOk}
                    onCancel={this.imgModalCancel}
                >
                    <img style={{width: '400px'}} src={imgUrl} alt=""/>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(WithdrawalReview);
