enum  novelTotalDataTypeKey {
    INIT_PAGE = 'NOVEL_TOTAL_INIT_PAGE',
    LOADING = 'NOVEL_TOTAL_LOADING',
    UPDATE_PAGE = 'NOVEL_TOTAL_UPDATE_PAGE',
    SEARCH_DATA = 'NOVEL_TOTAL_SEARCH_DATA',
    EXPORT_MODAL_VISIBLE = 'NOEL_TOTAL_EXPORT_MODAL_VISIBLE',
    SUBMIT_NOVEL_TOTAL_EXPORT = 'NOEL_TOTAL_SUBMIT_NOVEL_TOTAL_EXPORT',
    GET_TYPE_DATA_LIST = 'NOVEL_TOTAL_GET_TYPE_DATA_LIST',
    GET_TOTAL_DATE = 'NOVEL_TOTAL_GET_TOTAL_LIST'
}
export default novelTotalDataTypeKey;