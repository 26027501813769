import ChapterManager  from '../ChapterManager';
import { connect } from 'react-redux';
import * as chapterAction from '../../actions/chapterManagerAction';
import * as novelAction from '../../actions/novelManagerAction';

const mapStateToProps = (state: any) => ({
    data: state.content.chapterManager
});

const mapDispatchToProps = {
    initPage: chapterAction.initPage,
    getNovelId: chapterAction.getNovelId,
    uploadOpen: chapterAction.uploadOpen,
    getChapterList: chapterAction.getChapterList,
    getChapterSearchParam: chapterAction.getChapterSearchParam,
    getDelChapterId: chapterAction.getDelChapterId,
    getChapterTableChange: chapterAction.getChapterTableChange,
    chapterSortChange: chapterAction.chapterSortChange,
    btnLoading: chapterAction.btnLoading,
    getChapterId: chapterAction.getChapterId,
    updateChapterList: chapterAction.updateChapterList,
    changeNovelReviewStatus: chapterAction.changeNovelReviewStatus,
    downloadCover: chapterAction.downloadCover,
    paidModal: chapterAction.paidModal,
    getIgnoreBtn: chapterAction.getIgnoreBtn,
    showPaidButton: chapterAction.showPaidButton,
    getPaidSetting: chapterAction.getPaidSetting,
    downloadNovel: chapterAction.downloadNovel,
    isSettingOpen: chapterAction.isSettingOpen,
    getSettingSort: chapterAction.getSettingSort,
    getMinRatio: chapterAction.getMinRatio,
    getMaxRatio: chapterAction.getMaxRatio,
    SettingModalCancel: chapterAction.SettingModalCancel,
    getSettingMsg: chapterAction.getSettingMsg,
    getSettingName: chapterAction.getSettingName,
    getPaidChapterSettingMsg: chapterAction.getPaidChapterSettingMsg,
    goNovelList: novelAction.goNovelList,
    goNovelDetail: novelAction.goNovelDetail,

};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ChapterManager);