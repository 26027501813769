import helpDetailManagerKeys from './actionsTypeKey/helpDetailActionTypeKey';

export function initPage(id: number | string) {
    return { type: helpDetailManagerKeys.INIT_PAGE, payload: id };
}

export function isLoading(bol: boolean) {
    return { type: helpDetailManagerKeys.LOADING, payload: bol };
}

export function openCloseQuestionModal(bol: boolean) {
    return { type: helpDetailManagerKeys.OPEN_CLOSE_QUESTION_MODAL, payload: bol };
}

export function openCloseIdModal(bol: boolean) {
    return { type: helpDetailManagerKeys.OPEN_CLOSE_ID_MODAL, payload: bol };
}

export function getHelpModalList(param: any) {
    return { type: helpDetailManagerKeys.GET_MODAL_LIST, payload: param };
}

export function getQuestionList(list: any) {
    return { type: helpDetailManagerKeys.GET_QUESTION_LIST, payload: list };
}

export function getChangeModalQuestion(id: number | string) {
    return { type: helpDetailManagerKeys.CHANGE_MODAL_QUESTION, payload: id };
}

export function getAddQuestionMsg(param: any) {
    return { type: helpDetailManagerKeys.ADD_QUESTION, payload: param };
}

export function getEditQuestionMsg(param: any) {
    return { type: helpDetailManagerKeys.EDIT_QUESTION, payload: param };
}

export function getDelQuestionMsg(param: any) {
    return { type: helpDetailManagerKeys.DEL_QUESTION, payload: param };
}

export function getChangeQuestionSortMsg(param: any) {
    return { type: helpDetailManagerKeys.GET_CHANGE_QUESTION_SORT_MSG, payload: param };
}