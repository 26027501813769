enum NovelActionTypeKeys {
    INIT_PAGE = 'NOVEL_CONTENT_INIT_PAGE',
    LOADING = 'NOVEL_CONTENT_LOADING',
    GET_NOVEL_CONTENT_LIST = 'GET_NOVEL_CONTENT_LIST',
    LIST_DATA_SEARCH = 'NOVEL_CONTENT_LIST_DATA_SEARCH',
    DOWNLOAD_NOVEL = 'DOWNLOAD_NOVEL',
    DUMP_NOVEL = 'DUMP_NOVEL',
    GET_BRIEF_CONTENT_LIST = 'GET_BRIEF_CONTENT_LIST_CONTENT',
    SEND_BRIEF_ID = 'SEND_BRIEF_ID',
    BRIEF_MODAL_VISIBLE = 'BRIEF_MODAL_VISIBLE',
    DUMP_MODAL_VISIBLE = 'DUMP_MODAL_VISIBLE_NOVEL_CONTENT',
    SPIDER_START = 'SPIDER_START_NOVEL',
    GET_NOVEL_LIST_BY_NAME = 'GET_NOVEL_LIST_BY_NAME_NOVEL',
    GET_ALL_NOVEL_LIST_BY_NAME = 'GET_ALL_NOVEL_LIST_BY_NAME_NOVEL',
    SEND_NOVEL_VALUE = 'SEND_NOVEL_VALUE_NOVEL',
    CHECK_SPIDER_STATUS = 'CHECK_SPIDER_STATUS',
    UPDATE_PAGE = 'RESOURCE_UPDATE_PAGE',
}

export default NovelActionTypeKeys;