import activityKey from './actionsTypeKey/activityActionsTypeKey';

export function initPage() {
    return { type: activityKey.INIT_PAGE };
}

export function getVoteList(list: any) {
    return { type: activityKey.GET_ACTIVITY_LIST, payload: list };
}

export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: activityKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: activityKey.EXPORT_MODAL_VISIBLE, payload: value};
}

export function submitExport(value: any) {
    return { type: activityKey.SUBMIT_EXPORT, payload: value };
}

export function getDelId(id: number) {
    return { type: activityKey.GET_DEL_ID, payload: id };
}

export function getVirtualVote(ticket: number) {
    return { type: activityKey.GET_VIRTUAL_VOTE, payload: ticket };
}

export function getSearchParam(param: any) {
    return { type: activityKey.GET_SEARCH_DATA, payload: param };
}

export function activityLoading(value: boolean) {
    return { type: activityKey.LOADING, payload: value };
}

export function getAddBtn() {
    return { type: activityKey.ADD_BTN };
}

export function getTypeList(list: any[]) {
    return { type: activityKey.GET_TYPE_LIST, payload: list };
}

export function getSearchNovelParam(value: any) {
    return { type: activityKey.GET_SEARCH_NOVEL_PARAM, payload: value };
}

export function getNovelList(list: any[]) {
    return { type: activityKey.GET_NOVEL_LIST, payload: list };
}

export function getAddNovelId(id: number) {
    return { type: activityKey.GET_ADD_NOVEL_ID, payload: id };
}