import WebActionsTypeKey from './actionsTypeKey/webManagerActionsTypeKey';

export function initPage() {
    return { type: WebActionsTypeKey.INIT_PAGE };
}

export function getDataList(value: any) {
    return { type: WebActionsTypeKey.GET_DATA_LIST, payload: value };
}

export function listDataSearch(value: any) {
    return { type: WebActionsTypeKey.LIST_DATA_SEARCH, payload: value };
}

export function deleteDataList(value: any) {
    return { type: WebActionsTypeKey.DELETE_DATA_LIST, payload: value };
}

export function updatePage(value: any, filters: any, sorter: any) {
    return { type: WebActionsTypeKey.UPDATE_PAGE, payload: {value, sorter} };
}

export function loading(value: boolean) {
    return { type: WebActionsTypeKey.LOADING, payload: value };
}

export function exportModalVisible(value: boolean) {
    return { type: WebActionsTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: WebActionsTypeKey.SUBMIT_EXPORT, payload: value };
}

export function changeListTop(id: number) {
    return { type: WebActionsTypeKey.CHANGE_LIST_TOP, payload: id};
}