import IdentityVerification  from '../IdentityVerification';
import { connect } from 'react-redux';
import * as identityAction from '../../actions/identityAction';

const mapStateToProps = (state: any) => ({
    data: state.audit.identity
});

const mapDispatchToProps = {
    initPage: identityAction.initPage,
    getSearchParam: identityAction.getSearchParam,
    exportModalVisible: identityAction.exportModalVisible,
    submitExport: identityAction.submitExport,
    getUpdatePage: identityAction.getUpdatePage,
    getTabsKey: identityAction.getTabsKey,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IdentityVerification);