import { combineReducers } from 'redux';
import { blockWordReducer } from './blockWordReducers';
import { adBannerReducer } from './adBannerReducers';
import { webManagerReducer } from './webManagerReducer';
import { addWebManagerReducer } from './addWebManagerReducer';
import { webHelpCenterReducers } from './helpCenterReducers';
import { webHelpDetailReducers } from './helpDetailReducers';

export default combineReducers({
    blockWordData: blockWordReducer,
    adBannerData: adBannerReducer,
    webManager: webManagerReducer,
    addWebManager: addWebManagerReducer,
    webHelpCenter: webHelpCenterReducers,
    webHelpDetail: webHelpDetailReducers
});