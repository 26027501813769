import { fork, all, takeEvery, select, put, call, take } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import reportKey from '../../components/audit/actions/actionsTypeKey/reportManagerypeKey';
import * as reportAction from '../../components/audit/actions/reportManagerAction';
import * as reportServer from '../../servers/reportServer';
import { getChapterCon } from '../../servers/chapterServer';
import { getAllSensitive } from '../../servers/blockWordServer';
import { openNotification } from '../../components/widgets/Notification';

import { getImageUrl } from '../../servers/ossServer';

function* initPage() {
    yield getReportList();
}

function* getReportList() {
    const data = yield select();
    const manager = data.audit.reportManager;
    const param = {
        ...manager.page,
        ...manager.sorter,
        'data': {...manager.searchData},
    };

    param.data.status = manager.tabsKey;
    yield put(reportAction.getReportList([]));
    try {
        yield put(reportAction.loading(true));
        const { result } = yield call(reportServer.getReportList, param);
        yield put(reportAction.getReportList(result));
        yield put(reportAction.loading(false));
    } catch (err) {
        yield put(reportAction.loading(false));
        openNotification('error', 'Failed to get list.');
    }
}

function* exportModel() {
    while (true) {
        const { payload } = yield take(reportKey.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);

        const state = yield select();
        const manager = state.audit.reportManager;

        payload.titles.map((value: any) => {
            if (value.index === 'reportType') {
                value.index = 'reportTypeExport';
            }

            if (value.index === 'novelType') {
                value.index = 'novelTypeExport';
            }
        });

        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            exportName: '',
            ...manager.sorter,
            data: {...manager.searchData},
        };

        param.data.status = manager.tabsKey;

        if (manager.tabsKey === '1') {
            param.exportName = 'reportPendingReview.xlsx';
        } else if (manager.tabsKey === '2') {
            param.exportName = 'reportLocked.xlsx';
        } else {
            param.exportName = 'reportLgnored.xlsx';
        }

        try {
            yield put(reportAction.loading(true));
            yield call(reportServer.exportList, param);
            yield put(reportAction.loading(false));
            openNotification('success', 'Export success.');
            yield put(reportAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(reportAction.loading(false));
        }
    }
}

function* getReportChapterCon() {
    while (true) {
        const { payload } = yield take(reportKey.GET_CHAPTER_CON_URL);
        const url = yield getImageUrl(payload);
        try {
            const { data } = yield call(getChapterCon, url);
            const { result } = yield call(getAllSensitive);

            let content = `${data}`.replace(/\r\n/g, '</br>').replace(/\n/g, '</br>');

            if (payload.key) {
                for (const field of result) {
                    content = content.replace(new RegExp(field, 'g'), `<span style="color: red">${field}</span>`);
                }
            }

            yield put(reportAction.getChapterText(content));

        } catch (err) {
            openNotification('error', 'Failed to get Chapter Con.');
        }
    }
}

function* changeReportDetailStatus() {
    while (true) {
        const { payload } = yield take(reportKey.GET_CHAPTER_STATUS_CHANGE);
        try {
            yield call(reportServer.modifyReportStatus, payload);
            payload.status === '2' ?
                openNotification('success', 'Success to lock')
                :
                openNotification('success', 'Success to Ignore');
            yield delay(2000);
            yield put(reportAction.reportDetailToList(true));
            yield delay(1000);
            yield put(reportAction.reportDetailToList(false));
        } catch (err) {
            payload.status === '2' ?
                openNotification('error', 'Failed to lock')
                :
                openNotification('error', 'Failed to Ignore');

        }

    }
}

function* watchSearchData() {
    yield takeEvery(reportKey.GET_SEARCH_DATA, getReportList);
}

function* watchChangeTabsKey() {
    yield takeEvery(reportKey.GET_REPORT_TABS_KEY, getReportList);
}

function* watchInitPage() {
    yield takeEvery(reportKey.INIT_PAGE, initPage);
}

function* watchUpdatePage() {
    yield takeEvery(reportKey.UPDATE_PAGE, getReportList);
}

export function* watchReportSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchChangeTabsKey),
        fork(watchSearchData),
        fork(getReportChapterCon),
        fork(changeReportDetailStatus),
        fork(watchUpdatePage),
        fork(exportModel),
    ]);
}