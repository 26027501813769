enum  novelUpdateDataTypeKey {
    INIT_PAGE = 'NOVEL_UPDATE_INIT_PAGE',
    LOADING = 'NOVEL_UPDATE_LOADING',
    UPDATE_PAGE = 'NOVEL_UPDATE_UPDATE_PAGE',
    SEARCH_DATA = 'NOVEL_UPDATE_SEARCH_DATA',
    EXPORT_MODAL_VISIBLE = 'NOEL_UPDATE_EXPORT_MODAL_VISIBLE',
    SUBMIT_NOVEL_UPDATE_EXPORT = 'NOEL_UPDATE_SUBMIT_NOVEL_UPDATE_EXPORT',
    GET_TYPE_DATA_LIST = 'NOVEL_UPDATE_GET_TYPE_DATA_LIST',
    GET_UPDATE_DATE = 'NOVEL_UPDATE_GET_UPDATE_LIST'
}
export default novelUpdateDataTypeKey;