import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Row, Col, Select, Input, Table, DatePicker, Tabs } from 'antd';
// import * as moment from 'moment';

import { FormComponentProps } from 'antd/lib/form/Form';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { getExportTitles } from '../../../base/getExportTitles';
import { NovelReviewState } from '../reducers/novelReviewReducer';
import { changeToStamp, timeReverseWithNoHour, changeStampToDate } from '../../../base/dealWithTime';

const FormItem = Form.Item;
const Option = Select.Option;
const TabPane = Tabs.TabPane;

interface NovelReviewProps extends FormComponentProps {
    data: NovelReviewState;
    initPage: () => void;
    getSearchData: (param: any) => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getTabsKey: (key: string) => void;
}

interface NovelInitialState {
    startTime: string;
    endTime: string;
    novelType: any[];
    btnPermission: any[];
}

class NovelReview extends React.Component<NovelReviewProps, NovelInitialState> {

    state: NovelInitialState = {
        startTime: '',
        endTime: '',
        novelType: ['Original', 'Reprint', 'PGC', 'Star Novel'],
        btnPermission: []
    };

    componentWillMount() {
        // 获取功能权限
        const value = window.sessionStorage.getItem('btnPermission');
        let sessionBtnPermission: any =  value == null ? [] : JSON.parse(value);
        this.setState({btnPermission: sessionBtnPermission});
    }

    componentDidMount() {
        this.props.initPage();
    }

    // public action

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    clear = () => {
        this.setState({startTime: ''});
        this.setState({endTime: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            gtTime: this.state.startTime.length === 0 ? undefined : changeToStamp(this.state.startTime) / 1000,
            ltTime: this.state.endTime.length === 0 ? undefined : changeToStamp(this.state.endTime) / 1000,
            typeId: value.typeId,
            novelName: value.name,
            novelId: value.id,
            novelType: value.novelType,
            signed: value.signed
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.getSearchData(param);
    }

    columns = () => {
        const { data } = this.props;
        return [{
            title: 'Novel Id',
            dataIndex: 'novelId',
            key: 'novelId',
            className: 'column-center',
        }, {
            title: 'Novel Name',
            dataIndex: 'novelName',
            key: 'novelName',
            className: 'column-center',
        }, {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            className: 'column-center',
        }, {
            title: 'Category',
            dataIndex: 'typeName',
            key: 'typeName',
            className: 'column-center',
        }, {
            title: 'Chapter Number',
            dataIndex: 'chapterCount',
            key: 'chapterCount',
            className: 'column-center',
        }, {
            title: 'Type',
            dataIndex: 'novelType',
            key: 'novelType',
            className: 'column-center',
            render: (text: string) => {
                return <span>{this.state.novelType[text]}</span>;
            }
        }, {
            title: 'First Commit',
            dataIndex: 'firstCommit',
            className: 'column-center',
            render: (text) => {
                return text ? 'Yes' : 'No'
            }
        }, {
            title: 'Signing',
            dataIndex: 'signed',
            key: 'signed',
            className: 'column-center',
            render: (text: number | string, record: any) => {
                if (record.novelType === 3) {
                    return text ? 'Kontrak tertulis' : 'Kontrak digital';
                } else {
                    return '--';
                }
            }

        }, {
            title: 'Review Date',
            dataIndex: 'lastCheckAt',
            key: 'lastCheckAtDate',
            className: 'column-center',
            sorter: true,
            sortField: 'lastCheckAt',
            type: 'dd-MM-yyyy',
            render: (text: string) => {
                return <span>{changeStampToDate(text, 'DD-MM-YYYY')}</span>;
            }
        }, {
            title: 'Review Time',
            dataIndex: 'lastCheckAt',
            key: 'lastCheckAtTime',
            className: 'column-center',
            type: 'HH:mm:ss',
            render: (text: string) => {
                return <span>{changeStampToDate(text, 'HH:mm:ss')}</span>;
            }
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <Link to={{pathname: '/content/chapterManager', search: `?id=${record.novelId}&key=${data.tabsKey}` }}>view20</Link>;
            }
        }];
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    // page action
    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: timeReverseWithNoHour(startTime) + ' 00:00:00'});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: timeReverseWithNoHour(endTime) + ' 23:59:59'});
        } else {
            this.setState({endTime});
        }
    }

    tabsChange = (key: string) => {
        this.clear();
        this.props.getTabsKey(key);
    }

    render() {

        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const input = {
            style: {width: '100%', color: '#000'}
        };
        const { data, getUpdatePage } = this.props;

        const { btnPermission } = this.state;

        const worksTypeList = [
            {key: 'All', value: 'All'},
            {key: '0', value: 'Original'},
            {key: '1', value: 'Reprint'},
            {key: '2', value: 'PGC'},
            {key: '3', value: 'Star Novel'},
        ];

        const signingList = [
            {key: 'All', value: 'All'},
            {key: '1', value: 'Kontrak tertulis'},
            {key: '0', value: 'Kontrak digital'}
        ];

        return (
            <div>
                <Form>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="Date">
                                {getFieldDecorator('startTime')(
                                    <DatePicker
                                        allowClear={true}
                                        format={'DD-MM-YYYY'}
                                        placeholder={'Start Time'}
                                        style={{width : '48%'}}
                                        onChange={this.startTimeChange}
                                    />
                                )}
                                {getFieldDecorator('endTime')(
                                    <DatePicker
                                        allowClear={true}
                                        format={'DD-MM-YYYY'}
                                        placeholder={'End Time'}
                                        style={{width : '48%', marginLeft : '4%'}}
                                        onChange={this.endTimeChange}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Category">
                                {getFieldDecorator('typeId', {
                                    initialValue: 'All'
                                })(
                                    <Select {...input}>
                                        <Option key="All">All</Option>
                                        {data.novelTypeList.map(item => <Option key={item.id}>{item.name}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Novel Name"
                            >
                                {getFieldDecorator('name')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Novel ID"
                            >
                                {getFieldDecorator('id')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Type"
                            >
                                {getFieldDecorator('novelType', {
                                    initialValue: 'All'
                                })(
                                    <Select>
                                        {worksTypeList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Signing"
                            >
                                {getFieldDecorator('signed', {
                                    initialValue: 'All'
                                })(
                                    <Select>
                                        {signingList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>

                <Toolbar
                    onSearch={this.onSearch}
                    onClear={this.clear}
                    onExport={this.handleExport}
                    showExport={btnPermission.indexOf('novel-review-extra') >= 0 ? true : false}
                />

                <Tabs defaultActiveKey="review" onChange={this.tabsChange}>
                    <TabPane tab="Pending review" key="2">
                        <p><span style={{marginRight: '5px'}}>Pending review:</span>{data.total}</p>
                        <Table
                            rowKey="novelId"
                            loading={data.loading}
                            bordered={true}
                            columns={this.columns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.novelReviewList}
                            onChange={getUpdatePage}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Passed" key="4">
                        <p><span style={{marginRight: '5px'}}>Passed:</span>{data.total}</p>
                        <Table
                            rowKey="novelId"
                            loading={data.loading}
                            bordered={true}
                            columns={this.columns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.novelReviewList}
                            onChange={getUpdatePage}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Audit failure" key="5">
                        <p><span style={{marginRight: '5px'}}>Audit failure:</span>{data.total}</p>
                        <Table
                            rowKey="novelId"
                            loading={data.loading}
                            bordered={true}
                            columns={this.columns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.novelReviewList}
                            onChange={getUpdatePage}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                </Tabs>
                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />
            </div>
        );
    }
}

export default Form.create()(NovelReview);