import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Form, Row, Col, Spin, Modal, Button, Icon, Tooltip, Card, Popconfirm, Upload, Select, Divider } from 'antd'; // AutoComplete

import { StoreState } from '../reducers/adBannerReducers';
import { Toolbar } from '../../widgets/Toolbar';
import { default as Sortable } from 'sortablejs';
import { openNotification } from '../../widgets/Notification';

const FormItem = Form.Item;
const Option = Select.Option;

interface AdBannerProps extends FormComponentProps {
    data: StoreState;
    initPage: () => void;
    bannerListSearch: (value: any) => void;
    modifyBannerStatus: (value: any) => void;
    addBanner: (value: any) => void;
    sendNovelValue: (value: any) => void;
    modalVisible: (value: boolean) => void;
    bannerSortSubmit: (value: any[]) => void;
    deleteBanner: (value: any[]) => void;
    exportBannerList: (value: any) => void;
}

interface AdBannerInitialState {
    previewVisible: boolean;
    fileChange: boolean;
    showUrl: boolean;
    showNovelId: boolean;
    sortDisabled: boolean;
    sortIds: any[];
    edit: boolean;
    type: string;
    fileList: any[];
    previewImage: string;
    bannerId: number;
    novelId: number;
    novelName: string;
    link: string;
}

class AdBanner extends React.Component<AdBannerProps, AdBannerInitialState> {

    state: AdBannerInitialState = {
        previewVisible: false,
        fileChange: false,
        showUrl: false,
        showNovelId: false,
        sortDisabled: true,
        sortIds: [],
        edit: false,
        type: '',
        fileList: [],
        previewImage: '',
        bannerId: -1,
        novelId: -1,
        novelName: '',
        link: '',
    };

    componentWillMount() {
        this.props.initPage();
    }

    componentDidMount() {
        let list: any = document.getElementById('my-banner-card-list');
        new Sortable(list, {
            onEnd: (evt) => this.sortEnd(evt)
        });
    }

    sortEnd(evt: any) {
        const childNodes = evt.to.childNodes;
        let sortIds = [];
        for (let item of childNodes) {
            sortIds.push(item.id);
        }
        this.setState({sortIds: sortIds, sortDisabled: false});
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        // console.log(value);
        const param = {
            name: value.bannerName_search,
            status: value.bannerStatus_search
        };
        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.bannerListSearch(param);
    }

    onClear = () => {
        this.props.form.resetFields();
    }

    submitExport = () => {
        const value: any = this.props.form.getFieldsValue();
        let param = {
            'current': 1,
            'pageSize': 20,
            'data': {
                name: value.bannerName_search,
            },
            'titles': [
                {
                    title: 'NO',
                    index: 'sort'
                },
                {
                    title: 'Novel Id',
                    index: 'novelId'
                },
                {
                    title: 'Banner Content',
                    index: 'novelName'
                },
                {
                    title: 'clickTime',
                    index: 'clickTime'
                },
                {
                    title: 'Time',
                    index: 'shelvedAt',
                    type: 'datetime',
                    timeZone: 'Asia/Jakarta'
                }]
        };
        this.props.exportBannerList(param);
    }

    addBannerModalShow = (edit?: string, value?: any) => {
        this.props.form.resetFields();
        this.setState({novelName: '', link: '', showNovelId: false, showUrl: false, fileList: []});
        if (edit) {
            this.setState({edit: true,
                link: value.link,
                novelId: value.novelId,
                novelName: value.novelName,
                bannerId: value.id, fileList: [{uid: '11', name: '1', status: 'done', url: value.objectUrl}]});
            if (value.link === '') {
                this.setState({showNovelId: true, showUrl: false});
            } else {
                this.setState({showNovelId: false, showUrl: true});
            }
            this.props.form.setFieldsValue({
                type: value.link === '' ? 'None' : 'Url',
                name: value.name,
                banner: value.objectKey,
            });

        } else {
            this.setState({edit: false});
        }
        this.props.modalVisible(true);

    }

    addBannerModalHide = () => {
        this.props.form.resetFields();
        this.props.modalVisible(false);
        this.setState({novelName: '', link: '', showNovelId: false, showUrl: false, fileList: []});
    }

    bannerSortSubmit = () => {
        this.props.bannerSortSubmit(this.state.sortIds);
        this.setState({sortDisabled: true});
    }

    changeBannerStatus = (value: string, id: number) => {
        let param = {
            id: id,
            status: 1
        };
        if (value === 'hide') {
            param.status = 0;
        }
        this.props.modifyBannerStatus(param);
    }

    deleteBanner = (id: number) => {
        let param = [id];
        this.props.deleteBanner(param);
    }

    fileChange = ({file, fileList}: any) => {
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].status === 'error') {
                fileList[i].status = 'done';
            }
        }
        this.setState({fileList});
    }

    beforeUpload = (file: any) => {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJPG && (file.size > 819200)) {
            openNotification('error', 'You can only upload more than 800K JPG/PNG file!');
        }
        return isJPG;
    }

    handleStatus = (value: any, id: number) => {
        if (value) {
            return (
                <Popconfirm
                    title="Are you sure hide this banner?"
                    onConfirm={() => this.changeBannerStatus('hide', id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <span className="paramHide">Hide</span>
                </Popconfirm>
            );
        } else {
            return (
                <Popconfirm
                    title="Are you sure show this banner?"
                    onConfirm={() => this.changeBannerStatus('show', id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <span className="paramHide">Show</span>
                </Popconfirm>
            );
        }
    }

    handelDelete = (id: number) => {
        return (
            <Popconfirm
                title="Are you sure delete this banner?"
                onConfirm={() => this.deleteBanner(id)}
                okText="Yes"
                cancelText="No"
            >
                <span className="paramShow">Delete</span>
            </Popconfirm>
        );
    }

    handlePreview = (file: any) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleCancel = () => {
        this.setState({previewVisible: false});
    }

    changeTypeDisable = (value: any) => {
        if (value === '0') {
            // this.setState({showNovelId: true, showUrl: false, novelName: '', link: ''});
            this.setState({showNovelId: false, showUrl: false, novelName: '', link: ''});
        } else {
            this.setState({showNovelId: false, showUrl: true, novelName: '', link: ''});
        }
    }

    switchType = (value: any) => {
        switch (value) {
            case 'Url':
                return '1';
            case 'None':
                return '0';
            default:
                return value;
        }
    }

    editBanner = () => {
        const value: any = this.props.form.getFieldsValue();
        // let s = document.getElementsByTagName('img');
        // let imgWidth = s[s.length - 1].naturalWidth;
        // let imgHeight = s[s.length - 1].naturalHeight;
        const param: any = {
            id:  this.state.bannerId,
            name: value.name,
            type: this.switchType(value.type),
            link: value.url,
            novelId: value.novel_id === this.state.novelName ? this.state.novelId : value.novel_id,
            objectKeys: value.banner,
        };

        const novelAllList = this.props.data.allNovelList;
        for (let i = 0; i < novelAllList.length; i++) {
            if (novelAllList[i].name === param.novelId) {
                param.novelId = novelAllList[i].id;
            }
        }
        for (let item in param) {
            if (param[item] === undefined || param[item] === '') {
                delete param[item];
            }
        }

        if (typeof param.objectKeys !== 'undefined' && param.name && param.type ) {
            param.objectKeys.file ? param.objectKeys = param.objectKeys.file.originFileObj : param.objectKeys = value.banner;
            // if (imgWidth !== 1200 || imgHeight !== 300) {
                // openNotification('error', 'Banner image must be 1200*300!');
            // } else if (imgWidth === 1200 && imgHeight === 300) {
                this.props.addBanner(param);
            // }
        } else {
            openNotification('error', 'Information is not complete.');
        }
    }

    addBanner = () => {
        const value: any = this.props.form.getFieldsValue();
        // let s = document.getElementsByTagName('img');
        // let imgWidth = s[s.length - 1].naturalWidth;
        // let imgHeight = s[s.length - 1].naturalHeight;
        const param: any = {
            name: value.name,
            type: value.type,
            link: value.url,
            novelId: value.novel_id,
            objectKeys: value.banner,
        };
        const novelAllList = this.props.data.allNovelList;
        for (let i = 0; i < novelAllList.length; i++) {
            if (novelAllList[i].name === param.novelId) {
                param.novelId = novelAllList[i].id;
            }
        }
        for (let item in param) {
            if (param[item] === undefined) {
                delete param[item];
            }
        }

        if (typeof param.objectKeys !== 'undefined' && param.name && param.type ) {
            param.objectKeys = param.objectKeys.file.originFileObj;
            // if (imgWidth !== 1200 || imgHeight !== 300) {
                // openNotification('error', 'Banner image must be 1200*300!');
            // } else if (imgWidth === 1200 && imgHeight === 300) {
                this.props.addBanner(param);
            // }
        } else {
            openNotification('error', 'Information is not complete.');
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data } = this.props;
        const { edit, showUrl } = this.state;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        // const form = {
        //     style: {margin: '10px 30px'}
        // };

        const typeList = [
            {key: 0, value: 'None'},
            {key: 1, value: 'Url'}
        ];

        const dividerStyle = {
            style: {padding: '30px 0'}
        };

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={6}>
                                <FormItem
                                    label="Status"
                                >
                                    {getFieldDecorator('bannerStatus_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select>
                                            <Option key="All">All</Option>
                                            <Option key="0">Hide</Option>
                                            <Option key="1">Show</Option>
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Banner Name"
                                >
                                    {getFieldDecorator('bannerName_search')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>

                    <Toolbar onSearch={this.onSearch} onClear={this.onClear} onExport={this.submitExport}>
                        <Button
                            type="primary"
                            style={{marginRight: '15px'}}
                            onClick={() => this.addBannerModalShow()}
                        >
                            <Icon type="file-add"/>Add
                        </Button>
                        <Tooltip title="Save the sort.">
                            <Button
                                type="primary"
                                disabled={this.state.sortDisabled}
                                onClick={() => this.bannerSortSubmit()}
                            >
                                <Icon type="save"/>Submit Sort
                            </Button>
                        </Tooltip>
                    </Toolbar>
                    {/*<Form>*/}
                        {/*<Row gutter={16}>*/}
                            {/*<Col span={8}>*/}
                                {/**/}
                                {/**/}
                            {/*</Col>*/}
                            {/*<Col span={8}>*/}
                                {/*<FormItem*/}
                                    {/*label="Banner Name"*/}
                                    {/*{...form}*/}
                                {/*>*/}
                                    {/*{getFieldDecorator('bannerName_search')(*/}
                                        {/*<Input {...input}/>*/}
                                    {/*)}*/}
                                {/*</FormItem>*/}
                            {/*</Col>*/}
                            {/*<Col span={8}>*/}
                                {/*<Button className="bannerButton" type="primary" onClick={() => this.onSearch()}>Search</Button>*/}
                                {/*<Button className="bannerClearButton" type="primary" onClick={() => this.onClear()}>Clear</Button>*/}
                                {/*<Button className="bannerClearButton" type="primary" onClick={() => this.submitExport()}>Export</Button>*/}
                            {/*</Col>*/}
                        {/*</Row>*/}
                    {/*</Form>*/}
                    <div id="my-banner-card-list">
                        {
                            data.getBannerList.map((item: any, index: number) => {
                                if (item.status) {
                                    return (
                                        <Card
                                            key={index}
                                            id={item.id.toString()}
                                            style={{ width: 350, float: 'left', margin: 10 }}
                                            cover={<img alt="example" src={item.objectUrl} />}
                                            actions={
                                                [this.handleStatus(item.status, item.id), this.handelDelete(item.id),
                                                    <Icon key={2} onClick={() => this.addBannerModalShow('edit' , item)} type="edit" />
                                                ]}
                                        >
                                            <div className="paramItem"><span>Name:</span><span className="param">{item.name}</span></div>
                                            <div className="paramItem"><span>Time:</span><span className="param">{item.shelvedAt === 0 ? '-' : `${item.updatedDate} ${item.updatedTime}`}</span></div>
                                            {
                                                item.link === '' ?
                                                    <div className="paramItem"><span>Type:</span><span className="param">None</span></div> :
                                                    <div className="paramItem"><span>Url:</span><span className="param">{item.link}</span></div>
                                            }
                                            <div className="paramItem"><span>Click Times:</span><span className="param">{item.clickCount}</span></div>
                                            <div className="paramItem"><span>Status:</span>{item.status ? <span className="paramShow">Show</span> : <span className="paramHide">Hide</span>}</div>
                                        </Card>
                                    );
                                }
                                return null;
                            })
                        }
                    </div>
                    <Divider {...dividerStyle}>Dividing Line</Divider>

                    {
                        data.getBannerList.map((item: any, index: number) => {
                            if (!item.status) {
                                return (
                                    <Card
                                        key={index}
                                        id={item.id.toString()}
                                        style={{ width: 350, float: 'left', margin: 10 }}
                                        cover={<img alt="example" src={item.objectUrl} />}
                                        actions={
                                            [this.handleStatus(item.status, item.id), this.handelDelete(item.id),
                                                <Icon key={2} onClick={() => this.addBannerModalShow('edit' , item)} type="edit" />
                                            ]}
                                    >
                                        <div className="paramItem"><span>Name:</span><span className="param">{item.name}</span></div>
                                        <div className="paramItem"><span>Time:</span><span className="param">{item.shelvedAt === 0 ? '-' : `${item.updatedDate} ${item.updatedTime}`}</span></div>
                                        {
                                            item.link === '' ?
                                                <div className="paramItem"><span>Type:</span><span className="param">None</span></div> :
                                                <div className="paramItem"><span>Url:</span><span className="param">{item.link}</span></div>
                                        }

                                        <div className="paramItem"><span>Click Times:</span><span className="param">{item.clickCount}</span></div>
                                        <div className="paramItem"><span>Status:</span>{item.status ? <span className="paramShow">Show</span> : <span className="paramHide">Hide</span>}</div>
                                    </Card>
                                );
                            }
                            return null;
                        })
                    }
                </Spin>
                <Modal
                    title={edit ? 'Edit Banner' : 'Add Banner'}
                    visible={data.modalVisible}
                    onCancel={() => this.addBannerModalHide()}
                    footer={null}
                    maskClosable={false}
                >
                    <Spin spinning={data.modalLoading}>
                        <Form>
                            <FormItem label="Name">
                                {getFieldDecorator('name', {
                                    rules: [{
                                        required: true, message: 'please input name',
                                    }]
                                })(
                                    <Input/>
                                )}
                            </FormItem>
                            <FormItem label="Type">
                                {getFieldDecorator('type', {
                                    rules: [{
                                        required: true, message: 'please input type'
                                    }],
                                })(
                                    <Select
                                        onChange={(option: any) => this.changeTypeDisable(option)}
                                    >
                                        {typeList.map((item: any) => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                            {
                                showUrl ? <FormItem label="Url">
                                    {getFieldDecorator('url', {
                                        initialValue: this.state.link,
                                        rules: [{
                                            required: true, message: 'please input url',
                                        }]
                                    })(
                                        <Input/>
                                    )}
                                </FormItem> : null
                            }
                            {/*{*/}
                                {/*showNovelId ? <FormItem*/}
                                    {/*label="Novel"*/}
                                {/*>*/}
                                    {/*{*/}
                                        {/*getFieldDecorator('novel_id', {*/}
                                            {/*initialValue: this.state.novelName,*/}
                                            {/*rules: [{*/}
                                                {/*required: true, message: 'please input novel name',*/}
                                            {/*}]*/}
                                        {/*})(*/}
                                            {/*<AutoComplete*/}
                                                {/*dataSource={data.novelList}*/}
                                                {/*disabled={false}*/}
                                                {/*onChange={(value: any) => {*/}
                                                    {/*this.props.sendNovelValue(value);*/}
                                                {/*}}*/}
                                            {/*/>*/}
                                        {/*)*/}
                                    {/*}*/}
                                {/*</FormItem> : null*/}
                            {/*}*/}
                            <FormItem
                                label="Banner(1200*300)"
                            >
                                {getFieldDecorator('banner', {
                                    rules: [{
                                        required: true,
                                        message: 'Please upload Image',
                                    }],
                                })(
                                    <Upload
                                        listType="picture-card"
                                        fileList={this.state.fileList}
                                        onChange={this.fileChange}
                                        beforeUpload={this.beforeUpload}
                                        onPreview={(file) => this.handlePreview(file)}
                                        onRemove={() => this.setState({fileChange: true})}
                                    >
                                        {
                                            this.state.fileList.length >= 1 ? null : <div>
                                                <Icon type="plus"/>
                                                <div className="ant-upload-text">Upload</div>
                                            </div>
                                        }
                                    </Upload>
                                )}
                            </FormItem>
                            <FormItem>
                                <Popconfirm
                                    placement="topLeft"
                                    title="Are you sure submit ?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={edit ? this.editBanner : this.addBanner}
                                >
                                    <Button type="primary" icon="plus">Submit</Button>
                                </Popconfirm>
                            </FormItem>
                        </Form>
                    </Spin>
                </Modal>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handleCancel()}>
                    <img alt="example" style={{width: '100%'}} src={this.state.previewImage}/>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(AdBanner);
