import dayDataActionsTypeKey from './actionsTypeKey/dayDataActionsTypeKey';
import { DayDataList } from '../../../servers/responseTypes';

export function initPage() {
    return { type: dayDataActionsTypeKey.DAY_DATA_PAGE_INIT };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: dayDataActionsTypeKey.UPDATE_PAGE, payload: {pagination, sorter} };
}

export function loading(value: boolean) {
    return { type: dayDataActionsTypeKey.DAY_DATA_LOADING, payload: value };
}

export function dayDataListDataSearch(value: any) {
    return { type: dayDataActionsTypeKey.DAY_DATA_LIST_DATA_SEARCH, payload: value };
}

export function getDayDataList(value: {data: DayDataList, total: number}) {
    return { type: dayDataActionsTypeKey.GET_DAY_DATA_LIST, payload: value };
}

export function exportModalVisible(value: boolean) {
    return { type: dayDataActionsTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: dayDataActionsTypeKey.SUBMIT_DAY_DATA_EXPORT, payload: value };
}
