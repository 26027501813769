import  authorKey  from './actionsTypeKey/authorActionsTypeKey';
// import { financeList } from  '../../../servers/responseTypes';

export function initPage() {
    return { type: authorKey.INIT_PAGE };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: authorKey.UPDATE_PAGE, payload: { pagination, sorter } };
}

export function getAuthorList(value: {data: any, total: number}) {
    return { type: authorKey.GET_AUTHOR_LIST, payload: value };
}

export function authorListSearch(value: any) {
    return { type: authorKey.AUTHOR_SEARCH_PARAM, payload: value };
}

export function getAuthorId(id: number) {
    return {type: authorKey.GET_AUTHOR_ID, payload: id};
}

export function getAuthorNovelList(list: any[]) {
    return { type: authorKey.GET_AUTHOR_NOVEL_LIST, payload: list };
}

export function getAuthorStateChangeParam(param: any) {
    return { type: authorKey.GET_AUTHOR_STATE_PARAM, payload: param };
}

export function loading(bool: boolean) {
    return { type: authorKey.LOADING, payload: bool };
}

export function exportModalVisible(value: boolean) {
    return { type: authorKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: authorKey.SUBMIT_EXPORT, payload: value };
}