enum BookShelfActionsTypeKey {
    INIT_PAGE = 'BOOK_SHELF_INIT_PAGE',
    CHANGE_PAGE = 'BOOK_SHELF_CHANGE_PAGE',
    EXPORT_MODAL_VISIBLE = 'BOOK_SHELF_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'BOOK_SHELF_SUBMIT_EXPORT',
    LOADING = 'BOOK_SHELF_LOADING',
    SEARCH_PARAM = 'BOOK_SHELF_SRARCH_DATA',
    SEARCH_MODAL_PARAM = 'BOOK_SHELF_SEARCH_MODAL_PARAM',
    SEARCH_MODAL_NOVEL_LIST = 'BOOK_SHELF_SEARCH_MODAL_NOVEL_LIST',
    DATA_LIST = 'BOOK_SHELF_DATA_LIST',
    GET_TYPE_LIST = 'BOOK_SHELF_GET_TYPE_LIST',
    GET_ADD_OR_CHANGE_PARAM = 'BOOK_SHELF_GET_ADD_OR_CHANGE_PARAM',
    GET_DEL_NOVEL_ID = 'BOOK_SHELF_GET_DEL_NOVEL_ID',
    GET_SORT_NUMBER = 'GET_SORT_NUMBER'
}

export default BookShelfActionsTypeKey;