import { combineReducers } from 'redux';
import { novelReviewReducer } from './novelReviewReducer';
import { identityReducer } from './identityReducer';
import { reportManagerReducer } from './reportManagerReducer';
import { withdrawalReviewReducer } from './withdrawalReducer';

export default combineReducers({
    novelReview: novelReviewReducer,
    identity: identityReducer,
    reportManager: reportManagerReducer,
    withdrawal: withdrawalReviewReducer
});