import WebManager from '../WebManager';
import { connect } from 'react-redux';
import * as webAction from '../../actions/webAction';

const mapStateToProps = (state: any) => ({
    data: state.webManager.webManager,
});

const mapDispatchToProps = {
    initPage: webAction.initPage,
    listDataSearch: webAction.listDataSearch,
    updatePage: webAction.updatePage,
    exportModalVisible: webAction.exportModalVisible,
    submitExport: webAction.submitExport,
    deleteDataList: webAction.deleteDataList,
    changeListTop: webAction.changeListTop
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(WebManager);