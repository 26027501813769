import novelDataActionsTypeKey from './actionsTypeKey/novelDataActionsTypeKey';
import { NovelDataList, TopicList } from '../../../servers/responseTypes';

export function initPage() {
    return { type: novelDataActionsTypeKey.NOVEL_DATA_PAGE_INIT };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: novelDataActionsTypeKey.UPDATE_NOVEL_DATA_TABLE_PAGE_SIZE, payload: {pagination, sorter} };
}

export function loading(value: boolean) {
    return { type: novelDataActionsTypeKey.PAGE_LOADING, payload: value };
}

export function novelDataListDataSearch(value: any) {
    return { type: novelDataActionsTypeKey.NOVEL_DATA_LIST_DATA_SEARCH, payload: value };
}

export function getNovelDataList(value: {data: NovelDataList, total: number}) {
    return { type: novelDataActionsTypeKey.GET_NOVEL_DATA_LIST, payload: value };
}

export function getNovelTagList(value: any) {
    return { type: novelDataActionsTypeKey.GET_TAG_DATA_LIST, payload: value };
}

export function getTypeList(value: {data: TopicList, total: number}) {
    return { type: novelDataActionsTypeKey.GET_TYPE_DATA_LIST, payload: value };
}

export function exportModalVisible(value: boolean) {
    return { type: novelDataActionsTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: novelDataActionsTypeKey.SUBMIT_NOVEL_DATA_EXPORT, payload: value };
}
