enum CategoryActionsTypeKey {
    CATEGORY_INIT_PAGE = 'CATEGORY_INIT_PAGE', // 类型页面进入
    GET_TYPE_LIST = 'GET_CATEGORY_LIST', // 获取类型列表
    GET_TYPE_SEARCH_PARAM = 'GET_CATEGORY_SEARCH_PARAM', // 获取type搜索参数
    GET_ADD_EDIT_TYPE = 'CATEGORY_GET_ADD_EDIT_TYPE', // 获取添加/修改类型
    GET_BETCH_DEL_TYPE_ID = 'CATEGORY_GET_BETCH_DEL_TYPE_ID', // 获取删除类型id集合
    GET_BETCH_ADD_NOVEL_ID = 'CATEGORY_GET_BETCH_ADD_NOVEL_ID', // 批量获取增加的小说ID
    GET_TAG_LIST = 'CATEGORY_GET_TAG_LIST', // 获取tag 列表
    GET_TYPE_ID = 'CATEGORY_GET_TYPE_ID', // 编辑时获取typeid
    GET_TYPE_HAS_TAG = 'CATEGORY_GET_TYPE_HAS_TAG', // 获取type所对应tag
    GET_TYPE_DEFAULT_DEVICE = 'CATEGORY_GET_TYPE_DEFAULT_DEVICE',
    LOADING = 'CATEGORY_LOADING_PLUSA_WITHDRAWALS',
    EXPORT_MODAL_VISIBLE = 'EXPORT_CATEGORY_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'SUBMIT_CATEGORY_LIST_EXPORT',
    UPDATE_PAGE = 'CATEGORY_UPDATE_PAGE',
}

export default CategoryActionsTypeKey;