import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

export function getMonthlyList(param: any) {
    return postJson('/starRank/getMonthRankList', param);
}

export function getCompetitionList(param: any) {
    return postJson('/competition/getCompetitionList', param);
}

export function exportMonthList(param: any) {
    return postJsonForExport('/starRank/exportMonthRankList', param, 'MonthRankList.xlsx');
}

export function getQuarterlyList(param: any) {
    return postJson('/starRank/getQuarterRankList', param);
}

export function exportQuarterlyList(param: any) {
    return postJsonForExport('/starRank/exportQuarterRankList', param, 'quarterRankList.xlsx');
}

export function starConfirm(id: string) {
    return postJson('/competition/confirmCompetition', id);
}

export function removeRank(param: any) {
    return postJson('/starRank/removeRank', param);
}