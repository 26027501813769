import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 获取搜索列表
 */
export function getSearchList(param: any) {
    return postJson('/search/getSearchList', param);
}

/**
 * 删除搜索
 */
export function deleteSearch(id: number|string) {
    return postJson('/search/deleteSearch', id);
}

/**
 * 修改搜索状态
 */
export function modifySearchStatus(param: any) {
    return postJson('/search/modifySearchStatus', param);
}

/**
 * 添加搜索
 */
export function addSearch(param: any) {
    return postJson('/search/addSearch', param);
}

/**
 * 导出搜索
 */
export function searchListExport(param: any) {
    return postJsonForExport('/search/getSearchList/export', param, 'search.xlsx');
}