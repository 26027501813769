import ResourceManager  from '../ResourceManager';
import { connect } from 'react-redux';
import * as novelAction from '../../actions/novelAction';

const mapStateToProps = (state: any) => ({
    data: state.content.novel
});

const mapDispatchToProps = {
    initPage: novelAction.initPage,
    novelListSearch: novelAction.novelListSearch,
    downloadNovel: novelAction.downloadNovel,
    dumpNovel: novelAction.dumpNovel,
    sendBriefId: novelAction.sendBriefId,
    briefModalVisible: novelAction.briefModalVisible,
    spiderStart: novelAction.spiderStart,
    sendNovelValue: novelAction.sendNovelValue,
    checkSpiderStatus: novelAction.checkSpiderStatus,
    dumpModalVisible: novelAction.dumpModalVisible,
    getResourceTableChange: novelAction.getResourceTableChange
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ResourceManager);