import bookShelfActionTypeKey from '../action/actionsTypeKey/bookShelfActionTypeKey';
import {  ActionType } from '../action/actionsType/bookShelfActionsType';

interface Page {
    current: number;
    pageSize: number;
}

export interface StoreState {
    bookShelfList: any[];
    exportModalVisible: boolean;
    total: number;
    page: Page;
    sorter: any;
    searchData: any;
    loading: boolean;
    typeList: any[];
    modalSearchNovelList: any[];
}

const initialState: StoreState = {
    bookShelfList: [],
    exportModalVisible: false,
    total: 0,
    page: {
        current: 0,
        pageSize: 20
    },
    sorter: {},
    searchData: {},
    loading: false,
    typeList: [],
    modalSearchNovelList: [],
};

export const bookShelfReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {

        case bookShelfActionTypeKey.INIT_PAGE:
            return initialState;

        case bookShelfActionTypeKey.CHANGE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });
        
        case bookShelfActionTypeKey.GET_TYPE_LIST:
            return Object.assign({}, state, {
                typeList: action.payload
            });

        case bookShelfActionTypeKey.SEARCH_PARAM:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case bookShelfActionTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case bookShelfActionTypeKey.DATA_LIST:
            return Object.assign({}, state, {
                bookShelfList: action.payload.data,
                total: action.payload.total
            });

        case bookShelfActionTypeKey.SEARCH_MODAL_NOVEL_LIST:
            return Object.assign({}, state, {
                modalSearchNovelList: action.payload
            });

        default:
            return state;
    }
};