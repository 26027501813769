import  VipOrder  from '../VipOrder';
import { connect } from 'react-redux';
import * as vipOrderActions from '../../actions/vipOrderActions';

const mapStateToProps = (state: any) => ({
    data: state.financial.vipOrderData,
});

const mapDispatchToProps = {
    initPage: vipOrderActions.initPage,
    getSearchDataParam: vipOrderActions.getSearchDataParam,
    exportModalVisible: vipOrderActions.exportModalVisible,
    submitExport: vipOrderActions.submitExport,
    getUpdatePage: vipOrderActions.getUpdatePage,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(VipOrder);