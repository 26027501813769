import HelpDetail  from '../HelpDetail';
import { connect } from 'react-redux';
import * as helpDetailAction from '../../action/helpDetailActions';

const mapStateToProps = (state: any) => ({
    data: state.creation.helpDetail
});

const mapDispatchToProps = {
    initPage: helpDetailAction.initPage,
    openCloseQuestionModal: helpDetailAction.openCloseQuestionModal,
    openCloseIdModal: helpDetailAction.openCloseIdModal,
    getChangeModalQuestion: helpDetailAction.getChangeModalQuestion,
    getAddQuestionMsg: helpDetailAction.getAddQuestionMsg,
    getEditQuestionMsg: helpDetailAction.getEditQuestionMsg,
    getDelQuestionMsg: helpDetailAction.getDelQuestionMsg,
    getChangeQuestionSortMsg: helpDetailAction.getChangeQuestionSortMsg
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(HelpDetail);