import Chapter  from '../Chapter';
import { connect } from 'react-redux';
import * as chapterAction from '../../actions/chapterAction';

const mapStateToProps = (state: any) => ({
    data: state.content.chapter
});

const mapDispatchToProps = {
    initPage: chapterAction.initPage,
    sendChapterId: chapterAction.sendChapterId,
    chapterSort: chapterAction.chapterSort,
    dumpChapter: chapterAction.dumpChapter,
    sendNovelValue: chapterAction.sendNovelValue,
    modalVisible: chapterAction.modalVisible,
    downloadChapter: chapterAction.downloadChapter,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Chapter);