import FloorDataActionsTypeKey from './actionsTypeKey/floorDataActionsTypeKey';
import { DayDataList } from '../../../servers/responseTypes';

export function initPage() {
    return { type: FloorDataActionsTypeKey.PAGE_INIT };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: FloorDataActionsTypeKey.UPDATE_PAGE, payload: {pagination, sorter} };
}

export function loading(value: boolean) {
    return { type: FloorDataActionsTypeKey.LOADING, payload: value };
}

export function dataListSearch(value: any) {
    return { type: FloorDataActionsTypeKey.DATA_LIST_SEARCH, payload: value };
}

export function getDataList(value: {data: DayDataList, total: number}) {
    return { type: FloorDataActionsTypeKey.GET_DATA_LIST, payload: value };
}

export function getFloorList(value: any) {
    return { type: FloorDataActionsTypeKey.GET_FLOOR_LIST, payload: value };
}

export function exportModalVisible(value: boolean) {
    return { type: FloorDataActionsTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: FloorDataActionsTypeKey.SUBMIT_EXPORT, payload: value };
}
