import { fork, all, takeEvery, call, put, take, select } from 'redux-saga/effects';
import RecommendActionsTypeKey from '../../components/creation/action/actionsTypeKey/recommendActionsTypeKey';
import * as recommendServer from '../../servers/recommendServer';
import * as recommendAction from '../../components/creation/action/recommendAction';
import { openNotification } from '../../components/widgets/Notification';

function* initPage() {
   yield getFloorList();
   yield getStarList();
}

function* getFloorList() {
    try {
        yield put(recommendAction.isLoading(true));
        const state = yield select();
        const data = state.creation.dashboard;
        const param = data.searchData.theme;
        const { result } = yield call(recommendServer.getFloorList, param);
        yield put(recommendAction.getFloorList(result));
        yield put(recommendAction.isLoading(false));
    } catch (err) {
        console.error(err);
        openNotification('error', 'Failed to get floor list.');
    }
}

function* watchGetFloorData() {
    yield takeEvery(RecommendActionsTypeKey.LIST_DATA_SEARCH, getFloorList);
}

function* watchModifyFloorLocation() {
    while (true) {
        const { payload } = yield take(RecommendActionsTypeKey.CHANGE_FLOOR_LOCATION);
        try {
            yield put(recommendAction.isLoading(true));
            yield call(recommendServer.modifyFloorLocation, payload);
            yield getFloorList();
            yield put(recommendAction.isLoading(false));
            yield put(recommendAction.openCloseFloorLocationModal(false));
            openNotification('success', 'Change success.');
        } catch (err) {
            if (err.code === 232) {
                openNotification('error', 'floor not on shelf.');
            } else {
                openNotification('error', 'Change failed.');
            }
            yield put(recommendAction.openCloseFloorLocationModal(false));
            yield put(recommendAction.isLoading(false));
        }
    }
}

function* watchDeleteFloorDataList() {
    while (true) {
        const { payload } = yield take(RecommendActionsTypeKey.DELETE_FLOOR);
        try {
            yield put(recommendAction.isLoading(true));
            yield call(recommendServer.deleteFloor, payload);
            yield getFloorList();
            yield put(recommendAction.isLoading(false));
            openNotification('success', 'Delete success.');
        } catch (err) {
            openNotification('error', 'Delete failed.');
            yield put(recommendAction.isLoading(false));
        }
    }
}

function* watchChangeFloorDataListStatus() {
    while (true) {
        const { payload } = yield take(RecommendActionsTypeKey.CHANGE_FLOOR_STATUS);
        try {
            yield put(recommendAction.isLoading(true));
            yield call(recommendServer.modifyFloorStatus, payload);
            yield getFloorList();
            yield put(recommendAction.isLoading(false));
            openNotification('success', 'Change success.');
        } catch (err) {
            if (err.code === 230) {
                openNotification('error', 'The lack of novels in floor.');
            } else {
                openNotification('error', 'Change failed.');
            }
            yield put(recommendAction.isLoading(false));
        }
    }
}

function* watchAddFloorDataList() {
    while (true) {
        const { payload } = yield take(RecommendActionsTypeKey.ADD_FLOOR_DATA);
        try {
            yield put(recommendAction.isLoading(true));
            yield call(recommendServer.addFloor, payload);
            yield getFloorList();
            yield put(recommendAction.isLoading(false));
            yield put(recommendAction.openCloseFloorModal(false));
            openNotification('success', 'Add success.');
        } catch (err) {
            openNotification('error', 'Add failed.');
            yield put(recommendAction.isLoading(false));
        }
    }
}

function* watchEditFloorDataList() {
    while (true) {
        const { payload } = yield take(RecommendActionsTypeKey.EDIT_FLOOR_DATA);
        try {
            yield put(recommendAction.isLoading(true));
            // Number(payload.id) < 100 ?
            //     yield call(recommendServer.modifyFloorstyle, payload)
            //     :
            yield call(recommendServer.modifyFloor, payload);

            yield getFloorList();
            yield put(recommendAction.isLoading(false));
            yield put(recommendAction.openCloseFloorModal(false));
            openNotification('success', 'Edit success.');
        } catch (err) {
            openNotification('error', 'Edit failed.');
            yield put(recommendAction.isLoading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(RecommendActionsTypeKey.INIT_PAGE, initPage);
}

// Leaderboard modal

function* getStarList() {
    try {
        yield put(recommendAction.isLoading(true));
        const { result } = yield call(recommendServer.getRankList);
        yield put(recommendAction.getStarList(result));
        yield put(recommendAction.isLoading(false));
    } catch (err) {
        openNotification('error', 'Failed to get star list.');
        yield put(recommendAction.isLoading(false));
    }
}

function* changeStarSort() {
    while (true) {
        const { payload } = yield take(RecommendActionsTypeKey.CHANGE_STAR_LIST_SORT);
        try {
            yield put(recommendAction.isLoading(true));
            yield call(recommendServer.changeStarSort, payload);
            yield getStarList();
            yield put(recommendAction.OpenCloseStarIdModal(false));
            yield put(recommendAction.isLoading(false));
            openNotification('success', 'Success to change star sort.');
        } catch (e) {
            openNotification('error', 'Failed to change star sort.');
            yield put(recommendAction.isLoading(false));
        }
    }
}

function* changeStarName() {
    while (true) {
        const { payload } = yield take(RecommendActionsTypeKey.EDIT_STAR_LIST_NAME);
        try {
            yield put(recommendAction.isLoading(true));
            yield call(recommendServer.changeStarName, payload);
            yield getStarList();
            yield put(recommendAction.OpenCloseStarNameModal(false));
            yield put(recommendAction.isLoading(false));
            openNotification('success', 'Success to change star name.');
        } catch (e) {
            openNotification('error', 'Failed to change star name.');
            yield put(recommendAction.isLoading(false));
        }
    }
}

function* changeStarStatus() {
    while (true) {
        const { payload } = yield take(RecommendActionsTypeKey.CHANGE_STAR_LIST_STATUS);
        try {
            yield put(recommendAction.isLoading(true));
            yield call(recommendServer.changeStarStatus, payload);
            yield getStarList();
            yield put(recommendAction.isLoading(false));
            openNotification('success', 'Success to change star status.');
        } catch (e) {
            openNotification('error', 'Failed to change star status.');
            yield put(recommendAction.isLoading(false));
        }
    }
}

export function* watchRecommendSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchGetFloorData),
        fork(watchModifyFloorLocation),
        fork(watchDeleteFloorDataList),
        fork(watchChangeFloorDataListStatus),
        fork(watchAddFloorDataList),
        fork(watchEditFloorDataList),
        fork(changeStarSort),
        fork(changeStarName),
        fork(changeStarStatus)
    ]);
}