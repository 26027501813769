import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Row, Col, Input, Table, DatePicker, Tabs, Select } from 'antd';
// import * as moment from 'moment';

import { FormComponentProps } from 'antd/lib/form/Form';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal } from '../../widgets/ExportModal';
import { getExportTitles } from '../../../base/getExportTitles';
import { IdentityState } from '../reducers/identityReducer';
import { changeToStamp, timeReverseWithNoHour } from '../../../base/dealWithTime';

const FormItem = Form.Item;
const Option = Select.Option;
const TabPane = Tabs.TabPane;

interface VerificationProps extends FormComponentProps {
    data: IdentityState;
    initPage: () => void;
    getSearchParam: (param: any) => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getMonthlyListParam: (param: any) => void;
    getTabsKey: (key: string) => void;
}

interface VerificationInitialState {
    startTime: string;
    endTime: string;
    btnPermission: any[];
}

class IdentityVerification extends React.Component<VerificationProps, VerificationInitialState> {

    state: VerificationInitialState = {
        startTime: '',
        endTime: '',
        btnPermission: []
    };

    componentWillMount() {
        // 获取功能权限
        const value = window.sessionStorage.getItem('btnPermission');
        let sessionBtnPermission: any =  value == null ? [] : JSON.parse(value);
        this.setState({btnPermission: sessionBtnPermission});
    }

    componentDidMount() {
        this.props.initPage();
    }

    // public action

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    clear = () => {
        this.setState({startTime: ''});
        this.setState({endTime: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            gtTime: this.state.startTime.length === 0 ? undefined : changeToStamp(this.state.startTime) / 1000,
            ltTime: this.state.endTime.length === 0 ? undefined : changeToStamp(this.state.endTime) / 1000,
            userName: value.name,
            userId: value.id,
            adult: value.audit
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }

        this.props.getSearchParam(param);
    }

    columns = () => {
        return [{
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
            className: 'column-center',
        }, {
            title: 'Author ID',
            dataIndex: 'authorId',
            key: 'authorId',
            className: 'column-center',
        }, {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            className: 'column-center',
        }, {
            title: 'Age',
            dataIndex: 'adult',
            key: 'adult',
            className: 'column-center',
            render: (value: number | string) => (value ? 'Adult' : 'Minor')
        }, {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: true,
            sortField: 'createdAt',
            className: 'column-center',
        }, {
            title: 'Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
            className: 'column-center',
        }, {
            title: 'Reviewer',
            dataIndex: 'operatorName',
            key: 'operatorName',
            className: 'column-center',
        }, {
            title: 'Reason for rejection',
            dataIndex: 'message',
            key: 'message',
            className: 'column-center',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <Link to={{pathname: `/auditManage/identityDetail`, search: `?id=${record.id}&userName=${record.userName}`}} >view</Link>;
            }
        }];
    }

    resultColumns = () => {
        return [{
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
            className: 'column-center',
        }, {
            title: 'Author ID',
            dataIndex: 'authorId',
            key: 'authorId',
            className: 'column-center',
        }, {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            className: 'column-center',
        }, {
            title: 'Age',
            dataIndex: 'adult',
            key: 'adult',
            className: 'column-center',
            render: (value: number | string) => (value ? 'Adult' : 'Minor')
        }, {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: true,
            sortField: 'createdAt',
            className: 'column-center',
        }, {
            title: 'Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
            className: 'column-center',
        }, {
            title: 'Reviewer',
            dataIndex: 'operatorName',
            key: 'operatorName',
            className: 'column-center',
        }, {
            title: 'Review date',
            dataIndex: 'operatedDate',
            sorter: true,
            key: 'operatedDate',
            sortField: 'operatedAt',
            className: 'column-center',
        }, {
            title: 'Review Time',
            dataIndex: 'operatedTime',
            key: 'operatedTime',
            sortField: 'operatedAt',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <Link to={{pathname: `/auditManage/identityDetail`, search: `?id=${record.id}&userName=${record.userName}`}} >view</Link>;
            }
        }];
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        let { data } = this.props;

        const titles = data.tabsKey === '1' ? getExportTitles(this.columns()) : getExportTitles(this.resultColumns());
        this.props.submitExport({...value, titles});
    }

    // page action

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: timeReverseWithNoHour(startTime) + ' 00:00:00'});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: timeReverseWithNoHour(endTime) + ' 23:59:59'});
        } else {
            this.setState({endTime});
        }
    }

    tabsChange = (key: any) => {
        this.clear();
        this.props.getTabsKey(key);
    }

    render() {

        const {getFieldDecorator, getFieldsValue} = this.props.form;
        const input = {
            style: {width: '100%', color: '#000'}
        };

        const auditOption = [
            {key: 'All', value: 'All'},
            {key: '1', value: 'Adult'},
            {key: '0', value: 'minor'},
        ];

        const {data, getUpdatePage} = this.props;

        const { btnPermission } = this.state;

        return (
            <div>
                <Form>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="Date">
                                {getFieldDecorator('startTime')(
                                    <DatePicker
                                        allowClear={true}
                                        format={'DD-MM-YYYY'}
                                        placeholder={'Start Time'}
                                        style={{width: '48%'}}
                                        onChange={this.startTimeChange}
                                    />
                                )}
                                {getFieldDecorator('endTime')(
                                    <DatePicker
                                        allowClear={true}
                                        format={'DD-MM-YYYY'}
                                        placeholder={'End Time'}
                                        style={{width: '48%', marginLeft: '4%'}}
                                        onChange={this.endTimeChange}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="User Name"
                            >
                                {getFieldDecorator('name')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="User ID"
                            >
                                {getFieldDecorator('id')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Age"
                            >
                                {getFieldDecorator('audit', {
                                    initialValue: 'All'
                                })(
                                    <Select>
                                        {auditOption.map(item => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>

                <Toolbar
                    showExport={btnPermission.indexOf('identity-extra') >= 0 ? true : false}
                    onSearch={this.onSearch}
                    onClear={this.clear}
                    onExport={this.handleExport}
                />

                <Tabs defaultActiveKey="review" onChange={this.tabsChange}>
                    <TabPane tab="Pending review" key="1">
                        <p><span style={{marginRight: '5px'}}>Pending review:</span>{data.total}</p>
                        <Table
                            rowKey="id"
                            loading={data.loading}
                            bordered={true}
                            columns={this.columns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.authorIdentityList}
                            onChange={getUpdatePage}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Passed" key="2">
                        <p><span style={{marginRight: '5px'}}>Passed:</span>{data.total}</p>
                        <Table
                            rowKey="id"
                            loading={data.loading}
                            bordered={true}
                            columns={this.resultColumns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.authorIdentityList}
                            onChange={getUpdatePage}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Audit failure" key="3">
                        <p><span style={{marginRight: '5px'}}>Audit failure:</span>{data.total}</p>
                        <Table
                            rowKey="id"
                            loading={data.loading}
                            bordered={true}
                            columns={this.resultColumns()}
                            style={{marginTop: '20px'}}
                            dataSource={data.authorIdentityList}
                            onChange={getUpdatePage}
                            pagination={{
                                defaultPageSize: 20,
                                total: data.total,
                                current: data.page.current,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '80', '100'],
                                showTotal: total => {
                                    return `Total:${data.total} items`;
                                },
                            }}
                        />
                    </TabPane>
                </Tabs>
                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />
            </div>
        );
    }
}

export default Form.create()(IdentityVerification);