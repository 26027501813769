import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Table, Form, Row, Col, Spin, DatePicker, Select } from 'antd';
import moment from 'moment';

import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../reducers/recommendDataReducer';
import { changeToStamp, timeReverseWithHour } from '../../../base/dealWithTime';
import { getExportTitles } from '../../../base/getExportTitles';

const FormItem = Form.Item;
const Option = Select.Option;

interface RecommendDataProps {
    data: StoreState;
    initPage: () => void;
    listDataSearch: (value: any) => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
}

interface RecommendDataState  {
    startTime: string;
}

class RecommendData extends React.Component<RecommendDataProps & FormComponentProps, RecommendDataState> {

    state: RecommendDataState = {
        startTime: '',
    };

    componentDidMount() {
        this.props.initPage();
    }

    columns = () => {
        return [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                className: 'column-center',
            },
            {
                title: 'Recommend ID',
                dataIndex: 'recommendSort',
                key: 'recommendSort',
                className: 'column-center',
                sorter: true,
                sortField: 'recommendSort',
            },
            {
                title: 'Click',
                dataIndex: 'clickCount',
                key: 'clickCount',
                className: 'column-center',
                sorter: true,
                sortField: 'clickCount',
            },
        ];
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param = {
            startTime: this.state.startTime.length === 0 ? undefined : changeToStamp(timeReverseWithHour(this.state.startTime)) / 1000,
            device: value.device
        };
        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.listDataSearch(param);
    }

    onClear = () => {
        this.props.form.resetFields();
        this.setState({startTime: ''});
    }

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: moment(value._d).utc().utcOffset(7).format('DD-MM-YYYY 00:00:00')});
        } else {
            this.setState({startTime});
        }
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {

        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    render() {
        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const {data, updatePage } = this.props;

        const form = {
            style: {margin: '10px 30px'}
        };

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormItem label="Date" {...form}>
                                    {getFieldDecorator('startTime')(
                                        <DatePicker
                                            allowClear={false}
                                            format={'DD-MM-YYYY'}
                                            placeholder={'Select Date'}
                                            onChange={this.startTimeChange}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                    label="Device"
                                    {...form}
                                >
                                    {getFieldDecorator('device', {
                                        initialValue: 'All'
                                    })(
                                        <Select>
                                            <Option value="All">All</Option>
                                            <Option value="1">iOS</Option>
                                            <Option value="0">Android</Option>

                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>

                    <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.onClear} onExport={this.handleExport}/>
                    <Table
                        bordered={true}
                        rowKey="recommendId"
                        columns={this.columns()}
                        style={{marginTop: '20px'}}
                        dataSource={data.dataList}
                        onChange={updatePage}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total: ${total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={getFieldsValue}
                        visible={data.exportModalVisible}
                        loading={data.loading}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                    />
                </Spin>
            </div>
        );
    }
}

export default Form.create()(RecommendData);
