import activityKey from '../action/actionsTypeKey/activityActionsTypeKey';
import {  ActionType } from '../action/actionsType/activityType';

interface Page {
    current: number;
    pageSize: number;
}

export interface StoreState {
    activityList: any[];
    exportModalVisible: boolean;
    total: number;
    page: Page;
    sorter: any;
    searchData: any;
    loading: boolean;
    typeList: any[];
    searchList: any[];
}

const initialState: StoreState = {
    activityList: [],
    exportModalVisible: false,
    total: 1,
    page: {
        current: 1,
        pageSize: 20
    },
    sorter: {},
    searchData: {},
    loading: false,
    typeList: [],
    searchList: []
};

export const activityReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {

        case activityKey.INIT_PAGE:
            return initialState;

        case activityKey.GET_ACTIVITY_LIST:
            return Object.assign({}, state, {
                activityList: action.payload.data,
                total: action.payload.total
            });

        case activityKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
               exportModalVisible: action.payload
            });

        case activityKey.GET_SEARCH_DATA:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case activityKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case activityKey.LOADING:
            return Object.assign({}, state, {
               loading: action.payload
            });

        case activityKey.GET_TYPE_LIST:
            return Object.assign({}, state, {
                typeList: action.payload
            });

        case activityKey.GET_NOVEL_LIST:
            return Object.assign({}, state, {
                searchList: action.payload
            });

        default:
            return state;
    }
};