import  authorDetailKey  from './actionsTypeKey/authorDetailActionsTypeKey';
// import { financeList } from  '../../../servers/responseTypes';

export function initPage() {
    return { type: authorDetailKey.INIT_PAGE };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: authorDetailKey.UPDATE_PAGE, payload: { pagination, sorter } };
}

export function loading(bool: boolean) {
    return { type: authorDetailKey.LOADING, payload: bool };
}

export function getAuthorDetailId(id: string) {
    return { type: authorDetailKey.GET_AUTHOR_DETAIL_ID, payload: id };
}

export function getAuthorDetail(param: any) {
    return { type: authorDetailKey.GET_AUTHOR_DETAIL, payload: param };
}

export function getAuthorDetailNovelList(param: any) {
    return { type: authorDetailKey.GET_AUTHOR_DETAIL_NOVEL_LIST, payload: param };
}