import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

export function getVoteList(param: any) {
    return postJson('/eventEssay/getEventEssayList', param);
}

export function delVote(id: number) {
    return postJson('/eventEssay/deleteEventEssay', id);
}

export function changeVirturlNum(param: any) {
    return postJson('/eventEssay/modifyEventEssay', param);
}

export function exportList(param: any) {
    return postJsonForExport('/eventEssay/getEventEssayList/export', param, 'activity.xlsx');
}

export function addEventEssay(id: any) {
    return postJson('/eventEssay/addEventEssay', id);
}