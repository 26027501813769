import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

export function getSensitiveList(param: any) {
    return postJson('/web/sensitive/getSensitiveList', param);
}

export function deletaSensitivelWord(id: number) {
    return postJson('/web/sensitive/deleteSensitiveWord', id);
}

export function addSensitivel(param: any) {
    return postJson('/web/sensitive/addSensitiveWord', param);
}

export function exportSensitivel(param: any) {
    return postJsonForExport('/web/sensitive/exportSensitiveList', param, 'sensitiveData.xlsx');
}

export function getAllSensitive() {
    return postJson('/web/sensitive/getAllSecondLevelSensitive');
}