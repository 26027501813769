import  novelActionsTypeKey  from './actionsTypeKey/novelManagerActionsTypeKey';

export function initNovelPage() {
    return { type: novelActionsTypeKey.INIT_NOVEL_PAGE };
}

export function novelLoading(value: boolean) {
    return { type: novelActionsTypeKey.NOVEL_LOADING, payload: value };
}

export function getNovelList(list: any[]) {
    return { type: novelActionsTypeKey.GET_NOVEL_LIST, payload: list };
}

export function getNovelTypeList(list: any[]) {
    return { type: novelActionsTypeKey.GET_NOVEL_TYPE_LIST, payload: list };
}

export function getNovelSearchParam(param: any) {
    return { type: novelActionsTypeKey.GET_NOVEL_SEARCH_PARAM, payload: param };
}

export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: novelActionsTypeKey.NOVEL_UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: novelActionsTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: novelActionsTypeKey.SUBMIT_EXPORT, payload: value };
}

export function getNovelChangeStateParam(param: any) {
    return { type: novelActionsTypeKey.GET_CHANGE_NOVEL_STATE_PARAM, payload: param };
}

export function getDelNovelId(id: any) {
    return { type: novelActionsTypeKey.GET_DEL_NOVEL_ID, payload: id };
}

export function getSearchAuthorKey(key: any) {
    return { type: novelActionsTypeKey.GET_SEARCH_AUTHOR_KEY, payload: key };
}

export function getKeyAuthorList(list: any) {
    return { type: novelActionsTypeKey.GET_KEY_AUTHOR_List, payload: list };
}

export function getSearchTypeKey(key: any) {
    return { type: novelActionsTypeKey.GET_SEARCH_TYPE_KEY, payload: key };
}

export function getAddNovelParam(param: any) {
    return { type: novelActionsTypeKey.GET_ADD_NOVEL_PARAM, payload: param };
}

export function goNovelList(bool: boolean) {
    return { type: novelActionsTypeKey.GO_NOVEL_LIST, payload: bool };
}

export function goNovelDetail(bool: boolean) {
    return { type: novelActionsTypeKey.GO_NOVEL_DETAIL, payload: bool };
}

export function changeNovelUpdateStatus(id: number) {
    return { type: novelActionsTypeKey.CHANGE_NOVEL_UPDATE_STATUS, payload: id };
}

export function getEditNovelId(id: string) {
    return { type: novelActionsTypeKey.GET_NOVEL_ID, payload: id };
}

export function getNovelMsgToId(msg: any) {
    return { type: novelActionsTypeKey.GET_NOVEL_MSG_TO_ID, payload: msg };
}

export function getNovelCouverUrl(url: string) {
    return { type: novelActionsTypeKey.GET_NOVEL_COVERURL, payload: url };
}
