import * as React from 'react';
import { Row, Col, Divider, Spin, Card, Icon, Modal } from 'antd';
import { numFormat } from '../../../base/regular';

interface UserDetailProps {
    data: any;
    getUserDetailId: (id: string) => void;
    match: any;
}

interface InitialState {
    imgModal: boolean;
    imgUrl: string;
}

class UserDetail extends React.Component<UserDetailProps, InitialState> {

    state: InitialState = {
        imgModal: false,
        imgUrl: '',
    };

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getUserDetailId(id);
    }

    imgModalShow = (e: any) => {

        this.setState({imgModal: true, imgUrl: e.target.src});
    }

    imgModalOk = () => {
        this.imgModalCancel();
    }

    imgModalCancel = () => {
        this.setState({imgModal: false});
    }

    parameterFilter = (obj: any, name: string): string => {
        if (obj && obj[name]) {
            return obj[name];
        } else {
            return '--';
        }
    }

    render() {

        const { data } = this.props;

        const { imgModal, imgUrl, } = this.state;

        let msgList = {
            style: {
                fontSize: '16px',
                marginBottom: '15px',
                wordWrap: 'break-word',
            }
        } as any;

        let imgStyle = {
            style: {
                width: '200px',
                height: '104px',
                margin: '0 8px 8px 0',
                verticalAlign: 'top',
                marginLeft: '15px',
                padding: '8px',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                cursor: 'pointer'
            }
        };

        let bankInfo = data.userDetailMsg.bankInfo, basicInfo = data.userDetailMsg.basicInfo;

        return (
          <Spin spinning={data.loading}>
              <Card
                  title={<span><Icon style={{fontSize: '22px', marginRight: '10px'}} type="user" />{data.userDetailMsg.name}</span>}
                  bordered={false}
              >
                  <div style={{marginBottom: '15px'}}>
                      <p style={{fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>User Message</p>
                      <Row>
                          <Col span={12} {...msgList}>Creation time:{this.parameterFilter(data.userDetailMsg, 'createdAtDateTime')}</Col>
                          <Col span={11} offset={1} {...msgList}>User ID: {this.parameterFilter(data.userDetailMsg, 'id')}</Col>
                          <Col span={12} {...msgList}>Member status: {
                              data.userDetailMsg.vipType === 1 ? 'Yes' : 'No'
                          }</Col>
                          <Col span={11} offset={1} {...msgList}>Member Expire date: {this.parameterFilter(data.userDetailMsg, 'vipExpiredDate')}</Col>
                          <Col span={12}  {...msgList}>Koin: {numFormat(this.parameterFilter(basicInfo, 'coin'))}</Col>
                      </Row>
                  </div>
                  <Divider />
                  <div style={{marginBottom: '15px'}}>
                      <p style={{fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>Basic Information</p>
                      <Row>
                          <Col span={12} {...msgList}>
                              Name: {basicInfo ? `${basicInfo.givenName ? basicInfo.givenName : ''}` + `  ${basicInfo.familyName ? basicInfo.familyName : ''}`  : '--'}
                          </Col>
                          <Col span={11} offset={1} {...msgList}>
                              Phone number: {this.parameterFilter(basicInfo, 'phone')}
                          </Col>
                          <Col span={24} {...msgList}>
                              Identity number: {this.parameterFilter(basicInfo, 'cardNumber')}
                          </Col>
                          <Col span={24} {...msgList}>
                              E-mail: <span  style={{wordWrap: 'break-word', width: '80%', display: 'block'}}>
                                {this.parameterFilter(basicInfo, 'email')}
                              </span>
                          </Col>
                          <Col span={24}  {...msgList}>
                              Facebook: <span  style={{wordWrap: 'break-word', width: '80%', display: 'block'}}>
                                {this.parameterFilter(basicInfo, 'facebook')}
                              </span>
                          </Col>
                          <Col span={24} {...msgList}>
                              Ins: <span  style={{wordWrap: 'break-word', width: '80%', display: 'block'}}>
                                {this.parameterFilter(basicInfo, 'ins')}
                              </span>
                          </Col>
                          <Col span={12}  {...msgList}>
                              ID card (front photo): {basicInfo && basicInfo.cardPic ? <img {...imgStyle} src={basicInfo.cardPic} onClick={(e: any) => {this.imgModalShow(e); }} alt=""/> : '--'}
                          </Col>
                          <Col span={11} offset={1} {...msgList}>
                              Hand-held identity card: {basicInfo && basicInfo.cardHandPic ? <img {...imgStyle} src={basicInfo.cardHandPic} onClick={(e: any) => {this.imgModalShow(e); }} alt=""/> : '--'}
                          </Col>
                      </Row>
                  </div>
                  <Divider />
                  <div style={{marginBottom: '15px'}}>
                      <p style={{fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>Bank card information</p>
                      <Row>
                          <Col span={12} {...msgList}>Name: {this.parameterFilter(bankInfo, 'name')}</Col>
                          <Col span={11} offset={1} {...msgList}>Bank name: {this.parameterFilter(bankInfo, 'bankName')}</Col>
                          <Col span={12} {...msgList}>Bank card number: {this.parameterFilter(bankInfo, 'cardNumber')}</Col>
                      </Row>
                  </div>
              </Card>
              <Modal
                  title="Large Picture"
                  visible={imgModal}
                  onOk={this.imgModalOk}
                  onCancel={this.imgModalCancel}
                  maskClosable={false}
              >
                  <img style={{width: '400px'}} src={imgUrl} alt=""/>
              </Modal>
          </Spin>
        );
    }
}

export default UserDetail;