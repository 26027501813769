import ReportManager  from '../ReportManager';
import { connect } from 'react-redux';
import * as reportManagerAction from '../../actions/reportManagerAction';

const mapStateToProps = (state: any) => ({
    data: state.audit.reportManager
});

const mapDispatchToProps = {
    initPage: reportManagerAction.initPage,
    exportModalVisible: reportManagerAction.exportModalVisible,
    submitExport: reportManagerAction.submitExport,
    getUpdatePage: reportManagerAction.getUpdatePage,
    getSearchData: reportManagerAction.getSearchData,
    getReportTabsKey: reportManagerAction.getReportTabsKey
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReportManager);