import ChapterDataActionsTypeKey from '../actions/actionsTypeKey/chapterDataActionsTypeKey';
import { ActionTypes } from '../actions/actionsType/chapterDataActionsType';
import { SearchType, NovelDataList } from '../../../servers/responseTypes';

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    page: PageData;
    sorter: any;
    total: number;
    typeList: SearchType[];
    searchData: any;
    loading: boolean;
    exportModalVisible: boolean;
    dataList: NovelDataList[];
}

const initialState: StoreState = {
    dataList: [],
    typeList: [],
    total: 0,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    searchData: {},
    loading: false,
    exportModalVisible: false,
};

export const chapterDataReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ChapterDataActionsTypeKey.PAGE_INIT:
            return initialState;

        case ChapterDataActionsTypeKey.GET_DATA_LIST:
            return Object.assign({}, state, {
                dataList: action.payload.data,
                total: action.payload.total
            });

        case ChapterDataActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case ChapterDataActionsTypeKey.LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case ChapterDataActionsTypeKey.GET_TYPE_DATA_LIST:
            return Object.assign({}, state, {
                typeList: action.payload,
            });

        case ChapterDataActionsTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize,
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'DESC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC'),
                }
            });

        case ChapterDataActionsTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload,
            });

        default:
            return state;
        }
};