import RecommendDataActionsTypeKey from './actionsTypeKey/recommendDataActionsTypeKey';
import { DayDataList } from '../../../servers/responseTypes';

export function initPage() {
    return { type: RecommendDataActionsTypeKey.PAGE_INIT };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: RecommendDataActionsTypeKey.UPDATE_PAGE, payload: {pagination, sorter} };
}

export function loading(value: boolean) {
    return { type: RecommendDataActionsTypeKey.LOADING, payload: value };
}

export function listDataSearch(value: any) {
    return { type: RecommendDataActionsTypeKey.LIST_DATA_SEARCH, payload: value };
}

export function getDataList(value: {data: DayDataList, total: number}) {
    return { type: RecommendDataActionsTypeKey.GET_DATA_LIST, payload: value };
}

export function exportModalVisible(value: boolean) {
    return { type: RecommendDataActionsTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: RecommendDataActionsTypeKey.SUBMIT_EXPORT, payload: value };
}
