import CategoryManager from '../Category';
import * as categoryAction from '../../action/categoryAction';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => ({
    data: state.creation.category
});

const mapDispatchToProps = {
    initPage: categoryAction.initPage,
    getTypeSearchParam: categoryAction.getTypeSearchParam,
    exportModalVisible: categoryAction.exportModalVisible,
    submitExport: categoryAction.submitExport,
    addEditType: categoryAction.addEditType,
    getEditTypeId: categoryAction.getEditTypeId,
    getHasTagList: categoryAction.getHasTagList,
    getBetchDelTypeId: categoryAction.getBetchDelTypeId,
    getBatchNovelId: categoryAction.getBatchNovelId,
    changeUpdataPage: categoryAction.changeUpdataPage,
    getDefaultDevice: categoryAction.getDefaultDevice
};

export function mergeProps(stateProps: any, dispatchProps: any, ownProps: any) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CategoryManager);