import * as React from 'react';
import { Form, Row, Col, Select, Input, Table, DatePicker } from 'antd'; 
import moment from 'moment';
import { FormComponentProps } from 'antd/lib/form';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { getExportTitles } from '../../../base/getExportTitles';
import { changeToStamp, timeReverseWithNoHour } from '../../../base/dealWithTime';

const FormItem = Form.Item;
const Option = Select.Option;

interface Props extends FormComponentProps {
    data: any;
    initPage: () => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    searchDate: (value: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
}

class NovelUpdateData extends React.Component<Props, any> {

    state = {
        startTime: '',
        endTime: '',
        defaultStartTime: moment().startOf('month'),
        defaultEndTime: moment(),
    };

    componentDidMount() {
        let a = timeReverseWithNoHour(moment(this.state.defaultStartTime).format('DD-MM-YYYY'))  + ' 00:00:00',
            b = timeReverseWithNoHour(moment(this.state.defaultEndTime).format('DD-MM-YYYY')) +  ' 23:59:59';
        let param = {
            gtTime: changeToStamp(a) / 1000,
            ltTime: changeToStamp(b) / 1000,
        };
        this.props.initPage();
        this.props.searchDate(param);
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    clear = () => {
        this.setState({startTime: '', endTime: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {
        let a = timeReverseWithNoHour(moment(this.state.defaultStartTime).format('DD-MM-YYYY'))  + ' 00:00:00',
            b = timeReverseWithNoHour(moment(this.state.defaultEndTime).format('DD-MM-YYYY')) +  ' 23:59:59';
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            gtTime: this.state.startTime.length === 0 ? changeToStamp(a) / 1000 : changeToStamp(this.state.startTime) / 1000,
            ltTime: this.state.endTime.length === 0 ? changeToStamp(b) / 1000 : changeToStamp(this.state.endTime) / 1000,
            typeId: value.category_search,
            novelName: value.novelName_search,
            novelId: value.novelId_search,
            signed: value.signed_search,
            novelType: value.novelType_search
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }

        this.props.searchDate(param);
    }

    columns = () => {
        return [
            {
                title: 'Novel ID',
                dataIndex: 'novelId',
                key: 'novelId',
                className: 'column-center',
                sorter: true,
                fixed: true,
                width: 110,
                sortField: 'novelId',
            },
            {
                title: 'Novel Name',
                dataIndex: 'novelName',
                key: 'novelName',
                className: 'column-center',
                width: 250,
            },
            {
                title: 'Author',
                dataIndex: 'author',
                key: 'author',
                className: 'column-center',
            },
            {
                title: 'Category',
                dataIndex: 'typeName',
                key: 'typeName',
                className: 'column-center',
            },
            {
                title: 'Type',
                dataIndex: 'novelType',
                key: 'novelType',
                className: 'column-center',
                render: (text: any) => {
                    return (
                        (() => {
                            switch (text) {
                                case 0:
                                    return <span>Original</span>;
                                case 1:
                                    return <span>Reprint</span>;
                                case 2:
                                    return <span>PGC</span>;
                                case 3:
                                    return <span>Star Novel</span>;
                                default:
                                    return <span />;
                            }
                        })()
                    );
                }
            },
            {
                title: 'Signing',
                dataIndex: 'signed',
                key: 'signed',
                className: 'column-center',
                render: (text: number | string, record: any) => {
                    if (record.novelType === 3) {
                        return text ? 'Kontrak tertulis' : 'Kontrak digital';
                    } else {
                        return '--';
                    }
                }
            },
            {
                title: 'Update Days',
                dataIndex: 'updateDays',
                key: 'updateDays',
                className: 'column-center',
                sorter: true,
                sortField: 'updateDays',
                render: (text: any) => (text ? text : '0')
            },
            {
                title: 'Update Chapter Number',
                dataIndex: 'updateChapterCount',
                key: 'updateChapterCount',
                className: 'column-center',
                sorter: true,
                sortField: 'updateChapterCount',
                render: (text: any) => (text ? text : '0')
            },
            {
                title: 'Update Word Count',
                dataIndex: 'updateWordCount',
                key: 'updateWordCount',
                className: 'column-center',
                sorter: true,
                sortField: 'updateWordCount',
            },
        ];
    }

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: timeReverseWithNoHour(startTime) + ' 00:00:00'});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: timeReverseWithNoHour(endTime) + ' 23:59:59'});
        } else {
            this.setState({endTime});
        }
    }

    render() {

        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const { data, updatePage } = this.props;
        const { defaultStartTime, defaultEndTime } = this.state;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const form = {
            style: {margin: '10px 30px'}
        };

        const worksTypeList = [
            {key: 'All', value: 'All'},
            {key: '0', value: 'Original'},
            {key: '1', value: 'Reprint'},
            {key: '2', value: 'PGC'},
            {key: '3', value: 'Star Novel'},
        ];

        const signingList = [
            {key: 'All', value: 'All'},
            {key: '1', value: 'Kontrak tertulis'},
            {key: '0', value: 'Kontrak digital'}
        ];

        return (
            <div>
                <Form>
                        <Row gutter={16}>
                            <Col span={6}>
                                <FormItem label="Date" {...form}>
                                    {getFieldDecorator('startTime', {
                                        initialValue: defaultStartTime
                                    })(
                                        <DatePicker
                                            allowClear={true}
                                            format={'DD-MM-YYYY'}
                                            placeholder={'Start Time'}
                                            style={{width : '48%'}}
                                            onChange={this.startTimeChange}
                                        />
                                    )}
                                    {getFieldDecorator('endTime', {
                                        initialValue: defaultEndTime
                                    })(
                                        <DatePicker
                                            allowClear={true}
                                            format={'DD-MM-YYYY'}
                                            placeholder={'End Time'}
                                            style={{width : '48%', marginLeft : '4%'}}
                                            onChange={this.endTimeChange}
                                        />
                                    )}
                                </FormItem>
                            </Col>

                            <Col span={6}>
                                <FormItem
                                    label="Type"
                                    {...form}
                                >
                                    {getFieldDecorator('novelType_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {worksTypeList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Signing"
                                    {...form}
                                >
                                    {getFieldDecorator('signed_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {signingList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Novel Name"
                                    {...form}
                                >
                                    {getFieldDecorator('novelName_search')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Novel ID"
                                    {...form}
                                >
                                    {getFieldDecorator('novelId_search')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Category"
                                    {...form}
                                >
                                    {getFieldDecorator('category_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                             <Option value="All">All</Option>
                                            {
                                                data.typeList.map((item: any) => {
                                                    return <Option key={item.id}>{item.name}</Option>;
                                                })
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                <Toolbar
                    showExport={true}
                    onSearch={this.onSearch}
                    onClear={this.clear}
                    onExport={this.handleExport}
                />
                <Table
                    loading={data.loading}
                    bordered={true}
                    rowKey="novelId"
                    columns={this.columns()}
                    style={{marginTop: '20px'}}
                    dataSource={data.dataList}
                    onChange={updatePage}
                    scroll={{ x: 1400 }}
                    pagination={{
                        defaultPageSize: 20,
                        total: data.total,
                        current: data.page.current,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '50', '80', '100'],
                        showTotal: total => {
                            return `Total: ${total} items`;
                        },
                    }}
                />
                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />
            </div>
        );
    }
}

export default Form.create()(NovelUpdateData);