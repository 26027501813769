import IdentityDetail  from '../IdentityDetail';
import { connect } from 'react-redux';
import * as identityAction from '../../actions/identityAction';

const mapStateToProps = (state: any) => ({
    data: state.audit.identity
});

const mapDispatchToProps = {
    initPage: identityAction.initPage,
    getIdentityId: identityAction.getIdentityId,
    getIdentityDetailMsg: identityAction.getIdentityDetailMsg,
    getIdentityDetailModifyParam: identityAction.getIdentityDetailModifyParam
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IdentityDetail);