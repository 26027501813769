import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 获取小说列表信息
 */
export function getNovelList(param?: any) {
    return postJson('/novel/getNovelList', param);
}

/**
 * 获取小说类型列表
 */
export function getNovelTypeList(param?: any) {
    return postJson('/noveltype/getNovelTypeList', param);
}

/**
 * 导出小说列表
 */
export function exportNavelList(param: any) {
    return postJsonForExport('/novel/getNovelList/export', param, 'novel.xlsx');
}

/**
 * 修改小说上下架状态
 */
export function novelChangeState(param: any) {
    return postJson('/novel/modifyShelfStatus', param);
}

/**
 * 删除小说
 */
export function novelDelete(id: number) {
    return postJson('novel/deleteNovelById', id);
}

/**
 * 根据关键字搜索作者
 */
export function getKeyAuthor(key: any) {
    return postJson('/author/getAuthorListByName', key);
}

/**
 * 添加小说
 */
export function addNovel(param: any) {
    return postJson('/novel/addNovel', param);
}

/**
 * 编辑小说
 */
export function editNovel(param: any) {
    return postJson('/novel/modifyNovel', param);
}

/**
 * 修改小说更新状态
 */
export function changeNovelUPdateStatus(id: any) {
    return postJson('/novel/modifyNovelUpdatedStatus', id);
}

/**
 * 根据小说ID 获取小说信息
 */
export function getMsgToId(id: any) {
    return postJson('/novel/getNovelDetailById', id);
}

/**
 * 获取未上架ugc小说数量
 */
export function getNotReleaseUgcNovelCount() {
    return postJson('/novel/getNotReleaseUgcNovelCount');
}
