import * as React from 'react';
import { Row, Col, Button, Table, Modal, Form, Input, Popconfirm } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { StoreState } from '../reducers/helpCenterReducers';
import { Redirect } from 'react-router-dom';
import { isInt } from '../../../base/regular';
import { openNotification } from '../../widgets/Notification';

interface Props extends FormComponentProps {
    data: StoreState;
    initPage: () => void;
    getTitleModalStatus: (bol: boolean) => void;
    getLocationModalStatus: (bol: boolean) => void;
    getChangeHelpModalSortMsg: (param: any) => void;
    addHelpModal: (param: any) => void;
    editHelpModal: (param: any) => void;
    delHelpModal: (id: number | string) => void;
}

interface State {
    addEditTitle: string;
    questionModalId: string | number;
    isAddModal: boolean;
    detailItem: any;
    toDetail: boolean;
}

class HelpCenterSetting extends React.Component<Props, State> {

    state: State = {
        addEditTitle: 'Add Title',
        questionModalId: 0,
        isAddModal: true,
        detailItem: {},
        toDetail: false
    };

    componentDidMount(): void {
        this.props.initPage();
    }

    column = () => {
        return [
            {
                title: 'Location ID',
                dataIndex: 'sort',
                key: 'sort',
                render: (text: number | string, item: any) => {
                    return (
                        <span
                            style={{ color: '#1890ff',  cursor: 'pointer' }}
                            onClick={() => {this.locationModalShow(item); }}
                        >
                            {text}
                        </span>);
                }
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: (text: string, record: any) => {
                    return <a href="javascript:void(0)" onClick={() => {this.toDetail(record); }}>{text}</a>;
                }
            },
            {
                title: 'Setting',
                dataIndex: 'setting',
                key: 'setting',
                render: (text: any, item: any) => {
                    return (
                        <div>
                            <span
                                style={{marginRight: '15px', color: '#1890ff',  cursor: 'pointer'}}
                                onClick={() => {this.titleModalShow('Edit Title', item); }}
                            >
                                Edit
                            </span>
                            <span
                                style={{ color: 'red', cursor: 'pointer'}}
                            >
                                 <Popconfirm
                                     title="Are you sure delete this task?"
                                     onConfirm={() => {this.delTask(item.id); }}
                                     okText="Yes"
                                     cancelText="No"
                                 >
                                Delete
                                 </Popconfirm>
                            </span>
                        </div>
                    );
                }
            },
        ];
    }

    titleModalShow = (str: string, item?: any) => {
        this.setState({ addEditTitle: str });
        this.props.getTitleModalStatus(true);
        if (item) {
            this.setState({questionModalId: item.id, isAddModal: false});
            this.props.form.setFieldsValue({'title': item.title });
        } else {
            this.setState({isAddModal: true});
            this.props.form.resetFields(['title']);
        }
    }

    titleModalCancel = () => {
        this.props.getTitleModalStatus(false);
    }

    titleModalOk = () => {
        const { isAddModal, questionModalId }  = this.state;
        this.props.form.validateFields(['title'], (err, values) => {
            if (!err) {
                if (isAddModal) {
                    let param = {
                        title: values.title
                    };
                    this.props.addHelpModal(param);
                } else {
                    let param =  {
                        id: questionModalId,
                        title: values.title
                    };
                    this.props.editHelpModal(param);
                }
            }
        });
    }

    locationModalShow = (item: any) => {
        this.props.form.setFieldsValue({'id': item.sort});
        this.setState({questionModalId: item.id});
        this.props.getLocationModalStatus(true);
    }

    locationModalCancel = () => {
        this.props.form.resetFields(['id']);
        this.props.getLocationModalStatus(false);
    }

    locationModalOk = () => {
        const { data } = this.props;
        this.props.form.validateFields(['id'], (err, values) => {
            if (!err) {
                if (Number(values.id) <= data.dataSource.length) {
                    let param = {
                        id: this.state.questionModalId,
                        sort: values.id
                    };
                    this.props.getChangeHelpModalSortMsg(param);
                } else {
                    openNotification('error', 'The serial number cannot exceed the total number of modules');
                }
            }
        });
    }

    delTask = (id: number | string) => {
        this.props.delHelpModal(id);
    }

    toDetail = (item: any) => {
        this.setState({detailItem: item, toDetail: true});
    }

    render() {

        const { data } = this.props;

        const { addEditTitle, detailItem, toDetail } = this.state;

        const { getFieldDecorator } = this.props.form;

        if (toDetail) {
            return <Redirect push={true} to={{pathname: `/creationManage/helpDetail`, search: `?id=${detailItem.id}&name=${detailItem.title}`}}/>;
        }

        return (
            <div>
                <Row style={{marginBottom: '20px'}}>
                    <Col>
                        <Button type="primary" onClick={() => {this.titleModalShow('Add Title'); }}>Add Title</Button>
                    </Col>
                </Row>

                <Table
                    loading={data.loading}
                    rowKey={'id'}
                    dataSource={data.dataSource}
                    columns={this.column()}
                    pagination={false}
                />

                <Modal
                    title={addEditTitle}
                    visible={data.titleModalStatus}
                    onOk={this.titleModalOk}
                    onCancel={this.titleModalCancel}
                    maskClosable={false}
                    confirmLoading={data.loading}
                >
                    <Form>
                        <Form.Item label="Title Name">
                            {getFieldDecorator('title', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input title name!',
                                    },
                                    {
                                        max: 200,
                                        message: 'Cannot exceed 200 characters'
                                    }
                                ],
                            })(<Input />)}
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="Location ID"
                    visible={data.locationModalStatus}
                    onOk={this.locationModalOk}
                    onCancel={this.locationModalCancel}
                    maskClosable={false}
                    confirmLoading={data.loading}
                >
                    <Form>
                        <Form.Item label="Location ID">
                            {getFieldDecorator('id', {
                                rules: [
                                    {validator: isInt}

                                ],
                            })(<Input />)}
                        </Form.Item>
                    </Form>
                </Modal>

            </div>
        );
    }
}

export default Form.create()(HelpCenterSetting);