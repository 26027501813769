import { fork, all, takeEvery, call, put, take } from 'redux-saga/effects';
import helpCenterActionTypeKey from '../../components/webManager/actions/actionsTypeKey/helpCenterActionTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as  helpServer from '../../servers/helpServer';
import * as helpCenterActions from '../../components/webManager/actions/helpCenterActions';

function* initPage() {
    yield getHelpList();
}

function* getHelpList() {
    try {
        yield put(helpCenterActions.isLoading(true));
        const { result } = yield call(helpServer.getHelpList, 1);
        yield put(helpCenterActions.getHelpModalList(result));
        yield put(helpCenterActions.isLoading(false));
    } catch (e) {
        yield put(helpCenterActions.isLoading(false));
        openNotification('error', 'get help modal list fail');
    }
}

function* changeQuestionModalSort() {
    while (true) {
        const { payload } = yield take(helpCenterActionTypeKey.GET_CHANGE_HElP_MODAL_SORT);
        try {
            yield put(helpCenterActions.isLoading(true));
            yield call(helpServer.modifyHelpModalSort, payload);
            yield put(helpCenterActions.getLocationModalStatus(false));
            yield getHelpList();
            yield put(helpCenterActions.isLoading(false));
            openNotification('success', 'Success to change modal location');
        } catch (e) {
            openNotification('error', 'Fail to change modal location');
            yield put(helpCenterActions.isLoading(false));

        }
    }
}

function* addQuestionModal() {
    while (true) {
        const { payload } = yield take(helpCenterActionTypeKey.ADD_HELP_MODAL);
        try {
            yield put(helpCenterActions.isLoading(true));
            payload.serverType = 1;
            yield call(helpServer.addHelpModal, payload);
            yield put(helpCenterActions.getTitleModalStatus(false));
            yield getHelpList();
            yield put(helpCenterActions.isLoading(false));
            openNotification('success', 'Success to add question modal');
        } catch (e) {
            openNotification('error', 'Fail to add question modal');
            yield put(helpCenterActions.isLoading(false));
        }
    }
}

function* editQuestionModal() {
    while (true) {
        const { payload } = yield take(helpCenterActionTypeKey.EDIT_HELP_MODAL);
        try {
            yield put(helpCenterActions.isLoading(true));
            yield call(helpServer.editHelpModal, payload);
            yield put(helpCenterActions.getTitleModalStatus(false));
            yield getHelpList();
            yield put(helpCenterActions.isLoading(false));
            openNotification('success', 'Success to edit question modal');
        } catch (e) {
            openNotification('error', 'Fail to edit question modal');
            yield put(helpCenterActions.isLoading(false));
        }
    }
}

function* delQuestionModal() {
    while (true) {
        const { payload } = yield take(helpCenterActionTypeKey.DEL_HELP_MODAL);
        try {
            yield put(helpCenterActions.isLoading(true));
            yield call(helpServer.delHelpModal, payload);
            yield getHelpList();
            yield put(helpCenterActions.isLoading(false));
            openNotification('success', 'Success to delete question modal');
        } catch (e) {
            openNotification('error', 'Fail to delete question modal');
            yield put(helpCenterActions.isLoading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(helpCenterActionTypeKey.INIT_PAGE, initPage);
}

export function* watchWebHelpCenterSaga() {
    yield all([
        fork(watchInitPage),
        fork(changeQuestionModalSort),
        fork(addQuestionModal),
        fork(editQuestionModal),
        fork(delQuestionModal)
    ]);
}