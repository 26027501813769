import * as React from 'react';
import { Col, DatePicker, Divider, Form, Input, Row, Select, Table } from 'antd';
import { changeToStamp, timeReverseWithNoHour } from '../../../base/dealWithTime';
import TotalDataComponents from './TotalDataComponents';
import { FormComponentProps } from 'antd/lib/form';
import { CurrencyOrderState } from '../reducers/currencyOrderReducers';
import { Toolbar } from '../../widgets/Toolbar';
import { getExportTitles } from '../../../base/getExportTitles';
import { numFormat } from '../../../base/regular';
import { numberDivideWithComma } from '../../../base/base';
import { ExportModal } from '../../widgets/ExportModal';

const FormItem = Form.Item;
const Option = Select.Option;

interface  CurrencyOrderProps extends FormComponentProps {
    data: CurrencyOrderState;
    initPage: (type: number | string) => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getMonthlyListParam: (param: any) => void;
    getSearchDataParam: (param: any) => void;
}

interface CurrencyOrderInitialState {
    startTime: string;
    endTime: string;
    statusArr: any[];
}

class CurrencyOrder extends React.Component<CurrencyOrderProps, CurrencyOrderInitialState> {

    state: CurrencyOrderInitialState = {
        startTime: '',
        endTime: '',
        statusArr: ['UnPay', 'Completed', 'Pending'],
    };

    componentDidMount() {
        this.props.initPage(2);
    }

    // public action

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    clear = () => {
        this.setState({startTime: ''});
        this.setState({endTime: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {

        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            gtTime: this.state.startTime.length === 0 ? undefined : changeToStamp(this.state.startTime) / 1000,
            ltTime: this.state.endTime.length === 0 ? undefined : changeToStamp(this.state.endTime) / 1000,
            userName: value.name,
            userId: value.id,
            orderSn: value.orderSn,
            status: value.status,
            productType: value.gift,
            device: value.device
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.getSearchDataParam(param);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    // page action

    columns = () => {
        // const { data } = this.props;
        return [{
            title: 'Order number',
            dataIndex: 'orderSn',
            key: 'orderSn',
            fixed: true,
            width: 210,
            className: 'column-center',
        }, {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            className: 'column-center',
        }, {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
            className: 'column-center',
        }, {
            title: 'Device',
            dataIndex: 'device',
            key: 'device',
            className: 'column-center',
            render: (text: number) => {
                return <span>{text === 0 ? 'Android' : text === 1 ? 'iOS' : 'Wap'}</span>;
            }
        }, {
            title: 'Product Types',
            dataIndex: 'productType',
            key: 'productType',
            className: 'column-center',
            render: (text: string) => (`${numberDivideWithComma(text)} Koin`)
        },  {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            className: 'column-center',
            render: (text: number) => (numFormat(text))
        },  {
            title: 'Payment Channel',
            dataIndex: 'paymentChannel',
            key: 'paymentChannel',
            className: 'column-center',
        }, {
            title: 'Order Status',
            dataIndex: 'status',
            key: 'status',
            className: 'column-center',
            render: (text: number) => (<span>{this.state.statusArr[text]}</span>)
        }, {
            title: 'Order Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            className: 'column-center',
            sorter: true,
            sortField: 'createdAt'
        }, {
            title: 'Order Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
            className: 'column-center',
        }, {
            title: 'Payment Date',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            className: 'column-center',
            sorter: true,
            sortField: 'paymentAt'
        }, {
            title: 'Payment Time',
            dataIndex: 'paymentTime',
            key: 'paymentTime',
            className: 'column-center',
        }];
    }

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: timeReverseWithNoHour(startTime) + ' 00:00:00'});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: timeReverseWithNoHour(endTime) + ' 23:59:59'});
        } else {
            this.setState({endTime});
        }
    }

    render() {

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const {getFieldDecorator, getFieldsValue } = this.props.form;

        const { data, getUpdatePage } = this.props;

        const currencyTotalMsg = data.currencyTotalData;

        return (
          <div>

              <TotalDataComponents data={currencyTotalMsg}/>

              <Divider>Currency Order</Divider>

              <Form>
                  <Row gutter={16}>
                      <Col span={6}>
                          <FormItem label="Date">
                              {getFieldDecorator('startTime')(
                                  <DatePicker
                                      allowClear={true}
                                      format={'DD-MM-YYYY'}
                                      placeholder={'Start Time'}
                                      style={{width : '48%'}}
                                      onChange={this.startTimeChange}
                                  />
                              )}
                              {getFieldDecorator('endTime')(
                                  <DatePicker
                                      allowClear={true}
                                      format={'DD-MM-YYYY'}
                                      placeholder={'End Time'}
                                      style={{width : '48%', marginLeft : '4%'}}
                                      onChange={this.endTimeChange}
                                  />
                              )}
                          </FormItem>
                      </Col>
                      <Col span={6}>
                          <FormItem
                              label="User Name"
                          >
                              {getFieldDecorator('name')(
                                  <Input {...input}/>
                              )}
                          </FormItem>
                      </Col>
                      <Col span={6}>
                          <FormItem
                              label="User ID"
                          >
                              {getFieldDecorator('id')(
                                  <Input {...input}/>
                              )}
                          </FormItem>
                      </Col>
                      <Col span={6}>
                          <FormItem
                              label="Order Number"
                          >
                              {getFieldDecorator('orderSn')(
                                  <Input {...input}/>
                              )}
                          </FormItem>
                      </Col>
                      <Col span={8}>
                          <FormItem
                              label="Product Types "
                          >
                              {getFieldDecorator('gift', {
                                  initialValue: 'All'
                              })(
                                  <Select style={{ width: '100%' }} >
                                      <Option value="All">All</Option>
                                      {
                                          data.rechargeList.map((o: any, i: number) => {
                                              return <Option value={o.coinPrice} key={i} >{numFormat(o.coinPrice)} Koin</Option>;
                                          })
                                      }
                                  </Select>
                              )}
                          </FormItem>
                      </Col>
                      <Col span={8}>
                          <FormItem
                              label="Order Status"
                          >
                              {getFieldDecorator('status', {
                                  initialValue: 'All'
                              })(
                                  <Select style={{ width: '100%' }} >
                                      <Option value="All">All</Option>
                                      <Option value="0">UnPay</Option>
                                      <Option value="1">Completed</Option>
                                      <Option value="2">Pending</Option>
                                  </Select>
                              )}
                          </FormItem>
                      </Col>
                      <Col span={8}>
                          <FormItem
                              label="Device"
                          >
                              {getFieldDecorator('device', {
                                  initialValue: 'All'
                              })(
                                  <Select style={{ width: '100%' }} >
                                      <Option value="All">All</Option>
                                      <Option value="0">Android</Option>
                                      <Option value="1">iOS</Option>
                                  </Select>
                              )}
                          </FormItem>
                      </Col>
                  </Row>
              </Form>

              <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport} />

              <Table
                  rowKey="id"
                  loading={data.loading}
                  bordered={true}
                  columns={this.columns()}
                  style={{marginTop: '20px'}}
                  dataSource={data.currencyOrderList}
                  onChange={getUpdatePage}
                  scroll={{ x: 2100 }}
                  pagination={{
                      defaultPageSize: 20,
                      total: data.total,
                      current: data.page.current,
                      showSizeChanger: true,
                      pageSizeOptions: ['20', '50', '80', '100'],
                      showTotal: total => {
                          return `Total:${data.total} items`;
                      },
                  }}
              />

              <ExportModal
                  getFieldDecorator={getFieldDecorator}
                  getFieldsValue={getFieldsValue}
                  visible={data.exportModalVisible}
                  loading={data.loading}
                  onCancel={this.closeExportModal}
                  submitExport={this.submitExport}
              />
          </div>
        );
    }
}

export default Form.create()(CurrencyOrder);