import  CurrencyOrder  from '../CurrencyOrder';
import { connect } from 'react-redux';
import * as currencyOrderActions from '../../actions/currencyOrderActions';

const mapStateToProps = (state: any) => ({
    data: state.financial.currencyOrderData,
});

const mapDispatchToProps = {
    initPage: currencyOrderActions.initPage,
    getSearchDataParam: currencyOrderActions.getSearchDataParam,
    exportModalVisible: currencyOrderActions.exportModalVisible,
    submitExport: currencyOrderActions.submitExport,
    getUpdatePage: currencyOrderActions.getUpdatePage,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CurrencyOrder);