enum novelKey {
    NOVEL_LOADING = 'NOVEL_LOADING', // loading状态
    INIT_NOVEL_PAGE = 'INIT_NOVEL_PAGE', // 进入小说管理页面
    GET_NOVEL_LIST = 'GET_NOVEL_LIST', // 获取小说列表
    GET_NOVEL_TYPE_LIST = 'GET_NOVEL_TYPE_LIST', // 获取小说类型列表
    GET_NOVEL_SEARCH_PARAM = 'GET_NOVEL_SEARCH_PARAM', // 获取搜索小说参数
    GET_CHANGE_NOVEL_STATE_PARAM = 'GET_CHANGE_NOVEL_STATE_PARAM', // 获取修改状态参数
    GET_DEL_NOVEL_ID = 'GET_DEL_NOVEL_ID', // 获取删除小说id
    NOVEL_UPDATE_PAGE = 'NOVEL_UPDATE_PAGE', // 小说列表操作
    EXPORT_MODAL_VISIBLE = 'EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'SUBMIT_NOVEL_CONTNET_EXPORT',
    GET_SEARCH_AUTHOR_KEY = 'GET_SEARCH_AUTHOR_KEY', // 获取搜索作者关键字
    GET_KEY_AUTHOR_List = 'GET_KEY_AUTHOR_List', // 获取关键字作者列表
    GET_SEARCH_TYPE_KEY = 'GET_SEARCH_TYPE_KEY', // 获取搜索类型关键字
    GET_ADD_NOVEL_PARAM = 'GET_ADD_NOVEL_PARAM', // 获取添加小说信息
    GO_NOVEL_LIST = 'GO_NOVEL_LIST', // 去小说列表
    GO_NOVEL_DETAIL = 'GO_NOVEL_DETAIL', // 去小说详情
    CHANGE_NOVEL_UPDATE_STATUS = 'CHANGE_NOVEL_UPDATE_STATUS', // 修改小说更新状态
    GET_NOVEL_ID = 'GET_EDIT_NOVEL_ID', // 获取编辑小说ID
    GET_NOVEL_MSG_TO_ID = 'GET_NOVEL_MSG_TO_ID', // 根据id获取小说信息
    GET_NOVEL_COVERURL = 'GET_EDIT_NOVEL_COVERURL', // 获取编辑小说封面
}

export default novelKey;