import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

export function getReportList(param: any) {
    return postJson('/report/getChapterReportList', param);
}

export function modifyReportStatus(param: any) {
    return postJson('/report/modifyReportStatus', param);
}

export function modifyLockStatus(param: any) {
    return postJson('/chapter/modifyLockStatus', param);
}

export function exportList(param: any) {
    return postJsonForExport('/report/exportChapterReportList', param, param.exportName);
}

export function getReportStatistics() {
    return postJson('/report/getReportStatistics');
}
