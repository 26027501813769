import  RewardOrder  from '../RewardOrder';
import { connect } from 'react-redux';
import * as rewardOrderActions from '../../actions/rewardOrderActions';

const mapStateToProps = (state: any) => ({
    data: state.financial.rewardOrderData,
});

const mapDispatchToProps = {
    initPage: rewardOrderActions.initPage,
    getSearchDataParam: rewardOrderActions.getSearchDataParam,
    exportModalVisible: rewardOrderActions.exportModalVisible,
    submitExport: rewardOrderActions.submitExport,
    getUpdatePage: rewardOrderActions.getUpdatePage,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RewardOrder);