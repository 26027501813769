enum ActivityActionTypeKey {
    INIT_PAGE = 'ACTIVITY_INIT_PAGE',
    GET_ACTIVITY_LIST = 'GET_ACTIVITY_LIST',
    UPDATE_PAGE = 'ACTIVITY_UPDATE_PAGE',
    GET_DEL_ID = 'GET_DEL_ACTIVITY_ID',
    EXPORT_MODAL_VISIBLE = 'ACTIVITY_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'ACTIVITY_SUBMIT_EXPORT',
    GET_VIRTUAL_VOTE = 'GET_VIRTUAL_VOTE',
    GET_SEARCH_DATA = 'GET_VIRTUAL_SEARCH_DATA',
    LOADING = 'ACTIVITY_LOADING',
    ADD_BTN = 'ACTIVITY_ADD_BUTTON',
    GET_TYPE_LIST = 'ACTIVITY_GET_TYPE_LIST',
    GET_SEARCH_NOVEL_PARAM = 'ACTIVITY_GET_SEARCH_NOVEL_PARAM',
    GET_NOVEL_LIST = 'ACTIVITY_GET_NOVEL_LIST',
    GET_ADD_NOVEL_ID = 'ACTIVITY_GET_ADD_NOVEL_ID'
}

export default ActivityActionTypeKey;