import Vote  from '../Vote';
import { connect } from 'react-redux';
import * as voteAction from '../../action/voteAction';

const mapStateToProps = (state: any) => ({
    data: state.creation.vote
});

const mapDispatchToProps = {
    initPage: voteAction.initPage,
    getChangeParam: voteAction.getChangeParam,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Vote);