import AddNovel  from '../AddNovel';
import { connect } from 'react-redux';
import * as novelAction from '../../actions/novelManagerAction';

const mapStateToProps = (state: any) => ({
    data: state.content.novelManager,
});

const mapDispatchToProps = {
    getEditNovelId: novelAction.getEditNovelId,
    getSearchAuthorKey: novelAction.getSearchAuthorKey,
    getSearchTypeKey: novelAction.getSearchTypeKey,
    getAddNovelParam: novelAction.getAddNovelParam,
    getDelNovelId: novelAction.getDelNovelId,
    getKeyAuthorList: novelAction.getKeyAuthorList,
    getNovelTypeList: novelAction.getNovelTypeList,
    getNovelChangeStateParam: novelAction.getNovelChangeStateParam,
    getNovelCouverUrl: novelAction.getNovelCouverUrl,
    goNovelList: novelAction.goNovelList,
    goNovelDetail: novelAction.goNovelDetail,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddNovel);