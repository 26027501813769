import  QuarterlyList  from '../QuarterlyList';
import { connect } from 'react-redux';
import * as quarterlyListAction from '../../actions/quarterlyActions';

const mapStateToProps = (state: any) => ({
    data: state.star.quarterlyList
});

const mapDispatchToProps = {
    initPage: quarterlyListAction.initPage,
    exportModalVisible: quarterlyListAction.exportModalVisible,
    submitExport: quarterlyListAction.submitExport,
    getUpdatePage: quarterlyListAction.getUpdatePage,
    getQuarterlySearchData: quarterlyListAction.getQuarterlySearchData,
    quarterlyListConfirm: quarterlyListAction.quarterlyListConfirm,
    quarterlyRemoveRank: quarterlyListAction.quarterlyRemoveRank

};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(QuarterlyList);