import  ChapterActionTypeKeys  from './actionsTypeKey/chapterActionsTypeKey';

export function initPage() {
    return { type: ChapterActionTypeKeys.INIT_PAGE};
}

export function getChapterList(value: any) {
    return { type: ChapterActionTypeKeys.GET_CHAPTER_CONTENT_LIST_CHAPTER_CONTENT, payload: value };
}

export function dumpChapter(value: any) {
    return { type: ChapterActionTypeKeys.DUMP_CHAPTER, payload: value };
}

export function chapterSort(value: number) {
    return { type: ChapterActionTypeKeys.CHAPTER_SORT, payload: value };
}

export function loading(bool: boolean) {
    return { type: ChapterActionTypeKeys.LOADING, payload: bool };
}

export function modalVisible(bool: boolean) {
    return { type: ChapterActionTypeKeys.MODAL_VISIBLE, payload: bool };
}

export function sendChapterId(value: any) {
    return { type: ChapterActionTypeKeys.SEND_CHAPTER_ID, payload: value };
}

export function sendNovelValue(value: any) {
    return { type: ChapterActionTypeKeys.SEND_NOVEL_VALUE, payload: value };
}

export function getAllNovelList(value: any) {
    return { type: ChapterActionTypeKeys.GET_ALL_NOVEL_LIST_BY_NAME_CHAPTER_CONTENT, payload: value };
}

export function getNovelList(value: any) {
    return { type: ChapterActionTypeKeys.GET_NOVEL_LIST_BY_NAME_CHAPTER_CONTENT, payload: value };
}

export function downloadChapter(value: any) {
    return { type: ChapterActionTypeKeys.DOWNLOAD_CHAPTER, payload: value };
}
