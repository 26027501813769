enum AdBannerActionsTypeKey {
    INIT_PAGE = 'ADBANNER_PAGE_INIT_PAGE',
    LOADING = 'ADBANNER_PAGE_LOADING',
    GET_ADBANNER_LIST = 'GET_ADBANNER_LIST',
    LIST_DATA_SEARCH = 'ADBANNER_LIST_DATA_SEARCH',
    ADD_BANNER = 'ADBANNER_PAGE_ADD_BANNER',
    MODAL_LOADING = 'ADBANNER_PAGE_MODAL_LOADING',
    MODAL_VISIBLE = 'ADBANNER_PAGE_MODAL_VISIBLE',
    BANNER_SORT = 'ADBANNER_PAGE_BANNER_SORT',
    MODIFY_BANNER_STATUS = 'ADBANNER_PAGE_MODIFY_BANNER_STATUS',
    DELETE_BANNER = 'ADBANNER_PAGE_DELETE_BANNER',
    SEND_NOVEL_VALUE = 'ADBANNER_PAGE_SEND_NOVEL_VALUE',
    GET_NOVEL_LIST = 'ADBANNER_PAGE_GET_NOVEL_LIST',
    GET_ALL_NOVEL_LIST = 'ADBANNER_PAGE_GET_ALL_NOVEL_LIST',
    EXPORT_BANNER_LIST = 'ADBANNER_EXPORT_BANNER_LIST',
}

export default AdBannerActionsTypeKey;