enum BannerActionsTypeKey {
    INIT_PAGE = 'CONTENT_BANNER_PAGE_INIT_PAGE',
    LOADING = 'CONTENT_BANNER_PAGE_LOADING',
    GET_CONTENT_BANNER_LIST = 'GET_CONTENT_BANNER_LIST',
    LIST_DATA_SEARCH = 'CONTENT_BANNER_LIST_DATA_SEARCH',
    ADD_BANNER = 'CONTENT_BANNER_PAGE_ADD_BANNER',
    MODAL_LOADING = 'CONTENT_BANNER_PAGE_MODAL_LOADING',
    MODAL_VISIBLE = 'CONTENT_BANNER_PAGE_MODAL_VISIBLE',
    BANNER_SORT = 'CONTENT_BANNER_PAGE_BANNER_SORT',
    MODIFY_BANNER_STATUS = 'CONTENT_BANNER_PAGE_MODIFY_BANNER_STATUS',
    DELETE_BANNER = 'CONTENT_BANNER_PAGE_DELETE_BANNER',
    SEND_NOVEL_VALUE = 'CONTENT_BANNER_PAGE_SEND_NOVEL_VALUE',
    GET_NOVEL_LIST = 'CONTENT_BANNER_PAGE_GET_NOVEL_LIST',
    GET_ALL_NOVEL_LIST = 'CONTENT_BANNER_PAGE_GET_ALL_NOVEL_LIST',
    EXPORT_BANNER_LIST = 'CONTENT_BANNER_EXPORT_BANNER_LIST',
}

export default BannerActionsTypeKey;