import RecommendDetail from '../RecommendDetail';
import { connect } from 'react-redux';
import * as recommendDetailAction from '../../action/recommendDetailAction';

const mapStateToProps = (state: any) => ({
    data: state.creation.recommendDetail
});

const mapDispatchToProps = {
    initPage: recommendDetailAction.initPage,
    getFloorIdToRequest: recommendDetailAction.getFloorIdToRequest,
    updatePage: recommendDetailAction.updatePage,
    changeFloorId: recommendDetailAction.changeFloorId,
    getDelNovelMsg: recommendDetailAction.getDelNovelMsg,
    openAddEditFloorNovelModal: recommendDetailAction.openAddEditFloorNovelModal,
    setLimitFreeModalShow: recommendDetailAction.setLimitFreeModalShow,
    submitLimitFreeSetting: recommendDetailAction.submitLimitFreeSetting,
    getSearchNovelParam: recommendDetailAction.getSearchNovelParam,
    addRecommendNovel: recommendDetailAction.addRecommendNovel,
    editRecommendNovel: recommendDetailAction.editRecommendNovel,
    getSearchNovelList: recommendDetailAction.getSearchNovelList,
    getChangeNovelLocationMsg: recommendDetailAction.getChangeNovelLocationMsg
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RecommendDetail);