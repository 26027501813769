enum BannerDataActionsTypeKey {
    EXPORT_MODAL_VISIBLE = 'BANNER_DATA_EXPORT_MODAL_VISIBLE',
    BANNER_DATA_PAGE_INIT = 'BANNER_DATA_PAGE_INIT',
    SUBMIT_BANNER_DATA_EXPORT = 'SUBMIT_BANNER_DATA_EXPORT',
    LOADING = 'BANNER_DATA_LOADING',
    GET_BANNER_DATA_LIST = 'GET_BANNER_DATA_LIST',
    BANNER_DATA_LIST_DATA_SEARCH = 'BANNER_DATA_LIST_DATA_SEARCH',
    UPDATE_PAGE = 'UPDATE_PAGE_BANNER_DATA',
}

export default BannerDataActionsTypeKey;