import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Form, Row, Col, Select, Spin, Popconfirm, Button, Modal, AutoComplete, Table, Badge } from 'antd';
import { Link } from 'react-router-dom';

import { StoreState } from '../reducers/novelReducer';

const FormItem = Form.Item;
const Option = Select.Option;

interface NovelProps {
    data: StoreState;
    initPage: () => void;
    spiderStart: () => void;
    novelListSearch: (value: any) => void;
    downloadNovel: (value: any) => void;
    dumpNovel: (value: any) => void;
    sendBriefId: (value: any) => void;
    briefModalVisible: (value: boolean) => void;
    sendNovelValue: (value: boolean) => void;
    dumpModalVisible: (value: boolean) => void;
    checkSpiderStatus: () => void;
    getResourceTableChange: (pagination: any, filters: any, sorter: any) => void;
}

interface NovelState {
    autoChange: boolean;
    spiderId: number;
}

class ResourceManager extends React.Component<NovelProps & FormComponentProps, NovelState> {

    state: NovelState = {
        autoChange: true,
        spiderId: -1,
    };

    componentDidMount() {
        let isInit = sessionStorage.getItem('isInit');
        if (isInit !== 'true') {
            this.props.initPage();
        }
    }

    componentWillUnmount() {
        window.sessionStorage.setItem('isInit', 'false');
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param = {
            name: value.novel_search,
            updatedStatus: value.update_search,
            uploaded: value.dump_search,
        };
        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.novelListSearch(param);
    }

    clear = () => {
        this.props.form.resetFields();
    }

    download = (value: any) => {
        this.props.downloadNovel(value);
    }

    showNovelModal = (value: any) => {
        this.props.form.resetFields(['novel_id']);
        this.props.dumpModalVisible(true);
        this.setState({spiderId: value});
        let dumpDataList: any = localStorage.getItem('dumpListData');
        let parseDumpDataList = JSON.parse(dumpDataList);
        if (parseDumpDataList !== null) {
            parseDumpDataList.forEach((item: any) => {
                if (item.spiderId === value) {
                    this.props.form.setFieldsValue({
                        novel_id: item.name
                    });
                }
            });
        }
    }

    hideNovelModal = () => {
        this.props.form.resetFields(['novel_id']);
        this.props.dumpModalVisible(false);
    }

    briefModalCancel = () => {
        this.props.briefModalVisible(false);
    }

    spiderStart = () => {
        this.props.spiderStart();
    }

    dumpNovel = () => {
        const value: any = this.props.form.getFieldsValue();
        let param = {
            spiderNovelId: this.state.spiderId,
            novelId: -1
        };
        const novelAllList = this.props.data.novelAllList;
        let dumpDataList: any = localStorage.getItem('dumpListData');
        let parseDumpDataList = JSON.parse(dumpDataList);
        for (let i = 0; i < novelAllList.length; i++) {
            if (novelAllList[i].name === value.novel_id) {
                param.novelId = novelAllList[i].id;
            }
        }
        if (param.novelId === -1) {
            for (let i = 0; i < parseDumpDataList.length; i++) {
                if (parseDumpDataList[i].name === value.novel_id) {
                    param.novelId = parseDumpDataList[i].id;
                }
            }
        }
        if (parseDumpDataList !== null) {
            let data = {spiderId: this.state.spiderId, id: param.novelId, name: value.novel_id};
            let isFirst = true;
            parseDumpDataList.map((item: any) => {
                if (item.spiderId !== data.spiderId && item.id !== data.id && item.name !== data.name && isFirst) {
                    isFirst = false;
                    parseDumpDataList.push(data);
                    let stringDumpDataList = JSON.stringify(parseDumpDataList);
                    localStorage.setItem('dumpListData', stringDumpDataList);
                }
            });
        } else {
            let data = [{spiderId: this.state.spiderId, id: param.novelId, name: value.novel_id}];
            let stringData = JSON.stringify(data);
            localStorage.setItem('dumpListData', stringData);
        }
        this.props.dumpNovel(param);
    }

    showBriefModal = (value: any) => {
        this.props.sendBriefId(value);
    }

    urlConfirm = (url: any) => {
        Modal.info({
            content: (
                <div>
                    <a href={url} target="_blank">{url}</a>
                </div>
            )
        });
    }

    goChapter = () => {
        window.sessionStorage.setItem('isInit', 'false');
    }

    columns = () => {
        return [
            {
                title: 'Novel Name',
                dataIndex: 'name',
                key: 'name',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return  (
                        <Badge status={record.updatedStatus ? 'error' : 'default'}>
                            <Link onClick={() => this.goChapter()} to={{pathname: '/content/chapter', search: `id=${record.id}&name=${record.name}`}} style={{color: '#2392FC', textDecoration: 'underline'}}>
                                {text}
                            </Link>
                        </Badge>
                    );
                }
            },
            {
                title: 'Setting',
                dataIndex: 'setting',
                key: 'setting',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            <Button onClick={() => this.urlConfirm(record.fromUrl)} style={{margin: '0 10px', float: 'right'}}>Url</Button>
                            <Button onClick={() => this.showBriefModal(record.id)} style={{margin: '0 10px', float: 'right'}}>Brief</Button>
                            <Button disabled={record.updatedStatus === 0 ? true : false} onClick={() => this.showNovelModal(record.id)} style={{ float: 'right'}}>Dump</Button>
                            <Button onClick={() => this.download(record)} style={{margin: '0 10px', float: 'right'}}>Download</Button>
                        </div>
                    );
                }
            }
        ];
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {data, getResourceTableChange} = this.props;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const form = {
            style: {margin: '10px 30px'}
        };

        const upDateList = [
            {key: 'All', value: 'All'},
            {key: 1, value: 'Has update'},
            {key: 0, value: 'No update'}
        ];

        const dumpList = [
            {key: 'All', value: 'All'},
            {key: 1, value: 'Dump'},
            {key: 0, value: 'Not Dump'}
        ];

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormItem
                                    label="Novel Name"
                                    {...form}
                                >
                                    {getFieldDecorator('novel_search')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="Novel Status" {...form}>
                                    {getFieldDecorator('update_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {upDateList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="Dump" {...form}>
                                    {getFieldDecorator('dump_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {dumpList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col offset={16} span={8}>
                                <Popconfirm
                                    title="Are you sure crawl?"
                                    onConfirm={() => this.spiderStart()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button disabled={data.spiderStatus} style={{float: 'right', marginTop: '53px'}} type="primary" >Crawl</Button>
                                </Popconfirm>
                                <Button className="novelClearButton" type="primary" onClick={() => this.clear()}>Clear</Button>
                                <Button className="novelButton" type="primary" onClick={() => this.onSearch()}>Search</Button>
                            </Col>
                        </Row>
                    </Form>

                    <Table
                        rowKey="id"
                        bordered={true}
                        columns={this.columns()}
                        style={{marginTop: '20px'}}
                        dataSource={data.getNovelList}
                        onChange={getResourceTableChange}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total:${data.total} items`;
                            },
                        }}
                    />
                </Spin>
                <Modal
                    title="Novel"
                    visible={data.dumpModalVisible}
                    footer={null}
                    onCancel={() => this.hideNovelModal()}
                    maskClosable={false}
                    mask={false}
                >
                    <Form layout="vertical">
                        <FormItem
                            label="Novel"
                        >
                            {
                                getFieldDecorator('novel_id')(
                                    <AutoComplete
                                        dataSource={this.state.autoChange ? [] : data.novelList}
                                        disabled={false}
                                        onChange={(value: any) => {
                                            this.setState({autoChange: false});
                                            this.props.sendNovelValue(value);
                                        }}
                                    />
                                )
                            }
                        </FormItem>
                    </Form>
                    <Button onClick={() => this.dumpNovel()} type="primary" style={{marginTop: '20px'}}>Submit</Button>
                </Modal>
                <Modal
                    title="Brief"
                    visible={data.briefModalVisible}
                    footer={null}
                    onCancel={() => this.briefModalCancel()}
                    maskClosable={false}
                    mask={false}
                >
                    <Spin spinning={data.loading}>
                        <div style={{textIndent: '24px'}}>
                            {data.getBriefList}
                        </div>
                    </Spin>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(ResourceManager);
