import { combineReducers } from 'redux';

import { novelDataReducer } from './novelDataReducer';
import { dayDataReducer } from './dayDataReducer';
import { bannerDataReducer } from './bannerDataReducer';
import { recommendDataReducer } from './recommendDataReducer';
import { chapterDataReducer } from './chapterDataReducer';
import { floorDataReducer } from './floorDataReducer';
import { novelTotalDataReducer } from './novelTotalDataReducer';
import { novelUpdateDataReducer } from './novelUpdateDataReducer';

export default combineReducers({
    novelData: novelDataReducer,
    dayData: dayDataReducer,
    bannerData: bannerDataReducer,
    recommendData: recommendDataReducer,
    chapterData: chapterDataReducer,
    floorData: floorDataReducer,
    novelTotalData: novelTotalDataReducer,
    novelUpdateData: novelUpdateDataReducer,
});