enum  paidnovelTypeKey {
    INIT_PAGE = 'PAID_NOVEL_INIT_PAGE',
    LOADING = 'PAID_NOVEL_LOADING',
    UPDATE_PAGE = 'PAID_NOVEL_UPDATE_PAGE',
    SEARCH_DATA = 'PAID_NOVEL_SEARCH_DATA',
    EXPORT_MODAL_VISIBLE = 'PAID_NOVEL_EXPORT_MODAL_VISIBLE',
    SUBMIT_PAID_NOVEL_EXPORT = 'PAID_NOVEL_SUBMIT_PAID_NOVEL_EXPORT',
    GET_TYPE_DATA_LIST = 'PAID_NOVEL_GET_TYPE_DATA_LIST',
    GET_TOTAL_DATE = 'PAID_NOVEL_GET_TOTAL_LIST'
}
export default paidnovelTypeKey;