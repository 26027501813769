enum TypeActionsTypeKey {
    TYPE_INIT_PAGE = 'TAG_INIT_PAGE', // 类型页面进入
    GET_TYPE_LIST = 'GET_TAG_LIST', // 获取类型列表
    GET_TYPE_SEARCH_PARAM = 'GET_TAG_SEARCH_PARAM', // 获取type搜索参数
    GET_ADD_EDIT_TYPE = 'TAG_GET_ADD_EDIT_TYPE', // 获取添加/修改类型
    GET_BETCH_DEL_TYPE_ID = 'GET_BETCH_DEL_TAG_ID', // 获取删除类型id集合
    CHANGE_TAG_STATUS = 'CHANGE_TAG_STATUS', // 修改tag状态
    SETTING_RULE = 'SETTING_RULE', // 触发点击规则按钮
    GET_RULE_SELECTED = 'GET_RULE_SELECTED', // 获取默认选中规则
    GET_SORTER_RULE = 'GET_SORTER_RULE', // 获取排序规则
    GET_SORTER_CHANGE_ID = 'GET_SORTER_CHANGE_ID', // 获取修改规则id
    CHANGE_TAG_SORT = 'CHANGE_TAG_SORT', // 修改tag位置
    LOADING = 'LOADING_TAG_WITHDRAWALS',
    EXPORT_MODAL_VISIBLE = 'TAG_EXPORT_TYPE_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'SUBMIT_TAG_LIST_EXPORT',
    UPDATE_PAGE = 'TAG_UPDATE_PAGE',
}

export default TypeActionsTypeKey;