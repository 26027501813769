import novelTotalDataTypeKey from '../actions/actionsTypeKey/novelTotalDataTypeKey';
import { ActionTypes } from '../actions/actionsType/novelTotalDataType';

export interface SearchData {
    title: string;
    data: any[];
}

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    total: number;
    page: PageData;
    sorter: any;
    searchData: any;
    loading: boolean;
    typeList: any[];
    exportModalVisible: boolean;
    dataList: any[];
}

const initialState: StoreState = {
    total: 0,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    searchData: {},
    dataList: [],
    typeList: [],
    loading: false,
    exportModalVisible: false,
};

export const novelTotalDataReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case novelTotalDataTypeKey.INIT_PAGE:
            return initialState;

        case novelTotalDataTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case novelTotalDataTypeKey.GET_TYPE_DATA_LIST:
            return Object.assign({}, state, {
                typeList: action.payload
            });

        case novelTotalDataTypeKey.GET_TOTAL_DATE:
            return Object.assign({}, state, {
                dataList: action.payload.data,
                total: action.payload.total
            });

        case novelTotalDataTypeKey.SEARCH_DATA:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case novelTotalDataTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize,
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'DESC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC'),
                }
            });

        case novelTotalDataTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload
            });

        default:
            return state;
        }
};