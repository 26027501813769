import { fork, all, put, call, take } from 'redux-saga/effects';
import AddWebActionsTypeKey from '../../components/webManager/actions/actionsTypeKey/addWebManagerActionsTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as webServer from '../../servers/webServer';
import * as addWebAction from '../../components/webManager/actions/addWebAction';
import { uploadFile } from '../../servers/ossServer';

function* watchAddDataList() {
    while (true) {
        try {
            const {payload} = yield take(AddWebActionsTypeKey.ADD_DATA_LIST);
            yield put(addWebAction.loading(true));
            if (payload.contents.name) {
                payload.content = yield uploadFile('banner', payload.contents);
                // 后端获取图片oss地址
                // let result = yield getImageUrl(payload.content);
                payload.content = `<img src="${payload.content}" />`;
            } else {
                payload.content = payload.contents;
            }
            delete payload.contents;
            yield call(webServer.addBulletin, payload);
            yield put(addWebAction.loading(false));
            openNotification('success', 'Add success.');
            yield window.history.go(-1);
        } catch (error) {
            openNotification('error', 'Add failed.');
            yield put(addWebAction.loading(false));
        }
    }
}

export function* watchAddWebManagerRootSaga() {
    yield all([
        fork(watchAddDataList),
    ]);
}