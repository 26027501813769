import recommendDetailActionsTypeKey from '../action/actionsTypeKey/recommendLocationDetailActionsTypeKey';
import { ActionTypes } from '../action/actionsType/recommendLocationDetailActionType';

interface Page {
    current: number;
    pageSize: number;
}

export interface StoreState {
    novelList: any[];
    floorList: any[];
    typeList: any[];
    searchList: any[];
    floorId: string | number;
    loading: boolean;
    page: Page;
    sorter: any;
    total: number;
    addEditNovelVisible: boolean;
}

const initialState: StoreState = {
    novelList: [],
    floorList: [],
    typeList: [],
    searchList: [],
    floorId: 0,
    loading: false,
    page: {
        current: 1,
        pageSize: 10
    },
    sorter: {},
    total: 1,
    addEditNovelVisible: false,

};

export const recommendLocationDetailReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case recommendDetailActionsTypeKey.INIT_PAGE:
            return initialState;

        case recommendDetailActionsTypeKey.GET_FLOOR_ID_TO_REQUEST:
            return Object.assign({}, state, {
               floorId: action.payload
            });

        case recommendDetailActionsTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case recommendDetailActionsTypeKey.GET_FLOOR_LIST:
            return Object.assign({}, state, {
                floorList: action.payload
            });

        case recommendDetailActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case recommendDetailActionsTypeKey.GET_FLOOR_NOVEL_LIST:
            return Object.assign({}, state, {
                novelList: action.payload.data,
                total: action.payload.total
            });

        case recommendDetailActionsTypeKey.CHANGE_FLOOR_ID:
            return Object.assign({}, state, {
               page: {
                   current: 1,
                   pageSize: 10,
               },
               novelList: [],
               floorId: action.payload
            });

        case recommendDetailActionsTypeKey.ADD_EDIT_FLOOR_NOVEL_MODAL:
            return Object.assign({}, state, {
                addEditNovelVisible: action.payload
            });

        case recommendDetailActionsTypeKey.GET_NOVEL_TYPE_LIST:
            return Object.assign({}, state, {
               typeList: action.payload
            });

        case recommendDetailActionsTypeKey.GET_SEARCH_NOVEL_LIST:
            return Object.assign({}, state, {
               searchList: action.payload
            });

        default:
            return state;
    }
};
