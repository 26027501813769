import Author  from '../Author';
import { connect } from 'react-redux';
import * as authorAction from '../../actions/authorAction';

const mapStateToProps = (state: any) => ({
    data: state.user.author
});

const mapDispatchToProps = {
    initPage: authorAction.initPage,
    authorListSearch: authorAction.authorListSearch,
    updatePage: authorAction.updatePage,
    getAuthorId: authorAction.getAuthorId,
    exportModalVisible: authorAction.exportModalVisible,
    submitExport: authorAction.submitExport,
    getAuthorStateChangeParam: authorAction.getAuthorStateChangeParam,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Author);