import ChapterActionTypeKeys from '../actions/actionsTypeKey/chapterActionsTypeKey';
import {  ActionTypes } from '../actions/actionsType/chapterActionsType';

export interface StoreState {
    getChapterList: any;
    novelList: any;
    allNovelList: any;
    loading: boolean;
    modalVisible: boolean;
}

const initialState: StoreState = {
    loading: false,
    modalVisible: false,
    getChapterList: [],
    novelList: [],
    allNovelList: [],
};

export const chapterReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case ChapterActionTypeKeys.INIT_PAGE:
            return initialState;

        case ChapterActionTypeKeys.GET_CHAPTER_CONTENT_LIST_CHAPTER_CONTENT:
            return Object.assign({}, state, {
                getChapterList: action.payload,
            });

        case ChapterActionTypeKeys.LOADING:
            return Object.assign({}, state, {
                loading: action.payload,
            });

        case ChapterActionTypeKeys.MODAL_VISIBLE:
            return Object.assign({}, state, {
                modalVisible: action.payload,
            });

        case ChapterActionTypeKeys.GET_NOVEL_LIST_BY_NAME_CHAPTER_CONTENT:
            return Object.assign({}, state, {
                novelList: action.payload,
            });

        case ChapterActionTypeKeys.GET_ALL_NOVEL_LIST_BY_NAME_CHAPTER_CONTENT:
            return Object.assign({}, state, {
                allNovelList: action.payload,
            });

        default:
            return state;
    }
};