import * as React from 'react';
import { Input, Form, Row, Col, Table, Button, Modal, Select, Spin, Popconfirm } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Toolbar } from '../../widgets/Toolbar';
import { isInt } from '../../../base/regular';

const FormItem = Form.Item;
const Option = Select.Option;

interface Props extends FormComponentProps {
    data: any;
    initPage: () => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getSearchData: (param: any) => void;
    getModalSearchParam: (param: any) => void;
    getAddOrChangeParam: (param: any) => void;
    getModalSearchNovelList: (param: any) => void;
    getDelNovelId: (id: string | number) => void;
    getSortNumber: (param: any) => void;
}

class BookShelfManager extends React.Component<Props, any> {

    state = {
        addModal: false,
        addOrChange: 'add',
        bookShelfRecommendId: 0,
        bookShelfSort: 0,
        bookShelfSortNovelId: 0,
        changeSortModal: false
    };

    componentDidMount() {
        this.props.initPage();
    }

    // public action
    clear = () => {
        this.setState({searchMonth: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {
            const value: any = this.props.form.getFieldsValue();
            const param: any = {
                novelName: value.name,
                novelId: value.id,
            };

            for (let item in param) {
                if (param[item] === 'All' || param[item] === '') {
                    delete param[item];
                }
            }

            this.props.getSearchData(param);
    }

    // page action
    changeNovel = (record: any) => {
        let data: any = [];
        this.props.getModalSearchNovelList(data);
        this.props.form.setFieldsValue({
            typeId: '', search_name: '', search_id: ''
        });
        this.setState({addOrChange: 'change', addModal: true, bookShelfRecommendId: record.id});
    }

    addNovelButton = () => {
        let data: any = [];
        this.props.getModalSearchNovelList(data);
        this.props.form.setFieldsValue({
            typeId: '', search_name: '', search_id: ''
        });
        this.setState({addModal: true, addOrChange: 'add'});
    }

    addModelCancel = () => {
        this.setState({addModal: false});
    }

    addNovelSearchDataList = () => {
        const value: any = this.props.form.getFieldsValue(['typeId', 'search_name', 'search_id']);
        let param =  {
            name: value.search_name,
            typeId: value.typeId,
            id: value.search_id
        };
        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.getModalSearchParam(param);
    }

    addRecommend = (id?: string | number) => {
        const { addOrChange, bookShelfRecommendId } = this.state;
        let param: any;
        if (addOrChange === 'add') {
            param = {
                novelId: id,
                type: addOrChange
            };
        } else {
            param = {
                id: bookShelfRecommendId,
                novelId: id,
                type: addOrChange
            };
        }
        this.props.getAddOrChangeParam(param);
        this.addModelCancel();
    }

    delNovel = (record: any) => {
        this.props.getDelNovelId(record.id);
    }

    changeSort = (record: any) => {
        this.setState({bookShelfSort: record.sort, bookShelfSortNovelId: record.id, changeSortModal: true});
        this.props.form.setFieldsValue({sorterId: record.sort});
    }
    changeSortModalOk = () => {
        const { bookShelfSortNovelId } = this.state;
        const value: any = this.props.form.getFieldsValue(['sorterId']);
        let param = {
            id: bookShelfSortNovelId,
            location: value.sorterId
        };
        this.props.getSortNumber(param);
        this.changeSortModalCancel();
    }

    changeSortModalCancel = () => {
        this.setState({bookShelfSort: 0, changeSortModal: false});
    }

    columns = () => {
        return [{
            title: 'Serial Number',
            dataIndex: 'sort',
            key: 'sort',
            className: 'column-center',
            render: (text: number, record: any) => {
                return <a href="javascript: void(0)" style={{color: '#1890ff', marginRight: '15px'}} onClick={() => {this.changeSort(record); }}>{text}</a>;
            }
        }, {
            title: 'Novel ID',
            dataIndex: 'novelId',
            key: 'novelId',
            className: 'column-center',
        }, {
            title: 'Novel Name',
            dataIndex: 'novelName',
            key: 'novelName',
            className: 'column-center',
        }, {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            className: 'column-center',
        }, {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            className: 'column-center',
        }, {
            title: 'Chapter Number',
            dataIndex: 'chapters',
            key: 'chapters',
            className: 'column-center',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any) => {
                return (
                    <div>
                        <a href="javascript: void(0)" style={{color: '#1890ff', marginRight: '15px'}} onClick={() => {this.changeNovel(record); }}>Change</a>
                        <Popconfirm
                            title="Are you sure delete this novel?"
                            onConfirm={() => {this.delNovel(record); }}
                            okText="Yes"
                            cancelText="No"
                        >
                        <a href="javascript: void(0)" style={{color: 'red'}}>Delete</a>
                        </Popconfirm>
                    </div>
                );
            }
        }];
    }

    render () {

        const {getFieldDecorator } = this.props.form;
        const input = {
            style: {width: '100%', color: '#000'}
        };
        const { addModal, bookShelfSort, changeSortModal } = this.state;
        const { data, getUpdatePage,  } = this.props;
        return (
            <div>
                <Form>
                    <Row gutter={16}>
                        
                        <Col span={6}>
                            <FormItem
                                label="Novel Name"
                            >
                                {getFieldDecorator('name')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Novel ID"
                            >
                                {getFieldDecorator('id')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                <Toolbar showExport={false} onSearch={this.onSearch} onClear={this.clear}>
                    <Button type="primary" onClick={this.addNovelButton}>Add Novel</Button>
                </Toolbar>
                <Table
                    rowKey="novelId"
                    loading={data.loading}
                    bordered={true}
                    columns={this.columns()}
                    style={{marginTop: '20px'}}
                    dataSource={data.bookShelfList}
                    onChange={getUpdatePage}
                    pagination={{
                        defaultPageSize: 20,
                        total: data.total,
                        current: data.page.current,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '50', '80', '100'],
                        showTotal: total => {
                            return `Total:${data.total} items`;
                        },
                    }}
                />
                <Modal
                    visible={addModal}
                    onCancel={this.addModelCancel}
                    footer={null}
                    width="60%"
                    maskClosable={false}
                >
                    <Spin spinning={data.loading}>
                        <Row>
                            <Col span={16}>
                                <Form>
                                    <Row>
                                        <Col span={6}>
                                            <FormItem label="Category">
                                                {getFieldDecorator('typeId')(
                                                    <Select>
                                                        <Option key="All">All</Option>
                                                        {data.typeList.map((item: any) =>
                                                            <Option key={item.id}>
                                                                {item.name}
                                                            </Option>
                                                        )}
                                                    </Select>
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={6} offset={1}>
                                            <FormItem label="Novel ID">
                                                {getFieldDecorator('search_id')(
                                                    <Input/>
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={6} offset={1}>
                                            <FormItem label="Novel Name">
                                                {getFieldDecorator('search_name')(
                                                    <Input/>
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col span={3}>
                                <Button style={{marginTop: '30px'}} type="primary" onClick={this.addNovelSearchDataList}>Search</Button>
                            </Col>
                        </Row>
                        <ul className="addNovelList">
                            {
                                data.modalSearchNovelList.length > 0 ?
                                    data.modalSearchNovelList.map((item: any, idx: number) => (
                                        <li key={idx}>
                                            <div className="noveList">
                                                <p>
                                                    <img src={item.coverUrl} alt={item.name}/>
                                                    <span className="novelName">{item.name}</span>
                                                    <div style={{marginTop: 4}}>ID: {item.id}</div>
                                                </p>
                                                <Button
                                                    type="primary"
                                                    icon="plus"
                                                    onClick={(e: any) => {this.addRecommend(item.id); }}
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                        </li>
                                    )) : <div className="recBackground">Please click the search button to add content</div>
                            }
                        </ul>
                    </Spin>
                </Modal>
                <Modal
                    title="Serial Number"
                    visible={changeSortModal}
                    onOk={this.changeSortModalOk}
                    onCancel={this.changeSortModalCancel}
                    maskClosable={false}
                >
                    <Form>
                        <FormItem>
                            {getFieldDecorator('sorterId', {
                                initialValue: bookShelfSort,
                                rules: [
                                    {validator: isInt}
                                ]
                            })(
                                <Input />
                            )}
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(BookShelfManager);