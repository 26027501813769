import currencyOrderKey from '../actions/actionsTypeKey/currencyOrderActionsTypeKey';

// public action

export function initPage(type: number | string) {
    return { type: currencyOrderKey.INIT_PAGE, payload: type };
}

export function loading(value: boolean) {
    return { type: currencyOrderKey.LOADING, payload: value };
}
export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: currencyOrderKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: currencyOrderKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: currencyOrderKey.SUBMIT_EXPORT, payload: value };
}

export function getTotalCurrencyOrder(param: any) {
    return { type: currencyOrderKey.GET_TOTAL_CURRENCY_ORDER, payload: param };
}

export function getCurrencyOrderList(list: any) {
    return { type: currencyOrderKey.GET_CURRENCY_ORDER_LIST, payload: list };
}

export function getSearchDataParam(param: any) {
    return { type: currencyOrderKey.SEARCH_DATA, payload: param };
}

export function getRechargeList(param: any) {
    return { type: currencyOrderKey.GET_CURRENCY_RECHARGE_LIST, payload: param };
}