import dataAnalysisActionsTypeKey from '../actions/actionsTypeKey/dayDataActionsTypeKey';
import { ActionTypes } from '../actions/actionsType/dayDataActionsType';
import { DayDataList } from '../../../servers/responseTypes';

export interface SearchData {
    title: string;
    data: any[];
}

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    total: number;
    page: PageData;
    sorter: any;
    searchData: any;
    loading: boolean;
    exportModalVisible: boolean;
    DayDataList: DayDataList[];
}

const initialState: StoreState = {
    total: 0,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    searchData: {},
    DayDataList: [],
    loading: false,
    exportModalVisible: false,
};

export const dayDataReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case dataAnalysisActionsTypeKey.DAY_DATA_PAGE_INIT:
            return initialState;

        case dataAnalysisActionsTypeKey.GET_DAY_DATA_LIST:
            return Object.assign({}, state, {
                DayDataList: action.payload.data,
                total: action.payload.total
            });

        case dataAnalysisActionsTypeKey.DAY_DATA_LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case dataAnalysisActionsTypeKey.DAY_DATA_LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case dataAnalysisActionsTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize,
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'DESC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC'),
                }
            });

        case dataAnalysisActionsTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload,
            });

        default:
            return state;
        }
};