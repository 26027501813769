enum authorActionTypeKeys {
    INIT_PAGE = 'AUTHOR_INIt_PAGE', // 作者管理进入页面
    GET_AUTHOR_LIST = 'GET_AUTHOR_LIST', // 获取作者列表
    AUTHOR_SEARCH_PARAM = 'AUTHOR_SEARCH_PARAM', // 获取搜索关键词
    UPDATE_PAGE = 'UPDATE_PAGE_AUTHOR',
    GET_AUTHOR_ID = 'GET_AUTHOR_ID', // 获取作者id
    GET_AUTHOR_NOVEL_LIST = 'GET_AUTHOR_NOVEL_LIST', // 获取作者小说列表
    GET_AUTHOR_STATE_PARAM = 'GET_AUTHOR_STATE_PARAM', // 获取启用/禁用作者id
    LOADING = 'AUTHOR_LOADING',
    EXPORT_MODAL_VISIBLE = 'AUTHOR_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'SUBMIT_AUTHOR_LIST_EXPORT',
}

export default authorActionTypeKeys;