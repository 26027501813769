import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 获取小说总数据列表
 * @param: 0: vip订单 1：打赏订单 2：书币充值订单
 */
export function getNovelTotalList(param?: any) {
    return postJson('/financial/getTotalVipOrder', param);
}

/**
 * 获取打赏计费点
 */
export function getGiftList(param?: any) {
    return postJson('/reward/getRewardProps', param);
}

/**
 * 获取充值计费点
 */
export function getRechargeList(param?: any) {
    return postJson('/coinconsume/getAll', param);
}

/**
 * 获取vip Order
 */
export function getVipOrderList(param: any) {
    return postJson('/financial/getVipOrderList', param);
}

/**
 * 获取Reward order
 */
export function getRewardOrderList(param: any) {
    return postJson('/financial/getRewardOrderList', param);
}

/**
 * 获取Currency order
 */
export function getCurrencyOrderList(param: any) {
    return postJson('/financial/getCoinOrderList', param);
}

/**
 * 导出vip order
 */
export function exportVipOrder(param: any) {
    return postJsonForExport('/financial/exportVipOrderList', param, 'vipOrderList.xlsx');
}

/**
 * 导出Reward order
 */
export function exportRewardOrder(param: any) {
    return postJsonForExport('/financial/exportRewardOrderList', param, 'rewardOrderList.xlsx');
}

/**
 * 导出Currency order
 */
export function exportCurrencyOrder(param: any) {
    return postJsonForExport('/financial/exportCoinOrderList', param, 'currencyCurrencyList.xlsx');
}

/**
 * 获取日期天数
 */
export function getDayALl() {
    return postJson('/vipPrice/all');
}