import blockWordKey from '../actions/actionsTypeKey/blockWordActionTypeKey';

// public action

export function initPage() {
    return { type: blockWordKey.INIT_PAGE };
}

export function getSearchParam(param: any) {
    return { type: blockWordKey.SEARCH_PARAM, payload: param };
}

export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: blockWordKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: blockWordKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: blockWordKey.SUBMIT_EXPORT, payload: value };
}

export function loading(value: boolean) {
    return { type: blockWordKey.LOADING, payload: value };
}

// page action

export function getBlockWordList(list: any) {
    return { type: blockWordKey.GET_BLOCK_WORD_LIST, payload: list };
}

export function getDeleteId(id: number) {
    return { type: blockWordKey.GET_DELETE_ID, payload: id };
}

export function getAddWord(param: any) {
    return { type: blockWordKey.GET_ADD_BLOCK_WORD_PARAM, payload: param };
}