import  adBannerKey  from './actionsTypeKey/adBannerActionTypeKey';

export function initPage() {
    return { type: adBannerKey.INIT_PAGE };
}

export function getBannerList(value: any) {
    return { type: adBannerKey.GET_ADBANNER_LIST, payload: value };
}

export function bannerListSearch(value: any) {
    return { type: adBannerKey.LIST_DATA_SEARCH, payload: value };
}

export function addBanner(value: any) {
    return { type: adBannerKey.ADD_BANNER, payload: value };
}

export function modifyBannerStatus(value: any) {
    return { type: adBannerKey.MODIFY_BANNER_STATUS, payload: value };
}

export function deleteBanner(value: any) {
    return { type: adBannerKey.DELETE_BANNER, payload: value };
}

export function sendNovelValue(value: any) {
    return { type: adBannerKey.SEND_NOVEL_VALUE, payload: value };
}

export function getNovelListByName(value: any) {
    return { type: adBannerKey.GET_NOVEL_LIST, payload: value };
}

export function getAllNovelListByName(value: any) {
    return { type: adBannerKey.GET_ALL_NOVEL_LIST, payload: value };
}

export function exportBannerList(value: any) {
    return { type: adBannerKey.EXPORT_BANNER_LIST, payload: value };
}

export function loading(bool: boolean) {
    return { type: adBannerKey.LOADING, payload: bool };
}

export function modalLoading(bool: boolean) {
    return { type: adBannerKey.MODAL_LOADING, payload: bool };
}

export function modalVisible(bool: boolean) {
    return { type: adBannerKey.MODAL_VISIBLE, payload: bool };
}

export function bannerSortSubmit(value: any[]) {
    return { type: adBannerKey.BANNER_SORT, payload: value };
}