import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col, Button, Modal, Popconfirm, Input, Spin } from 'antd';
import { openNotification } from '../../widgets/Notification';
import { getParameter } from '../../../base/getUrlParam';

interface ReportDetailProps {
    data: any;
    match: any;
    getChapterUrl: (url: string) => void;
    getChapterChangeStatus: (param: any) => void;
}

interface ReportDetailInitialState {
    lockModal: boolean;
    lockText: string;
    reportId: any;
    tabsKey: string;
}

class ReportDetail extends React.Component<ReportDetailProps, ReportDetailInitialState> {

    state: ReportDetailInitialState = {
        lockModal: false,
        lockText: '',
        reportId: -1,
        tabsKey: '1'
    };

    componentDidMount() {
        let id = getParameter('id'), tabsKey = getParameter('tabsKey');
        // console.log(tabsKey);
        this.setState({reportId: id, tabsKey: tabsKey});
        let url: any = window.sessionStorage.getItem('reportUrl');
        this.props.getChapterUrl(url);
    }

    ignore = () => {
        this.changeChapterStatus('3');
    }

    lockModalShow = () => {
        this.setState({lockModal: true, lockText: ''});
    }

    lockModalOk = () => {
        if (this.state.lockText === '') {
            openNotification('error', 'Please enter the reason for the locking');
        } else {
            this.changeChapterStatus('2');
            this.lockModalCancel();
        }
    }

    lockModalCancel = () => {
        this.setState({lockModal: false});
    }

    changeChapterStatus = (status: string) => {
        let param = {
            id: this.state.reportId,
            status: status,
            message: this.state.lockText
        };
        this.props.getChapterChangeStatus(param);
    }

    changeLockText = (e: any) => {
        if (e.target.value.length > 200) {
            openNotification('error', 'Reason for rejection cannot exceed 200 characters');
        } else {
            this.setState({lockText: e.target.value});
        }
    }

    back = () => {
        window.history.go(-1);
    }

    render() {

        const { lockModal, tabsKey, lockText } = this.state;
        const { data } = this.props;
        if (data.toList) {
            return <Redirect to="/auditManage/reportManager" />;
        }

        return (
            <Spin spinning={data.loading}>
                <Row>
                    <Col offset={19} span={5}>
                        <Button style={{float: 'right', marginRight: '15px'}} type="primary" onClick={this.back}>Back</Button>
                        {
                            tabsKey === '1' &&
                                <span style={{float: 'right'}}>
                                    <Button style={{marginRight: '15px'}} onClick={this.ignore}>Ignore</Button>
                        <Popconfirm title="Are you sure locking this chapter?" onConfirm={this.lockModalShow} okText="Yes" cancelText="No">
                                    <Button style={{marginRight: '15px'}} type="danger">locking</Button>
                        </Popconfirm>
                                </span>
                        }

                    </Col>
                </Row>
                <div style={{marginTop: '15px'}} dangerouslySetInnerHTML={{ __html: data.chapterCon}} />
                <Modal
                    title="Reason for the locking"
                    visible={lockModal}
                    onOk={this.lockModalOk}
                    onCancel={this.lockModalCancel}
                    maskClosable={false}
                >
                   <Input value={lockText} onChange={(e: any) => { this.changeLockText(e); }} placeholder="Reason for the locking"/>
                </Modal>
            </Spin>
        );
    }
}

export default ReportDetail;