import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Table, Form, Spin, Modal, Button, Popconfirm } from 'antd';

import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../reducers/searchReducer';
import { getExportTitles } from '../../../base/getExportTitles';
import { openNotification } from '../../widgets/Notification';

const FormItem = Form.Item;

interface Props extends FormComponentProps {
    data: StoreState;
    initPage: () => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    changeDataListStatus: (value: any) => void;
    addDataList: (value: any) => void;
    deleteDataList: (id: number) => void;
    addModalVisible: (bol: boolean) => void;
    update: (pagination: any, filters: any, sorter: any) => void;
}

interface State {}

class SearchManage extends React.Component<Props, State> {

    state: State = {};

    componentDidMount() {
        this.props.initPage();
    }

    columns = () => {
        return [
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                className: 'column-center',
                render: (text: string, record: any) => {
                    return text ? 'Show' : 'Hide';
                }
            },
            {
                title: 'Search Key Word',
                dataIndex: 'name',
                key: 'name',
                className: 'column-center',
            },
            {
                title: 'Data',
                dataIndex: 'createdAtDate',
                key: 'createdAtDate',
                className: 'column-center',
            },
            {
                title: 'Time',
                dataIndex: 'createdAtTime',
                key: 'createdAtTime',
                className: 'column-center',
            },
            {
                title: 'Setting',
                dataIndex: 'setting',
                key: 'setting',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            {
                                record.status ?
                                    <Popconfirm
                                        title="Are you sure change this search word status?"
                                        onConfirm={() => {this.props.changeDataListStatus({id: record.id, status: Number(!record.status)}); }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <a style={{marginRight: '15px'}}>
                                            Hide
                                        </a>
                                    </Popconfirm>
                                    :
                                    <Popconfirm
                                        title="Are you sure change this search word status?"
                                        onConfirm={() => {this.props.changeDataListStatus({id: record.id, status: Number(!record.status)}); }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <a style={{marginRight: '15px'}}>
                                            Show
                                        </a>
                                    </Popconfirm>
                            }
                            <Popconfirm
                                title="Are you sure delete this search word?"
                                onConfirm={() => {this.props.deleteDataList(record.id); }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <a style={{color: 'red'}}>Delete</a>
                            </Popconfirm>
                        </div>
                    );
                },
            }
        ];
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    addModalShow = () => {
        this.props.form.resetFields();
        this.props.addModalVisible(true);
    }

    addModalClose = () => {
        this.props.addModalVisible(false);
    }

    addSearchWord = () => {
        const value: any = this.props.form.getFieldsValue();
        if (value.search_word.length === 0 || value.search_word.length > 20) {
            openNotification('error', 'Search key word no more than 20 characters cannot be null.');
        } else {
            this.props.addDataList({name: value.search_word});
        }
    }

    render() {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { data } = this.props;

        const button = { style: {width: '95px', margin : '0 8px 8px 0'}};
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Toolbar
                        showExport={true}
                        hideClearButton={true}
                        hideSearchButton={true}
                        onExport={this.handleExport}
                    >
                        <Button
                            icon="plus"
                            {...button}
                            type="primary"
                            onClick={this.addModalShow}
                        >
                            Add
                        </Button>
                    </Toolbar>
                    <Table
                        rowKey={(record: any) => record.id}
                        bordered={true}
                        columns={this.columns()}
                        style={{marginTop: '20px'}}
                        dataSource={data.dataList}
                        onChange={undefined}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total:${data.total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={getFieldsValue}
                        visible={data.exportModalVisible}
                        loading={data.loading}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                    />
                </Spin>
                <Modal
                    title="Add search key word"
                    visible={data.addModalVisible}
                    onCancel={this.addModalClose}
                    onOk={this.addSearchWord}
                    confirmLoading={data.modalLoading}
                    maskClosable={false}
                >
                    <Form>
                        <FormItem label="Search Key Word" {...formItemLayout}>
                            {getFieldDecorator('search_word')(
                                <Input />
                            )}
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(SearchManage);
