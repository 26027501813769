import { fork, all, put, call, takeEvery, select, take } from 'redux-saga/effects';
import blockWordKey from '../../components/webManager/actions/actionsTypeKey/blockWordActionTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as  blockWordServer from '../../servers/blockWordServer';
import * as blockWordAction from '../../components/webManager/actions/blockWordActions';

function* initPage() {
    yield getDataList();
}

function* getDataList() {
    const state = yield select();
    const data = state.webManager.blockWordData;
    const param = {
        ...data.page,
        ...data.sorter,
        data: {...data.searchData}
    };
    try {
        yield put(blockWordAction.loading(true));
        let { result } = yield call(blockWordServer.getSensitiveList, param);
        yield put(blockWordAction.getBlockWordList(result));
        yield put(blockWordAction.loading(false));
    } catch (error) {
        openNotification('error', 'Fail to get day data list.');
        yield put(blockWordAction.loading(false));
    }
}

function* deleteBlockWord() {
    while (true) {
        const { payload } = yield take(blockWordKey.GET_DELETE_ID);
        try {
            yield call(blockWordServer.deletaSensitivelWord, payload);
            openNotification('success', 'Delete Success');
            yield getDataList();
        } catch (err) {
            openNotification('err', 'Delete Failed');
        }
    }
}

function* addBlockWord() {
    while (true) {
        const { payload } = yield take(blockWordKey.GET_ADD_BLOCK_WORD_PARAM);
        try {
            if (payload.addType === 'add') {
                yield call(blockWordServer.addSensitivel, payload);
            }
            openNotification('success', 'Add Success');
            yield getDataList();
        } catch (err) {
            openNotification('error', 'Add Failed');
        }
    }
}

function* watchExport() {
    while (true) {
        const { payload } = yield take(blockWordKey.SUBMIT_EXPORT);
        const state = yield select();
        const data = state.webManager.blockWordData;
        payload.titles.splice(-1, 1);
        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            data: {...data.searchData},
            ...data.sorter
        };
        try {
            yield put(blockWordAction.loading(true));
            yield call(blockWordServer.exportSensitivel, param);
            yield put(blockWordAction.loading(false));
            yield put(blockWordAction.exportModalVisible(false));
            openNotification('success', 'Export success.');
        } catch (error) {
            openNotification('error', 'Export fail.');
            yield put(blockWordAction.loading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(blockWordKey.INIT_PAGE, initPage);
}

function* watchSearchData() {
    yield takeEvery(blockWordKey.SEARCH_PARAM, getDataList);
}

function* watchUpdatePageSize() {
    yield takeEvery(blockWordKey.UPDATE_PAGE, getDataList);
}

export function* watchBlockWordSaga() {
    yield all([
        fork(watchInitPage),
        fork(deleteBlockWord),
        fork(watchSearchData),
        fork(watchExport),
        fork(watchUpdatePageSize),
        fork(addBlockWord),
    ]);
}