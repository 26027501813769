import * as React from 'react';
import Contents from '../../../layouts/Content';

export const UserManage: React.SFC = (props) => {
    const linkList = [
        {
            icon: 'appstore-o',
            key: 'user',
            linkTo: '/userManage/user',
            value: 'User Registration',
        },
        {
            icon: 'tags',
            key: 'userData',
            linkTo: '/userManage/userData',
            value: 'User Data',
        },
        {
            icon: 'link',
            key: 'userLives',
            linkTo: '/userManage/userLives',
            value: 'User Lives',
        },
    ];
    return (
        <Contents children={props.children} linkList={linkList}/>
    );
};
