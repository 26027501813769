import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

export function getAuthenticationList(param: any) {
    return postJson('/authentication/getAuthenticationList', param);
}

export function authenticationDetail(id: string) {
    return postJson('/authentication/getAuthenticationDetailById', id);
}

export function changeAuthentication(param: any) {
    return postJson('/authentication/modifyStatus', param);
}

export function exportAuthentication(param: any) {
    return postJsonForExport('/authentication/exportAuthenticationList', param, param.exportName);
}