import identityKey from '../actions/actionsTypeKey/identityTypeKey';

// public action

export function initPage() {
    return { type: identityKey.INIT_PAGE };
}

export function getSearchParam(param: any) {
    return { type: identityKey.GET_SEARCH_DATA, payload: param };
}

export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: identityKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: identityKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: identityKey.SUBMIT_EXPORT, payload: value };
}

export function loading(value: boolean) {
    return { type: identityKey.LOADING, payload: value };
}

// page action

export function getIdentityList(param: any) {
    return { type: identityKey.GET_IDENTITY_LIST, payload: param };
}

export function getTabsKey(key: string) {
    return { type: identityKey.GET_IDENTITY_TABS_KEY, payload: key };
}

// page detail
export function getIdentityId(id: string) {
    return { type: identityKey.GET_IDENTITY_DETAIL_ID, payload: id };
}

export function getIdentityDetailMsg(msg: any) {
    return { type: identityKey.GET_IDENTITY_DETAIL_MSG, payload: msg };
}

export function getIdentityDetailModifyParam(param: any) {
    return { type: identityKey.GET_IDENTITY_DETAIL_MODIFY_PARAM, payload: param };
}

export function identityDetailToList(value: boolean) {
    return { type: identityKey.IDENTITY_DETAIL_TO_LIST, payload: value };
}