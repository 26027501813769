enum RecommendActionsTypeKey {
    INIT_PAGE = 'RECOMMEND_INIT_PAGE',
    OPEN_CLOSE_FLOOR_LOCATION_MODAL = 'RECOMMEND_OPEN_CLOSE_FLOOR_LOCATION_MODAL',
    UPDATE_FLOOR_PAGE = 'RECOMMEND_UPDATE_FLOOR_PAGE',
    OPEN_CLOSE_FLOOR_MODAL = 'RECOMMEND_OPEN_CLOSE_FLOOR_MODAL',
    LIST_DATA_SEARCH = 'RECOMMEND_LIST_DATA_SEARCH',
    GET_FLOOR_LIST = 'RECOMMEND_GET_FLOOR_LIST',
    LOADING = 'RECOMMEND_LOADING',
    CHANGE_FLOOR_LOCATION = 'RECOMMEND_CHANGE_FLOOR_LOCATION',
    CHANGE_FLOOR_STATUS = 'RECOMMEND_CHANGE_FLOOR_STATUS',
    DELETE_FLOOR = 'RECOMMEND_DELETE_FLOOR',
    ADD_FLOOR_DATA = 'RECOMMEND_ADD_FLOOR_DATA',
    EDIT_FLOOR_DATA = 'RECOMMEND_EDIT_FLOOR_DATA',
    GET_STAR_LIST = 'RECOMMEND_GET_STAR_LIST',
    OPEN_CLOSE_STAR_ID_MODAL = 'RECOMMEND_OPEN_CLOSE_START_ID_MODAL',
    OPEN_CLOSE_STAR_NAME_MODAL = 'RECOMMEND_OPEN_CLOSE_STAR_NAME_MODAL',
    CHANGE_STAR_LIST_SORT = 'RECOMMEND_CHANGE_STAR_LIST_SORT',
    CHANGE_STAR_LIST_STATUS = 'RECOMMEND_CHANGE_STAR_LIST_STATUS',
    EDIT_STAR_LIST_NAME = 'RECOMMEND_EDIT_STAR_LIST_NAME'
}

export default RecommendActionsTypeKey;