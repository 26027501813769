import * as React from 'react';
import { Badge, Col, Divider, Row } from 'antd';
import { numFormat } from '../../../base/regular';

interface Props {
    data: any;
}

class TotalDataComponents extends React.Component<any, Props> {
    render() {

        const { data } = this.props;

        return (
            <div>
                <Divider>Total Data</Divider>

                <Row style={{marginTop: '15px'}}>
                    <Col span={6} style={{margin: '15px 0 15px 0'}}>
                        <Badge
                            status="success"
                            text={`Total order: ${data.totalOrder ? numFormat(data.totalOrder) : 0}`}
                        />
                    </Col>
                    <Col span={6} style={{margin: '15px 0 15px 0'}}>
                        <Badge
                            status="success"
                            text={`Completed order: ${data.paidOrder ? numFormat(data.paidOrder) : 0}`}
                        />
                    </Col>
                    <Col span={6} style={{margin: '15px 0 15px 0'}}>
                        <Badge
                            status="success"
                            text={`UnPay order: ${data.unpaidOrder ? numFormat(data.unpaidOrder) : 0}`}
                        />
                    </Col>
                    <Col span={6} style={{margin: '15px 0 15px 0'}}>
                        <Badge
                            status="success"
                            text={`Pending order: ${data.refundedOrder ? numFormat(data.refundedOrder) : 0}`}
                        />
                    </Col>
                    <Col span={6} style={{margin: '15px 0 15px 0'}}>
                        <Badge
                            status="success"
                            text={`Amount of orders: ${data.totalAmount ? numFormat(data.totalAmount) : 0}`}
                        />
                    </Col>

                    <Col span={6} style={{margin: '15px 0 15px 0'}}>
                        <Badge
                            status="success"
                            text={`Amount of orders: ${data.totalPaidAmount ? numFormat(data.totalPaidAmount) : 0}`}
                        />
                    </Col>
                    <Col span={6} style={{margin: '15px 0 15px 0'}}>
                        <Badge
                            status="success"
                            text={`Amount of orders: ${data.unpaidAmount ? numFormat(data.unpaidAmount) : 0}`}
                        />
                    </Col>
                    <Col span={6} style={{margin: '15px 0 15px 0'}}>
                        <Badge
                            status="success"
                            text={`Amount of orders: ${data.refundedAmount ? numFormat(data.refundedAmount) : 0}`}
                        />
                    </Col>
                </Row>

            </div>
        );
    }
}

export default TotalDataComponents;