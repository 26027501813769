import  RecommendData  from '../RecommendData';
import { connect } from 'react-redux';
import * as recommendDataAction from '../../actions/recommendDataAction';

const mapStateToProps = (state: any) => ({
    data: state.data.recommendData,
});

const mapDispatchToProps = {
    initPage: recommendDataAction.initPage,
    updatePage: recommendDataAction.updatePage,
    submitExport: recommendDataAction.submitExport,
    exportModalVisible: recommendDataAction.exportModalVisible,
    listDataSearch: recommendDataAction.listDataSearch,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RecommendData);