enum RecommendDataActionsTypeKey {
    EXPORT_MODAL_VISIBLE = 'RECOMMEND_DATA_EXPORT_MODAL_VISIBLE',
    PAGE_INIT = 'RECOMMEND_DATA_PAGE_INIT',
    SUBMIT_EXPORT = 'SUBMIT_RECOMMEND_DATA_EXPORT',
    LOADING = 'RECOMMEND_DATA_LOADING',
    GET_DATA_LIST = 'GET_RECOMMEND_DATA_LIST',
    LIST_DATA_SEARCH = 'RECOMMEND_DATA_LIST_DATA_SEARCH',
    UPDATE_PAGE = 'UPDATE_PAGE_RECOMMEND_DATA',
}

export default RecommendDataActionsTypeKey;