import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Table, Form, Row, Col, Spin, Popconfirm, Button, Icon, Badge, DatePicker, Modal } from 'antd';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../../content/reducers/novelManagerReducer';
import { getExportTitles } from '../../../base/getExportTitles';
import { changeToStamp, timeReverseWithNoHour } from '../../../base/dealWithTime';
// import * as moment from 'moment';
import { Link } from 'react-router-dom';

const FormItem = Form.Item;

interface Props {
    data: StoreState;
    initPage: () => void;
    listDataSearch: (param: any) => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    deleteDataList: (id: number) => void;
    changeListTop: (id: number) => void;
}

interface State {
    startTime: string;
    endTime: string;
    content: string;
    visible: boolean;
}

class WebManager extends React.Component<Props & FormComponentProps, State> {

    state: State = {
        startTime: '',
        endTime: '',
        content: '',
        visible: false,
    };

    componentDidMount() {
        this.props.initPage();
    }

    columns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                className: 'column-center',
            },
            {
                title: 'Announcement Name',
                dataIndex: 'title',
                key: 'title',
                className: 'column-center',
                width: 300,
                render: (text: any, record: any) => {
                    return <Badge dot={record.updatedStatus === 1}>{text}</Badge>;
                }
            },
            {
                title: 'Date',
                dataIndex: 'releasedAtDateValue',
                key: 'releasedAtDateValue',
                className: 'column-center',
            },
            {
                title: 'Time',
                dataIndex: 'releasedAtTimeValue',
                key: 'releasedAtTimeValue',
                className: 'column-center',
            },
            {
                title: 'Setting',
                dataIndex: 'setting',
                key: 'setting',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            <Popconfirm
                                title="Are you sure change this announcement status?"
                                onConfirm={() => {this.changStatus(record.id); }}
                                okText="Yes"
                                cancelText="No"
                            >
                                {record.top !== 1  &&  <span style={{marginRight: '15px', color: '#1890ff', cursor: 'pointer'}}>Show</span>}
                            </Popconfirm>
                            <span style={{marginRight: '15px', color: '#1890ff', cursor: 'pointer'}} onClick={() => this.showModal(record)}>View9</span>
                            <Popconfirm
                                title="Are you sure delete this announcement?"
                                onConfirm={() => {this.delete(record.id); }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <a style={{color: 'red'}}>Delete</a>
                            </Popconfirm>
                        </div>
                    );
                },
            }
        ];
    }

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: timeReverseWithNoHour(startTime) + ' 00:00:00'});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: timeReverseWithNoHour(endTime) + ' 23:59:59'});
        } else {
            this.setState({endTime});
        }
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    clear = () => {
        this.setState({startTime: '', endTime: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            title: value.name,
            startTime: this.state.startTime.length === 0 ? undefined : changeToStamp(this.state.startTime) / 1000,
            endTime: this.state.endTime.length === 0 ? undefined : changeToStamp(this.state.endTime) / 1000,
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }

        this.props.listDataSearch(param);
    }

    delete = (id: number) => {
        this.props.deleteDataList(id);
    }

    changStatus = (id: number) => {
        this.props.changeListTop(id);
    }

    showModal = (value: any) => {
        this.setState({
            visible: true,
            content: value.content
        });
    }

    handleOk = () => {
        this.setState({
            visible: false,
        });
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }

    render() {
        const {getFieldDecorator, getFieldsValue} = this.props.form;
        const { updatePage, data } = this.props;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={6}>
                                <FormItem label="Date">
                                    {getFieldDecorator('startTime')(
                                        <DatePicker
                                            allowClear={true}
                                            format={'DD-MM-YYYY'}
                                            placeholder={'Start Time'}
                                            style={{width : '48%'}}
                                            onChange={this.startTimeChange}
                                        />
                                    )}
                                    {getFieldDecorator('endTime')(
                                        <DatePicker
                                            allowClear={true}
                                            format={'DD-MM-YYYY'}
                                            placeholder={'End Time'}
                                            style={{width : '48%', marginLeft : '4%'}}
                                            onChange={this.endTimeChange}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Announcement Name"
                                >
                                    {getFieldDecorator('name')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>

                        </Row>
                    </Form>
                    <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport}>
                        <Link to={{pathname: '/webManager/addWeb'}}>
                            <Button type="primary"><Icon type="plus" />Add</Button>
                        </Link>
                    </Toolbar>
                    <Table
                        rowKey="id"
                        bordered={true}
                        columns={this.columns()}
                        style={{marginTop: '20px'}}
                        onChange={updatePage}
                        dataSource={data.data}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total:${total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={getFieldsValue}
                        visible={data.exportModalVisible}
                        loading={data.loading}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                    />
                </Spin>
                <Modal
                    title="Basic Modal"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={700}
                >
                    <div className="tagDiv" dangerouslySetInnerHTML={{__html: `${this.state.content}`}} />
                </Modal>

            </div>
        );
    }
}

export default Form.create()(WebManager);