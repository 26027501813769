import WebActionsTypeKey from '../actions/actionsTypeKey/webManagerActionsTypeKey';
import {  ActionTypes } from '../actions/actionsType/webActionsType';

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    data: any[];
    total: number;
    page: PageData;
    searchData: any;
    exportModalVisible: boolean;
    loading: boolean;
    sorter: any;
}

const initialState: StoreState = {
    data: [],
    sorter: {},
    total: 0,
    searchData: {},
    page: {
        current: 1,
        pageSize: 20,
    },
    exportModalVisible: false,
    loading: false,
};

export const webManagerReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case WebActionsTypeKey.INIT_PAGE:
            return initialState;

        case WebActionsTypeKey.GET_DATA_LIST:
            return Object.assign({}, state, {
                data: action.payload.data,
                total: action.payload.total
            });

        case WebActionsTypeKey.LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case WebActionsTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.value.current,
                    pageSize: action.payload.value.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                }
            });

        case WebActionsTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload,
            });

        case WebActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        default:
            return state;
    }
};