import * as React from 'react';
import { Tabs, Row, Col, Button, Popconfirm, Table, Modal, Form, Input, Radio, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { isInt } from '../../../base/regular';
import { openNotification } from '../../widgets/Notification';
import { Redirect } from 'react-router-dom';
import '../../../style/settingTopic.css';

const { TabPane } = Tabs;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { Option } = Select;

interface Props extends FormComponentProps {
    data: any;
    initPage: () => void;
    floorListSearch: (param: any) => void;
    openCloseFloorLocationModal: (bol: boolean) => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    openCloseFloorModal: (bol: boolean) => void;
    getLocationSort: (param: any) => void;
    changeFloorStatus: (param: any) => void;
    delFloor: (id: number | string) => void;
    addFloorDataList: (value: any) => void;
    editFloorDataList: (value: any) => void;
    OpenCloseStarIdModal: (bol: boolean) => void;
    OpenCloseStarNameModal: (bol: boolean) => void;
    changeStarListSort: (param: any) => void;
    changeStarStatus: (param: any) => void;
    editStarName: (param: any) => void;
}

type State = {
    radioValue: number;
    isEditFloor: boolean;
    floorId: any;
    detailItem: any;
    toDetail: boolean;
    starId: number | string;
};

class RecommendManager extends React.Component<Props, State> {

    state: State = {
        radioValue: 1,
        isEditFloor: false,
        floorId: -1,
        detailItem: {},
        toDetail: false,
        starId: 0
    };

    componentDidMount(): void {
        this.props.initPage();
    }

    floorColumn = (): any[] => {
        const { changeFloorStatus, delFloor } = this.props;
        return [
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: 'Floor Name',
                dataIndex: 'name',
                key: 'floorName',
                render: (text: string, record: any) => {
                    return (
                        <span
                            style={{color: '#1890ff', cursor: 'pointer'}}
                            onClick={() => {this.toFloorDetail(record); }}
                        >
                            {text}
                        </span>
                    );
                }
            },
            {
                title: 'Theme',
                dataIndex: 'theme',
            },
            {
                title: 'Location ID',
                dataIndex: 'sort',
                key: 'sort',
                render: (text: string | number, record: any) => {
                    if (text === 0) {
                        return <span>{text}</span>;
                    } else {
                        return (
                            <span
                                style={{color: '#1890ff', cursor: 'pointer'}}
                                onClick={() => {this.floorLocationModalShow(record); }}
                            >
                                {text}
                            </span>
                        );
                    }
                }
            },
            {
                title: 'Free Enabled',
                render: (_, record) => { return '' + (record.freeConfig && record.freeConfig.enabled || false) }
            },
            {
                title: 'Free Time',
                render: (_, record) => { 
                    if (record.freeConfig == null) {
                        return null;
                    } else {
                        return <div><span>{record.freeConfig.startAtStr}</span><br/> ~ <span>{record.freeConfig.endAtStr}</span></div>
                    }
                }
            },
            {
                title: 'Show By Free Time',
                render: (_, record) => { return '' + (record.freeConfig && record.freeConfig.showByTime || false) }
            },
            {
                title: 'Style',
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: 'Operating',
                dataIndex: 'setting',
                key: 'setting',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            <span
                                style={{marginRight: '15px', color: '#1890ff',  cursor: 'pointer'}}
                                onClick={() => {this.floorModalShow(true, record); }}
                            >
                                Edit
                            </span>

                            <span
                                style={{marginRight: '15px', color: '#1890ff',  cursor: 'pointer'}}
                            >
                                <Popconfirm
                                    title="Are you sure change this floor status?"
                                    onConfirm={() => changeFloorStatus({id: record.id, status: Number(!record.status)})}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    {record.status ? 'Hide' : 'Show'}
                                </Popconfirm>
                            </span>

                            <span
                                style={{ color: 'red', cursor: 'pointer'}}
                            >
                                 <Popconfirm
                                     title="Are you sure delete this task?"
                                     onConfirm={() => delFloor(record.id)}
                                     okText="Yes"
                                     cancelText="No"
                                 >
                                Delete
                                 </Popconfirm>
                            </span>
                        </div>
                    );
                }
            },
        ];
    }

    starListCloums = (): any[] => {
        return [
            {
                title: 'Star List Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Location ID',
                dataIndex: 'sort',
                key: 'sort',
                render: (text: string | number, record: any) => {
                    if (text === 0) {
                        return <span>{text}</span>;
                    } else {
                        return (
                            <span
                                style={{color: '#1890ff', cursor: 'pointer'}}
                                onClick={() => {this.LeaderboardSortModalShow(record); }}
                            >
                                {text}
                            </span>
                        );
                    }
                }
            },
            {
                title: 'Operating',
                dataIndex: 'setting',
                key: 'setting',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            <span
                                style={{marginRight: '15px', color: '#1890ff',  cursor: 'pointer'}}
                                onClick={() => {this.LeaderboardNameModalShow(record); }}
                            >
                                Edit
                            </span>

                            <span
                                style={{marginRight: '15px', color: '#1890ff',  cursor: 'pointer'}}
                            >
                                <Popconfirm
                                    title="Are you sure change this star status?"
                                    onConfirm={() => { this.props.changeStarStatus({id: record.id, status: Number(!record.status)}); }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    {record.status ? 'Hide' : 'Show'}
                                </Popconfirm>
                            </span>
                        </div>
                    );
                }
            },
        ];
    }

    changeTabs = (key: string) => {
        this.props.initPage();

    }

    floorThemChange = (value: string) => {
        console.log(value);
        this.props.floorListSearch({theme: value});
    }

    toFloorDetail = (item: any) => {
        this.setState({detailItem: item, toDetail: true});
    }

    // floor modal

    floorLocationModalShow = (item: any) => {
        this.props.openCloseFloorLocationModal(true);
        this.props.form.setFieldsValue({'sort': item.sort });
        this.setState({floorId: item.id});
    }

    floorLocationModalOk = () => {
        const value: any = this.props.form.getFieldsValue();
        const { data } = this.props;
        if (Number(value.sort) <= data.floorList.length) {
            let param = {
                id: this.state.floorId,
                sort: value.sort
            };
            if (param.id === -1) {
                param.id = this.props.data.floorList[0].id;
            }
            this.props.getLocationSort(param);
        } else {
            openNotification('error', 'The serial number cannot exceed the total number of floors');
        }

    }

    floorLocationModalCancel = () => {
        this.props.form.resetFields(['sort']);
        this.props.openCloseFloorLocationModal(false);
    }

    floorModalShow = (bol: boolean, item?: any) => {
        this.setState({isEditFloor: bol});
        this.props.openCloseFloorModal(true);
        if (bol) {
            this.props.form.setFieldsValue({'floor_name': item.name });
            this.props.form.setFieldsValue({'theme': item.theme});
            this.setState({radioValue: item.type, floorId: item.id});
        } else {
            this.props.form.resetFields(['floor_name']);
            this.props.form.resetFields(['theme']);
            this.setState({radioValue: 1});
        }
    }

    floorAddFloorModal = () => {
        const value: any = this.props.form.getFieldsValue();
        const param = {
            name: value.floor_name,
            theme: value.theme,
            type: this.state.radioValue
        };
        if (param.name.length === 0 || param.name.length > 20) {
            openNotification('error', 'Floor name no more than 20 characters cannot be null.');
        } else {
            this.props.addFloorDataList(param);
        }
    }

    floorEditFloorModal = () => {
        const value: any = this.props.form.getFieldsValue();
        const param = {
            id: this.state.floorId,
            name: value.floor_name,
            theme: value.theme,
            type: this.state.radioValue
        };
        if (param.id === -1) {
            param.id = this.props.data.floorList[0].id;
        }
        if (param.name.length === 0 || param.name.length > 20) {
            openNotification('error', 'Floor name no more than 20 characters cannot be null.');
        } else {
            this.props.editFloorDataList(param);
        }
    }

    floorModalCancel = () => {
      this.props.openCloseFloorModal(false);
    }

    onChangeRadio = (e: any) => {
        this.setState({
            radioValue: e.target.value,
        });
    }

    // Leaderboard modal

    LeaderboardSortModalShow = (record: any) => {
        this.props.form.setFieldsValue({'star_sort': record.sort });
        this.setState({starId: record.id});
        this.props.OpenCloseStarIdModal(true);
    }

    LeaderboardSortModalOk = () => {
        const { data } = this.props;
        let value = this.props.form.getFieldValue('star_sort');
        if (Number(value) <= data.starList.length) {
            let param = {
                id: this.state.starId,
                sort: value
            };
            this.props.changeStarListSort(param);
        } else {
            openNotification('error', 'The serial number cannot exceed the total number of star charts');
        }

    }

    LeaderboardSortModalCancel = () => {
        this.props.OpenCloseStarIdModal(false);
    }

    LeaderboardNameModalShow = (record: any) => {
        this.props.form.setFieldsValue({'star_name': record.name });
        this.setState({starId: record.id});
        this.props.OpenCloseStarNameModal(true);
    }

    LeaderboardNameModalOk = () => {
        this.props.form.validateFields(['star_name'], (err, values) => {
            if (!err) {
                let char = /^[a-zA-Z\s]+$/;
                if (char.test(values.star_name)) {
                    let param = {
                        id: this.state.starId,
                        name: values.star_name
                    };

                    this.props.editStarName(param);
                } else {
                    openNotification('error', 'Can only enter letter and space');
                }

            }
        });

    }

    LeaderboardNameModalCancel = () => {
        this.props.OpenCloseStarNameModal(false);
    }

    render() {

        const { data, updatePage } = this.props;
        const { getFieldDecorator } = this.props.form;

        const { radioValue, isEditFloor, detailItem, toDetail } = this.state;

        if (toDetail) {
            return <Redirect push={true} to={{pathname: `/creationManage/recommendDetail`, search: `?id=${detailItem.id}&name=${detailItem.name}`}}/>;
        }

        return (
          <div>
              <Tabs defaultActiveKey="1" onChange={this.changeTabs}>
                  <TabPane tab="Floor Setting" key="1">
                      <Row style={{marginBottom: '20px'}}>
                          <Col span={16}>
                            <FormItem
                                label="Theme"
                            >
                                <Select style={{width: 120}} defaultValue="novel" onChange={this.floorThemChange}>
                                    <Option value="novel">Novel</Option>
                                    <Option value="free">Free</Option>
                                    <Option value="new">New</Option>
                                </Select>
                            </FormItem>
                          </Col>
                          <Col span={8}>
                              <Button type="primary" style={{float: 'right'}} onClick={() => {this.floorModalShow(false); }}>Add Floor</Button>
                          </Col>
                          <Col span={8}>
                                <br/>
                              <Button type="primary" style={{float: 'right'}} onClick={() => {window.open("/float.html?a="+(new Date()).getTime());}}>Add Float</Button>
                          </Col>
                      </Row>
                      <Table
                          rowKey={'id'}
                          loading={data.loading}
                          bordered={true}
                          dataSource={data.floorList}
                          columns={this.floorColumn()}
                          onChange={updatePage}
                          pagination={false}
                      />
                  </TabPane>
                  <TabPane tab="Star List Setting" key="2">
                      <Table
                          rowKey={'id'}
                          loading={data.loading}
                          bordered={true}
                          dataSource={data.starList}
                          columns={this.starListCloums()}
                          onChange={updatePage}
                          pagination={false}
                      />
                  </TabPane>
              </Tabs>

          {/*  change floor location   */}
              <Modal
                  title="Change Location ID"
                  visible={data.floorLocationModal}
                  onOk={this.floorLocationModalOk}
                  onCancel={this.floorLocationModalCancel}
                  maskClosable={false}
              >
                  <Form>
                      <Form.Item label="Location ID">
                          {getFieldDecorator('sort', {
                              rules: [
                                  {validator: isInt}
                              ],
                          })(<Input />)}
                      </Form.Item>
                  </Form>
              </Modal>

          {/*  add floor  */}
              <Modal
                  title={isEditFloor ? 'Edit Floor' : 'Add Floor'}
                  visible={data.floorModalVisible}
                  onOk={isEditFloor ? this.floorEditFloorModal : this.floorAddFloorModal}
                  onCancel={this.floorModalCancel}
                  confirmLoading={data.floorModalLoading}
                  width={500}
                  maskClosable={false}
              >
                  <Form>
                      <FormItem
                          label="Floor Name"
                      >
                          {getFieldDecorator('floor_name', {
                              rules: [
                                  {
                                      required: true,
                                      message: 'Please input floor Name!',
                                  }
                              ]
                          })(
                              <Input />
                          )}
                      </FormItem>
                      <FormItem label="Theme">
                          {getFieldDecorator('theme', {
                              rules: [{ required: true }]
                          })(
                              <Select>
                                  <Option value="novel">Novel</Option>
                                  <Option value="free">Free</Option>
                                  <Option value="new">New</Option>
                              </Select>
                          )}
                      </FormItem>
                      <RadioGroup onChange={this.onChangeRadio} value={radioValue} >
                          <Radio value={1}>
                              Style 1 (Please select no less than 3 novels)
                              <div className="line-style">
                                  <div />
                                  <div />
                                  <div />
                              </div>
                          </Radio>
                          <Radio value={2}>
                              Style 2 (Please select no less than 3 novels)
                              <div className="vertical-style">
                                  <div />
                                  <div />
                                  <div />
                              </div>
                          </Radio>
                          <Radio value={3}>
                              Style 3 (Please select no less than 6 novels)
                              <div className="vertical-line-style">
                                  <div />
                                  <div />
                                  <div />
                                  <div />
                                  <div />
                                  <div />
                              </div>
                          </Radio>
                          <Radio value={4}>
                              Style 4 (Please select no less than 5 novels)
                              <div className="carousel-style">
                                  <div />
                                  <div />
                                  <div />
                                  <div />
                                  <div />
                              </div>
                          </Radio>
                      </RadioGroup>
                  </Form>
              </Modal>

          {/*  star list change sort  */}
               <Modal
                   title="Leaderboard Loaction ID"
                   visible={data.starIdModal}
                   onOk={this.LeaderboardSortModalOk}
                   onCancel={this.LeaderboardSortModalCancel}
                   maskClosable={false}
               >
                   <Form>
                       <Form.Item label="Location ID">
                           {getFieldDecorator('star_sort', {
                               rules: [
                                   {validator: isInt}
                                   ],
                           })(<Input />)}
                       </Form.Item>
                   </Form>
               </Modal>

          {/*  star list change name  */}
              <Modal
                  title="Edit Leaderboard"
                  visible={data.starNameModal}
                  onOk={this.LeaderboardNameModalOk}
                  onCancel={this.LeaderboardNameModalCancel}
                  maskClosable={false}
              >
                  <Form>
                      <Form.Item label="Leaderboard Name">
                          {getFieldDecorator('star_name', {
                              rules: [
                                  {
                                      required: true,
                                      message: 'Please input Leaderboard Name!',
                                  },
                                  {
                                      max: 20,
                                      message: 'Can only enter 20 character'
                                  }
                              ],
                          })(<Input />)}
                      </Form.Item>
                  </Form>
              </Modal>
          </div>
        );
    }
}

export default Form.create()(RecommendManager);