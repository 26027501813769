import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

export function getWithdrawTotal() {
    return postJson('/withdraw/getWithdrawStatistics');
}

export function getWithdrawList(param: any) {
    return postJson('/withdraw/getWithdrawList', param);
}

export function getWithdrawDetailById(id: string) {
    return postJson('/withdraw/getWithdrawDetailById', id);
}

export function modifyStatus(param: any) {
    return postJson('/withdraw/modifyStatus', param);
}

export function exportWithdrawList(param: any) {
    return postJsonForExport('/withdraw/exportWithdrawList', param, param.exportName);
}
