import typeDetailActionsTypeKey from './actionsTypeKey/typeDetailActionsTypeKey';

export function initPage() {
    return { type: typeDetailActionsTypeKey.INIT_PAGE };
}

export function getTagId(id: string) {
    return { type: typeDetailActionsTypeKey.GET_TAG_ID, payload: id };
}

export function getTypeList(list: any[]) {
    return { type: typeDetailActionsTypeKey.GET_TYPE_LIST, payload: list };
}

export function getTypeId(id: string) {
    return { type: typeDetailActionsTypeKey.GET_TYPE_ID, payload: id };
}

export function getNovelList(list: any[]) {
    return { type: typeDetailActionsTypeKey.GET_NOVEL_LIST, payload: list };
}

export function getDelIds(param: any) {
    return { type: typeDetailActionsTypeKey.GET_DEL_TAG_IDS, payload: param };
}

export function loading(value: boolean) {
    return { type: typeDetailActionsTypeKey.TYPE_LIST_LOADING, payload: value };
}