import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getPackageId, getPackageName } from '../util/packageUtil';

export function numberDivideWithComma(num: string) {
    return num.toString().replace(/\B(?=(?:\d{3})+\b)/g, ',');
}

export function postJsonForExport(api: string, data: any, filename: string) {
    const API = '/api';
    const instance = axios.create({
        baseURL: 'http://' + window.location.host + API,
        withCredentials: true,
        responseType: 'blob',
    });

    instance.interceptors.request.use((config: AxiosRequestConfig) => {
        const token = window.localStorage.getItem('TOKEN');
        if (token != null) {
            config.headers['X-Auth-Token'] = token;
            config.headers['X-Package-Id'] = getPackageId();
            config.headers['X-Package-Name'] = getPackageName();
        }
        return config;
    });

    instance.interceptors.response.use(
        (res: AxiosResponse) => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(res.data);
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
            return res;
        },
        (err: { response: AxiosResponse }) => {
            return Promise.reject(new Error(JSON.stringify(err.response.data)));
        }
    );

    api = applyPathValues(api, null);

    return instance.post(api, {
        reqId: Math.random().toString(36).substr(2),
        timestamp: new Date().getTime(),
        param: {...data}
    });
}

export function applyPathValues(api: any, pathValues: any) {
    if (pathValues) {
        const rex = /{\w+}/g;

        let matched = api.match(rex);
        for (let match of matched) {
            let pathName = match.substr(1, match.length - 2);
            if (pathValues[pathName]) {
                api = api.replace(match, '' + pathValues[pathName]);
            }
        }
    }

    return api;
}

export function getThousandNum(num: number) {
    return (num || 0).toString().replace(/\d+/, function (n: any) {
        var len = n.length;
        if (len % 3 === 0) {
            return n.replace(/(\d{3})/g, ',$1').slice(1);
        } else {
            return n.slice(0, len % 3) + n.slice(len % 3).replace(/(\d{3})/g, ',$1');
        }
    });
}