import  AuthorDetail  from '../AuthorDetail';
import { connect } from 'react-redux';
import * as authorDetailAction from '../../actions/authorDetailAction';

const mapStateToProps = (state: any) => ({
    data: state.user.authorDetail
});

const mapDispatchToProps = {
    initPage: authorDetailAction.initPage,
    updatePage: authorDetailAction.updatePage,
    getAuthorDetailId: authorDetailAction.getAuthorDetailId
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AuthorDetail);