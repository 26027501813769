enum helpDetailManagerKeys {
    INIT_PAGE = 'HELP_DETAIL_INIT_PAGE',
    LOADING = 'HELP_DETAIL_LOADING',
    GET_MODAL_LIST = 'HELP_DETAIL_GET_HELP_MODAL_LIST',
    GET_QUESTION_LIST = 'HELP_DETAIL_GET_QUESTION_LIST',
    OPEN_CLOSE_QUESTION_MODAL = 'HELP_DETAIL_OPEN_CLOSE_QUESTION_MODAL',
    OPEN_CLOSE_ID_MODAL = 'HELP_DETAIL_OPEN_CLOSE_ID_MODAL',
    ADD_QUESTION = 'HELP_DETAIL_ADD_QUESTION',
    EDIT_QUESTION = 'HELP_DETAIL_EDIT_QUESTION',
    DEL_QUESTION = 'HELP_DETAIL_DEL_QUESTION',
    CHANGE_MODAL_QUESTION = 'HELP_DETAIL_CHANGE_MODAL_QUESTION',
    GET_CHANGE_QUESTION_SORT_MSG = 'HELP_DETAIL_GET_CHANGE_QUESTION_SORT_MSG'
}

export default helpDetailManagerKeys;