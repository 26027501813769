import { fork, all, put, call, takeEvery, select, take } from 'redux-saga/effects';
import bookShelfActionTypeKey from '../../components/creation/action/actionsTypeKey/bookShelfActionTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as  bookShelfServer from '../../servers/bookShelfServer';
import * as bookShelfAction from '../../components/creation/action/bookShelfAction';
import { getNovelTypeList } from '../../servers/novelServer';
import { getNovelListByType } from '../../servers/recommendServer';

function* initPage() {
    yield getBookShelfDataList();
    yield getNovelType();
}

function* getBookShelfDataList() {
    try {
        yield put(bookShelfAction.loading(true));
        const state = yield select();
        const data = state.creation.bookShelf;
        const param = {
            ...data.page,
            ...data.sorter,
            data: {...data.searchData}
        };

        let result = yield call(bookShelfServer.getRecommendList, param);
        yield put(bookShelfAction.getDataList({data: result.result.data, total: result.result.total}));
        yield put(bookShelfAction.loading(false));
    } catch (error) {
        openNotification('error', 'Failed to get bookShelf list.');
        yield put(bookShelfAction.loading(false));
    }
}

function* getNovelType() {
    try {
        let result = yield call(getNovelTypeList);
        yield put(bookShelfAction.getTypeList(result.result));
    } catch (error) {
        openNotification('error', 'Failed to get novel type.');
    }
}

function* getModalNovel() {
    while (true) {
        try {
            const { payload } = yield take(bookShelfActionTypeKey.SEARCH_MODAL_PARAM);
            yield put(bookShelfAction.loading(true));
            yield put(bookShelfAction.getModalSearchNovelList([]));
            const { result } = yield call(getNovelListByType, payload);
            yield put(bookShelfAction.getModalSearchNovelList(result));
            yield put(bookShelfAction.loading(false));
        } catch (err) {
            openNotification('error', 'Failed to get novel list.');
            yield put(bookShelfAction.loading(false));
        }
    }
}

function* addOrChangeNovel() {
    while (true) {
        try {
            const { payload } = yield take(bookShelfActionTypeKey.GET_ADD_OR_CHANGE_PARAM);
            if (payload.type === 'add') {
                yield call(bookShelfServer.addRecommendNovel, payload.novelId);
            } else {
                yield call(bookShelfServer.changeRecommendNovel, payload);
            }
            yield getBookShelfDataList();
        } catch (err) {
            if (err.code === 318) {
                openNotification('error', 'The recommend novel is delete');
            } else if (err.code === 203) {
                openNotification('error', 'The recommend novel is exist');
            } else {
                openNotification('error', 'Failed to Add/Change novel.');
            }
        }
    }
}

function* delNovel() {
    while (true) {
        try {
            const { payload } = yield take(bookShelfActionTypeKey.GET_DEL_NOVEL_ID);
            yield call(bookShelfServer.delRecommendNovel, payload);
            yield getBookShelfDataList();
            openNotification('success', 'Success to Del novel.');
        } catch (e) {
            openNotification('error', 'Failed to Del novel.');
        }
    }
}

function* changeSort() {
    while (true) {
        try {
            const { payload } = yield take(bookShelfActionTypeKey.GET_SORT_NUMBER);
            yield call(bookShelfServer.modifySort, payload);
            yield getBookShelfDataList();
            openNotification('success', 'Success to change sort.');
        } catch (e) {
            openNotification('error', 'Failed to change sort.');
        }
    }
}

// function* watchExport() {
//     while (true) {
//         const { payload } = yield take(bookShelfActionTypeKey.SUBMIT_EXPORT);
//         const state = yield select();
//         const data = state.data.floorData;
//         const param = {
//             current: payload.page,
//             pageSize: payload.pageSize,
//             titles: payload.titles,
//             data: {...data.searchData},
//             ...data.sorter
//         };
//         try {
//             yield put(bookShelfAction.loading(true));
//             yield call(dataServer.exportFloorData, param);
//             yield put(bookShelfAction.loading(false));
//             yield put(bookShelfAction.exportModalVisible(false));
//         } catch (error) {
//             openNotification('error', 'Export failed.');
//             yield put(bookShelfAction.loading(false));
//         }
//     }
// }

function* watchInitPage() {
    yield takeEvery(bookShelfActionTypeKey.INIT_PAGE, initPage);
}

function* watchSearchData() {
    yield takeEvery(bookShelfActionTypeKey.SEARCH_PARAM, getBookShelfDataList);
}

function* watchUpdatePageSize() {
    yield takeEvery(bookShelfActionTypeKey.CHANGE_PAGE, getBookShelfDataList);
}

export function* watchBookShelfRootSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearchData),
        // fork(watchExport),
        fork(watchUpdatePageSize),
        fork(getModalNovel),
        fork(addOrChangeNovel),
        fork(delNovel),
        fork(changeSort)
    ]);
}
