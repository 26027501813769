import reportKey from '../actions/actionsTypeKey/reportManagerypeKey';

// public action

export function initPage() {
    return { type: reportKey.INIT_PAGE };
}

export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: reportKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: reportKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: reportKey.SUBMIT_EXPORT, payload: value };
}

export function getSearchData(param: any) {
    return { type: reportKey.GET_SEARCH_DATA, payload: param };
}

export function loading(value: boolean) {
    return { type: reportKey.LOADING, payload: value };
}

// page action
export function getReportTabsKey(value: string) {
    return { type: reportKey.GET_REPORT_TABS_KEY, payload: value };
}

export function getReportList(list: any) {
    return { type: reportKey.GET_REPORT_LIST, payload: list };
}

export function getChapterUrl(url: string) {
    return { type: reportKey.GET_CHAPTER_CON_URL, payload: url };
}

export function getChapterText(text: string) {
    return { type: reportKey.GET_CHAPTER_CON_TEXT, payload: text };
}

export function getChapterChangeStatus(param: any) {
    return { type: reportKey.GET_CHAPTER_STATUS_CHANGE, payload: param };
}

export function reportDetailToList(value: boolean) {
    return { type: reportKey.REPORT_DRTAIL_TO_LIST, payload: value };
}