import BannerDataActionsTypeKey from './actionsTypeKey/bannerDataActionsTypeKey';
import { DayDataList } from '../../../servers/responseTypes';

export function initPage() {
    return { type: BannerDataActionsTypeKey.BANNER_DATA_PAGE_INIT };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: BannerDataActionsTypeKey.UPDATE_PAGE, payload: {pagination, sorter} };
}

export function loading(value: boolean) {
    return { type: BannerDataActionsTypeKey.LOADING, payload: value };
}

export function bannerDataListDataSearch(value: any) {
    return { type: BannerDataActionsTypeKey.BANNER_DATA_LIST_DATA_SEARCH, payload: value };
}

export function getBannerDataList(value: {data: DayDataList, total: number}) {
    return { type: BannerDataActionsTypeKey.GET_BANNER_DATA_LIST, payload: value };
}

export function exportModalVisible(value: boolean) {
    return { type: BannerDataActionsTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: BannerDataActionsTypeKey.SUBMIT_BANNER_DATA_EXPORT, payload: value };
}
