import recommendActionsTypeKey from './actionsTypeKey/recommendLocationActionsTypeKey';

export function initPage() {
    return { type: recommendActionsTypeKey.INIT_PAGE };
}

export function openCloseFloorLocationModal(bol: boolean) {
    return { type: recommendActionsTypeKey.OPEN_CLOSE_FLOOR_LOCATION_MODAL, payload: bol };
}

export function updateFloorPage(pagination: any, filters: any, sorter: any) {
    return { type: recommendActionsTypeKey.UPDATE_FLOOR_PAGE, payload: { pagination, sorter} };
}

export function openCloseFloorModal(bol: boolean) {
    return { type: recommendActionsTypeKey.OPEN_CLOSE_FLOOR_MODAL, payload: bol };
}

export function getFloorList(list: any) {
    return { type: recommendActionsTypeKey.GET_FLOOR_LIST, payload: list };
}

export function isLoading(bol: boolean) {
    return { type: recommendActionsTypeKey.LOADING, payload: bol };
}

export function getLocationSort(param: any) {
    return { type: recommendActionsTypeKey.CHANGE_FLOOR_LOCATION, payload: param };
}

export function changeFloorStatus(param: any) {
    return { type: recommendActionsTypeKey.CHANGE_FLOOR_STATUS, payload: param };
}

export function delFloor(id: number | string) {
    return { type: recommendActionsTypeKey.DELETE_FLOOR, payload: id };
}

export function addFloorDataList(value: any) {
    return { type: recommendActionsTypeKey.ADD_FLOOR_DATA, payload: value };
}

export function editFloorDataList(value: any) {
    return { type: recommendActionsTypeKey.EDIT_FLOOR_DATA, payload: value };
}