import * as React from 'react';
import { Form, Row, Col, Select, Input, Table } from 'antd'; 
import { FormComponentProps } from 'antd/lib/form';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { getExportTitles } from '../../../base/getExportTitles';
import { numFormat } from '../../../base/regular';

const FormItem = Form.Item;
const Option = Select.Option;

interface Props extends FormComponentProps {
    data: any;
    initPage: () => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    searchDate: (value: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
}

class NovelTotalData extends React.Component<Props, any> {

    componentDidMount() {
        this.props.initPage();
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    clear = () => {
        this.setState({Time: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            novelId: value.id,
            novelName: value.name,
            signed: value.signing,
            novelType: value.novelType,
            typeId: value.typeId,
            device: value.device
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.searchDate(param);
    }

    columns = () => {
        return [
            {
                title: 'Novel ID',
                dataIndex: 'novelId',
                key: 'novelId',
                className: 'column-center',
                sorter: true,
                fixed: true,
                width: 110,
                sortField: 'novelId',
            },
            {
                title: 'Novel Name',
                dataIndex: 'novelName',
                key: 'novelName',
                className: 'column-center',
                width: 250,
            },
            {
                title: 'Author',
                dataIndex: 'author',
                key: 'author',
                className: 'column-center',
            },
            {
                title: 'Category',
                dataIndex: 'typeName',
                key: 'typeName',
                className: 'column-center',
            },
            {
                title: 'Chapter Number',
                dataIndex: 'chapterCount',
                key: 'chapterCount',
                className: 'column-center',
                sorter: true,
                sortField: 'chapterCount',
            },
            {
                title: 'Total Words',
                dataIndex: 'totalWordCount',
                key: 'totalWordCount',
                className: 'column-center',
                sorter: true,
                sortField: 'totalWordCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Type',
                dataIndex: 'novelType',
                key: 'novelType',
                className: 'column-center',
                render: (text: any) => {
                    return (
                        (() => {
                            switch (text) {
                                case 0:
                                    return <span>Original</span>;
                                case 1:
                                    return <span>Reprint</span>;
                                case 2:
                                    return <span>PGC</span>;
                                case 3:
                                    return <span>Star Novel</span>;
                                default:
                                    return <span />;
                            }
                        })()
                    );
                }
            },
            {
                title: 'Signing',
                dataIndex: 'signed',
                key: 'signed',
                className: 'column-center',
                render: (text: number | string, record: any) => {
                    if (record.novelType === 3) {
                        return text ? 'Kontrak tertulis' : 'Kontrak digital';
                    } else {
                        return '--';
                    }
                }
            },
            {
                title: 'Writing Stars',
                dataIndex: 'writedStar',
                key: 'writedStar',
                className: 'column-center',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Reader Vote',
                dataIndex: 'voteStar',
                key: 'voteStar',
                className: 'column-center',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Voters',
                dataIndex: 'voteUserCount',
                key: 'voteUserCount',
                className: 'column-center',
                sorter: true,
                sortField: 'voteUserCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Click',
                dataIndex: 'clickCount',
                key: 'clickCount',
                className: 'column-center',
                sorter: true,
                sortField: 'clickCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Clickers',
                dataIndex: 'clickPeopleCount',
                key: 'clickPeopleCount',
                className: 'column-center',
                sorter: true,
                sortField: 'clickPeopleCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Subscribe',
                dataIndex: 'subscribeCount',
                key: 'subscribeCount',
                className: 'column-center',
                sorter: true,
                sortField: 'subscribeCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Subscribers',
                dataIndex: 'subscribePeopleCount',
                key: 'subscribePeopleCount',
                className: 'column-center',
                sorter: true,
                sortField: 'subscribePeopleCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Read Times',
                dataIndex: 'readTimes',
                key: 'readTimes',
                className: 'column-center',
                sorter: true,
                sortField: 'readTimes',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Update Date',
                dataIndex: 'updatedAtDateValue',
                key: 'updatedAtDateValue',
                className: 'column-center',
                sorter: true,
                sortField: 'shelfAt',
                render: (text: string) => (<span>{text ? text : '--'}</span>)
            },
            {
                title: 'Update Times',
                dataIndex: 'updatedAtTimeValue',
                key: 'updatedAtTimeValue',
                className: 'column-center',
                render: (text: string) => (<span>{text ? text : '--'}</span>)
            },
        ];
    }

    render() {

        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const { data, updatePage } = this.props;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const signingList = [
            {key: 'All', value: 'All'},
            {key: '1', value: 'Kontrak tertulis'},
            {key: '0', value: 'Kontrak digital'}
        ];

        const novelTYpeList = [
            {key: 'All', value: 'All'},
            {key: '0', value: 'Original'},
            {key: '1', value: 'Reprint'},
            {key: '2', value: 'PGC'},
            {key: '3', value: 'Star Novel'},
        ];

        return (
            <div>
                <Form>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="Category">
                                {getFieldDecorator('typeId', {
                                    initialValue: 'All'
                                })(
                                    <Select {...input}>
                                        <Option key="All">All</Option>
                                        {
                                            data.typeList.map((item: any) => {
                                                return <Option key={item.id}>{item.name}</Option>;
                                            })
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Signing">
                                {getFieldDecorator('signing', {
                                    initialValue: 'All'
                                })(
                                    <Select {...input}>
                                        {signingList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Type">
                                {getFieldDecorator('novelType', {
                                    initialValue: 'All'
                                })(
                                    <Select {...input}>
                                        {novelTYpeList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Device">
                                {getFieldDecorator('device', {
                                    initialValue: 'All'
                                })(
                                    <Select {...input}>
                                        <Option value="All">All</Option>
                                        <Option value="1">iOS</Option>
                                        <Option value="0">Android</Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Novel ID">
                                {getFieldDecorator('id')(
                                    <Input />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Novel Name">
                                {getFieldDecorator('name')(
                                    <Input />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                <Toolbar
                    showExport={true}
                    onSearch={this.onSearch}
                    onClear={this.clear}
                    onExport={this.handleExport}
                />
                <Table
                    loading={data.loading}
                    bordered={true}
                    rowKey="novelId"
                    columns={this.columns()}
                    style={{marginTop: '20px'}}
                    dataSource={data.dataList}
                    onChange={updatePage}
                    scroll={{ x: 2200 }}
                    pagination={{
                        defaultPageSize: 20,
                        total: data.total,
                        current: data.page.current,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '50', '80', '100'],
                        showTotal: total => {
                            return `Total: ${total} items`;
                        },
                    }}
                />
                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />
            </div>
        );
    }
}

export default Form.create()(NovelTotalData);