import typeActionsTypeKey from '../action/actionsTypeKey/typeActionsTypeKey';
import {  ActionTypes } from '../action/actionsType/typeActionsType';

export interface StoreState {
    page: PageData;
    typeList: any[];
    loading: boolean;
    total: number;
    searchData: any;
    exportModalVisible: boolean;
    sorterRule: any[];
    ruleSelected: any;
    sorter: any;
}

interface PageData {
    current: number;
    pageSize: number;
}

const initialState: StoreState = {
    loading: false,
    typeList: [],
    page: {
        current: 1,
        pageSize: 20,
    },
    total: 0,
    exportModalVisible: false,
    searchData: '',
    sorterRule: [],
    ruleSelected: '',
    sorter: {}
};

export const typeReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case typeActionsTypeKey.TYPE_INIT_PAGE:
            return initialState;

        case typeActionsTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case typeActionsTypeKey.GET_TYPE_LIST:
            return Object.assign({}, state, {
                typeList: action.payload,
                total: action.payload.length
            });

        case typeActionsTypeKey.GET_SORTER_RULE:
            return Object.assign({}, state, {
                sorterRule: action.payload,
            });

        case typeActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload,
            });

        case typeActionsTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload,
            });

        case typeActionsTypeKey.GET_TYPE_SEARCH_PARAM:
            return Object.assign({}, state, {
                searchData: action.payload,
            });

        case typeActionsTypeKey.GET_RULE_SELECTED:
            return Object.assign({}, state, {
                ruleSelected: action.payload
            });

        default:
            return state;
    }
};