import rewardOrderKey from '../actions/actionsTypeKey/rewardOrderActionsTypeKey';

// public action

export function initPage(type: number | string) {
    return { type: rewardOrderKey.INIT_PAGE, payload: type };
}

export function loading(value: boolean) {
    return { type: rewardOrderKey.LOADING, payload: value };
}
export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: rewardOrderKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: rewardOrderKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: rewardOrderKey.SUBMIT_EXPORT, payload: value };
}

export function getTotalRewardOrder(param: any) {
    return { type: rewardOrderKey.GET_TOTAL_REWARD_ORDER, payload: param };
}

export function getRewardOrderList(list: any) {
    return { type: rewardOrderKey.GET_REWARD_ORDER_LIST, payload: list };
}

export function getSearchDataParam(param: any) {
    return { type: rewardOrderKey.SEARCH_DATA, payload: param };
}

export function getGiftList(param: any[]) {
    return { type: rewardOrderKey.GET_REWARD_GIFT_LIST, payload: param };
}