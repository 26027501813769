import { fork, all, call, takeEvery, put, select, take } from 'redux-saga/effects';
import currencyOrderKey from '../../components/financial/actions/actionsTypeKey/currencyOrderActionsTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as vipOrderServer from '../../servers/vipOrderServer';
import * as currencyOrderActions from '../../components/financial/actions/currencyOrderActions';

// 初始页面请求登录
function* initPage() {
    while (true) {
        const { payload } = yield take(currencyOrderKey.INIT_PAGE);
        yield getVipOrderList();
        yield getNovelTotalData(payload);
        yield getRechargeList();
    }

}

function* getNovelTotalData(type: number | string) {
    try {
        const { result } = yield call(vipOrderServer.getNovelTotalList, type);
        yield put(currencyOrderActions.getTotalCurrencyOrder(result));
    } catch (err) {
        openNotification('error', 'Get Novel Total Failed');
    }
}

function* getRechargeList() {
    try {
        const { result } = yield call(vipOrderServer.getRechargeList);
        yield put(currencyOrderActions.getRechargeList(result));
    } catch (e) {
        openNotification('error', 'Get Product Types Failed');
    }
}

function* getVipOrderList() {
    const data = yield select();
    const vipData = data.financial.currencyOrderData;
    let param = {
        ...vipData.page,
        ...vipData.sorter,
        'data': {...vipData.searchData},
    };
    try {
        yield put(currencyOrderActions.loading(true));
        const { result } = yield call(vipOrderServer.getCurrencyOrderList, param);
        yield put(currencyOrderActions.getCurrencyOrderList(result));
        yield put(currencyOrderActions.loading(false));
    } catch (err) {
        yield put(currencyOrderActions.loading(false));
        openNotification('error', 'Get Reward Order Failed');
    }
}

function* watchExport() {
    while (true) {
        const { payload } = yield take(currencyOrderKey.SUBMIT_EXPORT);
        const state = yield select();
        const data = state.financial.currencyOrderData;

        payload.titles.map((o: any) => {
            if (o.index === 'status') {
                o.index = 'statusExport';
            }

            if (o.index === 'device') {
                o.index = 'deviceExport';
            }

            if (o.index === 'productType') {
                o.index = 'priceExport';
            }
        });

        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            data: {...data.searchData},
            ...data.sorter
        };

        try {
            yield put(currencyOrderActions.loading(true));
            yield call(vipOrderServer.exportCurrencyOrder, param);
            yield put(currencyOrderActions.loading(false));
            yield put(currencyOrderActions.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(currencyOrderActions.loading(false));
        }
    }
}

function* watchSearchData() {
    yield takeEvery(currencyOrderKey.SEARCH_DATA, getVipOrderList);
}

function* watchUpdatePage() {
    yield takeEvery(currencyOrderKey.UPDATE_PAGE, getVipOrderList);
}

export function* watchCurrencyOrderSaga() {
    yield all([
        fork(initPage),
        fork(watchSearchData),
        fork(watchUpdatePage),
        fork(watchExport)
    ]);
}