import * as React from 'react';
import { Link } from 'react-router-dom';
import {  Form, Row, Col, Select, Input, Table , Divider, Button, Popconfirm  } from 'antd';

import { FormComponentProps } from 'antd/lib/form/Form';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { getExportTitles } from '../../../base/getExportTitles';

import { numFormat } from '../../../base/regular';
import { QuarterlyListState } from '../reducers/quarterlyListReducers';
import { openNotification } from '../../widgets/Notification';

const FormItem = Form.Item;
const Option = Select.Option;

interface  QuarterlyListProps extends FormComponentProps {
    data: QuarterlyListState;
    initPage: () => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getQuarterlySearchData: (param: any) => void;
    quarterlyListConfirm: () => void;
    quarterlyRemoveRank: (param: any) => void;
}

interface QuarterlyState {
    currentQuarter: string;
    confirmButton: boolean;
    past: boolean;
    quarterId: number;
}

class QuarterlyList extends React.Component<QuarterlyListProps, QuarterlyState> {

    state: QuarterlyState = {
        currentQuarter: 'S1(2019/04 - 2019/06)',
        confirmButton: false,
        past: false,
        quarterId: -1
    };

    componentDidMount() {
        this.props.initPage();
    }

    // public action

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    clear = () => {
        this.props.form.resetFields();
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        let quarter =  value.quarter;
        if (quarter) {

            let msg = quarter.key.split('-');
            let status = msg[0], quarterId = msg[1];

            // 保存比赛ID
            this.setState({quarterId: quarterId});

            const param: any = {
                id: quarterId,
                novelTypeId: value.typeId,
                novelName: value.name,
                novelId: value.id,
                author: value.author
            };

            // 获取搜索赛季名称
            if (value.quarter) {
                this.setState({currentQuarter: quarter.label});
            }

            for (let item in param) {
                if (param[item] === 'All' || param[item] === '') {
                    delete param[item];
                }
            }

            status !== '0' ? this.setState({past: true}) : this.setState({past: false});

            this.props.getQuarterlySearchData(param);
        } else {
            openNotification('error', 'Quarter cannot empty!');
        }

    }

    removeList = (record: any) => {
        const { quarterId } = this.state;
        let param = {
            competitionId: quarterId,
            novelId: record.novelId
        };
        this.props.quarterlyRemoveRank(param);
    }

    columns = () => {
        return [{
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
            className: 'column-center',
        }, {
            title: 'Novel Id',
            dataIndex: 'novelId',
            key: 'novelId',
            className: 'column-center',
        }, {
            title: 'Novel Name',
            dataIndex: 'novelName',
            key: 'novelName',
            className: 'column-center',
        }, {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            className: 'column-center',
        }, {
            title: 'Category',
            dataIndex: 'novelTypeName',
            key: 'novelTypeName',
            className: 'column-center',
        }, {
            title: 'Stars',
            dataIndex: 'starCount',
            key: 'starCount',
            sorter: true,
            className: 'column-center',
        }, {
            title: 'Heat',
            dataIndex: 'heat',
            key: 'heat',
            sorter: true,
            className: 'column-center',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any) => {
                return <Link to={{pathname: `/content/chapterManager`, search: `?id=${record.novelId}`}}>view17</Link>;
            }
        }];
    }

    pastColumns = () => {
        const { data } = this.props;
        return [{
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
            className: 'column-center',
        }, {
            title: 'Novel Id',
            dataIndex: 'novelId',
            key: 'novelId',
            className: 'column-center',
        }, {
            title: 'Novel Name',
            dataIndex: 'novelName',
            key: 'novelName',
            className: 'column-center',
        }, {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            className: 'column-center',
        }, {
            title: 'Category',
            dataIndex: 'novelTypeName',
            key: 'novelTypeName',
            className: 'column-center',
        }, {
            title: 'Stars',
            dataIndex: 'starCount',
            key: 'starCount',
            sorter: true,
            className: 'column-center',
        }, {
            title: 'Bonus',
            dataIndex: 'bonus',
            key: 'bonus',
            className: 'column-center',
            render: (text: string) => (text && `Rp.${numFormat(text)}`)
        },  {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            className: 'column-center',
            render: (text: any, record: any, index: number) => {

                return(
                    <div>
                        <Link to={{pathname: `/content/chapterManager`, search: `?id=${record.novelId}`}}>view18</Link>
                        {
                            (data.gameStatus === 1 && record.rank < 11) &&
                            <Popconfirm
                                title="Are you sure delete this task?"
                                onConfirm={() => {this.removeList(record); }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <a style={{marginLeft: '15px'}}>Remove</a>
                            </Popconfirm>
                        }
                    </div>
                );
            }
        }];
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        let { past } = this.state;
        const titles = getExportTitles(past ? this.pastColumns() : this.columns());
        this.props.submitExport({...value, titles});
    }

    // page action
    quarterlyConfirm = () => {
        this.props.quarterlyListConfirm();
    }

    render () {

        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const { data } = this.props;
        const { getUpdatePage } = this.props;
        const { currentQuarter, past } = this.state;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        return (
            <div>
                <Form>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="Quarter">
                                {getFieldDecorator('quarter', {
                                })(
                                    <Select {...input} labelInValue={true}>
                                        {data.competitionList.map((item => <Option key={`${item.status}-${item.id}`}>{item.name}</Option>))}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Category">
                                {getFieldDecorator('typeId', {
                                    initialValue: 'All'
                                })(
                                    <Select {...input}>
                                        <Option key="All">All</Option>
                                        {data.novelTypeList.map(item => <Option key={item.id}>{item.name}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Novel Name"
                            >
                                {getFieldDecorator('name')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Novel ID"
                            >
                                {getFieldDecorator('id')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Author Name"
                            >
                                {getFieldDecorator('author')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport}  >
                    {
                        data.gameStatus === 1 &&
                        <Button
                            type="danger"
                            icon="check-circle"
                            style={{width: 95, margin: '8px 0', padding: 0}}
                            onClick={this.quarterlyConfirm}
                        >
                            Confirm
                        </Button>

                    }
                </Toolbar>
                <Divider>Current Quarter: {currentQuarter}</Divider>
                <Table
                    rowKey="rank"
                    loading={data.loading}
                    bordered={true}
                    columns={past ? this.pastColumns() : this.columns()}
                    style={{marginTop: '20px'}}
                    dataSource={data.quarterlyList}
                    onChange={getUpdatePage}
                    pagination={{
                        defaultPageSize: 20,
                        total: data.total,
                        current: data.page.current,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '50', '80', '100'],
                        showTotal: total => {
                            return `Total:${data.total} items`;
                        },
                    }}
                />
                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />
            </div>
        );
    }
}

export default Form.create()(QuarterlyList);