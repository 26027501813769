import * as React from 'react';
import { Col, DatePicker, Divider, Input, Row, Select, Form, Table } from 'antd';
import { changeToStamp, timeReverseWithNoHour } from '../../../base/dealWithTime';
import { getExportTitles } from '../../../base/getExportTitles';
import { numFormat } from '../../../base/regular';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal } from '../../widgets/ExportModal';
import { FormComponentProps } from 'antd/lib/form';
import { RewardOrderState } from '../reducers/rewardOrderReducers';
import TotalDataComponents from './TotalDataComponents';

const FormItem = Form.Item;
const Option = Select.Option;

interface  RewardOrderProps extends FormComponentProps {
    data: RewardOrderState;
    initPage: (type: number | string) => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getMonthlyListParam: (param: any) => void;
    getSearchDataParam: (param: any) => void;
}

interface RewardOrderInitialState {
    startTime: string;
    endTime: string;
    statusArr: any[];
}

class RewardOrder extends React.Component<RewardOrderProps, RewardOrderInitialState> {

    state: RewardOrderInitialState = {
        startTime: '',
        endTime: '',
        statusArr: ['UnPay', 'Completed', 'Pending'],
    };

    componentDidMount() {
        this.props.initPage(1);
    }

    // public action

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    clear = () => {
        this.setState({startTime: ''});
        this.setState({endTime: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {

        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            gtTime: this.state.startTime.length === 0 ? undefined : changeToStamp(this.state.startTime) / 1000,
            ltTime: this.state.endTime.length === 0 ? undefined : changeToStamp(this.state.endTime) / 1000,
            userName: value.name,
            userId: value.id,
            orderSn: value.orderSn,
            status: value.status,
            productProps: value.gift,
            device: value.device,
            novelId: value.novelId,
            novelName: value.novelName
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.getSearchDataParam(param);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    columns = () => {
        return [{
            title: 'Order number',
            dataIndex: 'orderSn',
            key: 'orderSn',
            fixed: true,
            width: 210,
            className: 'column-center',
        }, {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            className: 'column-center',
        }, {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
            className: 'column-center',
        }, {
            title: 'Novel Name',
            dataIndex: 'novelName',
            key: 'novelName',
            className: 'column-center',
        }, {
            title: 'Novel ID',
            dataIndex: 'novelId',
            key: 'novelId',
            className: 'column-center',
        }, {
            title: 'Product Props',
            dataIndex: 'productProps',
            key: 'productProps',
            className: 'column-center',
        }, {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            className: 'column-center',
            render: (text: number) => (numFormat(text))
        }, {
            title: 'Payment Channel',
            dataIndex: 'paymentChannel',
            key: 'paymentChannel',
            className: 'column-center',
        }, {
            title: 'Device',
            dataIndex: 'device',
            key: 'device',
            className: 'column-center',
            render: (text: number) => {
                return <span>{text === 0 ? 'Android' : 'iOS'}</span>;
            }
        }, {
            title: 'Order Status',
            dataIndex: 'status',
            key: 'status',
            className: 'column-center',
            render: (text: number) => (<span>{this.state.statusArr[text]}</span>)
        }, {
            title: 'Order Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            className: 'column-center',
            sorter: true,
            sortField: 'createdAt'
        }, {
            title: 'Order Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
            className: 'column-center',
        }, {
            title: 'Payment Date',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            className: 'column-center',
            sorter: true,
            sortField: 'paymentAt'
        }, {
            title: 'Payment Time',
            dataIndex: 'paymentTime',
            key: 'paymentTime',
            className: 'column-center',
        }];
    }

    // page action

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: timeReverseWithNoHour(startTime) + ' 00:00:00'});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: timeReverseWithNoHour(endTime) + ' 23:59:59'});
        } else {
            this.setState({endTime});
        }
    }

    render() {

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const {getFieldDecorator, getFieldsValue } = this.props.form;

        const { data, getUpdatePage } = this.props;
        const rewardTotalMsg = data.rewardTotalData;

        return (
            <div>
                <TotalDataComponents data={rewardTotalMsg}/>

                <Divider>Reward Order</Divider>

                <Form>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem label="Date">
                                {getFieldDecorator('startTime')(
                                    <DatePicker
                                        allowClear={true}
                                        format={'DD-MM-YYYY'}
                                        placeholder={'Start Time'}
                                        style={{width : '48%'}}
                                        onChange={this.startTimeChange}
                                    />
                                )}
                                {getFieldDecorator('endTime')(
                                    <DatePicker
                                        allowClear={true}
                                        format={'DD-MM-YYYY'}
                                        placeholder={'End Time'}
                                        style={{width : '48%', marginLeft : '4%'}}
                                        onChange={this.endTimeChange}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="User Name"
                            >
                                {getFieldDecorator('name')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="User ID"
                            >
                                {getFieldDecorator('id')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Order Number"
                            >
                                {getFieldDecorator('orderSn')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={4}>
                            <FormItem
                                label="Novel Name"
                            >
                                {getFieldDecorator('novelName')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={4}>
                            <FormItem
                                label="Novel ID"
                            >
                                {getFieldDecorator('novelId')(
                                    <Input {...input}/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={5}>
                            <FormItem
                                label="Product Props"
                            >
                                {getFieldDecorator('gift', {
                                    initialValue: 'All'
                                })(
                                    <Select style={{ width: '100%' }} >
                                        <Option value="All">All</Option>
                                        {
                                            data.giftList && data.giftList.map((o: any, i: number) => {
                                                return <Option value={o.rewardProps} key={i} >{o.rewardProps}</Option>;
                                            })
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={5}>
                            <FormItem
                                label="Order Status"
                            >
                                {getFieldDecorator('status', {
                                    initialValue: 'All'
                                })(
                                    <Select style={{ width: '100%' }} >
                                        <Option value="All">All</Option>
                                        <Option value="0">UnPay</Option>
                                        <Option value="1">Completed</Option>
                                        <Option value="2">Pending</Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Device"
                            >
                                {getFieldDecorator('device', {
                                    initialValue: 'All'
                                })(
                                    <Select style={{ width: '100%' }} >
                                        <Option value="All">All</Option>
                                        <Option value="0">Android</Option>
                                        <Option value="1">iOS</Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>

                <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport} />
                <Table
                    rowKey="id"
                    loading={data.loading}
                    bordered={true}
                    columns={this.columns()}
                    style={{marginTop: '20px'}}
                    scroll={{ x: 2100 }}
                    dataSource={data.rewardOrderList}
                    onChange={getUpdatePage}
                    pagination={{
                        defaultPageSize: 20,
                        total: data.total,
                        current: data.page.current,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '50', '80', '100'],
                        showTotal: total => {
                            return `Total:${data.total} items`;
                        },
                    }}
                />

                <ExportModal
                    getFieldDecorator={getFieldDecorator}
                    getFieldsValue={getFieldsValue}
                    visible={data.exportModalVisible}
                    loading={data.loading}
                    onCancel={this.closeExportModal}
                    submitExport={this.submitExport}
                />
            </div>
        );
    }
}

export default Form.create()(RewardOrder);