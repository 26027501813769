import  MonthlyList  from '../MonthlyList';
import { connect } from 'react-redux';
import * as monthlyListAction from '../../actions/monthlyActions';

const mapStateToProps = (state: any) => ({
    data: state.star.monthlyList
});

const mapDispatchToProps = {
    initPage: monthlyListAction.initPage,
    exportModalVisible: monthlyListAction.exportModalVisible,
    submitExport: monthlyListAction.submitExport,
    getUpdatePage: monthlyListAction.getUpdatePage,
    getMonthlySearchData: monthlyListAction.getMonthlySearchData,
    monthlyListConfirm: monthlyListAction.monthlyListConfirm,
    monthlyRemoveRank: monthlyListAction.monthlyRemoveRank

};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MonthlyList);