import { openNotification } from '../components/widgets/Notification';

export function isNum(value: any) {
    let reg = /^(?!_)([A-Za-z0-9 ]+)$/;
    if (reg.test(value)) {
        return true;
    } else {
        openNotification('error', 'Special characters cannot be included');
        return false;
    }
}

/**
 * 只能输入字符和空格英文逗号点横杆
 */
export function justChar(rule: any, value: any, callback: any) {
    let char = /^(?!_)([-A-Za-z0-9,. ]+)$/;
    if (!char.test(value)) {
        callback('Can only enter characters');
    } else {
        callback();
    }
}

/**
 * 判断是否是url
 */

export function IsURL(rule: any, value: any, callback: any) {
    var strRegex = /[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/;
    if (value) {
        if (strRegex.test(value)) {
            callback();
        } else {
            callback('Please enter URL！');
        }
    } else {
        callback();
    }
}

/**
 * 只能输入正整数
 */
export function isInt(rule: any, value: any, callback: any) {
    var reg = /^[1-9]\d*$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback('Please enter Number！');
    }
}

/**
 * 只能输入正整数和英文逗号
 */
export function isIntAndCommas(rule: any, value: any, callback: any) {
    var reg = /^([0-9,]+)$/;
    if (reg.test(value)) {
        callback();
    } else {
        callback('Parameter contains illegal characters！');
    }
}

/**
 * 金额千分位逗号
 */
export function numFormat(num: any) {
    if (num || num === 0) {
        var c = (num.toString().indexOf ('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
        return c;
    }
}