enum identityTypeKey {
    INIT_PAGE = 'IDENTITY_INIT_PAGE',
    UPDATE_PAGE = 'IDENTITY_UPDATE_PAGE',
    EXPORT_MODAL_VISIBLE = 'IDENTITY_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'IDENTITY_SUBMIT_EXPORT',
    LOADING = 'IDENTITY_LOADING',
    GET_SEARCH_DATA = 'GET_IDENTITY_SEARCH_DATA',
    GET_IDENTITY_LIST = 'GET_IDENTITY_LIST',
    GET_IDENTITY_TABS_KEY = 'GET_IDENTITY_TABS_KEY',
    GET_IDENTITY_DETAIL_ID = 'GET_IDENTITY_DETAIL_ID',
    GET_IDENTITY_DETAIL_MSG = 'GET_IDENTITY_DETAIL_MSG',
    GET_IDENTITY_DETAIL_MODIFY_PARAM = 'GET_IDENTITY_DETAIL_MODIFY_PARAM',
    IDENTITY_DETAIL_TO_LIST = 'IDENTITY_DETAIL_TO_LIST'
}

export default identityTypeKey;