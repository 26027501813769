import * as React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Divider, Card, Icon, Modal } from 'antd';

interface AuthorDetailProps {
    data: any;
    match: any;
    initPage: () => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    getAuthorDetailId: (id: string) => void;
}

interface InitialState {
    novelTypeList: any[];
    signedList: any[];
    imgModal: boolean;
    imgUrl: string;
    certification: any[];
    taskCardPicSHow: boolean;
}

class AuthorDetail extends React.Component<AuthorDetailProps, InitialState> {

    state: InitialState = {
        novelTypeList: ['Original', 'Reprint', 'PGC', 'Star Novel'],
        certification: ['not certified', 'In certification', 'verified', 'certification failed'],
        signedList: ['Not signed', 'Signed'],
        imgModal: false,
        imgUrl: '',
        taskCardPicSHow: false,
    };

    componentDidMount() {
        this.props.initPage();
        let id = this.props.match.params.id;
        this.props.getAuthorDetailId(id);
    }

    imgModalShow = (e: any) => {
        this.setState({imgModal: true, imgUrl: e.target.src});
    }

    imgModalOk = () => {
        this.imgModalCancel();
    }

    imgModalCancel = () => {
        this.setState({imgModal: false});
    }

    parameterFilter = (obj: any, name: string): string => {
        if (obj && obj[name]) {
            return obj[name];
        } else {
            return '--';
        }
    }

    columns = () => {
        return [{
            title: 'Novel ID',
            dataIndex: 'novelId',
            key: 'novelId',
        }, {
            title: 'Novel Name',
            dataIndex: 'novelName',
            key: 'novelName',
        }, {
            title: 'Category',
            dataIndex: 'novelTypeName',
            key: 'novelTypeName',
        }, {
            title: 'Chapter Number',
            dataIndex: 'chapterCount',
            key: 'chapterCount',
        }, {
            title: 'Type',
            dataIndex: 'novelType',
            key: 'novelType',
            render: (text: string) => {
               return <span>{this.state.novelTypeList[text]}</span>;
            }
        }, {
            title: 'Signing',
            dataIndex: 'signed',
            key: 'signed',
            render: (text: string) => {
                return <span>{this.state.signedList[text]}</span>;
            }
        }, {
            title: 'Update',
            dataIndex: 'updatedStatus',
            key: 'updatedStatus',
            render: (text: number) => (text === 0 ? 'Update' : 'Complete')
        }, {
            title: 'Update',
            dataIndex: 'updatedDate',
            key: 'updatedDate',
            sorter: true,
            sortField: 'updatedAt'
        }, {
            title: 'Update Time',
            dataIndex: 'updatedTime',
            key: 'updatedTime',
        }, {
            title: 'Setting',
            dataIndex: 'setting',
            key: 'setting',
            render: (text: any, record: any) => {
                return <Link to={{pathname: `/content/chapterManager`, search: `?id=${record.novelId}`}}>view19</Link>;
            }
        }];
    }

    render() {

        let msgList = {
            style: {
                fontSize: '16px',
                marginBottom: '15px',
                wordWrap: 'break-word',
            }
        } as any;

        let imgStyle = {
            style: {
                width: '200px',
                height: '104px',
                margin: '0 8px 8px 0',
                verticalAlign: 'top',
                marginLeft: '15px',
                padding: '8px',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                cursor: 'pointer'
            }
        };

        const { data, updatePage } = this.props;

        const { imgModal, imgUrl, certification } = this.state;

        let bankInfo = data.authorDetail.bankInfo, basicInfo = data.authorDetail.basicInfo;

        return (
          <div>
              <Card
                  title={<span><Icon style={{fontSize: '22px', marginRight: '10px'}} type="user" />{data.authorDetail.penName || data.authorDetail.userName}</span>}
                  bordered={false}
              >
                  <div style={{marginBottom: '15px'}}>
                      <p style={{fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>User Message</p>
                      <Row>
                          <Col span={12} {...msgList}>Creation time: {this.parameterFilter(data.authorDetail, 'createdAtDateTime')}</Col>
                          <Col span={11} offset={1} {...msgList}>Novel number: {this.parameterFilter(data.authorDetail, 'novelCount')}</Col>
                          <Col span={12} {...msgList}>Author ID number: {this.parameterFilter(data.authorDetail, 'id')}</Col>
                          <Col span={11} offset={1} {...msgList}>Total subscription number: {this.parameterFilter(data.authorDetail, 'totalSubscription')}</Col>
                          <Col span={12} {...msgList}>Certification: {
                              certification[data.authorDetail.authenticationStatus] || '--'
                             }
                             </Col>
                          <Col span={11} offset={1} {...msgList}>Total reading: {this.parameterFilter(data.authorDetail, 'totalReadCount')}</Col>
                      </Row>
                  </div>
                  <Divider />
                  <div style={{marginBottom: '15px'}}>
                      <p style={{fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>Basic Information</p>
                      <Row>
                          <Col span={12} {...msgList}>
                              Name: {basicInfo && basicInfo.givenName || basicInfo && basicInfo.familyName ? `${basicInfo.givenName ? basicInfo.givenName : ''}` + `  ${basicInfo.familyName ? basicInfo.familyName : ''}` : '--'}
                          </Col>
                          <Col span={11} offset={1} {...msgList}>
                              Phone number: {this.parameterFilter(basicInfo, 'phone')}
                          </Col>
                          <Col span={24} {...msgList}>
                              Identity number: {this.parameterFilter(basicInfo, 'cardNumber')}
                          </Col>

                          <Col span={24} {...msgList}>
                              Facebook: <span  style={{wordWrap: 'break-word', width: '80%', display: 'block'}}>
                                {this.parameterFilter(basicInfo, 'facebook')}
                              </span>
                          </Col>
                          <Col span={24} {...msgList}>
                              Ins: <span  style={{wordWrap: 'break-word', width: '80%', display: 'block'}}>
                                {this.parameterFilter(basicInfo, 'ins')}
                              </span>
                          </Col>
                          <Col span={24} {...msgList}>
                              E-mail: <span  style={{wordWrap: 'break-word', width: '80%', display: 'block'}}>
                                {this.parameterFilter(basicInfo, 'email')}
                              </span>
                          </Col>
                          <Col span={12} {...msgList}>
                              ID card (front photo): {basicInfo && basicInfo.cardPic ? <img {...imgStyle} src={basicInfo.cardPic} onClick={(e: any) => {this.imgModalShow(e); }} alt=""/> : '--'}
                          </Col>
                          <Col span={11} offset={1} {...msgList}>
                              Hand-held identity card: {basicInfo && basicInfo.cardHandPic ? <img {...imgStyle} src={basicInfo.cardHandPic} onClick={(e: any) => {this.imgModalShow(e); }} alt=""/> : '--'}
                          </Col>
                      </Row>
                  </div>
                  <Divider />
                  <div style={{marginBottom: '15px'}}>
                      <p style={{fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>Bank card information</p>
                      <Row>
                          <Col span={12} {...msgList}>Name: {this.parameterFilter(bankInfo, 'name')}</Col>
                          <Col span={11} offset={1} {...msgList}>Bank name: {this.parameterFilter(bankInfo, 'bankName')}</Col>
                          <Col span={12} {...msgList}>Bank card number: {this.parameterFilter(bankInfo, 'cardNumber')}</Col>
                          <Col span={11} offset={1} {...msgList}>Call: {this.parameterFilter(bankInfo, 'title')}</Col>
                          <Col span={12} {...msgList}>Nomer NPWP: {this.parameterFilter(bankInfo, 'taskCardNo')}</Col>
                          <Col span={11} offset={1} {...msgList}>
                              Foto NPWP: {bankInfo && bankInfo.taskCardPic ? <img {...imgStyle} src={bankInfo.taskCardPic} onClick={(e: any) => {this.imgModalShow(e); }} alt=""/> : '--'}
                          </Col>
                      </Row>
                  </div>
                  <Divider />
                  <div style={{marginBottom: '15px'}}>
                      <p style={{fontSize: '16px', fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>Novel information</p>
                      <Table
                          rowKey="novelId"
                          loading={data.loading}
                          bordered={true}
                          dataSource={data.authorDetailNovelList}
                          columns={this.columns()}
                          onChange={updatePage}
                          pagination={{
                              defaultPageSize: 20,
                              total: data.total,
                              current: data.page.current,
                              showSizeChanger: true,
                              pageSizeOptions: ['20', '50', '80', '100'],
                              showTotal: total => {
                                  return `Total: ${data.total} items`;
                              },
                          }}
                      />
                  </div>
              </Card>
              <Modal
                  title="Large Picture"
                  visible={imgModal}
                  onOk={this.imgModalOk}
                  onCancel={this.imgModalCancel}
              >
                  <img style={{width: '400px'}} src={imgUrl} alt=""/>
              </Modal>
          </div>
        );
    }
}

export default AuthorDetail;