import paidNovelActionsTypeKey from './actionsTypeKey/paidNovelActionsTypeKey';

export function initPage() {
    return {type: paidNovelActionsTypeKey.INIT_PAGE};
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: paidNovelActionsTypeKey.UPDATE_PAGE, payload: {pagination, sorter} };
}

export function searchDate(value: any) {
    return { type: paidNovelActionsTypeKey.SEARCH_DATA, payload: value };
}

export function loading(bol: boolean) {
    return { type: paidNovelActionsTypeKey.LOADING, payload: bol };
}

export function exportModalVisible(value: boolean) {
    return { type: paidNovelActionsTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: paidNovelActionsTypeKey.SUBMIT_PAID_NOVEL_EXPORT, payload: value };
}

export function getTypeList(value: {data: any, total: number}) {
    return { type: paidNovelActionsTypeKey.GET_TYPE_DATA_LIST, payload: value };
}

export function getTotalData(value: any) {
    return { type: paidNovelActionsTypeKey.GET_TOTAL_DATE, payload: value };
}