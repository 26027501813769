import  chapterDetailKey  from './actionsTypeKey/chapterDetailActionTypeKey';

export function initPage(param: any) {
    return { type: chapterDetailKey.INIT_PAGE, payload: param};
}

export function getChapterDetailData(text: string) {
    return { type: chapterDetailKey.GET_CHAPTER_DETAIL_DATA, payload: text };
}

export function getEditContent(param: any) {
    return { type: chapterDetailKey.GET_CHAPTER_EDIT_CONTENT, payload: param };
}

export function getOriginData(text: string) {
    return { type: chapterDetailKey.GET_CHAPTER_DETAIL_ORIGIN_DATA, payload: text };
}

export function chapterDetailLoading(bol: boolean) {
    return { type: chapterDetailKey.GET_CHAPTER_DETAIL_LOADING, payload: bol };
}

export function getEditContentForEditor(param: any) {
    return { type: chapterDetailKey.GET_CHAPTER_EDIT_CONTENT_FOR_EDITOR, payload: param };
}

export function isDiff(bol: boolean) {
    return { type: chapterDetailKey.GET_CHAPTER_DETAIL_DIFF, payload: bol };
}

export function changeShowContent(param: any) {
    return { type: chapterDetailKey.GET_CHAPTER_DETAIL_CHANGE, payload: param };
}