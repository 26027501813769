import { setDefaultConfig } from '@iliewen/js-common';
import { getPackageId, getPackageName } from '../util/packageUtil';

import loginModal from '../components/widgets/LoginModal';

export default {
    setDefaultConfig() {
        // const api = process.env.NODE_ENV === 'development' ? '/api' : '/api';
        const versionObj = {appVersion: '1.0'};
        setDefaultConfig({
            baseURL: 'http://' + window.location.host + '/api',
            withCredentials: true,
            headers: {
                'X-Auth-Token': window.localStorage.getItem('TOKEN'),
                'X-Device-Info': JSON.stringify(versionObj),
                'X-Package-Name': getPackageName(),
                'X-Package-Id': getPackageId()
            },
            transformResponse: [(data) => {
                if (data === 'Permission Deny' || data === 'Login Required') {
                    loginModal.modal('This user does not have permission, please switch the user to login again.');
                } else if (data === 'Authentication failed') {
                    loginModal.modal('Please log in.');
                }
                return JSON.parse(data);
            }]
        });
    },
};