import  BannerData  from '../BannerData';
import { connect } from 'react-redux';
import * as bannerDataAction from '../../actions/bannerDataAction';

const mapStateToProps = (state: any) => ({
    data: state.data.bannerData,
});

const mapDispatchToProps = {
    initPage: bannerDataAction.initPage,
    updatePage: bannerDataAction.updatePage,
    submitExport: bannerDataAction.submitExport,
    exportModalVisible: bannerDataAction.exportModalVisible,
    bannerDataListDataSearch: bannerDataAction.bannerDataListDataSearch,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BannerData);