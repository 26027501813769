enum RecommendDetailActionsTypeKey {
    INIT_PAGE = 'RECOMMEND_DETAIL_INIT_PAGE',
    GET_FLOOR_ID_TO_REQUEST = 'RECOMMEND_DETAIL_GET_FLOOR_ID_TO_REQUEST',
    GET_FLOOR_LIST = 'RECOMMEND_DETAIL_GET_FLOOR_LIST',
    UPDATE_PAGE = 'RECOMMEND_DETAIL_UPDATE_PAGE',
    LOADING = 'RECOMMEND_DETAIL_LOADING',
    MODIFY_LOADING = 'RECOMMEND_DETAIL_MODIFY_LOADING',
    GET_FLOOR_NOVEL_LIST = 'RECOMMEND_DETAIL_GET_FLOOR_NOVEL_LIST',
    CHANGE_FLOOR_ID = 'RECOMMEND_DETAIL_CHANGE_FLOOR_ID',
    GET_DEL_FLOOR_NOVEL_MSG = 'RECOMMEND_DETAIL_GET_DEL_FLOOR_NOVEL_MSG',
    ADD_EDIT_FLOOR_NOVEL_MODAL = 'RECOMMEND_DETAIL_ADD_EDIT_FLOOR_NOVEL_MODAL',
    LIMIT_FREE_SETTING_MODAL = 'RECOMMEND_DETAIL_LIMIT_FREE_SETTING_MODAL',
    GET_NOVEL_TYPE_LIST = 'RECOMMEND_DETAIL_GET_NOVEL_TYPE_LIST',
    GET_SEARCH_NOVEL_PARAM = 'RECOMMEND_DETAIL_GET_SEARCH_NOVEL_PARAM',
    GET_SEARCH_NOVEL_LIST = 'RECOMMEND_DETAIL_GET_SEARCH_NOVEL_LIST',
    ADD_RECOMMEND_NOVEL = 'RECOMMEND_DETAIL_ADD_RECOMMEND_NOVEL',
    EDIT_RECOMMEND_NOVEL = 'RECOMMEND_DETAIL_EDIT_RECOMMEND_NOVEL',
    GET_CHANG_NOVEL_LOCATOIN_MSG = 'RECOMMEND_DETAIL_GET_CHANG_NOVEL_LOCATOIN_MSG',
    UPDATE_LIMIT_FREE_SETTING = 'RECOMMEND_DETAIL_UPDATE_LIMIT_FREE_SETTING',
    SUBMIT_LIMIT_FREE_SETTING = 'RECOMMEND_DETAIL_SUBMIT_LIMIT_FREE_SETTING'
}

export default RecommendDetailActionsTypeKey;