import  chapterActionsTypeKey  from './actionsTypeKey/chapterManagerActionsTypeKey';

export function initPage(id: any) {
    return { type: chapterActionsTypeKey.CHAPTET_INIT_PAGE, payload: id};
}

export function getNovelId(param: any) {
    return { type: chapterActionsTypeKey.CHAPTET_NOVEL_ID, payload: param };
}

export function getNovelMsg(msg: any) {
    return { type: chapterActionsTypeKey.GET_NOVEL_MSG, payload: msg };
}

export function getChapterTableChange(pagination: any, filters: any, sorter: any) {
    return { type: chapterActionsTypeKey.CHAPTEL_TABLE_CHANGE, payload: { pagination, sorter} };
}

export function chapterSortChange(param: any) {
    return { type: chapterActionsTypeKey.CHAPTER_MANAGER_SORT, payload: param };
}

export function getChapterList(list: any) {
    return { type: chapterActionsTypeKey.GET_CHAPTER_LIST, payload: list };
}

export function getChapterSearchParam(param: any) {
    return { type: chapterActionsTypeKey.GET_CHAPTER_SEARCH_PARAM, payload: param };
}

export function loading(bool: boolean) {
    return { type: chapterActionsTypeKey.LOADING, payload: bool };
}

export function getDelChapterId(ids: any[]) {
    return { type: chapterActionsTypeKey.GET_DEL_CHAPTER_ID, payload: ids };
}

export function getNovelList(value: any) {
    return { type: chapterActionsTypeKey.GET_NOVEL_LIST, payload: value };
}

export function uploadOpen(value: boolean) {
    return { type: chapterActionsTypeKey.UPLOAD_OPEN, payload: value };
}

export function btnLoading(value: boolean) {
    return { type: chapterActionsTypeKey.BTN_LOADING, payload: value };
}

export function getChapterId(record: any) {
    return { type: chapterActionsTypeKey.GET_CHAPER_ID, payload: record };
}

export function updateChapterList() {
    return { type: chapterActionsTypeKey.UPDATE_CHAPTER_LIST };
}

export function changeNovelReviewStatus(param: any) {
    return { type: chapterActionsTypeKey.CHANGE_NOVEL_REVIEW_STATUS, payload: param };
}

export function downloadCover(param: any) {
    return { type: chapterActionsTypeKey.DOWNLOAD_COVER, payload: param };
}

export function paidModal(bol: boolean) {
    return { type: chapterActionsTypeKey.PAID_MODAL, payload: bol };
}

export function getIgnoreBtn(id: string) {
    return { type: chapterActionsTypeKey.IGNORE_NOVEL, payload: id };
}

export function showPaidButton(bol: boolean) {
    return { type: chapterActionsTypeKey.SHOW_PAID_BUTTON, payload: bol };
}

export function getPaidSetting(param: any) {
    return { type: chapterActionsTypeKey.GET_PAID_SETTING, payload: param };
}

export function downloadNovel() {
    return { type: chapterActionsTypeKey.DOWNLOAD_NOVEL };
}

export function isSettingOpen(param: string) {
    return { type: chapterActionsTypeKey.IS_SETTING_OPEN, payload: param };
}

export function getSettingSort(param: number) {
    return { type: chapterActionsTypeKey.GET_SETTING_SORT, payload: param };
}

export function getSettingName(param: string) {
    return { type: chapterActionsTypeKey.GET_SETTING_NAME, payload: param };
}

export function getMinRatio(param: any) {
    return { type: chapterActionsTypeKey.GET_MIN_RATIO, payload: param };
}

export function getMaxRatio(param: any) {
    return { type: chapterActionsTypeKey.GET_MAX_RATIO, payload: param };
}

export function SettingModalCancel() {
    return { type: chapterActionsTypeKey.SETTING_MODAL_CANCEL };
}

export function getSettingMsg(id: number | string) {
    return { type: chapterActionsTypeKey.GET_SETTING_MSG, payload: id };
}

export function getPaidChapterSettingMsg(param: any) {
    return { type: chapterActionsTypeKey.GET_PAID_CHAPTER_SETTING_MSG, payload: param };
}