import helpDetailManagerKeys from '../actions/actionsTypeKey/helpDetailActionTypeKey';
import { ActionTypes } from '../actions/actionsType/helpDetailActionType';

export interface StoreState {
    helpModalList: any[];
    questionList: any[];
    loading: boolean;
    openCloseQuestionModal: boolean;
    openCloseIdModal: boolean;
}

const initialState: StoreState = {
    helpModalList: [],
    questionList: [],
    loading: false,
    openCloseQuestionModal: false,
    openCloseIdModal: false,
};

export const webHelpDetailReducers = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case helpDetailManagerKeys.INIT_PAGE:
            return initialState;

        case helpDetailManagerKeys.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case helpDetailManagerKeys.OPEN_CLOSE_QUESTION_MODAL:
            return Object.assign({}, state, {
                openCloseQuestionModal: action.payload
            });

        case helpDetailManagerKeys.OPEN_CLOSE_ID_MODAL:
            return Object.assign({}, state, {
                openCloseIdModal: action.payload
            });

        case helpDetailManagerKeys.GET_QUESTION_LIST:
            return Object.assign({}, state, {
                questionList: action.payload
            });

        case helpDetailManagerKeys.GET_MODAL_LIST:
            return Object.assign({}, state, {
                helpModalList: action.payload
            });

        default:
            return state;
    }
};