import { fork, all, call, put, take } from 'redux-saga/effects';
import helpDetailActionTypeKey from '../../components/webManager/actions/actionsTypeKey/helpDetailActionTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as  helpServer from '../../servers/helpServer';
import * as helpCenterActions from '../../components/webManager/actions/helpDetailActions';

function* initPage() {
    while (true) {
        const { payload } = yield take(helpDetailActionTypeKey.INIT_PAGE);
        yield  getHelpModalList();
        yield getQuestionList(payload);
    }
}

function* getHelpModalList() {
    try {
        yield put(helpCenterActions.isLoading(true));
        const { result } = yield call(helpServer.getHelpList, 1);
        yield put(helpCenterActions.getHelpModalList(result));
        yield put(helpCenterActions.isLoading(false));
    } catch (e) {
        yield put(helpCenterActions.isLoading(false));
        openNotification('error', 'get help modal list fail');
    }
}

function* getQuestionList(id: number | string) {
    try {
        yield put(helpCenterActions.isLoading(true));
        const { result } = yield call(helpServer.getHelpDetailList, id);
        yield put(helpCenterActions.getQuestionList(result));
        yield put(helpCenterActions.isLoading(false));
    } catch (e) {
        yield put(helpCenterActions.isLoading(false));
        openNotification('error', 'get help modal list fail');
    }
}

function* changeHelpModalQuestion() {
    while (true) {
        const { payload } = yield take(helpDetailActionTypeKey.CHANGE_MODAL_QUESTION);
        yield getQuestionList(payload);
    }
}

function* addQuestion() {
    while (true) {
        const { payload } = yield take(helpDetailActionTypeKey.ADD_QUESTION);
        try {
            yield put(helpCenterActions.isLoading(true));
            yield call(helpServer.addQuestion, payload);
            yield put(helpCenterActions.openCloseQuestionModal(false));
            yield getQuestionList(payload.moduleId);
            yield put(helpCenterActions.isLoading(false));
            openNotification('success', 'Success to add question');
        } catch (e) {
            yield put(helpCenterActions.isLoading(false));
            openNotification('error', 'Fail to add question');
        }
    }
}

function* editQuestion() {
    while (true) {
        const { payload } = yield take(helpDetailActionTypeKey.EDIT_QUESTION);
        try {
            yield put(helpCenterActions.isLoading(true));
            yield call(helpServer.editQuestion, payload);
            yield put(helpCenterActions.openCloseQuestionModal(false));
            yield getQuestionList(payload.moduleId);
            yield put(helpCenterActions.isLoading(false));
            openNotification('success', 'Success to edit question');
        } catch (e) {
            yield put(helpCenterActions.isLoading(false));
            openNotification('error', 'Fail to edit question');
        }
    }
}

function* changerQuestionSort() {
    while (true) {
        const { payload } = yield take(helpDetailActionTypeKey.GET_CHANGE_QUESTION_SORT_MSG);
        try {
            yield put(helpCenterActions.isLoading(true));
            yield call(helpServer.modifyQuestionSort, payload);
            yield put(helpCenterActions.openCloseIdModal(false));
            yield getQuestionList(payload.moduleId);
            yield put(helpCenterActions.isLoading(false));
            openNotification('success', 'Success to change question sort');
        } catch (e) {
            yield put(helpCenterActions.isLoading(false));
            openNotification('error', 'Fail to change question sort');
        }
    }
}

function* delQuestion() {
    while (true) {
        const { payload } = yield take(helpDetailActionTypeKey.DEL_QUESTION);
        try {
            yield put(helpCenterActions.isLoading(true));
            yield call(helpServer.delQuestion, payload.id);
            yield getQuestionList(payload.moduleId);
            yield put(helpCenterActions.isLoading(false));
            openNotification('success', 'Success to del question');
        } catch (e) {
            yield put(helpCenterActions.isLoading(false));
            openNotification('error', 'Fail to del question');
        }
    }
}

export function* watchWebHelpDetailSaga() {
    yield all([
        fork(initPage),
        fork(changeHelpModalQuestion),
        fork(addQuestion),
        fork(editQuestion),
        fork(changerQuestionSort),
        fork(delQuestion)
    ]);
}