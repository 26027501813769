import  PaidNovelManager  from '../PaidNovelManager';
import { connect } from 'react-redux';
import * as paidNovelAction from '../../actions/paidNovelAction';

const mapStateToProps = (state: any) => ({
    data: state.content.paidNovelReducer
});

const mapDispatchToProps = {
    initPage: paidNovelAction.initPage,
    updatePage: paidNovelAction.updatePage,
    searchDate: paidNovelAction.searchDate,
    exportModalVisible: paidNovelAction.exportModalVisible,
    submitExport: paidNovelAction.submitExport
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PaidNovelManager);