import { postJson } from '@iliewen/js-common';

// import config from '../config';
import loginModal from '../components/widgets/LoginModal';

let getParam = function (name: string) {
    let search = document.location.search;
    let pattern = new RegExp('[?&]' + name + '\=([^&]+)', 'g');
    let matcher = pattern.exec(search);
    let items = null;
    if (null != matcher) {
        try {
            items = decodeURIComponent(decodeURIComponent(matcher[1]));
        } catch (e) {
            try {
                items = decodeURIComponent(matcher[1]);
            } catch (e) {
                items = matcher[1];
            }
        }
    }
    return items;
};

export function login() {
    const code = getParam('code');
    if (code == null || code.length === 0) {
        loginModal.modal('Please log in.');
        return null;
    }

    return postJson('/authApi/login', code).then((res: any) => {
        window.localStorage.setItem('TOKEN', res.result.token);
        window.location.href = '/';
    }).catch(err => {
        console.error(err);
        loginModal.modal('Please log in.');
    });
}

export function getSysMenus() {
    return postJson('/authApi/getSysMenus');
}

export function logout() {
    return postJson('/authApi/logout').then(res => {
        window.localStorage.removeItem('TOKEN');
        window.localStorage.removeItem('location');
        window.localStorage.removeItem('subKey');
        window.location.reload();
    }).catch(err => {
        console.error(err);
        window.localStorage.removeItem('TOKEN');
        window.localStorage.removeItem('location');
        window.localStorage.removeItem('subKey');
        window.location.reload();
    });
}

/**
 * 获取未上架ugc小说数量
 */
export function getNotReleaseUgcNovelCount() {
    return postJson('/novel/getNotReleaseUgcNovelCount');
}

export function getAppVersionList() {
    return postJson("/package/getAppVersionList")
}