import BannerDataActionsTypeKey from '../actions/actionsTypeKey/bannerDataActionsTypeKey';
import { ActionTypes } from '../actions/actionsType/bannerDataActionsType';

export interface SearchData {
    title: string;
    data: any[];
}

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    total: number;
    page: PageData;
    sorter: any;
    searchData: any;
    loading: boolean;
    exportModalVisible: boolean;
    bannerDataList: any[];
}

const initialState: StoreState = {
    total: 0,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    searchData: {},
    bannerDataList: [],
    loading: false,
    exportModalVisible: false,
};

export const bannerDataReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case BannerDataActionsTypeKey.BANNER_DATA_PAGE_INIT:
            return initialState;

        case BannerDataActionsTypeKey.GET_BANNER_DATA_LIST:
            return Object.assign({}, state, {
                bannerDataList: action.payload.data,
                total: action.payload.total
            });

        case BannerDataActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case BannerDataActionsTypeKey.BANNER_DATA_LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case BannerDataActionsTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize,
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'DESC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC'),
                }
            });

        case BannerDataActionsTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload,
            });

        default:
            return state;
        }
};