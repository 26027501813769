enum UserContentActionTypeKeys {
    LOADING = 'LOADING',
    GET_USER_LIST = 'GET_USER_LIST',
    UPDATE_PAGE = 'UPDATE_PAGE_USER',
    CHANGE_USER_STATUS = 'CHANGE_USER_STATUS',
    REQUEST_EXPORT_LIST = 'REQUEST_EXPORT_LIST',
    SUBMIT_EXPORT = 'SUBMIT_USER_CONTNET_EXPORT',
    EXPORT_MODAL_VISIBLE = 'EXPORT_MODAL_VISIBLE',
    USER_LIST_DATA_SEARCH = 'USER_LIST_DATA_SEARCH',
    USER_CONTNET_INIT_PAGE = 'USER_CONTNET_INIT_PAGE',
    GET_USER_DETAIL_ID = 'GET_USER_DETAIL_ID',
    GET_USER_DETAIL_MSG = 'GET_USER_DETAIL_MSG'
}

export default UserContentActionTypeKeys;