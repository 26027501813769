import BookShelfManager  from '../BookShelfManager';
import { connect } from 'react-redux';
import * as bookShelfAction from '../../action/bookShelfAction';

const mapStateToProps = (state: any) => ({
    data: state.creation.bookShelf
});

const mapDispatchToProps = {
    initPage: bookShelfAction.initPage,
    getUpdatePage: bookShelfAction.getUpdatePage,
    exportModalVisible: bookShelfAction.exportModalVisible,
    submitExport: bookShelfAction.submitExport,
    getSearchData: bookShelfAction.getSearchData,
    getModalSearchParam: bookShelfAction.getModalSearchParam,
    getAddOrChangeParam: bookShelfAction.getAddOrChangeParam,
    getModalSearchNovelList: bookShelfAction.getModalSearchNovelList,
    getDelNovelId: bookShelfAction.getDelNovelId,
    getSortNumber: bookShelfAction.getSortNumber

};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BookShelfManager);