import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, LocaleProvider, Popconfirm, Button, Icon, Badge, Spin, Select } from 'antd'; // Spin
import enUS from 'antd/lib/locale-provider/en_US';
import '../style/main.css';

import { login, getSysMenus, logout, getAppVersionList } from '../servers/loginServer';
import { openNotification } from '../components/widgets/Notification';

const { Header, Content, Sider, } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;

interface Managers {
    id: number;
    title: string;
    basePath: string;
    children: any[];
}

type State = {
    managers: Managers[];
    sysMenusUrl: any;
    collapsed: boolean;
    menu: any;
    key: string;
    nowSlider: any[];
    packageList: any[];
};

class Main extends React.Component<{}, State> {
    state: State = {
        managers: [],
        collapsed: false,
        sysMenusUrl: [],
        menu: '',
        key: '',
        nowSlider: [],
        packageList: [],
    };

    componentDidMount() {
        if (!window.localStorage.getItem('TOKEN')) {
            login();
        } else {
            getSysMenus().then((res: any) => {
                if (res.result.length === 0) {
                    openNotification('error', 'The system menu is empty.');
                }
                let sysMenusUrl = res.result.map((item: any) => item.expression);
                localStorage.setItem('menu', sysMenusUrl[0]);
                let managers = this.processFlatMenus(res.result);
                this.setState({sysMenusUrl});
                this.setState({managers});
                // 判断刷新页面后展示app还是web菜单
                let hash = window.location.href;
                let isWebManager = hash.split('#/')[1].split('/')[0];
                this.setState({nowSlider: isWebManager === 'webManager' ? managers[1].children : managers[0].children});
            });

            getAppVersionList().then((res: any) => {
                this.setState({
                    packageList: res.result
                })
            });
        }
    }

    processFlatMenus = (menus: any) => {
        const hiddenMenuCodes = menus.filter((item: any) => item.type === 'hidden').map((item: any) => item.code);
        const firstLevelMenus: any = [];
        // 一级菜单
        for (let item of menus) {
            if (item.parentMenuId === 0) {
                firstLevelMenus.push({
                    id: item.id,
                    title: item.name,
                    basePath: item.expression || null,
                    icon: item.icon,
                    children: []
                });
            }
        }
        // 二级菜单
        for (let item of menus) {
            for (let parentMenu of firstLevelMenus) {
                if (item.parentMenuId === parentMenu.id) {
                    parentMenu.children.push({
                        id: item.id,
                        title: item.name,
                        path: item.expression,
                        icon: item.icon,
                        children: [],
                        father: parentMenu.basePath
                    });
                }
            }
        }
        // 三级菜单
        for (let item of menus) {
            for (let firstParentMenu of firstLevelMenus) {
                for (let secondParentMenu of firstParentMenu.children) {
                    if (item.parentMenuId === secondParentMenu.id) {
                        secondParentMenu.children.push({
                            id: item.id,
                            title: item.name,
                            path: item.expression,
                        });
                    }
                }
            }
        }
        window.sessionStorage.setItem('btnPermission', JSON.stringify(hiddenMenuCodes));
        return firstLevelMenus;
    }

    // toggle = () => {
    //     this.setState({
    //         collapsed: !this.state.collapsed,
    //     });
    // }

    handleSelectKeys = (value: any) => {
        this.setState({key: value.key});
    }

    firstNavClick = (e: any) => {
        const { managers } = this.state;
        managers.map((value: any) => {
            if (value.id === Number(e.key)) {
                this.setState({ nowSlider: value.children});
            }
        });

    }

    logout = () => {
        logout();
    }

    handlePackageChange = value => {
        const { packageList } = this.state;
        let pacakgeItem = null;
        for (let item of packageList) {
            if (item.id + '' === value) {
                pacakgeItem = item;
                break;
            }
        }
        window.localStorage.setItem('pacakageName', pacakgeItem.packageName);
        window.localStorage.setItem('packageId', pacakgeItem.id);
        const props = this.props as any;
        props.history.push('/');
        window.location.reload();
    }

    render() {
        const { sysMenusUrl, collapsed, managers, nowSlider, packageList } = this.state;
        let count: any = localStorage.getItem('count');
        const selectPackageId = window.localStorage.getItem('packageId') || '1';

        const defaultActiveKey = (hostKey: any, subkey: any) => {
            let a = '';
            nowSlider.forEach((o: any, i: any) => {
                if (o.path === hostKey) {
                    o.children.forEach((j: any, k: any) => {
                        let path = j.path.split('/')[1];
                        if (path === subkey) {
                            a = `${j.title}_novel`;
                        }
                    });
                }
            });
            return a;
        };

        const defaultSelectedKeys = () => {
            let hash = window.location.href;
            let hostKey = '', subKey = '';
            if (hash.includes('#')) {
                hostKey = hash.split('#/')[1].split('/')[0];
                subKey = hash.split('#/')[1].split('/')[1];
            } else {
                let arrHash = hash.split('/');
                hostKey = arrHash[arrHash.length - 2];
                subKey = arrHash[arrHash.length - 1];
            }
            window.localStorage.setItem('location', hostKey + '/' + subKey);
            window.localStorage.setItem('subKey', '/' + subKey);
            hostKey = `/${hostKey}`;
            let keys = {
                outHostKey: hostKey,
                outSubKey: defaultActiveKey(hostKey, subKey),
                firstNavOpen: hostKey === '/webManager' ? ['/paramete'] : ['/content']
            };
            return keys;
        };

        let { outHostKey, outSubKey, firstNavOpen  } = defaultSelectedKeys();

        if (window.localStorage.getItem('TOKEN') === null || managers.length === 0) {
            return (<div className="page-placeholder"><Spin size="large" /></div>);
        }

        const iconStyle = {
            style: {marginRight: collapsed ? '35px' : '10px'}
        } as any;

        const headerStyle = {
                background: '#001529',
                paddingLeft: '20px',
                borderBottom: '1px solid rgba(0, 21, 41, .1)',
                boxShadow: '0 1px 4px rgba(0,21,41,.08)'
        };

        return (
            <div className="main" style={{height: '100%'}}>
                <LocaleProvider locale={enUS} >
                    <Layout className="layout" style={{height: '100%'}}>
                        <Header style={headerStyle}>
                            <div className="logo">
                                <img src={collapsed ? '../icon.png' : '../logo.png'}/>
                            </div>
                            {/*<Icon*/}
                                {/*className="trigger"*/}
                                {/*type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}*/}
                                {/*onClick={this.toggle}*/}
                            {/*/>*/}
                            <Menu
                                theme="dark"
                                mode="horizontal"
                                defaultSelectedKeys={firstNavOpen}
                                style={{ lineHeight: '64px', float: 'left' }}
                                onClick={this.firstNavClick}
                            >
                                {
                                    managers.map((value: any) => {
                                       return  <Menu.Item  key={`${value.id}`}>{value.title}</Menu.Item>;
                                    })
                                }

                            </Menu>
                            {
                                packageList.length > 0 && (
                                <div className="package-selector">
                                    <Select style={{width: 200}} defaultValue={selectPackageId} onChange={this.handlePackageChange}>
                                        {
                                            packageList.map(item => (
                                                <Option key={item.id}>{item.name.toUpperCase()}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                )
                            }
                            <div className="exit">
                                <Popconfirm
                                    placement="bottom"
                                    okText="Yes"
                                    cancelText="No"
                                    title="Are you sure to log out?"
                                    onConfirm={this.logout}
                                >
                                    <Button icon="logout" type="primary">
                                        Exit
                                    </Button>
                                </Popconfirm>
                            </div>
                        </Header>
                        <Content>
                            <Layout style={{ height: '100%' }}>
                                <Sider
                                    trigger={null}
                                    collapsible={true}
                                    collapsed={collapsed}
                                >
                                    <Menu
                                        theme="dark"
                                        mode="inline"
                                        defaultOpenKeys={[outHostKey === '' ?  sysMenusUrl[0] : outHostKey]}
                                        selectedKeys={[outSubKey === '' ?  '' : outSubKey]}
                                        onSelect={(item) => this.handleSelectKeys(item)}
                                    >
                                        {nowSlider.map((item: any) =>
                                            <SubMenu key={item.path} title={<span><Icon {...iconStyle} type={item.icon} />{item.title}</span>}>
                                                {
                                                    item.children.map((value: any) => {
                                                            return <Menu.Item key={`${value.title}_novel`}>
                                                                {
                                                                    value.path === '/novelManager'
                                                                        ?
                                                                        <Badge count={Number(count)} overflowCount={999} style={{right: '-5px', top: '10px'}}>
                                                                            <Link style={{color: '#b0b4b6', display: 'inline-block', lineHeight: '40px', width: '150px', height: '40px'}} to={`${item.path}${value.path}`} >{value.title}</Link>
                                                                        </Badge>
                                                                        :
                                                                        <Link to={`${item.path}${value.path}`} >{value.title}</Link>

                                                                }
                                                            </Menu.Item>;
                                                        }
                                                    )
                                                }
                                            </SubMenu>
                                        )}
                                    </Menu>
                                </Sider>
                                <Content style={{ margin: '15px 25px 0 25px', borderRadius: '8px'}}>
                                    {this.props.children}
                                </Content>
                            </Layout>
                        </Content>
                    </Layout>
                </LocaleProvider>
            </div>
        );
    }
}

export default withRouter(Main);
