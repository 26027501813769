import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Data } from './views/Data';
import DayData  from './views/containers/dayDataContainers';
import NovelData from './views/containers/novelDataContainers';
import NovelTotalData from './views/containers/novelTotalDataContainers';
import NovelUpdateData from './views/containers/novelUpdateDataContainers';
import BannerData from './views/containers/bannerDataContainers';
import RecommendData from './views/containers/recommendDataContainers';
import ChapterData from './views/containers/chapterDataContainers';
import FloorData from './views/containers/floorDataContainers';

export const dataRouters = (
    <Data>
        <Switch>
            <Route path="/dataManage/novelData" component={NovelData}/>
            <Route path="/dataManage/novelTotalData" component={NovelTotalData}/>
            <Route path="/dataManage/novelUpdateData" component={NovelUpdateData}/>
            <Route path="/dataManage/dayData" component={DayData}/>
            <Route path="/dataManage/bannerData" component={BannerData}/>
            <Route path="/dataManage/recommendData" component={RecommendData}/>
            <Route path="/dataManage/chapterData" component={ChapterData}/>
            <Route path="/dataManage/floorData" component={FloorData}/>
            <Redirect to="/dataManage/novelData"/>
        </Switch>
    </Data>
);