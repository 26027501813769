import novelDataActionsTypeKey from '../actions/actionsTypeKey/novelDataActionsTypeKey';
import { ActionTypes } from '../actions/actionsType/novelDataActionsType';
import { SearchType, NovelDataList } from '../../../servers/responseTypes';

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    page: PageData;
    sorter: any;
    total: number;
    typeList: SearchType[];
    tagList: any[];
    searchData: any;
    loading: boolean;
    exportModalVisible: boolean;
    novelDataList: NovelDataList[];
}

const initialState: StoreState = {
    novelDataList: [],
    typeList: [],
    tagList: [],
    total: 0,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    searchData: {},
    loading: false,
    exportModalVisible: false,
};

export const novelDataReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case novelDataActionsTypeKey.NOVEL_DATA_PAGE_INIT:
            return initialState;

        case novelDataActionsTypeKey.GET_NOVEL_DATA_LIST:
            return Object.assign({}, state, {
                novelDataList: action.payload.data,
                total: action.payload.total
            });

        case novelDataActionsTypeKey.PAGE_LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case novelDataActionsTypeKey.NOVEL_DATA_LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case novelDataActionsTypeKey.GET_TYPE_DATA_LIST:
            return Object.assign({}, state, {
                typeList: action.payload,
            });

        case novelDataActionsTypeKey.GET_TAG_DATA_LIST:
            return Object.assign({}, state, {
                tagList: action.payload
        });

        case novelDataActionsTypeKey.UPDATE_NOVEL_DATA_TABLE_PAGE_SIZE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize,
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'DESC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC'),
                }
            });

        case novelDataActionsTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload,
            });

        default:
            return state;
        }
};