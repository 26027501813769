import * as React from 'react';
import { Select, Row, Col, Table, Popconfirm, Modal, Button, Form, Input, Spin } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { getParameter } from '../../../base/getUrlParam';
import { isInt } from '../../../base/regular';
import { openNotification } from '../../widgets/Notification';

const { Option } = Select;
const FormItem = Form.Item;

interface Props extends FormComponentProps {
    data: any;
    initPage: () => void;
    getFloorIdToRequest: (id: string | number) => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
    changeFloorId: (id: string | number) => void;
    getDelNovelMsg: (param: any) => void;
    openAddEditFloorNovelModal: (bol: boolean) => void;
    getSearchNovelParam: (param: any) => void;
    addRecommendNovel: (param: any) => void;
    editRecommendNovel: (param: any) => void;
    getSearchNovelList: (list: any[]) => void;
    getChangeNovelLocationMsg: (param: any) => void;
}

interface State {
    floorId: string | number;
    floorName: string;
    bigImgUrl: string;
    bigImgVisible: boolean;
    editNovelMsg: any;
    isChangeNovel: boolean;
    changeNovelLocationVisible: boolean;
    novelRecommendId: number | string;
}

class RecommendLocationDetail extends React.Component<Props, State> {

    state: State = {
        floorId: 0,
        floorName: '',
        bigImgUrl: '',
        bigImgVisible: false,
        editNovelMsg: {},
        isChangeNovel: false,
        changeNovelLocationVisible: false,
        novelRecommendId: 0
    };

    componentWillMount(): void {
        let name = getParameter('name');
        let id = getParameter('id');
        this.setState({floorName: name, floorId: id});
    }

    componentDidMount(): void {
        this.props.initPage();
        this.props.getFloorIdToRequest(this.state.floorId);
    }

    columns = () => {
        return [
            {
                title: 'Novel ID',
                dataIndex: 'novelId',
                key: 'novelId',
            },
            {
                title: 'Sort',
                dataIndex: 'sort',
                key: 'sort',
                render: (text: number, record: any) => {
                    return <span style={{color: '#1890ff', cursor: 'pointer'}} onClick={(e: any) => {this.changeNovelLocationShow(record); }}>{text}</span>;
                }
            },
            {
                title: 'Picture',
                dataIndex: 'novelCoverUrl',
                key: 'novelCoverUrl',
                render: (text: string) => {
                    return <img onClick={(e: any) => {this.imgModalOpen(text); }} style={{width: '50px', cursor: 'pointer'}} src={text} alt=""/>;
                }
            },
            {
                title: 'Novel Name',
                dataIndex: 'novelName',
                key: 'novelName',
            },
            {
                title: 'Setting',
                dataIndex: 'setting',
                key: 'setting',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            {
                                <span
                                    style={{marginRight: '15px', color: 'red', cursor: 'pointer'}}
                                >
                                    <Popconfirm
                                        title="Are you sure remove book from recommend location?"
                                        onConfirm={() => {
                                            this.delFloorNovel(record);
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        Delete
                                    </Popconfirm>
                                </span>
                            }
                        </div>
                    );
                }
            },

        ];
    }

    imgModalOpen = (img: string) => {
        this.setState({bigImgVisible: true, bigImgUrl: img});
    }

    imgModalCancel = () => {
        this.setState({bigImgVisible: false, bigImgUrl: ''});
    }

    changeFloor = (value: any) => {
        this.setState({floorId: value.key});
        this.props.changeFloorId(value.key);
    }

    delFloorNovel = (item: any) => {
        let param =  {
            recordId: item.id,
            locationId: this.state.floorId
        };

        this.props.getDelNovelMsg(param);
    }

    addEditFloorNovelShow = (record?: any) => {
        this.props.form.setFields({typeId: '', name: ''});
        this.props.openAddEditFloorNovelModal(true);
        this.props.getSearchNovelList([]);
        if (record) {
            this.setState({editNovelMsg: record, isChangeNovel: true});
        } else {
            this.setState({isChangeNovel: false});
        }
    }

    addEditFloorNovelCancel = () => {
        this.props.openAddEditFloorNovelModal(false);
    }

    addEditFloorNovelModalSearch = () => {
        const value: any = this.props.form.getFieldsValue(['typeId', 'name', 'charge', 'id']);
        for (let item in value) {
            if (value[item] === 'All' || value[item] === '') {
                delete value[item];
            }
        }
        this.props.getSearchNovelParam(value);
    }

    addFloorNovelButton = (id?: number | string) => {
        const { isChangeNovel, editNovelMsg, floorId } = this.state;
        if (isChangeNovel) {
            let param = {
                locationId: floorId,
                sort: editNovelMsg.sort,
                novelId: id,
                id: editNovelMsg.id
            };
            this.props.editRecommendNovel(param);
        } else {
            let param = {
                locationId: floorId,
                novelId: id
            };
            this.props.addRecommendNovel(param);
        }
    }

    changeNovelLocationShow = (record: any) => {
        this.props.form.setFieldsValue({
            sort: record.sort,
        });
        this.setState({novelRecommendId: record.id, changeNovelLocationVisible: true});
    }

    changeNovelLocationOk = () => {
        const { data } = this.props;
        const value: any = this.props.form.getFieldsValue(['sort']);
        if (Number(value.sort) <= data.novelList.length) {
            let param = {
                id: this.state.novelRecommendId,
                sort: value.sort,
                locationId: this.state.floorId
            };
            this.props.getChangeNovelLocationMsg(param);
            this.changeNovelLocationCancel();
        } else {
            openNotification('error', 'The serial number cannot exceed the total number of novels');
        }

    }

    changeNovelLocationCancel = () => {
        this.setState({changeNovelLocationVisible: false});
    }

    render() {

        const { data, updatePage } = this.props;
        const { getFieldDecorator } = this.props.form;

        const { bigImgVisible, bigImgUrl, floorName, floorId, changeNovelLocationVisible } = this.state;

        let pram = {
            key: floorId,
            label: floorName
        };

        return (
          <div>
              <Row>
                  <Col span={5}>
                      <FormItem>
                          {getFieldDecorator('password', {
                              initialValue: pram,
                          })(
                              <Select
                                  labelInValue={true}
                                  style={{width: '300px', marginBottom: '20px'}}
                                  onChange={this.changeFloor}
                              >
                                  {
                                      data.floorList.map((o: any, i: number) => {
                                          return <Option key={o.id} value={o.id}>{o.name}</Option>;
                                      })
                                  }
                              </Select>
                          )}
                      </FormItem>
                  </Col>
                  <Col span={2} style={{float: 'right'}}>
                      {
                          <Button type="primary" onClick={() => {this.addEditFloorNovelShow(); }}>Add Novel</Button>
                      }
                  </Col>
              </Row>

              <Table
                  rowKey="id"
                  loading={data.loading}
                  dataSource={data.novelList}
                  columns={this.columns()}
                  onChange={updatePage}
                  pagination={{
                      defaultPageSize: 10,
                      total: data.total,
                      current: data.page.current,
                      pageSize: data.page.pageSize,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '50', '100'],
                      showTotal: total => {
                          return `Total: ${total} items`;
                      },
                  }}
              />

          {/*  big img  */}
              <Modal
                  visible={bigImgVisible}
                  onCancel={this.imgModalCancel}
                  footer={null}
              >
                  <img style={{width: '350px'}} src={bigImgUrl} alt=""/>
              </Modal>

          {/*  change location id  */}
              <Modal
                  visible={changeNovelLocationVisible}
                  onOk={this.changeNovelLocationOk}
                  onCancel={this.changeNovelLocationCancel}
                  maskClosable={false}
              >
                  <Form>
                      <Form.Item label="Location ID">
                          {getFieldDecorator('sort', {
                              rules: [
                                  {validator: isInt}
                              ],
                          })(<Input />)}
                      </Form.Item>
                  </Form>
              </Modal>

          {/*  add edit floor novel  */}
              <Modal
                  visible={data.addEditNovelVisible}
                  onCancel={this.addEditFloorNovelCancel}
                  footer={null}
                  width="60%"
                  maskClosable={false}
              >
                  <Row>
                        <Col span={18}>
                            <Form>
                                <Row gutter={18}>
                                    <Col span={6}>
                                        <FormItem label="Category">
                                            {getFieldDecorator('typeId')(
                                                <Select style={{width: '100%'}}>
                                                    <Option key="All">All</Option>
                                                    {data.typeList.map((item: any) =>
                                                        <Option key={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    )}
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem label="Charge">
                                            {getFieldDecorator('charge')(
                                                <Select style={{width: '100%'}}>
                                                    <Option key="All">All</Option>
                                                    <Option key="true">Charge</Option>
                                                    <Option key="false">Free</Option>
                                                </Select>
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem label="Novel ID">
                                            {getFieldDecorator('id')(
                                                <Input/>
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem label="Novel Name">
                                            {getFieldDecorator('name')(
                                                <Input/>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col span={4} style={{textAlign: 'right', marginTop: 40}}>
                            <Button type="primary" onClick={this.addEditFloorNovelModalSearch}>Search</Button>
                        </Col>
                    </Row>
                  <Spin spinning={data.loading}>
                      <ul className="addNovelList">
                          {
                              data.searchList.length > 0 ?
                                  data.searchList.map((item: any, idx: number) => (
                                      <li key={idx}>
                                          <div className="noveList">
                                              <p>
                                                  <img src={item.coverUrl} alt={item.name}/>
                                                  <span className="novelName">{item.name} ({item.charge ? 'Charge' : 'Free'})</span>
                                                  <div style={{marginTop: 4}}>ID: {item.id}</div>
                                              </p>
                                              <Button
                                                  type="primary"
                                                  icon="plus"
                                                  onClick={(e: any) => {this.addFloorNovelButton(item.id); }}
                                              >
                                                  Add
                                              </Button>
                                          </div>
                                      </li>
                                  )) : <div className="recBackground">Please click the search button to add content</div>
                          }
                      </ul>
                  </Spin>
              </Modal>
          </div>
        );
    }
}

export default Form.create()(RecommendLocationDetail);