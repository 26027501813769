import * as React from 'react';
import { Route, Switch, HashRouter, Redirect } from 'react-router-dom';

import Main from '../layouts/Main';
import { userContentRouters } from '../components/user';
import { contentRouters } from '../components/content';
import { dataRouters } from  '../components/data';
import { settingContentRouters } from  '../components/setting';
import { starListRouters } from '../components/starList';
import { creationRouters } from '../components/creation';
import { auditRouters } from '../components/audit';
import { financialRouters } from '../components/financial';
import { webManagerRouters } from '../components/webManager';

class Router extends React.Component<{}, {}> {

    render() {
        return (
            <HashRouter>
                <Main>
                    <Switch>
                        <Route exact={true} path="/" component={() => <Redirect to={{pathname: '/content'}}/>}/>
                        <Route path="/content" render={() => contentRouters}/>
                        <Route path="/dataManage" render={() => dataRouters}/>
                        <Route path="/userManage" render={() => userContentRouters}/>
                        <Route path="/settingManage" render={() => settingContentRouters}/>
                        <Route path="/starListManage" render={() => starListRouters} />
                        <Route path="/creationManage" render={() => creationRouters} />
                        <Route path="/auditManage" render={() => auditRouters} />
                        <Route path="/financialManage" render={() => financialRouters} />
                        <Route path="/webManager" render={() => webManagerRouters} />
                    </Switch>
                </Main>
            </HashRouter>
        );
    }
}

export default Router;