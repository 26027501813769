import { combineReducers } from 'redux';
import { dashboardReducer } from './recommendReducer';
import { recommendLocationReducer } from './recommendLocationReducer';
import { recommendDetailReducer } from './recommendDetailReducer';
import { recommendLocationDetailReducer } from './recommendLocationDetailReducer';
import { bannerReducer } from './bannerReducer';
import { activityReducer } from './activityReducer';

import { typeReducer } from './typeReducer';
import { typeDetailReducer } from './typeDetailReducer';
import { categoryReducer } from './categoryReducer';
import { searchReducer } from './searchReducer';

import { voteReducer } from './voteReducer';
import { bookShelfReducer } from './bookShelfReducer';

import { helpCenterReducers } from './helpCenterReducers';
import { helpDetailReducers } from './helpDetailReducers';

export default combineReducers({
    dashboard: dashboardReducer,
    recommendLocation: recommendLocationReducer,
    recommendDetail: recommendDetailReducer,
    recommendLocationDetail: recommendLocationDetailReducer,
    banner: bannerReducer,
    activity: activityReducer,
    type: typeReducer,
    typeDetail: typeDetailReducer,
    category: categoryReducer,
    search: searchReducer,
    vote: voteReducer,
    bookShelf: bookShelfReducer,
    helpCenter: helpCenterReducers,
    helpDetail: helpDetailReducers
});