enum RewardOrderDataTypeKey {
    INIT_PAGE = 'REWARD_ORDER_INIT_PAGE',
    UPDATE_PAGE = 'REWARD_ORDER_UPDATE_PAGE',
    SEARCH_DATA = 'REWARD_ORDER_SEARCH_DATA',
    EXPORT_MODAL_VISIBLE = 'REWARD_ORDER_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'REWARD_ORDER_SUBMIT_EXPORT',
    LOADING = 'REWARD_ORDER_LOADING',
    GET_TOTAL_REWARD_ORDER = 'GET_TOTAL_REWARD_ORDER',
    GET_REWARD_ORDER_LIST = 'GET_REWARD_ORDER_LIST',
    GET_REWARD_GIFT_LIST = 'GET_REWARD_GIFT_LIST'
}

export default RewardOrderDataTypeKey;