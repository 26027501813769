import { fork, all, put, call, takeEvery, select, take } from 'redux-saga/effects';
import WebActionsTypeKey from '../../components/webManager/actions/actionsTypeKey/webManagerActionsTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as webServer from '../../servers/webServer';
import * as webAction from '../../components/webManager/actions/webAction';

// 初始页面请求登录
function* initPage() {
    yield getDataList();
}

function* getDataList() {
    try {
        yield put(webAction.loading(true));
        const state = yield select();
        const data = state.webManager.webManager;
        const param = {
            ...data.page,
            ...data.sorter,
            data: {...data.searchData}
        };
        let result = yield call(webServer.getBulletinList, param);
        yield put(webAction.getDataList({data: result.result.data, total: result.result.total}));
        yield put(webAction.loading(false));
    } catch (error) {
        openNotification('error', 'Failed to get web announcement list.');
        yield put(webAction.loading(false));
    }
}

function* watchDeleteDataList() {
    while (true) {
        try {
            const {payload} = yield take(WebActionsTypeKey.DELETE_DATA_LIST);
            yield put(webAction.loading(true));
            yield call(webServer.deleteBulletin, payload);
            yield put(webAction.loading(false));
            yield getDataList();
        } catch (error) {
            openNotification('error', 'Failed to delete data list.');
            yield put(webAction.loading(false));
        }
    }
}

function* watchChangeListTop() {
    while (true) {
        try {
            const { payload } = yield take(WebActionsTypeKey.CHANGE_LIST_TOP);
            yield put(webAction.loading(true));
            yield call(webServer.changeListTop, payload);
            yield put(webAction.loading(false));
            yield getDataList();
        } catch (error) {
            openNotification('error', 'Failed to change data list.');
            yield put(webAction.loading(false));
        }
    }
}

function* watchExport() {
    while (true) {
        const { payload } = yield take(WebActionsTypeKey.SUBMIT_EXPORT);
        const state = yield select();
        const data = state.webManager.webManager;
        payload.titles.splice(-1, 1);

        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            data: {...data.searchData},
            ...data.sorter
        };

        try {
            yield put(webAction.loading(true));
            yield call(webServer.exportBulletinList, param);
            yield put(webAction.loading(false));
            yield put(webAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(webAction.loading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(WebActionsTypeKey.INIT_PAGE, initPage);
}

function* watchSearchData() {
    yield takeEvery(WebActionsTypeKey.LIST_DATA_SEARCH, getDataList);
}

function* watchUpdatePageSize() {
    yield takeEvery(WebActionsTypeKey.UPDATE_PAGE, getDataList);
}

export function* watchWebManagerRootSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchUpdatePageSize),
        fork(watchSearchData),
        fork(watchExport),
        fork(watchDeleteDataList),
        fork(watchChangeListTop)
    ]);
}