import * as React from 'react';
import { Form, Row, Col, Input, Table, DatePicker, Divider, Select } from 'antd';
// import * as moment from 'moment';

import { FormComponentProps } from 'antd/lib/form/Form';
import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { getExportTitles } from '../../../base/getExportTitles';
import { VipOrderState } from '../reducers/vipOrderReducers';

import { numFormat } from '../../../base/regular';
import { changeToStamp, timeReverseWithNoHour } from '../../../base/dealWithTime';

import TotalDataComponents from './TotalDataComponents';

const FormItem = Form.Item;
const Option = Select.Option;
// const monthFormat = 'MM-YYYY';

interface  VipOrderProps extends FormComponentProps {
    data: VipOrderState;
    initPage: (type: number | string) => void;
    getUpdatePage: (pagination: any, filters: any, sorter: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    getMonthlyListParam: (param: any) => void;
    getSearchDataParam: (param: any) => void;
}

interface VipOrderInitialState {
    startTime: string;
    endTime: string;
    statusArr: any[];
}

class VipOrder extends React.Component<VipOrderProps, VipOrderInitialState> {

    state: VipOrderInitialState = {
        startTime: '',
        endTime: '',
        statusArr: ['UnPay', 'completed', 'Pending']
    };

    componentDidMount() {
        this.props.initPage(0);
    }

    // public action

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    clear = () => {
        this.setState({startTime: ''});
        this.setState({endTime: ''});
        this.props.form.resetFields();
    }

    onSearch = () => {

        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            gtTime: this.state.startTime.length === 0 ? undefined : changeToStamp(this.state.startTime) / 1000,
            ltTime: this.state.endTime.length === 0 ? undefined : changeToStamp(this.state.endTime) / 1000,
            userName: value.name,
            userId: value.id,
            orderSn: value.orderSn,
            status: value.status,
            days: value.days,
            device: value.device
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }
        this.props.getSearchDataParam(param);
    }

    columns = () => {
        return [{
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            className: 'column-center',
        }, {
            title: 'Order number',
            dataIndex: 'orderSn',
            key: 'orderSn',
            className: 'column-center',
        }, {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            className: 'column-center',
        }, {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
            className: 'column-center',
        }, {
            title: 'Device',
            dataIndex: 'device',
            key: 'device',
            className: 'column-center',
            render: (text: number) => {
                return <span>{text === 0 ? 'Android' : 'iOS'}</span>;
            }
        }, {
            title: 'Product Types',
            dataIndex: 'vipDays',
            key: 'vipDays',
            className: 'column-center',
        }, {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            className: 'column-center',
            render: (text: number) => (numFormat(text))
        }, {
            title: 'Order Status',
            dataIndex: 'status',
            key: 'status',
            className: 'column-center',
            render: (text: number) => (<span>{this.state.statusArr[text]}</span>)
        }, {
            title: 'Payment Channel',
            dataIndex: 'paymentChannel',
            key: 'paymentChannel',
            className: 'column-center',
        }, {
            title: 'Order Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            className: 'column-center',
            sorter: true,
            sortField: 'createdAt'
        }, {
            title: 'Order Time',
            dataIndex: 'createdTime',
            key: 'createdTime',
            className: 'column-center',
        }, {
            title: 'Payment Date',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            className: 'column-center',
            sorter: true,
            sortField: 'paymentAt'
        }, {
            title: 'Payment Time',
            dataIndex: 'paymentTime',
            key: 'paymentTime',
            className: 'column-center',
        }];
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    // page action

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: timeReverseWithNoHour(startTime) + ' 00:00:00'});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: timeReverseWithNoHour(endTime) + ' 23:59:59'});
        } else {
            this.setState({endTime});
        }
    }

    render() {

        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const { data, getUpdatePage } = this.props;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const vipTotalMsg = data.vipTotalData;

        return (
          <div>

              <TotalDataComponents data={vipTotalMsg}/>

              <Divider>Vip Order</Divider>

              <Form>
                  <Row gutter={16}>
                      <Col span={6}>
                          <FormItem label="Date">
                              {getFieldDecorator('startTime')(
                                  <DatePicker
                                      allowClear={true}
                                      format={'DD-MM-YYYY'}
                                      placeholder={'Start Time'}
                                      style={{width : '48%'}}
                                      onChange={this.startTimeChange}
                                  />
                              )}
                              {getFieldDecorator('endTime')(
                                  <DatePicker
                                      allowClear={true}
                                      format={'DD-MM-YYYY'}
                                      placeholder={'End Time'}
                                      style={{width : '48%', marginLeft : '4%'}}
                                      onChange={this.endTimeChange}
                                  />
                              )}
                          </FormItem>
                      </Col>
                      <Col span={6}>
                          <FormItem
                              label="User Name"
                          >
                              {getFieldDecorator('name')(
                                  <Input {...input}/>
                              )}
                          </FormItem>
                      </Col>
                      <Col span={6}>
                          <FormItem
                              label="User ID"
                          >
                              {getFieldDecorator('id')(
                                  <Input {...input}/>
                              )}
                          </FormItem>
                      </Col>
                      <Col span={6}>
                          <FormItem
                              label="Order Number"
                          >
                              {getFieldDecorator('orderSn')(
                                  <Input {...input}/>
                              )}
                          </FormItem>
                      </Col>
                      <Col span={6}>
                          <FormItem
                              label="Product Types "
                          >
                              {getFieldDecorator('days', {
                                  initialValue: 'All'
                              })(
                                  <Select style={{ width: '100%' }} >
                                      <Option value="All">All</Option>
                                      {
                                          data.vipAllDay.map((o: any, i: number) => {
                                             return <Option key={i} value={o.days}>{o.days}-day member</Option>;
                                          })
                                      }
                                  </Select>
                              )}
                          </FormItem>
                      </Col>
                      <Col span={6}>
                          <FormItem
                              label="Order Status"
                          >
                              {getFieldDecorator('status', {
                                  initialValue: 'All'
                              })(
                                  <Select style={{ width: '100%' }} >
                                      <Option value="All">All</Option>
                                      <Option value="0">UnPay</Option>
                                      <Option value="1">Completed</Option>
                                      <Option value="2">Pending</Option>
                                  </Select>
                              )}
                          </FormItem>
                      </Col>
                      <Col span={6}>
                          <FormItem
                              label="Device"
                          >
                              {getFieldDecorator('device', {
                                  initialValue: 'All'
                              })(
                                  <Select style={{ width: '100%' }} >
                                      <Option value="All">All</Option>
                                      <Option value="0">Android</Option>
                                      <Option value="1">iOS</Option>
                                  </Select>
                              )}
                          </FormItem>
                      </Col>
                  </Row>
              </Form>

              <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport} />

              <Table
                  rowKey="id"
                  loading={data.loading}
                  bordered={true}
                  columns={this.columns()}
                  style={{marginTop: '20px'}}
                  dataSource={data.vipOrderList}
                  onChange={getUpdatePage}
                  pagination={{
                      defaultPageSize: 20,
                      total: data.total,
                      current: data.page.current,
                      showSizeChanger: true,
                      pageSizeOptions: ['20', '50', '80', '100'],
                      showTotal: total => {
                          return `Total:${data.total} items`;
                      },
                  }}
              />

              <ExportModal
                  getFieldDecorator={getFieldDecorator}
                  getFieldsValue={getFieldsValue}
                  visible={data.exportModalVisible}
                  loading={data.loading}
                  onCancel={this.closeExportModal}
                  submitExport={this.submitExport}
              />
          </div>
        );
    }
}

export default Form.create()(VipOrder);