import NovelActionTypeKeys from '../actions/actionsTypeKey/novelActionsTypeKey';
import {  ActionTypes } from '../actions/actionsType/novelActionsType';
// import { withdrawalsList } from '../../../servers/responseTypes';

interface Page {
    current: number;
    pageSize: number;
}

export interface StoreState {
    getNovelList: any;
    novelList: any[];
    novelAllList: any[];
    getBriefList: any;
    loading: boolean;
    searchData: any;
    briefModalVisible: boolean;
    dumpModalVisible: boolean;
    spiderStatus: boolean;
    page: Page;
    total: number;
    sorter: any;
}

const initialState: StoreState = {
    loading: false,
    getNovelList: [],
    novelList: [],
    novelAllList: [],
    getBriefList: '',
    searchData: {},
    briefModalVisible: false,
    dumpModalVisible: false,
    spiderStatus: false,
    page: {
        current: 1,
        pageSize: 20
    },
    total: 0,
    sorter: {},
};

export const novelReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case NovelActionTypeKeys.INIT_PAGE:
            return initialState;

        case NovelActionTypeKeys.GET_NOVEL_CONTENT_LIST:
            return Object.assign({}, state, {
                getNovelList: action.payload.data,
                total: action.payload.total

            });

        case NovelActionTypeKeys.GET_BRIEF_CONTENT_LIST:
            return Object.assign({}, state, {
                getBriefList: action.payload,
            });

        case NovelActionTypeKeys.LOADING:
            return Object.assign({}, state, {
                loading: action.payload,
            });

        case NovelActionTypeKeys.BRIEF_MODAL_VISIBLE:
            return Object.assign({}, state, {
                briefModalVisible: action.payload,
            });

        case NovelActionTypeKeys.DUMP_MODAL_VISIBLE:
            return Object.assign({}, state, {
                dumpModalVisible: action.payload,
            });

        case NovelActionTypeKeys.LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload,
            });

        case NovelActionTypeKeys.GET_NOVEL_LIST_BY_NAME:
            return Object.assign({}, state, {
                novelList: action.payload,
            });

        case NovelActionTypeKeys.GET_ALL_NOVEL_LIST_BY_NAME:
            return Object.assign({}, state, {
                novelAllList: action.payload,
            });

        case NovelActionTypeKeys.CHECK_SPIDER_STATUS:
            return Object.assign({}, state, {
                spiderStatus: action.payload,
            });

        case NovelActionTypeKeys.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        default:
            return state;
    }
};