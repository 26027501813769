import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 获取小说数据
 */
export function getNovelData(param: any) {
    return postJson('/data/getNovelDailyDataList', param);
}

/**
 * 获取每日数据
 */
export function getDailyData(param: any) {
    return postJson('/data/getDailyData', param);
}

/**
 * 获取推荐数据
 */
export function getRecommendData(param: any) {
    return postJson('/data/getRecommendData', param);
}

/**
 * 获取banner数据
 */
export function getBannerData(param: any) {
    return postJson('/data/getBannerData', param);
}

/**
 * 获取小说总数据
 */
export function getNovelTotalData(param: any) {
    return postJson('/data/getNovelTotalDataList', param);
}

/**
 * 获取小说更新数据
 */
export function getNovelUpdateData(param: any) {
    return postJson('/data/getNovelUpdateDataList', param);
}

/**
 * 获取类型列表
 */
export function getNovelTypeList(param?: any) {
    return postJson('/noveltype/getNovelTypeList');
}

/**
 * 获取tag 列表
 */
export function getNovelTagList(param?: any) {
    return postJson('/noveltag/getNovelTagList');
}

/**
 * 获取楼层数据
 */
export function getFloorData(param: any) {
    return postJson('/data/getFloorData', param);
}

/**
 * 获取楼层列表
 */
export function getFloorList() {
    return postJson('/floor/getFloorList');
}

/**
 * 导出楼层数据
 */
export function exportFloorData(param: any) {
    return postJsonForExport('/data/getFloorData/export', param, 'floorData.xlsx');
}

/**
 * 导出小说数据
 */
export function exportNovelData(param: any) {
    return postJsonForExport('data/exportNovelDailyDataList', param, 'novelData.xlsx');
}

/**
 * 导出小说总数据
 */
export function exportNovelTotalData(param: any) {
    return postJsonForExport('/data/getNovelTotalDataList/export', param, 'novelTotalData.xlsx');
}

/**
 * 导出小说更新数据
 */
export function exportNovelUpdateData(param: any) {
    return postJsonForExport('/data/getNovelUpdateDataList/export', param, 'novelUpdateData.xlsx');
}

/**
 * 导出每日数据
 */
export function exportDailyData(param: any) {
    return postJsonForExport('/data/getDailyData/export', param, 'DailyData.xlsx');
}

/**
 * 导出推荐数据
 */
export function exportRecommendData(param: any) {
    return postJsonForExport('/data/getRecommendData/export', param, 'RecommendData.xlsx');
}

/**
 * 导出banner数据
 */
export function exportBannerData(param: any) {
    return postJsonForExport('/data/getBannerData/export', param, 'BannerData.xlsx');
}
