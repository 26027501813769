enum VersionContentActionTypeKeys {
    VERSION_CONTNET_INIT_PAGE = 'VERSION_CONTNET_INIT_PAGE',
    UPDATE_PAGE = 'UPDATE_PAGE_VERSION',
    LOADING = 'LOADING_VERSION',
    VERSION_LIST_DATA_SEARCH = 'VERSION_LIST_DATA_SEARCH',
    GET_VERSION_LIST = 'GET_VERSION_LIST',
    ADD_VERSION_DATA = 'ADD_VERSION_DATA',
    DELETE_VERSION_LIST_DATA = 'DELETE_VERSION_LIST_DATA',
    VERSION_MODAL_VISIBLE = 'VERSION_MODAL_VISIBLE',
    CHANGE_VERSION_STATUS = 'CHANGE_VERSION_STATUS',
    EXPORT_MODAL_VISIBLE = 'EXPORT_MODAL_VISIBLE_VERSION',
    SUBMIT_EXPORT = 'SUBMIT_VERSION_CONTNET_EXPORT',
}

export default VersionContentActionTypeKeys;