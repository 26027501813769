import * as React from 'react';
import { Row, Col, Button, Spin } from 'antd';

import { getParameter } from '../../../base/getUrlParam';
import { StoreState } from '../reducers/chapterDetailReducer';
import { Editor } from '@tinymce/tinymce-react';

interface ReportDetailProps {
    data: StoreState;
    initPage: (url: any) => void;
    getEditContent: (param: any) => void;
    changeShowContent: (param: any) => void;
}

interface ReportDetailInitialState {
    key: string;
    content: string;
    isEdit: boolean;
    id: any;
    param: any;
    showChangeBtn: boolean;
}

class ReportDetail extends React.Component<ReportDetailProps, ReportDetailInitialState> {

    state: ReportDetailInitialState = {
        key: '',
        content: '',
        isEdit: false,
        id: -1,
        param: {},
        showChangeBtn: false
    };

    componentDidMount() {
        const chapterMsg: any = window.sessionStorage.getItem('chapterMsg');
        
        const record = JSON.parse(chapterMsg);
        const url: any = record.contentUrl,
              name: any = record.chapterName || record.name,
              originName: any = record.orignChapterName,
              originUrl: any = record.orignContentUrl,
              key = getParameter('key'),
              id = getParameter('id'),
              firstCommit = getParameter('commit');      
        let param = {
            url: url,
            name: name,
            originName: originName,
            originUrl: originUrl,
            key: key,
            commit: firstCommit === '2' && record.draftState === 1 && record.locked !== 1
        };
        this.props.initPage(param);
        this.setState({key: key, id: id, param: param, showChangeBtn: param.commit});
    }

    handleEditorChange = (e: any) => {
        this.setState({content: e.target.getContent()});
    }

    editChapter = () => {
        this.setState({isEdit: !this.state.isEdit});
    }

    saveEdit = () => {
        const { id, content } = this.state;
        let param = {
            id: id,
            content: content
        };
        this.props.getEditContent(param);
    }

    back = () => {
        window.history.go(-1);
    }

    changeShow = () => {
        const { data } = this.props;
        const { param } = this.state;
        param.commit = !data.isDiff;
        this.props.changeShowContent(param);
    }

    render() {
        const { isEdit, showChangeBtn, key } = this.state;
        const { data } = this.props;
        console.log(data);
        return (
            <Spin spinning={data.loading} tip="Switching, please wait...">

                    <Row style={{marginBottom: '20px'}}>
                        <Col offset={18} span={6}>
                            <Button style={{marginRight: '15px', float: 'right'}} type="primary" onClick={this.back}>Back</Button>
                            {
                                (showChangeBtn && key === '2') &&
                                <Button style={{marginRight: '15px', float: 'right'}} type="primary" onClick={this.changeShow}>
                                    {data.isDiff ? 'View sensitive words' : 'View content comparison'}
                                </Button>
                            }

                            {/*{*/}
                                {/*key &&*/}
                                        {/*<span style={{float: 'right'}}>*/}
                                            {/*<Button style={{marginRight: '15px'}} onClick={this.editChapter}>{isEdit ? 'See' : 'Edit'}</Button>*/}
                                        {/*<Button style={{marginRight: '15px'}} type="primary" onClick={this.saveEdit}>save</Button>*/}
                                        {/*</span>*/}

                            {/*}*/}

                        </Col>
                    </Row>
                {
                    isEdit ?
                        <div>
                            <Editor
                                initialValue={data.detailDataForEditor}
                                init={{
                                    height: 500,
                                    plugins: [
                                        'textcolor',
                                    ],
                                    toolbar: 'undo redo | forecolor',
                                }}
                                onChange={this.handleEditorChange}
                            />
                        </div>
                    :
                        <Row>
                            {
                                data.isDiff ?
                                <div>
                                    <Col span={11}>
                                        <h3>new chapter:</h3>
                                        <div style={{marginTop: '15px', lineHeight: '150%'}} dangerouslySetInnerHTML={{ __html: data.detailData}} />
                                    </Col>
                                    <Col span={11} offset={2}>
                                        <h3>old chapter:</h3>
                                        <div style={{marginTop: '15px', lineHeight: '150%'}} dangerouslySetInnerHTML={{ __html: data.originData}} />
                                    </Col>
                                </div>
                                    :
                                <Col>
                                    <div style={{marginTop: '15px', lineHeight: '150%'}} dangerouslySetInnerHTML={{ __html: data.detailData}} />
                                </Col>
                            }

                        </Row>

                }

            </Spin>
        );
    }
}

export default ReportDetail;