import voteKey from '../action/actionsTypeKey/voteActionTypekey';
import {  ActionType } from '../action/actionsType/voteActionsType';

export interface StoreState {
    voteList: any[];
    loading: boolean;
}

const initialState: StoreState = {
    voteList: [],
    loading: false
};

export const voteReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {

        case voteKey.INIT_PAGE:
            return initialState;

        case voteKey.GET_VOTE_LIST:
            return Object.assign({}, state, {
                voteList: action.payload
            });

        default:
            return state;
    }
};