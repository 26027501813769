import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ContentManage } from './views/ContentManage';
import Recommend from './views/containers/recommendContainers';
import RecommendLocation from './views/containers/recommendLocationContainers';
import Recommend2 from './views/containers/recommendContainers';
import RecommendDetail from './views/containers/recommendDetailContainers';
import RecommendLocationDetail from './views/containers/recommendLocationDetailContainers';
import Banner from './views/containers/bannerContainers';
import Activity from './views/containers/activityContainers';

import  TypeManager  from './views/containers/typeContainers';
import  TypeDetail from './views/containers/typeDetailContainers';
import  CategoryManager from './views/containers/categoryContainers';
import  SearchManage from './views/containers/searchManageContainers';
import  VoteManager from './views/containers/voteContainers';

import BookShelfManager from './views/containers/bookShelfContainers';

import HelpCenterManager from './views/containers/helpCenterContainers';
import HelpDetailManager from './views/containers/helpDetailContainers';

export const creationRouters = (
    <ContentManage>
        <Switch>
            <Route path="/creationManage/dashboard" component={Recommend}/>
            <Route path="/creationManage/dashboard2" component={Recommend2}/>
            <Route path="/creationManage/recommendDetail" component={RecommendDetail}/>
            <Route path="/creationManage/banner" component={Banner}/>
            <Route path="/creationManage/activity" component={Activity}/>
            <Route path="/creationManage/tag" component={TypeManager}/>
            <Route path="/creationManage/tagDetail/:id" component={TypeDetail} />
            <Route path="/creationManage/category" component={CategoryManager}/>
            <Route path="/creationManage/search" component={SearchManage}/>
            <Route path="/creationManage/vote" component={VoteManager}/>
            <Route path="/creationManage/bookShelf" component={BookShelfManager}/>
            <Route path="/creationManage/help" component={HelpCenterManager}/>
            <Route path="/creationManage/helpDetail" component={HelpDetailManager} />
            <Route path="/creationManage/recommendLocation" component={RecommendLocation}/>
            <Route path="/creationManage/recommendLocationDetail" component={RecommendLocationDetail}/>
            <Redirect to="/creationManage/dashboard"/>
        </Switch>
    </ContentManage>
);