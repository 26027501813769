import { fork, all, takeEvery, call, put, select, take } from 'redux-saga/effects';
import novelReviewKey from '../../components/audit/actions/actionsTypeKey/novelReviewTypeKey';
import * as novelReviewAction from '../../components/audit/actions/novelReviewAction';
import * as novelReviewServer from '../../servers/novelReviewServer';
import { getNovelTypeList } from '../../servers/novelServer';
import { openNotification } from '../../components/widgets/Notification';

function* initPage() {
    yield getNovelReviewList();
    yield getTypeList();
}

function* getTypeList(param?: any) {
    try {
        const {result} = yield call(getNovelTypeList, param);
        yield put(novelReviewAction.getNovelTypeList(result));
    } catch (err) {
        openNotification('error', 'Failed to get type list.');
    }
}

function* getNovelReviewList() {
    const data = yield select();
    const manager = data.audit.novelReview;
    const param = {
        ...manager.page,
        ...manager.sorter,
        'data': {...manager.searchData},
    };

    param.data.status = manager.tabsKey;
    yield put(novelReviewAction.getNovelReviewList([]));
    try {
        yield put(novelReviewAction.loading(true));
        const { result } = yield call(novelReviewServer.getAuditNovelList, param);
        yield put(novelReviewAction.getNovelReviewList(result));
        yield put(novelReviewAction.loading(false));
    } catch (err) {
        yield put(novelReviewAction.loading(false));
        openNotification('error', 'Fail to get list');
    }

}

function* exportModel() {
    while (true) {
        const { payload } = yield take(novelReviewKey.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);

        const state = yield select();
        const manager = state.audit.novelReview;

        payload.titles.map((o: any) => {
           if (o.index === 'novelType') {
               o.index = 'novelTypeExport';
           }

           if (o.index === 'signed') {
               o.index = 'signedExport';
           }
        });

        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            exportName: '',
            ...manager.sorter,
            data: {...manager.searchData},
        };

        param.data.status = manager.tabsKey;

        if (manager.tabsKey === '2') {
            param.exportName = 'novelReviewPendingReview.xlsx';
        } else if (manager.tabsKey === '3') {
            param.exportName = 'novelReviewPassed.xlsx';
        } else {
            param.exportName = 'novelReviewAuditFailure.xlsx';
        }

        try {
            yield put(novelReviewAction.loading(true));
            yield call(novelReviewServer.exportAuditNovelList, param);
            yield put(novelReviewAction.loading(false));
            openNotification('success', 'Export success.');
            yield put(novelReviewAction.exportModalVisible(false));
        } catch (err) {
            openNotification('error', 'Export Failed.');
            yield put(novelReviewAction.loading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(novelReviewKey.INIT_PAGE, initPage);
}

function* watchSearchData() {
    yield takeEvery(novelReviewKey.GET_SEARCH_DATA, getNovelReviewList);
}

function* watchChangeKey() {
    yield takeEvery(novelReviewKey.GET_TABS_KEY, getNovelReviewList);
}

function* watchUpdatePage() {
    yield takeEvery(novelReviewKey.UPDATE_PAGE, getNovelReviewList);
}

export function* watchNovelReviewSaga() {
    yield all([
        fork(watchInitPage),
        fork(exportModel),
        fork(watchChangeKey),
        fork(watchSearchData),
        fork(watchUpdatePage)
    ]);
}