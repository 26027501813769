import quarterlyListKey from '../actions/actionsTypeKey/quarterlyListTypeKey';

// public action

export function initPage() {
    return { type: quarterlyListKey.INIT_PAGE };
}

export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: quarterlyListKey.UPDATE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: quarterlyListKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: quarterlyListKey.SUBMIT_EXPORT, payload: value };
}

// page action

export function loadingStatus(value: boolean) {
    return { type: quarterlyListKey.LOADING, payload: value };
}

export function getQuarterlySearchData(param: any) {
    return { type: quarterlyListKey.GET_SEARCH_DATA, payload: param };
}

export function getNovelTypeList(param: any) {
    return { type: quarterlyListKey.GET_TYPE_LIST, payload: param };
}

export function getQuarterData(list: any) {
    return { type: quarterlyListKey.GET_QUARTERLY_LIST_DATA, payload: list };
}

export function  getCompetitionList(list: any[]) {
    return { type: quarterlyListKey.GET_COMPETITION_LIST, payload: list };
}

export function quarterlyListConfirm() {
    return { type: quarterlyListKey.QUARTERLY_LIST_CONFIRM };
}

export function quarterlyRemoveRank(param: any) {
    return { type: quarterlyListKey.REMOVE_RANK, payload: param };
}