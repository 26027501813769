import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';
import axios from 'axios';

/**
 * 获取小说详情
 */
export function getNovelMsg(param: any) {
    console.log("mmmmmm2", param);
    return postJson('/novel/getNovelDetailById', param);
}

/**
 * 获取章节列表
 */
export function getChapterList(param: any) {
    return postJson('/content/getChapterList', param);
}

/**
 * 删除章节
 */
export function delChapter(ids: any) {
    return postJson('/chapter/deleteChapterBatch', ids);
}

/**
 * 修改章节顺序
 */
export function changeChapterSorter(param: any) {
    return postJson('/chapter/modifyChapterLocation', param);
}

/**
 * 批量上传章节
 */
export function uploadChapter(param: any) {
    return postJson('/content/uploadChapter', param);
}

/**
 * 修改章节更新状态
 */
export function changeChapterStatus(id: any) {
    return postJson('/content/modifyChapterUpdatedStatus', id);
}

/**
 * 获取章节内容
 */
export function getChapterCon(url: string) {
    return axios.get(url, {headers: {'content-type': 'text/plain; utf-8; application/vnd.openxmlformats-officedocument.wordprocessingml.document; application/wpsoffice'}});
}

/**
 * 更新章节内容
 */
export function modifyChapterContent(param: any) {
    return postJson('chapter/modifyChapterContent', param);
}

/**
 * 下载小说封面
 */
export function downloadCover(param: any) {
    return postJsonForExport('novel/downloadCover', param, 'cover.png');
}

/**
 * 下载小说 - zip压缩包
 */
export function downNovelZip(param: any) {
    return postJson('/novel/getDownloadInfo', param);
}

/**
 * 根据序号获取章节名
 */
export function getChapterNameForSort(param: any) {
    return postJson('/chapter/getChapterBySort', param);
}
