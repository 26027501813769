import { all, fork, take, call, put } from 'redux-saga/effects'; // select, takeEvery, put, call,
import  chapterDetailKey  from '../../components/content/actions/actionsTypeKey/chapterDetailActionTypeKey';
import * as chapterDetailAction from '../../components/content/actions/chapterDetailAction';
import * as chapterServer from '../../servers/chapterServer';
import { getAllSensitive } from '../../servers/blockWordServer';
import { openNotification } from '../../components/widgets/Notification';
import { getImageUrl, uploadFile } from '../../servers/ossServer';

import * as DiffMatchPatch from 'diff-match-patch';

function* initPage() {
    while (true) {
        const { payload } = yield take(chapterDetailKey.INIT_PAGE);
        yield pageShow(payload);
    }
}

function* filterBrackets(str: any) {
    var RexStr = /\<|\>/g;
    str = str.replace(RexStr, function (MatchStr: any) {
        switch (MatchStr) {
            case '<':
                return '< ';
            case '>':
                return ' >';
            default:
                return '';
        }
    });
    return str;
}

/**
 * 二极敏感词审核
 * @param text: string
 * @param sensitive: any[]
 * @return content: string
 */
function* sensitiveDetection(text: string, sensitive: any[]) {
    let content = yield filterBrackets(text);

    let enSymbol = /[\.|\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\>|\/|\?|\d]/g;
    // let cnSymbol = /[\，|\“|\”|\：|\。|\！|\'</br>']/g;

    content = text.replace(/\r\n|\r|\n/g, '</br> ').split(' ');

    let filterCon = text.replace(enSymbol, function (s: any) {
        return s.replace(/./g, '');
    }).replace(/\r\n|\r|\n/g, '</br> ').split(' ');

    filterCon.map((o: any, i: any) => {
        sensitive.map((k: any) => {
            o = o.replace('<br>', '').replace('</br>', '');
            if (o.toLowerCase() === k.toLowerCase()) {
                content[i] = `<span style="color: #E21F0F">${content[i]}</span>`;
            }
        });
    });

    content = content.join(' ').replace(/\r\n/g, '</br>').replace(/\n/g, '</br>');
    return content;
}

function* showContentDiff(payload: any, result: any, newContent: any) {
    let originFile, origin, addOriginName: any;
    if (payload.commit) {
        originFile = yield getImageUrl(payload.originUrl);
        origin = yield call(chapterServer.getChapterCon, originFile);
        addOriginName = (payload.originName ? payload.originName : payload.name) + '\n' + '\n' + origin.data;
    }

    // 有修改章节比对方法
    if (addOriginName) {
        const diffEngine = new DiffMatchPatch.diff_match_patch();
        const diffs = diffEngine.diff_main(addOriginName, newContent);
        diffEngine.diff_cleanupEfficiency(diffs);
        let html = diffEngine.diff_prettyHtml(diffs);
        yield put(chapterDetailAction.getChapterDetailData(html.replace(/\r\n|\r|\n/g, '</br> ')));
    } else {
        yield put(chapterDetailAction.getChapterDetailData(newContent.replace(/\r\n|\r|\n/g, '</br> ')));
    }

    yield put(chapterDetailAction.getOriginData(addOriginName.replace(/\r\n|\r|\n/g, '</br> ')));
}

function* showContentWords(content: any, result: any) {
    const newContent = yield sensitiveDetection(content, result);
    yield put(chapterDetailAction.getChapterDetailData(newContent));
}

function* pageShow(payload: any) {
    const file = yield getImageUrl(payload.url);
    try {
        yield put(chapterDetailAction.chapterDetailLoading(true));
        const { data } = yield call(chapterServer.getChapterCon, file);
        const { result } = yield call(getAllSensitive);
        let addNameContent = payload.name + '\n' + '\n' + data;
        // 原先检测敏感词方法
        if (payload.commit && payload.key === '2') {
            yield put(chapterDetailAction.isDiff(true));
            yield showContentDiff(payload, result, addNameContent);
        } else {
            yield put(chapterDetailAction.isDiff(false));
            yield showContentWords(addNameContent, result);
        }

        yield put(chapterDetailAction.chapterDetailLoading(false));
    } catch (err) {
        openNotification('error', 'Failed to get chapter con');
    }
}

/**
 * 切换内容显示按钮触发
 */
function* changeContentShow() {
    while (true) {
        const { payload } = yield take(chapterDetailKey.GET_CHAPTER_DETAIL_CHANGE);
        yield pageShow(payload);
    }
}

function* editContent() {
    while (true) {
        const { payload } = yield take(chapterDetailKey.GET_CHAPTER_EDIT_CONTENT);
        try {
            let blob = new Blob([payload.content], {type: 'text/plain'});
            let file = new File([blob], 'editChapter.txt', {type: 'text/plain'});
            payload.contentKey = yield uploadFile('novel', file);
            yield call(chapterServer.modifyChapterContent, payload);
            openNotification('success', 'Success to edit');
        } catch (err) {
            openNotification('error', 'Failed to save');
        }
    }
}

export function* watchChapterDetailSaga() {
    yield all([
        fork(initPage),
        fork(editContent),
        fork(changeContentShow)
    ]);
}