enum VipOrderDataTypeKey {
    INIT_PAGE = 'VIP_ORDER_INIT_PAGE',
    UPDATE_PAGE = 'VIP_ORDER_UPDATE_PAGE',
    SEARCH_DATA = 'VIP_ORDER_SEARCH_DATA',
    EXPORT_MODAL_VISIBLE = 'VIP_ORDER_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'VIP_ORDER_SUBMIT_EXPORT',
    LOADING = 'VIP_ORDER_LOADING',
    GET_TOTAL_VIP_ORDER = 'GET_TOTAL_VIP_ORDER',
    GET_VIP_ORDER_LIST = 'GET_VIP_ORDER_LIST',
    GET_VIP_DAY_ALL = 'GET_VIP_DAY_ALL'
}

export default VipOrderDataTypeKey;