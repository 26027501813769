import  NovelUpdateData  from '../NovelUpdateData';
import { connect } from 'react-redux';
import * as novelUpdateDataAction from '../../actions/novelUpdateDataAction';

const mapStateToProps = (state: any) => ({
   data: state.data.novelUpdateData
});

const mapDispatchToProps = {
    initPage: novelUpdateDataAction.initPage,
    updatePage: novelUpdateDataAction.updatePage,
    searchDate: novelUpdateDataAction.searchDate,
    exportModalVisible: novelUpdateDataAction.exportModalVisible,
    submitExport: novelUpdateDataAction.submitExport
};
export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NovelUpdateData);