enum helpCenterManagerKeys {
    INIT_PAGE = 'HELP_INIT_PAGE',
    LOADING = 'HELP_LOADING',
    OPEN_CLOSE_TITLE_MODAL = 'HELP_OPEN_CLOSE_TITLE_MODAL',
    OPEN_CLOSE_LOCATION_MODAL = 'HELP_OPEN_CLOSE_LOCATION_MODAL',
    GET_HELP_MODAL_LIST = 'HELP_GET_HELP_MODAL_LIST',
    GET_CHANGE_HElP_MODAL_SORT = 'HELP_GET_CHANGE_HElP_MODAL_SORT',
    ADD_HELP_MODAL = 'HELP_ADD_HELP_MODAL',
    EDIT_HELP_MODAL = 'HELP_EDIT_HELP_MODAL',
    DEL_HELP_MODAL = 'HELP_DEL_HELP_MODAL'
}

export default helpCenterManagerKeys;