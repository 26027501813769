import ChapterDataActionsTypeKey from './actionsTypeKey/chapterDataActionsTypeKey';
import { NovelDataList, TopicList } from '../../../servers/responseTypes';

export function initPage() {
    return { type: ChapterDataActionsTypeKey.PAGE_INIT };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: ChapterDataActionsTypeKey.UPDATE_PAGE, payload: {pagination, sorter} };
}

export function loading(value: boolean) {
    return { type: ChapterDataActionsTypeKey.LOADING, payload: value };
}

export function listDataSearch(value: any) {
    return { type: ChapterDataActionsTypeKey.LIST_DATA_SEARCH, payload: value };
}

export function getDataList(value: {data: NovelDataList, total: number}) {
    return { type: ChapterDataActionsTypeKey.GET_DATA_LIST, payload: value };
}

export function getTypeList(value: {data: TopicList, total: number}) {
    return { type: ChapterDataActionsTypeKey.GET_TYPE_DATA_LIST, payload: value };
}

export function exportModalVisible(value: boolean) {
    return { type: ChapterDataActionsTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: ChapterDataActionsTypeKey.SUBMIT_EXPORT, payload: value };
}
