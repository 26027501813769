import  SearchTypeKey  from './actionsTypeKey/searchTypeKey';

export function initPage() {
    return { type: SearchTypeKey.INIT_PAGE };
}

export function getDataList(list: any[]) {
    return { type: SearchTypeKey.GET_DATA_LIST, payload: list };
}

export function loading(bool: boolean) {
    return { type: SearchTypeKey.LOADING, payload: bool };
}

export function modalLoading(bool: boolean) {
    return { type: SearchTypeKey.MODAL_LOADING, payload: bool };
}

export function addModalVisible(bool: boolean) {
    return { type: SearchTypeKey.ADD_MODAL_VISIBLE, payload: bool };
}

export function exportModalVisible(value: boolean) {
    return { type: SearchTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: SearchTypeKey.SUBMIT_EXPORT, payload: value };
}

export function changeDataListStatus(value: any) {
    return { type: SearchTypeKey.CHANGE_DATA_LIST_STATUS, payload: value };
}

export function addDataList(value: any) {
    return { type: SearchTypeKey.ADD_DATA_LIST, payload: value };
}

export function deleteDataList(id: string) {
    return { type: SearchTypeKey.DELETE_DATA_LIST, payload: id };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: SearchTypeKey.UPDATE_PAGE, payload: { pagination, sorter} };
}