import { all, fork, select, takeEvery, put, call, take } from 'redux-saga/effects';
import authorKey from '../../components/user/actions/actionsTypeKey/authorActionsTypeKey';
import * as authorAction from '../../components/user/actions/authorAction';
import * as authorServer from '../../servers/authorServer';
import { openNotification } from '../../components/widgets/Notification';

function* authorList() {
    const state = yield select();
    const author = state.user.author;
    const param = {
        ...author.page,
        ...author.sorter,
        'data': {...author.searchData},
    };
    try {
        yield put(authorAction.loading(true));
        const {result} = yield call(authorServer.getAuthorList, param);
        yield put(authorAction.loading(false));
        yield put(authorAction.getAuthorList(result));
    } catch (err) {
        yield put(authorAction.loading(false));
        openNotification('error', 'Fail to get author list');
    }

}

function* initPage() {
    yield authorList();
}

function* watchInitPage() {
    yield takeEvery(authorKey.INIT_PAGE, initPage);
}

function* watchAuthorSearch() {
    yield takeEvery(authorKey.AUTHOR_SEARCH_PARAM, authorList);
}

function* watchAuthorUpdatePage() {
    yield takeEvery(authorKey.UPDATE_PAGE, authorList);
}

function* getAuthorNovelList() {
    while (true) {
        const { payload } = yield take(authorKey.GET_AUTHOR_ID);
        try {
            const {result} = yield call(authorServer.getAuthorNovelList, payload);
            yield put(authorAction.getAuthorNovelList(result));
        } catch (err) {
            openNotification('error', 'Fail to get novel list');
        }
    }
}

function* authorStateChange() {
    while (true) {
        const { payload } = yield take(authorKey.GET_AUTHOR_STATE_PARAM);
        try {
            yield call(authorServer.authorStateChange, payload);

            yield authorList();
        } catch (err) {
            if (err.code === 216) {
                openNotification('error', 'The user is disabled.');
            } else if (err.code === 208) {
                openNotification('error', 'The user does not exist.');
            } else if (err.code === 223) {
                openNotification('error', 'The user is not authorized.');
            } else if (err.code === 224) {
                openNotification('error', 'Clear user cache error.');
            } else {
                openNotification('error', 'Change failed.');
            }
        }
    }
}

function* exportModel() {
    while (true) {
        const { payload } = yield take(authorKey.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);
        payload.titles.forEach((o: any, idx: number) => {
            if (o.index === 'enabled') {
                o.index = 'enabledValue';
            }

            if (o.index === 'authenticationStatus') {
                o.index = 'authenticationStatusExport';
            }

            if (o.index === 'userName') {
                o.index = 'penName';
            }
        });

        const state = yield select();
        const author = state.user.author;
        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            ...author.sorter,
            data: {...author.searchData},
        };
        try {
            yield put(authorAction.loading(true));
            yield call(authorServer.exportAuthor, param);
            yield put(authorAction.loading(false));
            yield put(authorAction.exportModalVisible(false));
        } catch (err) {
            yield put(authorAction.loading(false));
            openNotification('error', 'Export failed.');
        }
    }
}

export function* watchAuthorSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchAuthorSearch),
        fork(watchAuthorUpdatePage),
        fork(getAuthorNovelList),
        fork(authorStateChange),
        fork(exportModel),
    ]);
}