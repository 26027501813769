import authorActionsTypeKey from '../actions/actionsTypeKey/authorActionsTypeKey';
import {  ActionTypes } from '../actions/actionsType/authorActionsType';

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    page: PageData;
    sorter: any;
    getAuthorList: any[];
    loading: boolean;
    total: number;
    changeUserStatus: boolean;
    searchData: any;
    exportModalVisible: boolean;
    authorNovelList: any[];
    authorDetailNovelList: any[];
}

const initialState: StoreState = {
    loading: false,
    changeUserStatus: false,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    total: 0,
    getAuthorList: [],
    exportModalVisible: false,
    searchData: {},
    authorNovelList: [],
    authorDetailNovelList: [{
        key: '1',
        name: '胡彦斌',
        age: 32,
        address: '西湖区湖底公园1号'
    }, {
        key: '2',
        name: '胡彦祖',
        age: 42,
        address: '西湖区湖底公园1号'
    }]
};

export const authorReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {

        case authorActionsTypeKey.INIT_PAGE:
            return initialState;

        case authorActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case authorActionsTypeKey.GET_AUTHOR_LIST:
            return Object.assign({}, state, {
                getAuthorList: action.payload.data,
                total: action.payload.total
            });

        case authorActionsTypeKey.AUTHOR_SEARCH_PARAM:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case authorActionsTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case authorActionsTypeKey.GET_AUTHOR_NOVEL_LIST:
            return Object.assign({}, state, {
                authorNovelList: action.payload
            });

        case authorActionsTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload
            });

        default:
            return state;
    }
};