import { combineReducers } from 'redux';
import { content } from '../components/content';
import { data } from '../components/data';
import { user } from '../components/user';
import { setting } from  '../components/setting';
import { star } from '../components/starList';
import { creation } from '../components/creation';
import { audit } from '../components/audit';
import { financial } from '../components/financial';
import { webManager } from '../components/webManager';

export default combineReducers({
    content,
    data,
    user,
    setting,
    star,
    creation,
    audit,
    financial,
    webManager
});