enum SearchActionsTypeKey {
    INIT_PAGE = 'SEARCH_MANAGER_INIT_PAGE',
    GET_DATA_LIST = 'GET_SEARCH_MANAGER_DATA_LIST',
    LOADING = 'SEARCH_MANAGER_LOADING',
    MODAL_LOADING = 'SEARCH_MANAGER_MODAL_LOADING',
    ADD_MODAL_VISIBLE = 'SEARCH_MANAGER_ADD_MODAL_VISIBLE',
    EXPORT_MODAL_VISIBLE = 'EXPORT_SEARCH_MANAGER_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'SUBMIT_SEARCH_MANAGER_EXPORT',
    UPDATE_PAGE = 'SEARCH_MANAGER_UPDATE_PAGE',
    CHANGE_DATA_LIST_STATUS = 'CHANGE_SEARCH_MANAGER_DATA_LIST_STATUS',
    DELETE_DATA_LIST = 'DELETE_SEARCH_MANAGER_DATA_LIST',
    ADD_DATA_LIST= 'ADD_SEARCH_MANAGER_DATA_LIST',
}

export default SearchActionsTypeKey;