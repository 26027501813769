enum reportManagerTypeKey {
    INIT_PAGE = 'REPORT_INIT_PAGE',
    UPDATE_PAGE = 'REPORT_UPDATE_PAGE',
    EXPORT_MODAL_VISIBLE = 'REPORT_EXPORT_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'REPORT_SUBMIT_EXPORT',
    LOADING = 'REPORT_LOADING',
    GET_SEARCH_DATA = 'GET_REPORT_SEARCH_DATA',
    GET_REPORT_TABS_KEY = 'GET_REPORT_TABS_KEY',
    GET_REPORT_LIST = 'GET_REPORT_LIST',
    GET_CHAPTER_CON_URL = 'REPORT_GET_CHAPTER_CON_URL',
    GET_CHAPTER_CON_TEXT = 'REPORT_GET_CHAPTER_CON_TEXT',
    GET_CHAPTER_STATUS_CHANGE = 'REPORT_GET_CHAPTER_STATUS_CHANGE',
    REPORT_DRTAIL_TO_LIST = 'REPORT_DRTAIL_TO_LIST'
}

export default reportManagerTypeKey;