import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Input, Table, Form, Row, Col, Popconfirm, Spin, Modal, Button, Radio } from 'antd';
import { Link } from 'react-router-dom';

import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../reducers/typeReducer';
import { getExportTitles } from '../../../base/getExportTitles';
import { isNum, isInt } from '../../../base/regular';
import { openNotification } from '../../widgets/Notification';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

interface TypeContentProps extends FormComponentProps {
    data: StoreState;
    initPage: () => void;
    changeTagPage: (pagination: any, filters: any, sorter: any) => void;
    getTypeSearchParam: (type: any) => void;
    exportModalVisible: (value: boolean) => void;
    submitExport: (value: any) => void;
    addEditType: (param: any) => void;
    getBetchDelTypeId: (param: any) => void;
    settingRuleBtn: () => void;
    getChangeSorterId: () => void;
    getRuleSelected: (select: any) => void;
    changeTagStatus: (value: any) => void;
    changeTagSort: (value: any) => void;
}

interface TypeContentState {
    typeName: string;
    typeList: any[];
    delDisabled: boolean;
    failReasonState: boolean;
    selectedId: string;
    settingRuleState: boolean;
    sortModal: boolean;
    tagId: string;
}

class TypeManager extends React.Component<TypeContentProps , TypeContentState> {

    state: TypeContentState = {
        typeName: '',
        typeList: [],
        delDisabled: true,
        failReasonState: false,
        selectedId: '',
        settingRuleState: false,
        sortModal: false,
        tagId: ''
    };

    componentDidMount() {
        this.props.initPage();
    }

    columns = () => {
        return [
            {
                title: 'Status',
                dataIndex: 'shelfStatus',
                key: 'shelfStatus',
                className: 'column-center',
                render: (text: number) => {
                    return <span>{text === 0 ? 'Hide' : 'Show'}</span>;
                }
            },
            {
                title: 'Location ID',
                dataIndex: 'sort',
                key: 'sort',
                className: 'column-center',
                render: (text: number, record: any) => {
                    return <span style={{cursor: 'pointer', color: '#1890ff'}} onClick={() => {this.sortModalShow(record); }}>{text}</span>;
                }
            },
            {
                title: 'Tag ID',
                dataIndex: 'id',
                key: 'id',
                className: 'column-center',
            },
            {
                title: 'Tag Name',
                dataIndex: 'name',
                key: 'name',
                className: 'column-center',
            },
            {
                title: 'Novel Count',
                dataIndex: 'count',
                key: 'count',
                className: 'column-center',
            },
            {
                title: 'Setting',
                dataIndex: 'edit',
                key: 'edit',
                className: 'column-center',
                render: (text: any, record: any) => {
                    return (
                        <div>
                            <a href="javascript:void(0)" style={{marginRight: '15px'}} onClick={() => {this.typeModalShow(record); }}>Edit</a>
                            <Link
                                to={{
                                    pathname: `/creationManage/tagDetail/${record.id}`,
                                    search: `?name=${record.name}`
                                }}
                                style={{marginRight: '15px'}}
                            >
                                View5
                            </Link>
                            <Popconfirm
                                title="Are you sure change status?"
                                onConfirm={() => {this.changeStatus(record); }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <a href="javascript:void(0)" style={{marginRight: '15px'}}>{record.shelfStatus === 0 ? 'Show' : 'Hide'}</a>
                            </Popconfirm>

                        </div>
                    );
                },
            }
        ];
    }

    typeModalShow = (record: any =  null) => {
        if (record && record.id) {
            this.setState({
                selectedId: record.id,
                typeName: record.name
            });
        } else {
            this.setState({
                selectedId: ''
            });
        }

        this.setState({
            failReasonState: true,
        });

    }

    typeModalClose = () => {
        this.setState({
            failReasonState: false,
            typeName: ''
        });
    }

    typeName = (e: any) => {
        this.setState({typeName: e.target.value});
    }

    addEditType = () => {
        let param = {};
        const { selectedId, typeName } = this.state;
        if (selectedId) {
            param = {
                type: 'edit',
                id: selectedId,
                name: typeName
            };
        } else {
            param = {
                type: 'add',
                name: typeName
            };
        }
        if (isNum(typeName)) {
            if (typeName.length < 15) {
                this.props.addEditType(param);
                this.typeModalClose();
            } else {
                openNotification('error', 'No more than 15 characters');
            }
        }

    }

    settingRuleShow = () => {
        this.setState({
            settingRuleState: true,
        });
        this.props.getRuleSelected('');
        this.props.settingRuleBtn();
    }

    settingRuleClose = () => {
        this.props.getRuleSelected('');
        this.setState({
            settingRuleState: false
        });
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    onSearch = () => {
        const value: any = this.props.form.getFieldsValue();
        this.props.getTypeSearchParam(value.type);
    }

    onClear = () => {
        this.props.form.resetFields();
    }

    delType = () => {
        const { typeList } = this.state;
        this.props.getBetchDelTypeId(typeList);
    }

    radioChange = (e: any) => {
        this.props.getRuleSelected(e.target.value);
    }

    changeSorterRule = () => {
        this.props.getChangeSorterId();
        this.settingRuleClose();
    }

    changeStatus = (record: any) => {
        let param = {
            id: record.id,
            shelfStatus: record.shelfStatus === 0 ? 1 : 0,
        };
        this.props.changeTagStatus(param);
    }

    sortModalShow = (record: any) => {
        this.props.form.setFieldsValue({
            sortId: record.sort
        });
        this.setState({sortModal: true, tagId: record.id});
    }

    sortModalOk = () => {
        let value: any = this.props.form.getFieldsValue(['sortId']);
        let param = {
            tagId: this.state.tagId,
            location: value.sortId
        };
        this.props.changeTagSort(param);
        this.sortModalCancel();
    }

    sortModalCancel = () => {
        this.props.form.setFieldsValue({
            sortId: ''
        });
        this.setState({sortModal: false, tagId: ''});
    }

    rowChange = (selectedRowKeys: any) => {
        if (selectedRowKeys.length > 0) {
            this.setState({
                delDisabled: false
            });
        } else {
            this.setState({
                delDisabled: true
            });
        }
        this.setState({typeList: selectedRowKeys});
    }

    render() {
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { data, changeTagPage } = this.props;
        const { failReasonState, settingRuleState, typeName, delDisabled, sortModal } = this.state;

        const input = {
            style: {width: '100%', color: '#000'}
        };

        const button = { style: {width: '95px', margin : '0 8px 8px 0'}};

        const radioStyle = {
            display: 'block',
            height: '50px',
            lineHeight: '50px',
        };

        const rowSelection = {
            onChange: this.rowChange
        };

        return (
            <div>
                <Spin spinning={data.loading}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormItem
                                    label="Tag"
                                >
                                    {getFieldDecorator('type')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                    <Toolbar
                        showExport={true}
                        onSearch={this.onSearch}
                        onClear={this.onClear}
                        onExport={this.handleExport}
                    >
                        <Button
                            icon="plus"
                            {...button}
                            type="primary"
                            onClick={() => {this.typeModalShow(); }}
                        >
                            Add
                        </Button>

                        <Popconfirm
                            title="Are you sure delete this task?"
                            onConfirm={this.delType}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                icon="delete"
                                {...button}
                                type="danger"
                                disabled={delDisabled}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </Toolbar>

                    <Button
                        type="primary"
                        onClick={this.settingRuleShow}
                    >
                        Setting Rule
                    </Button>
                    <Table
                        rowKey={(record: any) => record.id}
                        bordered={true}
                        columns={this.columns()}
                        style={{marginTop: '20px'}}
                        dataSource={data.typeList}
                        rowSelection={ rowSelection }
                        onChange={changeTagPage}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total:${data.total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={getFieldsValue}
                        visible={data.exportModalVisible}
                        loading={data.loading}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                    />
                </Spin>
                <Modal
                    visible={failReasonState}
                    onCancel={this.typeModalClose}
                    footer={null}
                    maskClosable={false}
                >
                    <Row style={{marginTop: '20px'}}>
                        <Col>
                            <Row>
                                <Col span={4}>
                                    Tag:
                                </Col>
                                <Col span={20}>
                                    <Input value={typeName} onChange={(e: any) => {this.typeName(e); }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{textAlign: 'center', marginTop: '30px'}}>
                            <Button
                                type="primary"
                                onClick={this.addEditType}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Modal>
                <Modal
                    visible={settingRuleState}
                    onCancel={this.settingRuleClose}
                    footer={null}
                    maskClosable={false}
                >
                    <div>
                        <RadioGroup onChange={this.radioChange} value={data.ruleSelected}>
                            {
                                data.sorterRule.map((item: any, index: number) => {
                                    return <Radio key={index} value={item.id} style={radioStyle} >{item.rule}</Radio>;
                                })
                            }
                        </RadioGroup>
                    </div>
                    <div  style={{textAlign: 'right'}}>
                        <Button type="primary" onClick={this.changeSorterRule}>Submit</Button>
                    </div>
                </Modal>
                <Modal
                    visible={sortModal}
                    onOk={this.sortModalOk}
                    onCancel={this.sortModalCancel}
                    maskClosable={false}
                >
                   <Form>
                       <FormItem>
                           {getFieldDecorator('sortId', {
                               rules: [
                                   {validator: isInt}
                               ]
                           })(
                               <Input {...input}/>
                           )}
                       </FormItem>
                   </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(TypeManager);
