import { postJsonForExport } from '../base/base';
import { postJson } from '@iliewen/js-common';

/**
 * 获取公告列表
 */
export function getBulletinList(param: any) {
    return postJson('/bulletin/getBulletinList', param);
}

/**
 * 添加公告
 */
export function addBulletin(param: any) {
    return postJson('/bulletin/addBulletin', param);
}

/**
 * 删除公告
 */
export function deleteBulletin(id: any) {
    return postJson('/bulletin/deleteBulletin', id);
}

/**
 * 获取公告列表
 */
export function exportBulletinList(param: any) {
    return postJsonForExport('/bulletin/exportBulletinList', param, 'bulletin.xlsx');
}

/**
 * 更改公告置顶
 */
export function changeListTop(id: number) {
    return postJson('/bulletin/setTopBulletin', id);
}