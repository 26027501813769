enum WebActionsTypeKey {
    LOADING = 'NOVEL_LOADING',
    INIT_PAGE = 'INIT_WEB_PAGE',
    LIST_DATA_SEARCH = 'WEB_MANAGER_LIST_DATA_SEARCH',
    GET_DATA_LIST = 'GET__WEB_MANAGER_DATA_LIST',
    UPDATE_PAGE = 'WEB_MANAGER_UPDATE_PAGE',
    EXPORT_MODAL_VISIBLE = 'EXPORT_WEB_MANAGER_MODAL_VISIBLE',
    SUBMIT_EXPORT = 'SUBMIT_WEB_MANAGER_EXPORT',
    DELETE_DATA_LIST = 'DELETE_WEB_MANAGER_DATA_LIST',
    CHANGE_LIST_TOP = 'CHANGE_WEB_MANAGER_LIST_TOP'
}

export default WebActionsTypeKey;