import { postJson } from '@iliewen/js-common';
import { postJsonForExport } from '../base/base';

/**
 * 获取小说列表信息
 */
export function getAuditNovelList(param: any) {
    return postJson('/novel/getAuditNovelList', param);
}

export function exportAuditNovelList(param: any) {
    return postJsonForExport('/novel/exportAuditNovelList', param, param.exportName);
}

export function getAuditChapterList(param: any) {
    return postJson('/chapter/getAuditChapterList', param);
}

export function modifyAuditStatus(param: any) {
    return postJson('/chapter/modifyAuditStatus', param);
}
