import RecommendDataActionsTypeKey from '../actions/actionsTypeKey/recommendDataActionsTypeKey';
import { ActionTypes } from '../actions/actionsType/recommendDataActionsType';

export interface SearchData {
    title: string;
    data: any[];
}

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    total: number;
    page: PageData;
    sorter: any;
    searchData: any;
    loading: boolean;
    exportModalVisible: boolean;
    dataList: any[];
}

const initialState: StoreState = {
    total: 0,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    searchData: {},
    dataList: [],
    loading: false,
    exportModalVisible: false,
};

export const recommendDataReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case RecommendDataActionsTypeKey.PAGE_INIT:
            return initialState;

        case RecommendDataActionsTypeKey.GET_DATA_LIST:
            return Object.assign({}, state, {
                dataList: action.payload.data,
                total: action.payload.total
            });

        case RecommendDataActionsTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case RecommendDataActionsTypeKey.LIST_DATA_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case RecommendDataActionsTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize,
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'DESC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC'),
                }
            });

        case RecommendDataActionsTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload,
            });

        default:
            return state;
        }
};