import SearchTypeKey from '../action/actionsTypeKey/searchTypeKey';
import { ActionTypes } from '../action/actionsType/searchActionsType';

export interface StoreState {
    page: PageData;
    dataList: any[];
    loading: boolean;
    modalLoading: boolean;
    addModalVisible: boolean;
    total: number;
    exportModalVisible: boolean;
}

interface PageData {
    current: number;
    pageSize: number;
}

const initialState: StoreState = {
    loading: false,
    modalLoading: false,
    addModalVisible: false,
    dataList: [],
    page: {
        current: 1,
        pageSize: 20,
    },
    total: 0,
    exportModalVisible: false,
};

export const searchReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {

        case SearchTypeKey.INIT_PAGE:
            return initialState;

        case SearchTypeKey.GET_DATA_LIST:
            return Object.assign({}, state, {
                dataList: action.payload,
                total: action.payload.length
            });

        case SearchTypeKey.LOADING:
            return Object.assign({}, state, {
                loading: action.payload,
            });

        case SearchTypeKey.MODAL_LOADING:
            return Object.assign({}, state, {
                modalLoading: action.payload,
            });

        case SearchTypeKey.ADD_MODAL_VISIBLE:
            return Object.assign({}, state, {
                addModalVisible: action.payload,
            });

        case SearchTypeKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload,
            });

        case SearchTypeKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        default:
            return state;
    }
};