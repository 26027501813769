enum dayDataActionsTypeKey {
    EXPORT_MODAL_VISIBLE = 'DAY_DATA_EXPORT_MODAL_VISIBLE',
    DAY_DATA_PAGE_INIT = 'DAY_DATA_PAGE_INIT',
    SUBMIT_DAY_DATA_EXPORT = 'SUBMIT_DAY_DATA_EXPORT',
    DAY_DATA_LOADING = 'DAY_DATA_LOADING',
    GET_DAY_DATA_LIST = 'GET_DAY_DATA_LIST',
    DAY_DATA_LIST_DATA_SEARCH = 'DAY_DATA_LIST_DATA_SEARCH',
    UPDATE_PAGE = 'UPDATE_PAGE_DAY_DATA',
}

export default dayDataActionsTypeKey;