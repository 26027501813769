import { Modal } from 'antd';
import { getLoginConfig } from '../../servers/publicServer';

export default {
    modal(content: string) {
        Modal.warning({
            title: 'Warning',
            content: content,
            onOk() {
                getLoginConfig().then((res: any) => {
                    window.localStorage.removeItem('TOKEN');
                    const redirectUrl = window.location.protocol + '//' + window.location.host;
                    const url =  res.result.loginUrl + '?appCode=' + res.result.appCode + '&redirectUrl=' + redirectUrl;
                    window.location.href = url;
                });
            }
        });
    }
};