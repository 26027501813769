import  typeActionsTypeKey  from './actionsTypeKey/typeActionsTypeKey';

export function initPage() {
    return { type: typeActionsTypeKey.TYPE_INIT_PAGE };
}

export function getTypeList(list: any[]) {
    return { type: typeActionsTypeKey.GET_TYPE_LIST, payload: list };
}

export function getTypeSearchParam(type: any) {
    return { type: typeActionsTypeKey.GET_TYPE_SEARCH_PARAM, payload: type };
}

export function addEditType(param: any) {
    return { type: typeActionsTypeKey.GET_ADD_EDIT_TYPE, payload: param };
}

export function loading(bool: boolean) {
    return { type: typeActionsTypeKey.LOADING, payload: bool };
}

export function exportModalVisible(value: boolean) {
    return { type: typeActionsTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: typeActionsTypeKey.SUBMIT_EXPORT, payload: value };
}

export function getBetchDelTypeId(param: any[]) {
    return { type: typeActionsTypeKey.GET_BETCH_DEL_TYPE_ID, payload: param };
}

export function settingRuleBtn() {
    return { type: typeActionsTypeKey.SETTING_RULE };
}

export function getSorterRule(list: any) {
    return { type: typeActionsTypeKey.GET_SORTER_RULE, payload: list };
}

export function getChangeSorterId() {
    return { type: typeActionsTypeKey.GET_SORTER_CHANGE_ID };
}

export function getRuleSelected(select: any) {
    return { type: typeActionsTypeKey.GET_RULE_SELECTED, payload: select };
}

export function changeTagStatus(value: any) {
    return { type: typeActionsTypeKey.CHANGE_TAG_STATUS, payload: value };
}

export function changeTagSort(value: any) {
    return { type: typeActionsTypeKey.CHANGE_TAG_SORT, payload: value };
}

export function changeTagPage(pagination: any, filters: any, sorter: any) {
    return { type: typeActionsTypeKey.UPDATE_PAGE, payload: { pagination, sorter} };
}