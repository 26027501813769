import reportKey from '../actions/actionsTypeKey/reportManagerypeKey';
import {  ActionType } from '../actions/actionsType/reportManagerType';
// import { versionList } from '../../../servers/responseTypes';

interface PageData {
    current: number;
    pageSize: number;
}

interface ExportData {
    titles: any;
}

export interface ReportManagerState {
    page: PageData;
    sorter: any;
    loading: boolean;
    total: number;
    export: ExportData;
    searchData: any;
    versionModalVisible: boolean;
    exportModalVisible: boolean;
    reportList: any[];
    tabsKey: string;
    chapterCon: string;
    toList: boolean;
}

const initialState: ReportManagerState = {
    loading: false,
    page: {
        current: 1,
        pageSize: 20,
    },
    sorter: {},
    total: 0,
    export: {
        titles: []
    },
    searchData: {},
    versionModalVisible: false,
    exportModalVisible: false,
    tabsKey: '1',
    toList: false,
    chapterCon: '',
    reportList: []
};

export const reportManagerReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case reportKey.INIT_PAGE:
            return initialState;

        case reportKey.LOADING:
            return Object.assign({}, state, {
               loading: action.payload
            });

        case reportKey.EXPORT_MODAL_VISIBLE:
            return Object.assign({}, state, {
                exportModalVisible: action.payload
            });

        case reportKey.GET_SEARCH_DATA:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case reportKey.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize
                },
                sorter: {
                    sortField: Object.keys(action.payload.sorter).length === 0 ?  null : action.payload.sorter.column.sortField,
                    sortOrder: action.payload.sorter === undefined ? 'ASC' : (action.payload.sorter.order === 'descend' ? 'DESC' : 'ASC')
                },
            });

        case reportKey.GET_REPORT_TABS_KEY:
            return Object.assign({}, state, {
                tabsKey: action.payload,
                searchData: {},
                page: {
                    current: 1,
                    pageSize: 20
                },
            });

        case reportKey.GET_REPORT_LIST:
            return Object.assign({}, state, {
                reportList: action.payload.data,
                total: action.payload.total
            });

        case reportKey.GET_CHAPTER_CON_URL:
            return Object.assign({}, state, {
                chapterCon: ''
            });

        case reportKey.GET_CHAPTER_CON_TEXT:
            return Object.assign({}, state, {
               chapterCon: action.payload
            });

        case reportKey.REPORT_DRTAIL_TO_LIST:
            return Object.assign({}, state, {
                toList: action.payload
            });

        default:
            return state;
    }
};