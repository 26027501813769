import { fork, all, put, call, takeEvery, select, take } from 'redux-saga/effects';
import dayDataActionsTypeKey from '../../components/data/actions/actionsTypeKey/dayDataActionsTypeKey';

import { openNotification } from '../../components/widgets/Notification';

import * as  dataServer from '../../servers/dataServer';
import * as dayDataAction from '../../components/data/actions/dayDataAction';

function* initPage() {
    yield getDayDataList();
}

function* getDayDataList() {
    try {
        yield put(dayDataAction.loading(true));
        const state = yield select();
        const data = state.data.dayData;
        const param = {
            ...data.page,
            ...data.sorter,
            data: {...data.searchData}
        };
        let result = yield call(dataServer.getDailyData, param);
        yield put(dayDataAction.getDayDataList({data: result.result.data, total: result.result.total}));
        yield put(dayDataAction.loading(false));
    } catch (error) {
        openNotification('error', 'Fail to get day data list.');
        yield put(dayDataAction.loading(false));
    }
}

function* watchExport() {
    while (true) {
        const { payload } = yield take(dayDataActionsTypeKey.SUBMIT_DAY_DATA_EXPORT);
        const state = yield select();
        const data = state.data.dayData;
        const param = {
            current: payload.page,
            pageSize: payload.pageSize,
            titles: payload.titles,
            data: {...data.searchData},
            ...data.sorter
        };
        try {
            yield put(dayDataAction.loading(true));
            yield call(dataServer.exportDailyData, param);
            yield put(dayDataAction.loading(false));
            yield put(dayDataAction.exportModalVisible(false));
        } catch (error) {
            openNotification('error', 'export fail.');
            yield put(dayDataAction.loading(false));
        }
    }
}

function* watchInitPage() {
    yield takeEvery(dayDataActionsTypeKey.DAY_DATA_PAGE_INIT, initPage);
}

function* watchSearchData() {
    yield takeEvery(dayDataActionsTypeKey.DAY_DATA_LIST_DATA_SEARCH, getDayDataList);
}

function* watchUpdatePageSize() {
    yield takeEvery(dayDataActionsTypeKey.UPDATE_PAGE, getDayDataList);
}

export function* watchDayDataRootSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearchData),
        fork(watchExport),
        fork(watchUpdatePageSize),
    ]);
}