import  VersionContentActionTypeKeys  from './actionsTypeKey/actionTypeKey';
import { versionList } from  '../../../servers/responseTypes';

export function initPage() {
    return { type: VersionContentActionTypeKeys.VERSION_CONTNET_INIT_PAGE };
}

export function updatePage(pagination: any, filters: any, sorter: any) {
    return { type: VersionContentActionTypeKeys.UPDATE_PAGE, payload: { pagination, sorter } };
}

export function getVersionList(value: {data: versionList, total: number}) {
    return { type: VersionContentActionTypeKeys.GET_VERSION_LIST, payload: value };
}

export function loading(bool: boolean) {
    return { type: VersionContentActionTypeKeys.LOADING, payload: bool };
}

export function exportModalVisible(value: boolean) {
    return { type: VersionContentActionTypeKeys.EXPORT_MODAL_VISIBLE, payload: value };
}

export function changeVersionStatus(value: any) {
    return { type: VersionContentActionTypeKeys.CHANGE_VERSION_STATUS, payload: value };
}

export function deleteVersionList(value: string[]) {
    return { type: VersionContentActionTypeKeys.DELETE_VERSION_LIST_DATA, payload: value };
}

export function addVersionModalVisible(value: boolean) {
    return { type: VersionContentActionTypeKeys.VERSION_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: VersionContentActionTypeKeys.SUBMIT_EXPORT, payload: value };
}

export function versionListSearch(value: any) {
    return { type: VersionContentActionTypeKeys.VERSION_LIST_DATA_SEARCH, payload: value };
}

export function addVersionData(value: any) {
    return { type: VersionContentActionTypeKeys.ADD_VERSION_DATA, payload: value };
}