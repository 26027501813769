import bookShelfActionTypeKey from './actionsTypeKey/bookShelfActionTypeKey';

export function initPage() {
    return { type: bookShelfActionTypeKey.INIT_PAGE };
}

export function getUpdatePage(pagination: any, filters: any, sorter: any) {
    return { type: bookShelfActionTypeKey.CHANGE_PAGE, payload: { pagination, sorter} };
}

export function exportModalVisible(value: boolean) {
    return { type: bookShelfActionTypeKey.EXPORT_MODAL_VISIBLE, payload: value };
}

export function submitExport(value: any) {
    return { type: bookShelfActionTypeKey.SUBMIT_EXPORT, payload: value };
}

export function loading(value: boolean) {
    return { type: bookShelfActionTypeKey.LOADING, payload: value };
}

export function getSearchData(param: any) {
    return { type: bookShelfActionTypeKey.SEARCH_PARAM, payload: param };
}

export function getModalSearchParam(param: any) {
    return { type: bookShelfActionTypeKey.SEARCH_MODAL_PARAM, payload: param };
}

export function getModalSearchNovelList(param: any) {
    return { type: bookShelfActionTypeKey.SEARCH_MODAL_NOVEL_LIST, payload: param };
}

export function getDataList(list: any) {
    return { type: bookShelfActionTypeKey.DATA_LIST, payload: list };
}

export function getTypeList(param: any) {
    return { type: bookShelfActionTypeKey.GET_TYPE_LIST, payload: param };
}

export function getAddOrChangeParam(param: any) {
    return { type: bookShelfActionTypeKey.GET_ADD_OR_CHANGE_PARAM, payload: param };
}

export function getDelNovelId(id: string | number) {
    return { type: bookShelfActionTypeKey.GET_DEL_NOVEL_ID, payload: id };
}

export function getSortNumber(param: any) {
    return { type: bookShelfActionTypeKey.GET_SORT_NUMBER, payload: param };
}