import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Table, Form, Row, Col,  Select, Spin, Input, DatePicker } from 'antd';
import moment from 'moment';

import { Toolbar } from '../../widgets/Toolbar';
import { ExportModal }  from '../../widgets/ExportModal';
import { StoreState } from '../reducers/novelDataReducer';
import { getExportTitles } from '../../../base/getExportTitles';
import { changeToStamp, timeReverseWithNoHour } from '../../../base/dealWithTime';
import { numFormat } from '../../../base/regular';

const FormItem = Form.Item;
const Option = Select.Option;

interface NovelDataProps extends FormComponentProps {
    data: StoreState;
    initPage: () => void;
    submitExport: (value: any) => void;
    exportModalVisible: (value: boolean) => void;
    novelDataListDataSearch: (value: any) => void;
    updatePage: (pagination: any, filters: any, sorter: any) => void;
}

interface NovelDataState {
    id: number;
    startTime: string;
    endTime: string;
    novelType: any[];
    signed: any[];
    defaultTime: any;
}

class NovelData extends React.Component<NovelDataProps, NovelDataState> {

    state: NovelDataState = {
        id: -1,
        startTime: '',
        endTime: '',
        novelType: ['Original', 'Reprint', 'PGC', 'Star Novel'],
        signed: ['Kontrak digital', 'Kontrak tertulis'],
        defaultTime: moment() .add(-1, 'day'),
    };

    componentDidMount() {
        this.props.initPage();
    }

    columns = () => {
        return [
            {
                title: 'Date',
                dataIndex: 'createdDate',
                key: 'createdDate',
                className: 'column-center',
                fixed: true,
                width: 110
            },
            {
                title: 'Novel ID',
                dataIndex: 'id',
                key: 'id',
                className: 'column-center',
                fixed: true,
                width: 110,
            },
            {
                title: 'Novel Name',
                dataIndex: 'name',
                key: 'name',
                className: 'column-center',
                fixed: true,
                width: 300,
            },
            {
                title: 'Category',
                dataIndex: 'typeName',
                key: 'typeName',
                className: 'column-center',
            },
            {
                title: 'Author',
                dataIndex: 'author',
                key: 'author',
                className: 'column-center',
            },
            {
                title: 'Type',
                dataIndex: 'novelType',
                key: 'novelType',
                className: 'column-center',
                render: (text: number) => (<span>{this.state.novelType[text]}</span>)
            },
            {
                title: 'Signing',
                dataIndex: 'signed',
                key: 'signed',
                className: 'column-center',
                render: (text: number | string, record: any) => {
                    if (record.novelType === 3) {
                        return <span>{this.state.signed[text]}</span>;
                    } else {
                        return '--';
                    }
                }
            },
            {
                title: 'Writing Stars',
                dataIndex: 'writingStar',
                key: 'writingStar',
                className: 'column-center',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Reader Vote',
                dataIndex: 'voteStar',
                key: 'voteStar',
                className: 'column-center',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Voters',
                dataIndex: 'voteUserCount',
                key: 'voteUserCount',
                className: 'column-center',
                sorter: true,
                sortField: 'voteUserCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Click',
                dataIndex: 'clickCount',
                key: 'clickCount',
                className: 'column-center',
                sorter: true,
                sortField: 'clickCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Clickers',
                dataIndex: 'clickPeopleCount',
                key: 'clickPeopleCount',
                className: 'column-center',
                sorter: true,
                sortField: 'clickPeopleCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Subscribe',
                dataIndex: 'subscribeCount',
                key: 'subscribeCount',
                className: 'column-center',
                sorter: true,
                sortField: 'subscribeCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Subscribers',
                dataIndex: 'subscribePeopleCount',
                key: 'subscribePeopleCount',
                className: 'column-center',
                sorter: true,
                sortField: 'subscribePeopleCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Read Times',
                dataIndex: 'pageview',
                key: 'pageview',
                className: 'column-center',
                sorter: true,
                sortField: 'pageview',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Readers',
                dataIndex: 'readUserCount',
                key: 'readUserCount',
                className: 'column-center',
                sorter: true,
                sortField: 'readUserCount',
                render: (text: number) => (<span>{text ? numFormat(text) : 0}</span>)
            },
            {
                title: 'Per capita reading time',
                dataIndex: 'readPerTime',
                key: 'readPerTime',
                className: 'column-center',
                sorter: true,
                sortField: 'readPerTime',
            },
        ];
    }

    startTimeChange = (value: any, startTime: string) => {
        if (value) {
            this.setState({startTime: timeReverseWithNoHour(startTime) + ' 00:00:00'});
        } else {
            this.setState({startTime});
        }
    }

    endTimeChange = (value: any, endTime: string) => {
        if (value) {
            this.setState({endTime: timeReverseWithNoHour(endTime) + ' 23:59:59'});
        } else {
            this.setState({endTime});
        }
    }

    handleExport = () => {
        this.props.exportModalVisible(true);
    }

    closeExportModal = () => {
        this.props.exportModalVisible(false);
    }

    submitExport = (value: any) => {
        const titles = getExportTitles(this.columns());
        this.props.submitExport({...value, titles});
    }

    clear = () => {
        this.props.form.resetFields();
        this.setState({startTime: ''});
        this.setState({endTime: ''});
    }

    onSearch = () => {
        let defaultStart = this.state.defaultTime.format('YYYY-MM-DD') + ' 00:00:00',
            defaultEnd = this.state.defaultTime.format('YYYY-MM-DD') + ' 23:59:59';
        const value: any = this.props.form.getFieldsValue();
        const param: any = {
            gtTime: this.state.startTime.length === 0 ? changeToStamp(defaultStart) / 1000 : changeToStamp(this.state.startTime) / 1000,
            ltTime: this.state.endTime.length === 0 ? changeToStamp(defaultEnd) / 1000 : changeToStamp(this.state.endTime) / 1000,
            typeId: value.category_search,
            name: value.novelName_search,
            id: value.novelId_search,
            signed: value.signed_search,
            device: value.device_search,
            novelType: value.novelType_search
        };

        for (let item in param) {
            if (param[item] === 'All' || param[item] === '') {
                delete param[item];
            }
        }

        this.props.novelDataListDataSearch(param);
    }

    render() {
        const { defaultTime } = this.state;
        const {getFieldDecorator, getFieldsValue } = this.props.form;
        const {data, updatePage } = this.props;

        const worksTypeList = [
            {key: 'All', value: 'All'},
            {key: '0', value: 'Original'},
            {key: '1', value: 'Reprint'},
            {key: '2', value: 'PGC'},
            {key: '3', value: 'Star Novel'},
        ];

        const signingList = [
            {key: 'All', value: 'All'},
            {key: '1', value: 'Kontrak tertulis'},
            {key: '0', value: 'Kontrak digital'}
        ];

        const input = {
            style: {width: '100%', color: '#000'}
        };
        const form = {
            style: {margin: '10px 30px'}
        };

        return (
            <div>
                {/*{data.loading}*/}
                <Spin spinning={false}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={6}>
                                <FormItem label="Date" {...form}>
                                    {getFieldDecorator('startTime', {
                                        initialValue: defaultTime
                                    })(
                                        <DatePicker
                                            allowClear={true}
                                            format={'DD-MM-YYYY'}
                                            placeholder={'Start Time'}
                                            style={{width : '48%'}}
                                            onChange={this.startTimeChange}
                                        />
                                    )}
                                    {getFieldDecorator('endTime', {
                                        initialValue: defaultTime
                                    })(
                                        <DatePicker
                                            allowClear={true}
                                            format={'DD-MM-YYYY'}
                                            placeholder={'End Time'}
                                            style={{width : '48%', marginLeft : '4%'}}
                                            onChange={this.endTimeChange}
                                        />
                                    )}
                                </FormItem>
                            </Col>

                            <Col span={6}>
                                <FormItem
                                    label="Type"
                                    {...form}
                                >
                                    {getFieldDecorator('novelType_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {worksTypeList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Signing"
                                    {...form}
                                >
                                    {getFieldDecorator('signed_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            {signingList.map(item => <Option key={item.key}>{item.value}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Novel Name"
                                    {...form}
                                >
                                    {getFieldDecorator('novelName_search')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Novel ID"
                                    {...form}
                                >
                                    {getFieldDecorator('novelId_search')(
                                        <Input {...input}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Device"
                                    {...form}
                                >
                                    {getFieldDecorator('device_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select>
                                            <Option value="All">All</Option>
                                            <Option value="1">iOS</Option>
                                            <Option value="0">Android</Option>

                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                    label="Category"
                                    {...form}
                                >
                                    {getFieldDecorator('category_search', {
                                        initialValue: 'All'
                                    })(
                                        <Select {...input}>
                                            <Option key="All">All</Option>
                                            {
                                                data.typeList.map(item => {
                                                    return <Option key={item.id}>{item.name}</Option>;
                                                })
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                    <Toolbar showExport={true} onSearch={this.onSearch} onClear={this.clear} onExport={this.handleExport}/>
                    <Table
                        loading={data.loading}
                        bordered={true}
                        rowKey="questionId"
                        columns={this.columns()}
                        style={{marginTop: '20px'}}
                        dataSource={data.novelDataList}
                        onChange={updatePage}
                        scroll={{ x: 2400 }}
                        pagination={{
                            defaultPageSize: 20,
                            total: data.total,
                            current: data.page.current,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '80', '100'],
                            showTotal: total => {
                                return `Total: ${total} items`;
                            },
                        }}
                    />
                    <ExportModal
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={getFieldsValue}
                        visible={data.exportModalVisible}
                        loading={data.loading}
                        onCancel={this.closeExportModal}
                        submitExport={this.submitExport}
                    />
                </Spin>
            </div>
        );
    }
}

export default Form.create()(NovelData);
