import  ChapterData  from '../ChapterData';
import { connect } from 'react-redux';
import * as chapterDataAction from '../../actions/chapterDataAction';

const mapStateToProps = (state: any) => ({
    data: state.data.chapterData
});

const mapDispatchToProps = {
    initPage: chapterDataAction.initPage,
    updatePage: chapterDataAction.updatePage,
    loading: chapterDataAction.loading,
    listDataSearch: chapterDataAction.listDataSearch,
    exportModalVisible: chapterDataAction.exportModalVisible,
    submitExport: chapterDataAction.submitExport,
};

export function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ChapterData);