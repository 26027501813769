import { fork, all, select, takeEvery, put, call, take } from 'redux-saga/effects';
import SearchActionsTypeKey from '../../components/creation/action/actionsTypeKey/searchTypeKey';
import * as searchAction from '../../components/creation/action/searchAction';
import * as searchServer from '../../servers/searchServer';
import { openNotification } from '../../components/widgets/Notification';

function* getDataList() {
    const state = yield select();
    const type = state.creation.search;
    const param = type.searchData;
    try {
        yield put(searchAction.loading(true));
        const {result} = yield call(searchServer.getSearchList, param);
        yield put(searchAction.getDataList(result));
        yield put(searchAction.loading(false));
    } catch (err) {
        yield put(searchAction.loading(false));
        openNotification('error', 'Fail to get Category List');
    }
}

function* watchAddDataList() {
    while (true) {
        try {
            const { payload } = yield take(SearchActionsTypeKey.ADD_DATA_LIST);
            yield call(searchServer.addSearch, payload);
            yield put(searchAction.addModalVisible(false));
            openNotification('success', 'Add search word success.');
            yield getDataList();
        } catch (err) {
            openNotification('error', 'Failed to add search word.');
        }
    }
}

function* watchChangeDataListStatus() {
    while (true) {
        try {
            const { payload } = yield take(SearchActionsTypeKey.CHANGE_DATA_LIST_STATUS);
            yield call(searchServer.modifySearchStatus, payload);
            openNotification('success', 'Change search word status success.');
            yield getDataList();
        } catch (err) {
            openNotification('error', 'Failed change search word status.');
        }
    }
}

function* watchExportData() {
    while (true) {
        const { payload } = yield take(SearchActionsTypeKey.SUBMIT_EXPORT);
        payload.titles.splice(-1, 1);
        payload.titles.splice(0, 1, {title: 'Status', index: 'statusValue'});
        const state = yield select();
        const type = state.creation.search;
        const param = {
            current: 1,
            pageSize: 20,
            titles: payload.titles,
            data: {
                ...type.searchData
            },
        };
        try {
            yield put(searchAction.loading(true));
            yield call(searchServer.searchListExport, param);
            yield put(searchAction.loading(false));
            yield put(searchAction.exportModalVisible(false));
        } catch (err) {
            yield put(searchAction.loading(false));
            yield put(searchAction.exportModalVisible(false));
            openNotification('error', 'Export failed.');
        }
    }
}

function* watchDeleteDataList() {
    while (true) {
        const { payload } = yield take(SearchActionsTypeKey.DELETE_DATA_LIST);
        try {
            yield call(searchServer.deleteSearch, payload);
            openNotification('success', 'Delete success.');
            yield getDataList();
        } catch (err) {
            openNotification('error', 'Delete failed.');

        }
    }
}

function* initPage() {
    yield getDataList();
}

function* watchInitPage() {
    yield takeEvery(SearchActionsTypeKey.INIT_PAGE, initPage);
}

function* watchUpdatePageSize() {
    yield takeEvery(SearchActionsTypeKey.UPDATE_PAGE, getDataList);
}

export function* watchSearchManagerSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchAddDataList),
        fork(watchExportData),
        fork(watchDeleteDataList),
        fork(watchChangeDataListStatus),
        fork(watchUpdatePageSize)
    ]);
}